import React from "react";
import {
  Box,
  TextField,
  Typography,
  Button,
  Select,
  MenuItem,
} from "@mui/material";
import CircularProgress from "@mui/material/CircularProgress";
import Backdrop from "@mui/material/Backdrop";
import { makeStyles } from "@mui/styles";
import Snackbar from "@mui/material/Snackbar";
import MuiAlert from "@mui/material/Alert";
import PricesDetailsKTSilver from "src/components/AleynaComponents/PricesDetailsKTSilver";
import PricesDetailsKTPlatin from "src/components/AleynaComponents/pricesDetailsKTPlatin";
import PricesDetailsKTAltin from "src/components/AleynaComponents/PricesDetailsKTAltin";
import PricesDetailsFintagAltin from "src/components/AleynaComponents/PricesDetailsFintagAltin";
import PricesDetailsFintagSilver from "src/components/AleynaComponents/PricesDetailsFintagSilver";
import PricesDetailsFintagPlatin from "src/components/AleynaComponents/PricesDetailsFintagPlatin";
import { pageContainer } from "src/utils/customStyles";

export default function Price() {
  const [fetchUrl, setFetchUrl] = React.useState(
    sessionStorage.getItem("FetchUrl")
  );
  const [authCode, setAuthCode] = React.useState(
    sessionStorage.getItem("AuthCode")
  );

  const classes = useStyles();

  // SnackBar
  const [alertmessage, setAlertMessage] = React.useState("");
  const [alertStatus, setAlertStatus] = React.useState(false);
  const [openAlert, setOpenAlert] = React.useState(false);

  const Alert = React.forwardRef(function Alert(props, ref) {
    return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
  });

  const handleAlertClick = () => {
    setOpenAlert(true);
  };

  const handleAlertClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }

    setOpenAlert(false);
  };

  // Indicator
  const [open, setOpen] = React.useState(false);

  // Status
  const [provider, setProvider] = React.useState("");
  const [providerStatus, setProviderStatus] = React.useState("");

  // Fintag
  const [fintagId, setFintagId] = React.useState(
    "40B16FE1-CE48-4846-B380-635EFB36E97A"
  );

  // KT
  const [kuveytTürkId, setKuveytTürkId] = React.useState(
    "4BEA4F9A-6467-4117-85C5-22C79E413C83"
  );
  const [kTGoldPrices, setKTGoldPrices] = React.useState([]);
  const [kTSilverPrices, setKTSilverPrices] = React.useState([]);
  const [kTPlatinPrices, setKTPlatinPrices] = React.useState([]);

  // Prices - Sell Buy dışında hepsi aynı yerden değişiyor.

  const [updateCheckFintag, setUpdateCheckFintag] = React.useState(false);
  const [statusCheck, setStatusCheck] = React.useState(false);

  const [altinMessage, setAltinMessage] = React.useState("");
  const [gumusMessage, setGumusMessage] = React.useState("");
  const [platinMessage, setPlatinMessage] = React.useState("");

  //dropdown
  const [selectedOptionAltin, setSelectedOptionAltin] = React.useState("");
  const [selectedOptionGumus, setSelectedOptionGumus] = React.useState("");
  const [selectedOptionPlatin, setSelectedOptionPlatin] = React.useState("");

  // Keys
  const [goldKey, setGoldKey] = React.useState("");
  const [silverKey, setSilverKey] = React.useState("");
  const [platinKey, setPlatinKey] = React.useState("");

  const [goldKeyKT, setGoldKeyKT] = React.useState("");
  const [silverKeyKT, setSilverKeyKT] = React.useState("");
  const [platinKeyKT, setPlatinKeyKT] = React.useState("");

  const [goldValueFintag, setGoldValueFintag] = React.useState("");
  const [silverValueFintag, setSilverValueFintag] = React.useState("");
  const [platinValueFintag, setPlatinValueFintag] = React.useState("");

  const [goldValueKT, setGoldValueKT] = React.useState("");
  const [silverValueKT, setSilverValueKT] = React.useState("");
  const [platinValueKT, setPlatinValueKT] = React.useState("");

  const [refresh, setRefresh] = React.useState(false);

  const handleChangeAltin = (event) => {
    setSelectedOptionAltin(event.target.value);
  };

  const handleChangeGumus = (event) => {
    setSelectedOptionGumus(event.target.value);
  };

  const handleChangePlatin = (event) => {
    setSelectedOptionPlatin(event.target.value);
  };

  // 5 Basamak kontrolü
  function kisitliOndalikSayi(sayi, basamakSayisi) {
    const ondalikKatsayisi = 10 ** basamakSayisi;
    return Math.floor(sayi * ondalikKatsayisi) / ondalikKatsayisi;
  }

  // Hangi Provider aktif , ve fiyatları çekmek için
  React.useEffect(() => {
    getStatusOfProviders();
  }, [statusCheck]);

  React.useEffect(() => {
    setTimeout(() => {
      setAltinMessage("");
      setGumusMessage("");
      setPlatinMessage("");
    }, 5000);
  }, [updateCheckFintag, platinMessage]);

  // Sekme değişince değerler sıfırlansın diye.
  React.useEffect(() => {
    setAltinMessage("");
    setGumusMessage("");
    setPlatinMessage("");

    setGoldKey("");
    setSilverKey("");
    setPlatinKey("");

    setGoldKeyKT("");
    setSilverKeyKT("");
    setPlatinKeyKT("");

    setGoldValueFintag("");
    setSilverValueFintag("");
    setPlatinValueFintag("");

    setGoldValueKT("");
    setSilverValueKT("");
    setPlatinValueKT("");

    setStatusCheck(false);
  }, [provider]);

  // Provider Status için
  async function getStatusOfProviders() {
    var myHeaders = new Headers();
    myHeaders.append("Authorization", authCode);

    var requestOptions = {
      method: "GET",
      headers: myHeaders,
      redirect: "follow",
    };

    const response = await fetch(
      `${fetchUrl}Admin/GetAllProvider?pageNumber=1`,
      requestOptions
    );
    const fetchedData = await response.json();
    const myData = fetchedData.Data;

    for (let o = 0; o < myData.length; o++) {
      if (myData[o].Name === "Fintag") {
        if (myData[o].IsActive === "True") {
          setProvider("Fintag");
          setProviderStatus("Fintag");
        }
      }

      if (myData[o].Name === "KuveytTürk") {
        if (myData[o].IsActive === "True") {
          setProvider("KuveytTürk");
          setProviderStatus("KuveytTürk");
        }
      }
    }
  }

  // Update Prices
  // async function makeProviderActivePassive(choosenVendor) {
  //   setOpen(true);
  //   var myHeaders = new Headers();
  //   myHeaders.append("Authorization", authCode);

  //   var requestOptions = {
  //     method: "POST",
  //     headers: myHeaders,
  //     redirect: "follow",
  //   };

  //   const response = await fetch(
  //     `${fetchUrl}Provider/MakeActive?id=${choosenVendor}`,
  //     requestOptions
  //   );
  //   const fetchedData = await response.json();
  //   setStatusCheck(true);

  //   if (fetchedData.Success == true) {
  //     setTimeout(() => {
  //       setOpen(false);
  //     }, 1000);
  //   } else if (fetchedData.Success == false) {
  //     setTimeout(() => {
  //       setOpen(false);
  //     }, 1000);
  //   }
  // }

  // Update Gold Price Fintag
  async function updateGoldPrice() {
    setAltinMessage("");
    setGumusMessage("");
    setPlatinMessage("");
    setOpen(true);
    if (goldKey === 9 || goldKey === 8 || goldKey === 7 || goldKey === 6) {
      if (goldValueFintag >= 1 || goldValueFintag < 0) {
        setAlertMessage("Lütfen 0 ve 1 aralığında bir sayı girin.");
        setAlertStatus(false);
        handleAlertClick();
      } else {
        var myHeaders = new Headers();
        myHeaders.append("Authorization", authCode);
        myHeaders.append("Content-Type", "application/json");

        var raw = JSON.stringify({
          FieldType: goldKey,
          FieldValue: kisitliOndalikSayi(goldValueFintag, 4),
        });

        var requestOptions = {
          method: "POST",
          headers: myHeaders,
          body: raw,
          redirect: "follow",
        };
        const response = await fetch(
          `${fetchUrl}Admin/UpdateFintagPriceValue?commodityId=7BE7C383-03E0-4A29-9564-1772BF0E996E`,
          requestOptions
        );
        const fetchedDataPrice = await response.json();

        if (fetchedDataPrice.Success === true) {
          setAlertMessage(fetchedDataPrice.Message);
          setAlertStatus(fetchedDataPrice.Success);
          handleAlertClick();
          setOpen(false);
        } else if (fetchedDataPrice.Success === false) {
          setAlertMessage(fetchedDataPrice.Message);
          setAlertStatus(fetchedDataPrice.Success);
          handleAlertClick();
          setOpen(false);
        }
      }
    } else {
      var myHeaders = new Headers();
      myHeaders.append("Authorization", authCode);
      myHeaders.append("Content-Type", "application/json");

      var raw = JSON.stringify({
        FieldType: goldKey,
        FieldValue: goldValueFintag,
      });

      var requestOptions = {
        method: "POST",
        headers: myHeaders,
        body: raw,
        redirect: "follow",
      };
      const response = await fetch(
        `${fetchUrl}Admin/UpdateFintagPriceValue?commodityId=7BE7C383-03E0-4A29-9564-1772BF0E996E`,
        requestOptions
      );
      const fetchedDataPrice = await response.json();

      if (fetchedDataPrice.Success === true) {
        setAlertMessage(fetchedDataPrice.Message);
        setAlertStatus(fetchedDataPrice.Success);
        handleAlertClick();
        setOpen(false);
      } else if (fetchedDataPrice.Success === false) {
        setAlertMessage(fetchedDataPrice.Message);
        setAlertStatus(fetchedDataPrice.Success);
        handleAlertClick();
        setOpen(false);
      }
    }

    setOpen(false);
    handleClick();
  }

  // Update Silver Price Fintag
  async function updateSilverPrice() {
    var myHeaders = new Headers();
    myHeaders.append("Authorization", authCode);
    myHeaders.append("Content-Type", "application/json");
    setAltinMessage("");
    setGumusMessage("");
    setPlatinMessage("");
    setOpen(true);
    if (
      silverKey === 9 ||
      silverKey === 8 ||
      silverKey === 7 ||
      silverKey === 6
    ) {
      if (silverValueFintag >= 1 || silverValueFintag < 0) {
        setAlertMessage("Lütfen 0 ve 1 aralığında bir sayı girin.");
        setAlertStatus(false);
        handleAlertClick();
      } else {
        var raw = JSON.stringify({
          FieldType: silverKey,
          FieldValue: kisitliOndalikSayi(silverValueFintag, 4),
        });

        var requestOptions = {
          method: "POST",
          headers: myHeaders,
          body: raw,
          redirect: "follow",
        };

        const response = await fetch(
          `${fetchUrl}Admin/UpdateFintagPriceValue?commodityId=5D713008-15BB-4509-BE0D-B2F9355E8FF4`,
          requestOptions
        );
        const fetchedDataPrice = await response.json();

        if (fetchedDataPrice.Success === true) {
          setAlertMessage(fetchedDataPrice.Message);
          setAlertStatus(fetchedDataPrice.Success);
          handleAlertClick();
          setOpen(false);
        } else if (fetchedDataPrice.Success === false) {
          setAlertMessage(fetchedDataPrice.Message);
          setAlertStatus(fetchedDataPrice.Success);
          handleAlertClick();
          setOpen(false);
        }
      }
    } else {
      var raw = JSON.stringify({
        FieldType: silverKey,
        FieldValue: silverValueFintag,
      });

      var requestOptions = {
        method: "POST",
        headers: myHeaders,
        body: raw,
        redirect: "follow",
      };

      const response = await fetch(
        `${fetchUrl}Admin/UpdateFintagPriceValue?commodityId=5D713008-15BB-4509-BE0D-B2F9355E8FF4`,
        requestOptions
      );
      const fetchedDataPrice = await response.json();

      if (fetchedDataPrice.Success === true) {
        setAlertMessage(fetchedDataPrice.Message);
        setAlertStatus(fetchedDataPrice.Success);
        handleAlertClick();
        setOpen(false);
      } else if (fetchedDataPrice.Success === false) {
        setAlertMessage(fetchedDataPrice.Message);
        setAlertStatus(fetchedDataPrice.Success);
        handleAlertClick();
        setOpen(false);
      }
    }

    handleClick();
  }

  // Update Platin Price Fintag
  async function updatePlatinPrice() {
    var myHeaders = new Headers();
    myHeaders.append("Authorization", authCode);
    myHeaders.append("Content-Type", "application/json");
    setAltinMessage("");
    setGumusMessage("");
    setPlatinMessage("");
    setOpen(true);
    if (
      platinKey === 9 ||
      platinKey === 8 ||
      platinKey === 7 ||
      platinKey === 6
    ) {
      if (platinValueFintag >= 1 || platinValueFintag < 0) {
        setAlertMessage("Lütfen 0 ve 1 aralığında bir sayı girin.");
        setAlertStatus(false);
        handleAlertClick();
      } else {
        var raw = JSON.stringify({
          FieldType: platinKey,
          FieldValue: kisitliOndalikSayi(platinValueFintag, 4),
        });
        var requestOptions = {
          method: "POST",
          headers: myHeaders,
          body: raw,
          redirect: "follow",
        };
        const response = await fetch(
          `${fetchUrl}Admin/UpdateFintagPriceValue?commodityId=8FC928D5-D9F9-4ABD-9859-567883AA3F06`,
          requestOptions
        );
        const fetchedDataPrice = await response.json();

        if (fetchedDataPrice.Success === true) {
          setAlertMessage(fetchedDataPrice.Message);
          setAlertStatus(fetchedDataPrice.Success);
          handleAlertClick();
          setOpen(false);
        } else if (fetchedDataPrice.Success === false) {
          setAlertMessage(fetchedDataPrice.Message);
          setAlertStatus(fetchedDataPrice.Success);
          handleAlertClick();
          setOpen(false);
        }

        setUpdateCheckFintag(true);
        setTimeout(() => {
          setAltinMessage("");
          setGumusMessage("");
          setPlatinMessage("");
        }, 4000);
      }
    } else {
      var raw = JSON.stringify({
        FieldType: platinKey,
        FieldValue: platinValueFintag,
      });
      var requestOptions = {
        method: "POST",
        headers: myHeaders,
        body: raw,
        redirect: "follow",
      };
      const response = await fetch(
        `${fetchUrl}Admin/UpdateFintagPriceValue?commodityId=8FC928D5-D9F9-4ABD-9859-567883AA3F06`,
        requestOptions
      );
      const fetchedDataPrice = await response.json();

      if (fetchedDataPrice.Success === true) {
        setAlertMessage(fetchedDataPrice.Message);
        setAlertStatus(fetchedDataPrice.Success);
        handleAlertClick();
        setOpen(false);
      } else if (fetchedDataPrice.Success === false) {
        setAlertMessage(fetchedDataPrice.Message);
        setAlertStatus(fetchedDataPrice.Success);
        handleAlertClick();
        setOpen(false);
      }

      setUpdateCheckFintag(true);
      setTimeout(() => {
        setAltinMessage("");
        setGumusMessage("");
        setPlatinMessage("");
      }, 4000);
    }

    handleClick();
  }

  //////////////////////////////////

  // Update Gold Price Kuveyttürk
  async function updateGoldPrice2() {
    setAltinMessage("");
    setGumusMessage("");
    setPlatinMessage("");
    setOpen(true);

    var myHeaders = new Headers();
    myHeaders.append("Authorization", authCode);
    myHeaders.append("Content-Type", "application/json");

    if (
      goldKeyKT === 9 ||
      goldKeyKT === 8 ||
      goldKeyKT === 7 ||
      goldKeyKT === 6
    ) {
      if (goldValueKT >= 1 || goldValueKT < 0) {
        setAlertMessage("Lütfen 0 ve 1 aralığında bir sayı girin.");
        setAlertStatus(false);
        handleAlertClick();
      } else {
        var raw = JSON.stringify({
          FieldType: goldKeyKT,
          FieldValue: kisitliOndalikSayi(goldValueKT, 4),
        });

        var requestOptions = {
          method: "POST",
          headers: myHeaders,
          body: raw,
          redirect: "follow",
        };

        const response = await fetch(
          `${fetchUrl}Admin/UpdateProviderPriceValue?providerId=4BEA4F9A-6467-4117-85C5-22C79E413C83&commodityId=7BE7C383-03E0-4A29-9564-1772BF0E996E`,
          requestOptions
        );
        const fetchedDataPrice = await response.json();
        const myDataSuccess = fetchedDataPrice.Success;
        const myDataMessage = fetchedDataPrice.Message;

        if (fetchedDataPrice.Success === true) {
          setAlertMessage(fetchedDataPrice.Message);
          setAlertStatus(fetchedDataPrice.Success);
          handleAlertClick();
          setOpen(false);
        } else if (myDataSuccess === false) {
          setAlertMessage(fetchedDataPrice.Message);
          setAlertStatus(fetchedDataPrice.Success);
          handleAlertClick();
          setOpen(false);
        }
      }
    } else {
      var raw = JSON.stringify({
        FieldType: goldKeyKT,
        FieldValue: goldValueKT,
      });

      var requestOptions = {
        method: "POST",
        headers: myHeaders,
        body: raw,
        redirect: "follow",
      };

      const response = await fetch(
        `${fetchUrl}Admin/UpdateProviderPriceValue?providerId=4BEA4F9A-6467-4117-85C5-22C79E413C83&commodityId=7BE7C383-03E0-4A29-9564-1772BF0E996E`,
        requestOptions
      );
      const fetchedDataPrice = await response.json();
      const myDataSuccess = fetchedDataPrice.Success;
      const myDataMessage = fetchedDataPrice.Message;

      if (fetchedDataPrice.Success === true) {
        setAlertMessage(fetchedDataPrice.Message);
        setAlertStatus(fetchedDataPrice.Success);
        handleAlertClick();
        setOpen(false);
      } else if (myDataSuccess === false) {
        setAlertMessage(fetchedDataPrice.Message);
        setAlertStatus(fetchedDataPrice.Success);
        handleAlertClick();
        setOpen(false);
      }
    }

    setOpen(false);
    handleClick();
  }

  // Update Silver Price Kuveyttürk
  async function updateSilverPrice2() {
    setAltinMessage("");
    setGumusMessage("");
    setPlatinMessage("");
    setOpen(true);

    var myHeaders = new Headers();
    myHeaders.append("Authorization", authCode);
    myHeaders.append("Content-Type", "application/json");

    if (
      silverKeyKT === 9 ||
      silverKeyKT === 8 ||
      silverKeyKT === 7 ||
      silverKeyKT === 6
    ) {
      if (silverValueKT >= 1 || silverValueKT < 0) {
        setAlertMessage("Lütfen 0 ve 1 aralığında bir sayı girin.");
        setAlertStatus(false);
        handleAlertClick();
      } else {
        var raw = JSON.stringify({
          FieldType: silverKeyKT,
          FieldValue: kisitliOndalikSayi(silverValueKT, 4),
        });

        var requestOptions = {
          method: "POST",
          headers: myHeaders,
          body: raw,
          redirect: "follow",
        };

        const response = await fetch(
          `${fetchUrl}Admin/UpdateProviderPriceValue?providerId=4BEA4F9A-6467-4117-85C5-22C79E413C83&commodityId=5D713008-15BB-4509-BE0D-B2F9355E8FF4`,
          requestOptions
        );
        const fetchedDataPrice = await response.json();
        const myDataSuccess = fetchedDataPrice.Success;
        const myDataMessage = fetchedDataPrice.Message;

        if (fetchedDataPrice.Success === true) {
          setAlertMessage(fetchedDataPrice.Message);
          setAlertStatus(fetchedDataPrice.Success);
          handleAlertClick();
          setOpen(false);
        } else if (myDataSuccess === false) {
          setAlertMessage(fetchedDataPrice.Message);
          setAlertStatus(fetchedDataPrice.Success);
          handleAlertClick();
          setOpen(false);
        }
      }
    } else {
      var raw = JSON.stringify({
        FieldType: silverKeyKT,
        FieldValue: silverValueKT,
      });

      var requestOptions = {
        method: "POST",
        headers: myHeaders,
        body: raw,
        redirect: "follow",
      };

      const response = await fetch(
        `${fetchUrl}Admin/UpdateProviderPriceValue?providerId=4BEA4F9A-6467-4117-85C5-22C79E413C83&commodityId=5D713008-15BB-4509-BE0D-B2F9355E8FF4`,
        requestOptions
      );
      const fetchedDataPrice = await response.json();
      const myDataSuccess = fetchedDataPrice.Success;
      const myDataMessage = fetchedDataPrice.Message;

      if (fetchedDataPrice.Success === true) {
        setAlertMessage(fetchedDataPrice.Message);
        setAlertStatus(fetchedDataPrice.Success);
        handleAlertClick();
        setOpen(false);
      } else if (myDataSuccess === false) {
        setAlertMessage(fetchedDataPrice.Message);
        setAlertStatus(fetchedDataPrice.Success);
        handleAlertClick();
        setOpen(false);
      }
    }

    setOpen(false);

    handleClick();
  }

  async function updatePlatinPrice2() {
    setAltinMessage("");
    setGumusMessage("");
    setPlatinMessage("");
    setOpen(true);

    if (
      platinKeyKT === 9 ||
      platinKeyKT === 8 ||
      platinKeyKT === 7 ||
      platinKeyKT === 6
    ) {
      if (platinValueKT >= 1 || platinValueKT < 0) {
        setAlertMessage("Lütfen 0 ve 1 aralığında bir sayı girin.");
        setAlertStatus(false);
        handleAlertClick();
      } else {
        var myHeaders = new Headers();
        myHeaders.append("Authorization", authCode);
        myHeaders.append("Content-Type", "application/json");

        var raw = JSON.stringify({
          FieldType: platinKeyKT,
          FieldValue: kisitliOndalikSayi(platinValueKT, 4),
        });

        var requestOptions = {
          method: "POST",
          headers: myHeaders,
          body: raw,
          redirect: "follow",
        };

        const response = await fetch(
          `${fetchUrl}Admin/UpdateProviderPriceValue?providerId=4BEA4F9A-6467-4117-85C5-22C79E413C83&commodityId=8FC928D5-D9F9-4ABD-9859-567883AA3F06`,
          requestOptions
        );
        const fetchedDataPrice = await response.json();
        const myDataSuccess = fetchedDataPrice.Success;
        const myDataMessage = fetchedDataPrice.Message;

        if (fetchedDataPrice.Success === true) {
          setAlertMessage(fetchedDataPrice.Message);
          setAlertStatus(fetchedDataPrice.Success);
          handleAlertClick();
          setOpen(false);
        } else if (myDataSuccess === false) {
          setAlertMessage(fetchedDataPrice.Message);
          setAlertStatus(fetchedDataPrice.Success);
          handleAlertClick();
          setOpen(false);
        }
      }
    } else {
      var myHeaders = new Headers();
      myHeaders.append("Authorization", authCode);
      myHeaders.append("Content-Type", "application/json");

      var raw = JSON.stringify({
        FieldType: platinKeyKT,
        FieldValue: platinValueKT,
      });

      var requestOptions = {
        method: "POST",
        headers: myHeaders,
        body: raw,
        redirect: "follow",
      };

      const response = await fetch(
        `${fetchUrl}Admin/UpdateProviderPriceValue?providerId=4BEA4F9A-6467-4117-85C5-22C79E413C83&commodityId=8FC928D5-D9F9-4ABD-9859-567883AA3F06`,
        requestOptions
      );
      const fetchedDataPrice = await response.json();
      const myDataSuccess = fetchedDataPrice.Success;
      const myDataMessage = fetchedDataPrice.Message;

      if (fetchedDataPrice.Success === true) {
        setAlertMessage(fetchedDataPrice.Message);
        setAlertStatus(fetchedDataPrice.Success);
        handleAlertClick();
        setOpen(false);
      } else if (myDataSuccess === false) {
        setAlertMessage(fetchedDataPrice.Message);
        setAlertStatus(fetchedDataPrice.Success);
        handleAlertClick();
        setOpen(false);
      }
    }

    setOpen(false);

    handleClick();
  }

  // //Price Table
  // const [fintagPrices,setFintagPrices] = React.useState([])
  // const [ktPrices,setKtPrices] = React.useState([])

  // async function updatePlatinPrice2() {
  //   var myHeaders = new Headers();
  //   myHeaders.append("Authorization", authCode);
  //   myHeaders.append("Content-Type", "application/json");

  //   var raw = JSON.stringify({
  //     "FieldType" : platinKeyKT,
  //     "FieldValue" : platinValueKT,
  //   });

  //   var requestOptions = {
  //     method: "POST",
  //     headers: myHeaders,
  //     body: raw,
  //     redirect: "follow",
  //   };

  //   const response = await fetch(
  //     `${fetchUrl}ProviderPrice/GetAll?pageNumber=1`,
  //     requestOptions
  //   );
  //   const fetchedDataPrice = await response.json();

  //   var fintagArr = [];
  //   var ktArr = [];

  //   if (fetchedDataPrice.Success === true) {
  //     for(let i = 0; i < fetchedDataPrice.length ; i++){
  //       if (fetchedDataPrice[i].ProviderId === "40B16FE1-CE48-4846-B380-635EFB36E97A") {
  //         let commodityName = "";
  //         if (fetchedDataPrice[i].CommodityId === "5D713008-15BB-4509-BE0D-B2F9355E8FF4") {
  //           commodityName = "Gümüş";
  //         } else if (fetchedDataPrice[i].CommodityId === "8FC928D5-D9F9-4ABD-9859-567883AA3F06") {
  //           commodityName = "Platin";
  //         } else if (fetchedDataPrice[i].CommodityId === "7BE7C383-03E0-4A29-9564-1772BF0E996E") {
  //           commodityName = "Altın";
  //         }

  //       if(fetchedDataPrice[i].ProviderId === "40B16FE1-CE48-4846-B380-635EFB36E97A"){
  //         fintagArr.push({
  //           ProviderName : "KuveytTürk",
  //           CommodityName: fetchedDataPrice[i].commodityName,
  //           BuyPrice: fetchedDataPrice[i].BuyPrice,
  //           SellPrice: fetchedDataPrice[i].SellPrice,

  //         })
  //       }
  //     }

  //   } else if (myDataSuccess === false) {

  //   }
  // }

  // const columns = React.useMemo(
  //   () => [
  //     {
  //       accessorKey: "Id",
  //       header: "Id",
  //       size: 30,
  //     },
  //     {
  //       accessorKey: "IsActive",
  //       header: "Is Active",
  //       size: 30,
  //     },
  //     {
  //       accessorKey: "Type",
  //       header: "Transaction Ids",
  //       size: 30,
  //     },
  //     {
  //       accessorKey: "Key",
  //       header: "Key",
  //       size: 30,
  //     },
  //     {
  //       accessorKey: "Value",
  //       header: "Value",
  //       size: 30,
  //     },
  //     {
  //       accessorKey: "CustomField1",
  //       header: "Field 1",
  //       size: 30,
  //     },
  //     {
  //       accessorKey: "CustomField2",
  //       header: "Field 2",
  //       size: 30,
  //     },
  //     {
  //       accessorKey: "CustomField3",
  //       header: "Field 3",
  //       size: 30,
  //     },
  //     {
  //       accessorKey: "CreatedDate",
  //       header: "Created Date",
  //       size: 30,
  //     },
  //   ],
  //   []
  // );

  const handleClick = () => {
    setRefresh(true);
  };

  React.useEffect(() => {
    setRefresh(false);
  }, [refresh]);

  return (
    <Box sx={pageContainer}>
      <Box
        style={{
          gap: "5rem",
          alignItems: "center",
          justifyContent: "center",
          display: "flex",
          marginBottom: "2rem",
        }}
      >
        <Backdrop
          sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
          open={open}
        >
          <CircularProgress color="inherit" />
        </Backdrop>

        <Button
          style={{ width: "10rem", gap: "0.5rem" }}
          color="primary"
          variant={provider == "Fintag" ? "contained" : "outlined"}
          onClick={() => setProvider("Fintag")}
        >
          <Typography>Fintag</Typography>
        </Button>
        <Button
          style={{ width: "10rem", gap: "0.5rem" }}
          color="primary"
          variant={provider == "KuveytTürk" ? "contained" : "outlined"}
          onClick={() => setProvider("KuveytTürk")}
        >
          <Typography>KuveytTürk</Typography>
        </Button>
      </Box>
      {provider == "Fintag" && (
        <Box
          style={{
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
          }}
        >
          <Box
            sx={{
              display: "flex",
              flexDirection: "row",
              justifyContent: "space-around",
              flexWrap: "wrap",
              gap: "1rem",
            }}
          >
            {/* Fintag Altın Fiyatları  */}
            <Box component="form" sx={formStyle} noValidate autoComplete="off">
              <Typography
                sx={{
                  textAlign: "center",
                  fontSize: 16,
                  fontWeight: "bold",
                  textDecoration: "underline",
                  color: "rgb(212, 175, 1)",
                }}
              >
                Altın
              </Typography>
              <Select
                labelId="demo-customized-select-label"
                id="demo-customized-select"
                value={selectedOptionAltin}
                onChange={handleChangeAltin}
                className={classes.select}
              >
                {options.map((option) => (
                  <MenuItem
                    key={option.key}
                    value={option.value}
                    onClick={() => setGoldKey(option.key)}
                  >
                    {option.value}
                  </MenuItem>
                ))}
              </Select>
              <TextField
                type="number"
                id="standard-required"
                label={"Değer Girin: "}
                defaultValue=""
                variant="standard"
                value={goldValueFintag}
                onChange={(event) => {
                  setGoldValueFintag(event.target.value);
                }}
              />

              <Button
                color="primary"
                variant="contained"
                style={{
                  width: "20rem",
                  alignSelf: "center",
                  marginTop: "1rem",
                }}
                onClick={() => updateGoldPrice()}
              >
                <Typography>Fiyatları Onayla</Typography>
              </Button>

              <PricesDetailsFintagAltin open={refresh} />
            </Box>

            {/* Fintag Gümüş Fiyatları  */}
            <Box
              component="form"
              sx={[formStyle, { backgroundColor: "#D3D3D3" }]}
              noValidate
              autoComplete="off"
            >
              <Typography
                sx={{
                  textAlign: "center",
                  fontSize: 16,
                  fontWeight: "bold",
                  textDecoration: "underline",
                  color: "#808080",
                }}
              >
                Gümüş
              </Typography>
              <Select
                labelId="demo-customized-select-label"
                id="demo-customized-select"
                value={selectedOptionGumus}
                onChange={handleChangeGumus}
                className={classes.select}
              >
                {options.map((option) => (
                  <MenuItem
                    key={option.key}
                    value={option.value}
                    onClick={() => setSilverKey(option.key)}
                  >
                    {option.value}
                  </MenuItem>
                ))}
              </Select>
              <TextField
                type="number"
                id="standard-required"
                label={"Değer Girin: "}
                defaultValue=""
                variant="standard"
                value={silverValueFintag}
                onChange={(event) => {
                  setSilverValueFintag(event.target.value);
                }}
              />

              <Button
                color="primary"
                variant="contained"
                style={{
                  width: "20rem",
                  alignSelf: "center",
                  marginTop: "1rem",
                }}
                onClick={() => updateSilverPrice()}
              >
                <Typography>Fiyatları Onayla</Typography>
              </Button>

              <PricesDetailsFintagSilver open={refresh} />
            </Box>

            {/* Fintag Platin Fiyatları  */}
            <Box
              component="form"
              sx={[formStyle, { backgroundColor: "#81d9dd" }]}
              noValidate
              autoComplete="off"
            >
              <Typography
                sx={{
                  textAlign: "center",
                  fontSize: 16,
                  fontWeight: "bold",
                  textDecoration: "underline",
                  color: "##6abac3",
                }}
              >
                Platin
              </Typography>
              <Select
                labelId="demo-customized-select-label"
                id="demo-customized-select"
                value={selectedOptionPlatin}
                onChange={handleChangePlatin}
                className={classes.select}
              >
                {options.map((option) => (
                  <MenuItem
                    key={option.key}
                    value={option.value}
                    onClick={() => setPlatinKey(option.key)}
                  >
                    {option.value}
                  </MenuItem>
                ))}
              </Select>
              <TextField
                type="number"
                id="standard-required"
                label={"Değer Girin: "}
                defaultValue=""
                variant="standard"
                value={platinValueFintag}
                onChange={(event) => {
                  setPlatinValueFintag(event.target.value);
                }}
              />

              <Button
                color="primary"
                variant="contained"
                style={{
                  width: "20rem",
                  alignSelf: "center",
                  marginTop: "1rem",
                }}
                onClick={() => updatePlatinPrice()}
              >
                <Typography>Fiyatları Onayla</Typography>
              </Button>
              <PricesDetailsFintagPlatin open={refresh} />
            </Box>
          </Box>
        </Box>
      )}
      {provider == "KuveytTürk" && (
        <Box
          style={{
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
          }}
        >
          <Box
            sx={{
              display: "flex",
              flexDirection: "row",
              justifyContent: "space-around",
              flexWrap: "wrap",
              gap: "1rem",
            }}
          >
            {/* KT Altın Fiyatları  */}
            <Box component="form" sx={formStyle} noValidate autoComplete="off">
              <Typography
                sx={{
                  textAlign: "center",
                  fontSize: 16,
                  fontWeight: "bold",
                  textDecoration: "underline",
                  color: "rgb(212, 175, 1)",
                }}
              >
                Altın
              </Typography>
              <Select
                labelId="demo-customized-select-label"
                id="demo-customized-select"
                value={selectedOptionAltin}
                onChange={handleChangeAltin}
                className={classes.select}
              >
                {optionsKT.map((option) => (
                  <MenuItem
                    key={option.key}
                    value={option.value}
                    onClick={() => setGoldKeyKT(option.key)}
                  >
                    {option.value}
                  </MenuItem>
                ))}
              </Select>
              <TextField
                type="number"
                id="standard-required"
                label={"Değer Girin: "}
                defaultValue=""
                variant="standard"
                value={goldValueKT}
                onChange={(event) => {
                  setGoldValueKT(event.target.value);
                }}
              />

              <Button
                color="primary"
                variant="contained"
                style={{
                  width: "20rem",
                  alignSelf: "center",
                  marginTop: "1rem",
                }}
                onClick={() => updateGoldPrice2()}
              >
                <Typography>Fiyatları Onayla</Typography>
              </Button>

              <PricesDetailsKTAltin open={refresh} />
            </Box>

            {/* KT Gümüş Fiyatları  */}
            <Box
              component="form"
              sx={[formStyle, { backgroundColor: "#D3D3D3" }]}
              noValidate
              autoComplete="off"
            >
              <Typography
                sx={{
                  textAlign: "center",
                  fontSize: 16,
                  fontWeight: "bold",
                  textDecoration: "underline",
                  color: "#808080",
                }}
              >
                Gümüs
              </Typography>
              <Select
                labelId="demo-customized-select-label"
                id="demo-customized-select"
                value={selectedOptionGumus}
                onChange={handleChangeGumus}
                className={classes.select}
              >
                {optionsKT.map((option) => (
                  <MenuItem
                    key={option.key}
                    value={option.value}
                    onClick={() => setSilverKeyKT(option.key)}
                  >
                    {option.value}
                  </MenuItem>
                ))}
              </Select>
              <TextField
                type="number"
                id="standard-required"
                label={"Değer Girin: "}
                defaultValue=""
                variant="standard"
                value={silverValueKT}
                onChange={(event) => {
                  setSilverValueKT(event.target.value);
                }}
              />

              <Button
                color="primary"
                variant="contained"
                style={{
                  width: "20rem",
                  alignSelf: "center",
                  marginTop: "1rem",
                }}
                onClick={() => updateSilverPrice2()}
              >
                <Typography>Fiyatları Onayla</Typography>
              </Button>

              <PricesDetailsKTSilver open={refresh} />
            </Box>

            {/* KT Platin Fiyatları  */}
            <Box
              component="form"
              sx={[formStyle, { backgroundColor: "#81d9dd" }]}
              noValidate
              autoComplete="off"
            >
              <Typography
                sx={{
                  textAlign: "center",
                  fontSize: 16,
                  fontWeight: "bold",
                  textDecoration: "underline",
                  color: "##6abac3",
                }}
              >
                Platin
              </Typography>
              <Select
                labelId="demo-customized-select-label"
                id="demo-customized-select"
                value={selectedOptionPlatin}
                onChange={handleChangePlatin}
                className={classes.select}
              >
                {optionsKT.map((option) => (
                  <MenuItem
                    key={option.key}
                    value={option.value}
                    onClick={() => setPlatinKeyKT(option.key)}
                  >
                    {option.value}
                  </MenuItem>
                ))}
              </Select>
              <TextField
                type="number"
                id="standard-required"
                label={"Değer Girin: "}
                defaultValue=""
                variant="standard"
                value={platinValueKT}
                onChange={(event) => {
                  setPlatinValueKT(event.target.value);
                }}
              />

              <Button
                color="primary"
                variant="contained"
                style={{
                  width: "20rem",
                  alignSelf: "center",
                  marginTop: "1rem",
                }}
                onClick={() => updatePlatinPrice2()}
              >
                <Typography>Fiyatları Onayla</Typography>
              </Button>
              <PricesDetailsKTPlatin open={refresh} />
            </Box>
          </Box>
        </Box>
      )}
      <Box
        sx={{
          width: "100%",
          height: "0.01rem",
          backgroundColor: "lightgray",
          marginBottom: "2rem",
          marginTop: "2rem",
        }}
      />

      <Snackbar
        open={openAlert}
        onClose={handleAlertClose}
        autoHideDuration={2000}
      >
        <Alert severity={alertStatus ? "success" : "error"}>
          {alertmessage}
        </Alert>
      </Snackbar>
      {/* <MaterialReactTable
      columns={columns}
      data={data}
      enableRowSelection
      positionToolbarAlertBanner="bottom"
     
    />
     */}
    </Box>
  );
}

const formStyle = {
  "& > :not(style)": { width: "15rem" },
  display: "flex",
  flexDirection: "column",
  gap: 2,
  backgroundColor: "rgba(232, 219, 176, 0.5)",
  borderRadius: "0.5rem",
  justifyContent: "center",
  alignItems: "center",
  padding: "2rem",
};

const options = [
  { key: 1, value: "BuyPrice" },
  { key: 2, value: "SellPrice" },
  { key: 3, value: "VPosBuyPrice" },
  { key: 4, value: "BuyProfitTl" },
  { key: 5, value: "SellProfitTl" },
  { key: 6, value: "VPosRate" },
  { key: 7, value: "BuyPercentage" },
  { key: 8, value: "SellPercentage" },
  { key: 9, value: "BSMV" },
];

const optionsKT = [
  { key: 3, value: "VPosBuyPrice" },
  { key: 4, value: "BuyProfitTl" },
  { key: 5, value: "SellProfitTl" },
  { key: 6, value: "VPosRate" },
  { key: 7, value: "BuyPercentage" },
  { key: 8, value: "SellPercentage" },
  { key: 9, value: "BSMV" },
];

const useStyles = makeStyles((theme) => ({
  select: {
    fontSize: "16px", // Dropdown içeriğinin font boyutu
    height: "2rem", // Dropdown boyutu 5rem olarak ayarlandı
  },
}));
