import { Box, Button, Typography } from "@mui/material";
import React from "react";
import FinalizedListeleme from "./FinalizedListeleme";
import NotPositionListeleme from "./NotPositionsListeleme";
import UnexpectedListeleme from "./UnexpectedListeleme";

function FinalizedNotPositionUnexpected(props) {
  const [switchIt, setSwitchIT] = React.useState("Finalized");

  return (
    <Box sx={{ display: "flex", flexDirection: "column" }}>
      <Box
        sx={{
          display: "flex",
          alignSelf: "center",
          gap: "5rem",
        }}
      >
        <Button
          variant={switchIt == "Finalized" ? "contained" : "outlined"}
          onClick={() => setSwitchIT("Finalized")}
        >
          <Typography>Finalized Listeleme</Typography>
        </Button>

        <Button
          variant={switchIt == "NotPosition" ? "contained" : "outlined"}
          onClick={() => setSwitchIT("NotPosition")}
        >
          <Typography>Not Position Listeleme</Typography>
        </Button>
        <Button
          variant={switchIt == "Unexpected" ? "contained" : "outlined"}
          onClick={() => setSwitchIT("Unexpected")}
        >
          <Typography>Unexpected Listeleme </Typography>
        </Button>
      </Box>

      <Box
        sx={{
          width: "100%",
          height: "0.01rem",
          backgroundColor: "lightgray",
          marginBottom: "2rem",
          marginTop: "2rem",
        }}
      />

      {switchIt == "Finalized" && (
        <Box>
          <FinalizedListeleme />
        </Box>
      )}
      {switchIt == "NotPosition" && (
        <Box>
          <NotPositionListeleme />
        </Box>
      )}
      {switchIt == "Unexpected" && (
        <Box>
          <UnexpectedListeleme />
        </Box>
      )}

      <Box
        sx={{
          height: "0.01rem",
          width: "100%",
          marginTop: "2rem",
          border: "0.01rem dotted #F0EBD5",
        }}
      />
    </Box>
  );
}

export default FinalizedNotPositionUnexpected;
