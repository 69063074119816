import * as React from "react";

import { Box, Button, Typography } from "@mui/material";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import TextField from "@mui/material/TextField";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import FileDownloadIcon from "@mui/icons-material/FileDownload";
import { ExportToCsv } from "export-to-csv";
import MaterialReactTable from "material-react-table";
import CircularProgress from "@mui/material/CircularProgress";
import Backdrop from "@mui/material/Backdrop";
import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles((theme) => ({
  button: {
    [theme.breakpoints.down("sm")]: {
      // Küçük ekranlarda düğme stilleri
      minWidth: "160px",
    },
    [theme.breakpoints.up("md")]: {
      // Orta boy ekranlarda düğme stilleri
      minWidth: "140px",
    },
    [theme.breakpoints.up("lg")]: {
      // Büyük ekranlarda düğme stilleri
      minWidth: "160px",
    },
  },

  box: {
    [theme.breakpoints.down("sm")]: {
      gap: "0.2rem",
      alignItems: "center",
      display: "flex",
      flexWrap: "wrap",
      marginBottom: "1rem",
    },
    [theme.breakpoints.up("md")]: {
      gap: "1rem",
      alignItems: "center",
      display: "flex",
      marginBottom: "2rem",
      marginTop: "2rem",
      marginBottom: "1rem",
    },
    [theme.breakpoints.up("lg")]: {
      gap: "5rem",
      alignItems: "center",
      display: "flex",
      marginBottom: "2rem",
      marginTop: "2rem",
    },
  },
}));
export default function AddGatewayTransactions() {
  const classes = useStyles();
  const [res, setRes] = React.useState([]);
  const [range, setRange] = React.useState([]);
  const [open, setOpen] = React.useState(false);

  const handleChange = (event) => {
    setVendorId(event.target.value);
  };
  const [choosenVendor, setChoosenVendor] = React.useState("");
  const handleChooseVendor = (name) => {
    setStatus(false);
    setChoosenVendor(name.target.value);
  };
  const [authCode, setAuthCode] = React.useState(
    sessionStorage.getItem("AuthCode")
  );
  const [fetchUrl, setFetchUrl] = React.useState(
    sessionStorage.getItem("FetchUrl")
  );

  const [vendorId, setVendorId] = React.useState(0);
  const [emtiaId, setEmtiaId] = React.useState("");

  const handleOnChange = (event) => {
    setEmtiaId(event.target.value);
  };

  const [startDate, setStartDate] = React.useState("00/01/2023");
  const [endDate, setEndDate] = React.useState("00/00/0000");
  const [startDay, setStartDay] = React.useState("");
  const [startMonth, setStartMonth] = React.useState("");
  const [startYear, setStartYear] = React.useState("");

  const [endDay, setEndDay] = React.useState("");
  const [endMonth, setEndMonth] = React.useState("");
  const [endYear, setEndYear] = React.useState("");
  const [status, setStatus] = React.useState(false);

  const [filtered, setFiltered] = React.useState([]);
  const [buyFilter, setBuyFilter] = React.useState([]);
  const [buyFilterTL, setBuyFilterTL] = React.useState([]);
  const [buyFilterTL2, setBuyFilterTL2] = React.useState([]);
  const total = filtered.reduce((acc, gram) => acc + gram.GrAmount, 0);

  const totalsell = buyFilter.reduce((acc, gram) => acc + gram.GrAmount, 0);

  const totalTL = buyFilterTL.reduce((acc, gram) => acc + gram.TLAmount, 0);
  const totalsellTL = buyFilterTL2.reduce(
    (acc, gram) => acc + gram.TLAmount,
    0
  );

  const handleChangeStart = (event) => {
    setStartDate(event);
    if (event.$D < 10) {
      setStartDay("0" + event.$D);
    } else {
      setStartDay(event.$D.toString());
    }
    if (event.$M < 10) {
      setStartMonth("0" + (event.$M + 1));
      setStartYear(event.$y.toString());
    } else {
      setStartMonth((event.$M + 1).toString());
      setStartYear(event.$y.toString());
    }
  };
  const handleChangeEnd = (event) => {
    setEndDate(event);
    if (event.$D < 10) {
      setEndDay("0" + event.$D);
    } else {
      setEndDay(event.$D.toString());
    }
    if (event.$M < 10) {
      setEndMonth("0" + (event.$M + 1));
      setEndYear(event.$y.toString());
    } else {
      setEndMonth((event.$M + 1).toString());
      setEndYear(event.$y.toString());
    }
  };

  React.useEffect(() => {
    fetchedData();
  }, []);

  async function fetchedData() {
    //     var myHeaders = new Headers();
    //     myHeaders.append("Authorization", authCode);
    //     var requestOptions = {
    //       method: 'GET',
    //       headers: myHeaders,
    //       redirect: 'follow'
    //     };
    //     const response = await fetch(`${fetchUrl}Admin/GetAll?pageNumber=1`, requestOptions)
    //     const fetchedData = await response.json();
    //     const myData =fetchedData.Data
    //     setRes(myData)
    //    }
    //    async function fetchedDataRange(choosenVendor,emtiaId, startDay,startMonth,startYear,endDay,endMonth,endYear){
    //     setOpen(true);
    //     var myHeaders = new Headers();
    //     myHeaders.append("Authorization", authCode);
    //     var requestOptions = {
    //       method: 'GET',
    //       headers: myHeaders,
    //       redirect: 'follow'
    //     };
    //     const response = await fetch(`${fetchUrl}Admin/GetVendorTransactions?vendorId=${choosenVendor}&commodityId=${emtiaId}&startDate=${startMonth}%2F${startDay}%2F${startYear}&endDate=${endMonth}%2F${endDay}%2F${endYear}`, requestOptions)
    //     const fetchedData = await response.json();
    //     setTimeout(() => {
    //       setOpen(false)
    //     }, 1000);
    //     const myData =fetchedData.Data
    //     console.log(myData)
    //     var endpointsArr = []
    //     for(let i = 0; i<myData.length; i++){
    //       endpointsArr.push({
    //         Id:myData[i].Id,
    //         OrderType:myData[i].Comment.slice(0,3)==="BUY"?"0":"1",
    //         Ticker:myData[i].CommodityName==="Altin"? "gld-tl" : myData[i].CommodityName==="Gümüş" ? "slv-tl" : "plt-tl",
    //         TLAmount:myData[i].TlAmount,
    //         GrAmount:myData[i].GramAmount,
    //         Date:myData[i].TransactionDate
    //       })
    //       if(endpointsArr[i].OrderType === "0"){
    //         setFiltered(endpointsArr.filter(info=>info.OrderType==="0"))
    //         setBuyFilterTL(endpointsArr.filter(info=>info.OrderType==="0"))
    //       }else if(endpointsArr[i].OrderType === "1"){
    //         setBuyFilter(endpointsArr.filter(info=>info.OrderType==="1"))
    //         setBuyFilterTL2(endpointsArr.filter(info=>info.OrderType==="1"))
    //       }
    //     }
    //     setRange(endpointsArr)
    //     console.log(range)
  }
  const columns = React.useMemo(
    () => [
      {
        accessorKey: "Id",
        header: "ID",
        size: 30,
      },
      {
        accessorKey: "OrderType",
        header: "OrderType",
        size: 120,
      },
      {
        accessorKey: "Ticker",
        header: "Ticker",
        size: 120,
      },
      {
        accessorKey: "TLAmount",
        header: "TLAmount",
        size: 30,
      },
      {
        accessorKey: "GrAmount",
        header: "GrAmount",
        size: 30,
      },
      {
        accessorKey: "Date",
        header: "Date",
        size: 20,
      },
    ],
    []
  );
  const csvOptions = {
    fieldSeparator: ";",
    quoteStrings: '"',
    decimalSeparator: ".",
    showLabels: true,
    useBom: true,
    useKeysAsHeaders: false,
    headers: columns.map((c) => c.header),
    filename: "Transactions",
  };

  const csvExporter = new ExportToCsv(csvOptions);

  const handleExportRows = (rows) => {
    csvExporter.generateCsv(rows.map((row) => row.original));
  };

  const handleExportData = () => {
    csvExporter.generateCsv(range);
  };

  return (
    <Box sx={{ paddingLeft: "3rem", paddingRight: "3rem" }}>
      <Typography variant="h4" gutterBottom>
        App to Gateway Transactions
      </Typography>
      <Backdrop
        sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
        open={open}
      >
        <CircularProgress color="inherit" />
      </Backdrop>
      <Box className={classes.box}>
        <Box
          sx={{
            gap: "5rem",
            alignItems: "center",
            display: "flex",
            marginBottom: "2rem",
            marginTop: "2rem",
          }}
        >
          <FormControl sx={{ width: "170px" }}>
            <InputLabel id="demo-simple-select-label">
              Vendor Seçiniz
            </InputLabel>
            <Select
              labelId="demo-simple-select-label"
              id="demo-simple-select"
              value={choosenVendor}
              label="Vendor Seçiniz"
              onChange={handleChooseVendor}
            >
              {res.map((res) => (
                <MenuItem key={res.Id} value={res.Id}>
                  {res.Name}
                </MenuItem>
              ))}
            </Select>
          </FormControl>

          <FormControl sx={{ width: "160px" }}>
            <InputLabel id="demo-simple-select-label">Emtia Seçiniz</InputLabel>
            <Select
              labelId="demo-simple-select-label"
              id="demo-simple-select"
              value={emtiaId}
              label="Emtia Seçiniz"
              onChange={handleOnChange}
            >
              <MenuItem value="7BE7C383-03E0-4A29-9564-1772BF0E996E">
                Altın
              </MenuItem>
              <MenuItem value="5D713008-15BB-4509-BE0D-B2F9355E8FF4">
                Gümüş
              </MenuItem>
              <MenuItem value="8FC928D5-D9F9-4ABD-9859-567883AA3F06">
                Platin
              </MenuItem>
            </Select>
          </FormControl>
          <LocalizationProvider dateAdapter={AdapterDayjs}>
            <DatePicker
              label="Başlangıç Tarihi"
              value={startDate}
              onChange={handleChangeStart}
              renderInput={(params) => <TextField {...params} error={false} />}
            />

            <DatePicker
              label="Bitiş Tarihi"
              value={endDate}
              onChange={handleChangeEnd}
              renderInput={(params) => <TextField {...params} error={false} />}
            />
          </LocalizationProvider>

          <Button variant="contained" onClick={() => [setStatus(true)]}>
            Listele
          </Button>
        </Box>
      </Box>
      {status && range.length === 0 ? (
        <Typography
          sx={{ color: "#ffb703", fontWeight: "bold", marginTop: "1rem" }}
        >
          Girilen tarihlere ilişkin veri bulunmamaktadır.
        </Typography>
      ) : status && range.length > 0 ? (
        <Typography
          sx={{ color: "green", fontWeight: "bold", marginTop: "1rem" }}
        >
          İşlem Başarılı.
        </Typography>
      ) : (
        status && (
          <Typography
            sx={{ color: "red", fontWeight: "bold", marginTop: "1rem" }}
          >
            İşlem Başarısız.
          </Typography>
        )
      )}

      <MaterialReactTable
        columns={columns}
        data={range}
        enableRowSelection
        positionToolbarAlertBanner="bottom"
        renderTopToolbarCustomActions={({ table }) => (
          <Box
            sx={{ display: "flex", gap: "1rem", p: "0.5rem", flexWrap: "wrap" }}
          >
            <Button
              color="primary"
              onClick={handleExportData}
              startIcon={<FileDownloadIcon />}
              variant="contained"
            >
              Tüm Verileri Dışa Aktar
            </Button>
            <Button
              disabled={table.getPrePaginationRowModel().rows.length === 0}
              onClick={() =>
                handleExportRows(table.getPrePaginationRowModel().rows)
              }
              startIcon={<FileDownloadIcon />}
              variant="contained"
            >
              Tüm Satırları Dışa Aktar
            </Button>
            <Button
              disabled={table.getRowModel().rows.length === 0}
              onClick={() => handleExportRows(table.getRowModel().rows)}
              startIcon={<FileDownloadIcon />}
              variant="contained"
            >
              Bu Sayfadaki Satırları Dışa Aktar
            </Button>
            <Button
              disabled={
                !table.getIsSomeRowsSelected() && !table.getIsAllRowsSelected()
              }
              onClick={() => handleExportRows(table.getSelectedRowModel().rows)}
              startIcon={<FileDownloadIcon />}
              variant="contained"
            >
              Seçilmiş Satırları Dışa Aktar
            </Button>
          </Box>
        )}
      />

      <Box
        sx={{
          border: "1px solid #ffb703",
          display: "flex",
          marginTop: "1rem",
          flexDirection: "column",
          width: "42rem",
          marginLeft: "auto",
          marginRight: "auto",
          borderRadius: "20px",
          padding: "1rem",
          bgcolor: "background.paper",
        }}
      >
        <Typography
          sx={{
            display: "flex",
            flexDirection: "row",
            fontSize: "20px",
            alignItems: "center",
            gap: "0.2rem",
          }}
        >
          Toplam Alınan Emtia Gram :{" "}
          <Typography
            sx={{
              marginLeft: "1rem",
              color: "darkgrey",
              fontWeight: "bold",
              fontSize: "24px",
            }}
          >
            {range.length == 0 ? "0" : total}
          </Typography>
          <Typography
            sx={{ fontSize: "12px", color: "gray", marginTop: "0.5rem" }}
          >
            GR
          </Typography>
        </Typography>
        <Typography
          sx={{
            display: "flex",
            flexDirection: "row",
            fontSize: "20px",
            alignItems: "center",
            gap: "0.2rem",
          }}
        >
          Toplam Satılan Emtia Gram :{" "}
          <Typography
            sx={{
              marginLeft: "1rem",
              color: "darkgrey",
              fontWeight: "bold",
              fontSize: "24px",
            }}
          >
            {range.length == 0 ? "0" : totalsell}{" "}
          </Typography>
          <Typography
            sx={{ fontSize: "12px", color: "gray", marginTop: "0.5rem" }}
          >
            GR
          </Typography>
        </Typography>
        <Typography
          sx={{
            display: "flex",
            flexDirection: "row",
            fontSize: "20px",
            alignItems: "center",
            gap: "0.2rem",
          }}
        >
          Toplam Alınan Emtia TL :{" "}
          <Typography
            sx={{
              marginLeft: "1rem",
              color: "darkgrey",
              fontWeight: "bold",
              fontSize: "24px",
            }}
          >
            {range.length == 0 ? "0" : totalTL}{" "}
          </Typography>
          <Typography
            sx={{ fontSize: "12px", color: "gray", marginTop: "0.5rem" }}
          >
            TL
          </Typography>
        </Typography>
        <Typography
          sx={{
            display: "flex",
            flexDirection: "row",
            fontSize: "20px",
            alignItems: "center",
            gap: "0.2rem",
          }}
        >
          Toplam Satılan Emtia TL :{" "}
          <Typography
            sx={{
              marginLeft: "1rem",
              color: "darkgrey",
              fontWeight: "bold",
              fontSize: "24px",
            }}
          >
            {range.length == 0 ? "0" : totalsellTL}{" "}
          </Typography>
          <Typography
            sx={{ fontSize: "12px", color: "gray", marginTop: "0.5rem" }}
          >
            TL
          </Typography>
        </Typography>
      </Box>
    </Box>
  );
}
