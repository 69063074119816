import {
  Alert,
  Backdrop,
  Box,
  Button,
  CircularProgress,
  MenuItem,
  Modal,
  Select,
  Snackbar,
  Switch,
  TextField,
  Typography,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import FintagPriceDetails from "src/CryptoComponents/Prices/FintagPriceDetails";
import {
  lightTitle14,
  modalStyle,
  orderContainer,
  priceEnterButton,
  underlineBold18,
} from "src/utils/customStyles";
import { makeStyles } from "@mui/styles";
import FintagPriceDetailsMinMax from "src/CryptoComponents/Prices/FintagPriceDetailsMinMax";

const options = [
  { key: 1, value: "Min Buy Price" },
  { key: 2, value: "Max Buy Price" },
  { key: 3, value: "Min Sell Price" },
  { key: 4, value: "Max Sell Price" },
];

const formStyle = {
  "& > :not(style)": { width: "10rem" },
  display: "flex",
  flexDirection: "column",
  gap: 2,
  borderRadius: "0.5rem",
  justifyContent: "center",
  alignItems: "center",
  padding: "1rem",
  width: "20rem",
};
const useStyles = makeStyles((theme) => ({
  select: {
    fontSize: "16px", // Dropdown içeriğinin font boyutu
    height: "2rem", // Dropdown boyutu 5rem olarak ayarlandı
  },
}));
const FintagMinMax = () => {
  const classes = useStyles();
  const [fetchUrl, setFetchUrl] = React.useState(
    sessionStorage.getItem("FetchUrl")
  );
  const [authCode, setAuthCode] = React.useState(
    sessionStorage.getItem("AuthCode")
  );
  const [open, setOpen] = React.useState(false);
  const [suffix, setSuffix] = React.useState("");
  const [finValue, setFinValue] = useState(0);
  const [finKey, setFinKey] = useState(1);
  const [finKey2, setFinKey2] = useState(0);
  const [dataFintagMinMax, setDataFintagMinMax] = useState([]);
  const [minBuy, setMinBuy] = useState(dataFintagMinMax.BuyPrice);
  const [maxBuy, setMaxBuy] = useState(dataFintagMinMax.ViewBuyPrice);
  const [minSell, setMinSell] = useState(dataFintagMinMax.SellPrice);
  const [maxSell, setMaxSell] = useState(dataFintagMinMax.ViewSellPrice);
  const handleClose = () => setOpen(false);
  const [status, setStatus] = React.useState(false);

  // SnackBar
  //console.log(authCode);
  const [alertmessage, setAlertMessage] = React.useState("");
  const [alertStatus, setAlertStatus] = React.useState(false);
  const [openAlert, setOpenAlert] = React.useState(false);
  const handleAlertClick = () => {
    setOpenAlert(true);
  };
  const handleAlertClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }

    setOpenAlert(false);
  };

  const handleInputChange = (event) => {
    const inputValue = event.target.value;

    // Nokta veya virgül içeriyorsa
    if (inputValue.includes(".") || inputValue.includes(",")) {
      // Nokta veya virgülün index'ini bul
      const decimalIndex =
        inputValue.indexOf(".") !== -1
          ? inputValue.indexOf(".")
          : inputValue.indexOf(",");

      // Nokta veya virgülden sonraki kısmı al ve uzunluğunu kontrol et
      const decimalPartLength = inputValue.substring(decimalIndex + 1).length;

      // Eğer nokta veya virgülden sonraki kısım 4 haneden uzunsa
      if (decimalPartLength > 4) {
        // İlk 4 hane dışındaki karakterleri at ve sadece ilk 4 haneyi al
        const newValue = inputValue.substring(0, decimalIndex + 5);
        setFinValue(newValue);
      } else {
        // 4 haneden azsa herhangi bir işlem yapma, direkt değeri at
        setFinValue(inputValue);
      }
    } else {
      // Nokta veya virgül yoksa direkt değeri at
      setFinValue(inputValue);
    }
  };
  async function getPrices() {
    var requestOptions = {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Authorization: authCode,
      },
    };
    const response = await fetch(
      `${fetchUrl}Admin/GetCurrencyPriceForAdmin`,
      requestOptions
    );
    const fetchedData = await response.json();
    const stringfyData = await fetchedData.Data;
    // console.log(stringfyData);
    for (let i = 0; i < stringfyData.length; i++) {
      if (stringfyData[i].ProviderSuffix == 20) {
        setDataFintagMinMax(stringfyData[i]);
        setMinBuy(stringfyData[i].BuyPrice);
        setMaxBuy(stringfyData[i].ViewBuyPrice);
        setMinSell(stringfyData[i].SellPrice);
        setMaxSell(stringfyData[i].ViewSellPrice);
        setStatus(stringfyData[i].IsDeleted);
      }
    }
  }
  useEffect(() => {
    getPrices();
  }, [finValue]);
  async function checkFintagPrice() {
    if (finValue == 0) {
      updateFintagPrice();
    } else {
      if (finKey == 3 || finKey == 4) {
        if (finKey == 3) {
          if (finValue < maxSell) {
            if (finValue >= minBuy || finValue >= maxBuy) {
              setAlertMessage("Satış fiyatı alış fiyatından yüksek olamaz");
              setAlertStatus(false);
              handleAlertClick();
            } else {
              updateFintagPrice();
            }
          } else {
            setAlertMessage("Min Sell Price  Max Sell Pricedan yüksek olamaz");
            setAlertStatus(false);
            handleAlertClick();
          }
        } else {
          if (finValue > minSell) {
            if (finValue >= minBuy || finValue >= maxBuy) {
              setAlertMessage("Satış fiyatı alış fiyatından yüksek olamaz");
              setAlertStatus(false);
              handleAlertClick();
            } else {
              updateFintagPrice();
            }
          } else {
            setAlertMessage("Max Sell Price  Min Sell Pricedan düşük olamaz");
            setAlertStatus(false);
            handleAlertClick();
          }
        }
      } else {
        if (finKey === 1) {
          if (finValue < maxBuy) {
            if (finValue >= minSell && finValue >= maxSell) {
              updateFintagPrice();
            } else {
              setAlertMessage("Alış fiyatı satış fiyatından düşük olamaz");
              setAlertStatus(false);
              handleAlertClick();
            }
          } else {
            setAlertMessage("Min Buy Price Max Buy Pricedan yüksek olamaz.");
            setAlertStatus(false);
            handleAlertClick();
          }
        } else {
          if (finValue > minBuy) {
            if (finValue <= minSell || finValue <= maxSell) {
              setAlertMessage("Satış fiyatı alış fiyatından yüksek olamaz");
              setAlertStatus(false);
              handleAlertClick();
            } else {
              updateFintagPrice();
            }
          } else {
            setAlertMessage("Max Buy Price Min Buy Pricedan düşük olamaz");
            setAlertStatus(false);
            handleAlertClick();
          }
        }
      }
    }
  }

  async function updateFintagPrice() {
    setOpen(true);

    var myHeaders = new Headers();
    myHeaders.append("Authorization", authCode);
    myHeaders.append("Content-Type", "application/json");
    myHeaders.append("accept", "*/*");
    var raw = {};
    if (finKey === 1) {
      raw = {
        MinCurrencyBuyPrice:
          finValue == "" || finValue == NaN ? 0 : parseFloat(finValue),
        MaxCurrencyBuyPrice: maxBuy,
        MinCurrencySellPrice: minSell,
        MaxCurrencySellPrice: maxSell,
        IsDeleted: status,
      };
    } else if (finKey === 2) {
      raw = {
        MinCurrencyBuyPrice: minBuy,
        MaxCurrencyBuyPrice:
          finValue == "" || finValue == NaN ? 0 : parseFloat(finValue),
        MinCurrencySellPrice: minSell,
        MaxCurrencySellPrice: maxSell,
        IsDeleted: status,
      };
    } else if (finKey === 3) {
      raw = {
        MinCurrencyBuyPrice: minBuy,
        MaxCurrencyBuyPrice: maxBuy,
        MinCurrencySellPrice:
          finValue == "" || finValue == NaN ? 0 : parseFloat(finValue),
        MaxCurrencySellPrice: maxSell,
        IsDeleted: status,
      };
    } else if (finKey === 4) {
      raw = {
        MinCurrencyBuyPrice: minBuy,
        MaxCurrencyBuyPrice: maxBuy,
        MinCurrencySellPrice: minSell,
        MaxCurrencySellPrice:
          finValue == "" || finValue == NaN ? 0 : parseFloat(finValue),
        IsDeleted: status,
      };
    }

    var jsonString = JSON.stringify(raw);

    var requestOptions = {
      method: "POST",
      headers: myHeaders,
      body: jsonString,
      redirect: "follow",
    };
    const response = await fetch(
      `${fetchUrl}Admin/SetFintagMinMax`,
      requestOptions
    );
    const fetchedDataPrice = await response.json();
    if (fetchedDataPrice.Success === true) {
      setAlertMessage(fetchedDataPrice.Message);
      setAlertStatus(fetchedDataPrice.Success);
      handleAlertClick();
      getPrices();
      setFinValue(0);
      setOpen(false);
    } else if (fetchedDataPrice.Success === false) {
      setAlertMessage(fetchedDataPrice.Message);
      setAlertStatus(fetchedDataPrice.Success);
      handleAlertClick();
      setOpen(false);
    }
  }

  return (
    <Box sx={orderContainer}>
      <Backdrop
        sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
        open={open}
      >
        <CircularProgress color="inherit" />
      </Backdrop>
      <Snackbar
        open={openAlert}
        onClose={handleAlertClose}
        autoHideDuration={2000}
      >
        <Alert severity={alertStatus ? "success" : "error"}>
          {alertmessage}
        </Alert>
      </Snackbar>

      <Box
        component="form"
        sx={[formStyle, { backgroundColor: "#c7ae5d62" }]}
        noValidate
        autoComplete="off"
      >
        <Typography sx={[underlineBold18, { textAlign: "center" }]}>
          Fintag Min Max
        </Typography>
        <Select
          labelId="demo-customized-select-label"
          id="demo-customized-select"
          className={classes.select}
          style={{ fontSize: 14 }}
          value={finKey}
          MenuProps={{
            PaperProps: {
              style: {
                backgroundColor: "#f5f3eb", // Açılan menünün arka plan rengini buradan ayarlayabilirsiniz
              },
            },
          }}
        >
          {options.map((option) => (
            <MenuItem
              key={option.key}
              value={option.key}
              onClick={() => setFinKey(option.key)}
            >
              {option.value}
            </MenuItem>
          ))}
        </Select>
        <TextField
          type="number"
          id="standard-required"
          label={"Değer Girin: "}
          defaultValue=""
          variant="standard"
          value={finValue}
          onChange={handleInputChange}
        />
        <Select
          labelId="demo-customized-select-label"
          id="demo-customized-select"
          className={classes.select}
          style={{ fontSize: 14 }}
          value={status}
          MenuProps={{
            PaperProps: {
              style: {
                backgroundColor: "#f5f3eb", // Açılan menünün arka plan rengini buradan ayarlayabilirsiniz
              },
            },
          }}
        >
          <MenuItem
            key={"Aktif"}
            value={"true"}
            onClick={() => [setStatus(true), setFinKey2(5)]}
          >
            Aktif
          </MenuItem>
          <MenuItem
            key={"Pasif"}
            value={"false"}
            onClick={() => [setStatus(false), setFinKey2(5)]}
          >
            Pasif
          </MenuItem>
        </Select>
        <Button
          color="primary"
          variant="contained"
          style={priceEnterButton}
          onClick={() => checkFintagPrice()}
        >
          <Typography sx={{ fontSize: 14 }}>Fiyatları Onayla</Typography>
        </Button>
        <Box sx={{ display: "flex", marginLeft: -11.9 }}>
          <FintagPriceDetailsMinMax State={20} open={open} />
        </Box>
      </Box>
    </Box>
  );
};

export default FintagMinMax;
