import * as React from "react";
import { DataGrid } from "@mui/x-data-grid";
import { Button, Box, Typography, TextField } from "@mui/material";

export default function ListAllEndPoints() {
  const [authCode, setAuthCode] = React.useState(
    sessionStorage.getItem("AuthCode")
  );
  const [fetchUrl, setFetchUrl] = React.useState(
    sessionStorage.getItem("FetchUrl")
  );
  const [selectionModel, setSelectionModel] = React.useState([]);
  const [mailValue, setMailValue] = React.useState("");
  const [dataMessage, setDataMessage] = React.useState("");

  const columns = [
    { field: "Method", headerName: "Method", width: 100 },
    { field: "Route", headerName: "Route", width: 300 },
    { field: "Action", headerName: "Action", width: 300 },
  ];

  //let copiedRows = Object.assign({},endPoints)

  const [endPoints, setEndPoints] = React.useState([]);
  React.useEffect(() => {
    fetchedData();
  }, []);

  React.useEffect(() => {
    const interval = setInterval(() => {
      setDataMessage("");
    }, 3000);
    return () => clearInterval(interval);
  }, [dataMessage]);

  async function fetchedData() {
    var myHeaders = new Headers();
    myHeaders.append("Authorization", authCode);

    var requestOptions = {
      method: "GET",
      headers: myHeaders,
      redirect: "follow",
    };

    const response = await fetch(
      `${fetchUrl}Auth/ListAllEndpoints`,
      requestOptions
    );
    const fetchedData = await response.json();
    var endPointsArr = [];
    var counter = 0;

    for (let i = 0; i < fetchedData.length; i++) {
      if (fetchedData[i].Route.startsWith("/Admin") === true)
        endPointsArr.push({
          Method: fetchedData[i].Method,
          Route: fetchedData[i].Route,
          Action: fetchedData[i].Action,
        });
    }

    var newArray = [];

    for (let x = 0; x < endPointsArr.length; x++) {
      newArray.push({
        Id: x + 1,
        Method: endPointsArr[x].Method,
        Route: endPointsArr[x].Route,
        Action: endPointsArr[x].Action,
      });
    }

    setEndPoints(newArray);
  }

  function getRoutes(endPoints, selectionModel, mailValue) {
    let routesArray = [];

    for (let x = 0; x < selectionModel.length; x++) {
      let y = selectionModel[x] - 1;
      routesArray.push(endPoints[y].Action.substring(6) + " (GoldtagGateway)");
    }

    AllowRoutesForAdmin(mailValue, routesArray);

    async function AllowRoutesForAdmin() {
      var myHeaders = new Headers();
      myHeaders.append("accept", "*/*");
      myHeaders.append("Authorization", authCode);
      myHeaders.append("Content-Type", "application/json");

      var requestOptions = {
        method: "POST",
        headers: myHeaders,
        redirect: "follow",
      };

      const arrayToString = routesArray.join(",");

      let mailDivide = mailValue.split("@");

      const response = await fetch(
        `${fetchUrl}Auth/UpdateAdminAllowRoutes?mail=${mailDivide[0]}%40${mailDivide[1]}&routeNames=${arrayToString}`,
        requestOptions
      );
      const fetchedData = await response.json();

      setDataMessage(fetchedData.Message);
    }
  }

  return (
    <Box style={{ height: "80%", width: "100%" }}>
      <DataGrid
        getRowId={(data) => data.Id}
        rows={endPoints}
        columns={columns}
        rowSelection
        checkboxSelection
        onRowSelectionModelChange={(newSelectionModel) => [
          setSelectionModel(newSelectionModel),
        ]}
        selectionModel={selectionModel}
        rowHeight={70}
      />

      <Box sx={{ marginLeft: "2rem" }}>
        <Typography variant="h4" gutterBottom>
          Admin Yetkilendirme
        </Typography>
        <Typography sx={{ fontStyle: "italic", color: "gray" }}>
          Seçtiğiniz metodların ardından , yetkinlendirmek istediğiniz
          kullanıcının mail adresini girin.
        </Typography>

        <Box
          sx={{
            display: "flex",
            flexDirection: "row",
            alignItems: "center",
            marginTop: "1rem",
          }}
        >
          <TextField
            id="standard-required"
            label={"Mail Adresi"}
            defaultValue=""
            variant="standard"
            onChange={(event) => {
              setMailValue(event.target.value);
            }}
            value={mailValue}
          />
          <Button
            onClick={() => getRoutes(endPoints, selectionModel, mailValue)}
            sx={{
              marginTop: "1rem",
              backgroundColor: "#BE8D1E",
              borderRadius: "10px",
              marginLeft: "1rem",
            }}
          >
            <Typography sx={{ color: "white", fontSize: "14px" }}>
              Yetkinlendir
            </Typography>
          </Button>
        </Box>
        {dataMessage.length > 0 && (
          <Typography
            sx={{
              marginTop: "0.5rem",
              color: dataMessage === "İşlem başarılı." ? "green" : "red",
            }}
          >
            {dataMessage}
          </Typography>
        )}
      </Box>
    </Box>
  );
}
