// component
import SvgColor from "../../../components/svg-color";

// ----------------------------------------------------------------------

const icon = (name) => (
  <SvgColor
    src={`/assets/icons/navbar/${name}.svg`}
    sx={{ width: 1, height: 1 }}
  />
);

const navConfig = [
  {
    title: "Anaysafa",
    path: "/dash/Currencies",
    icon: icon("ic_vendor"),
  },
  {
    title: "Fiyatlar",
    path: "/dash/Fiyatlar",
    icon: icon("ic_price"),
  },
  {
    title: "İşlemler",
    path: "/dash/Islemler",
    icon: icon("ic_trans"),
  },
  {
    title: "Fintag Min Max Fiyat Değiştir",
    path: "/dash/FintagMinMaxSetPrice",
    icon: icon("ic_price"),
  },
  // {
  //   title: "Provider İşlemleri",
  //   path: "/dashboard/İşlemler",
  //   icon: icon("ic_wait"),
  // },

  // {
  //   title: 'Bekleyen Grain İşlemleri',
  //   path: '/dashboard/getgraintransactionsbuysell',
  //   icon: icon('ic_providerprice'),
  // },
  // {
  //   title: 'Bekleyen Grain İşlemleri Muhasebe',
  //   path: '/dashboard/getgraintransactions',
  //   icon: icon('ic_wait'),
  // },
  // {
  //   title: 'KuveytTürk Hesapları',
  //   path: '/dashboard/hesaplar',
  //   icon: icon('ic_userz'),
  // },
  // {
  //   title: "Finalized Listeleme",
  //   path: "/dashboard/finalizedlisteleme",
  //   icon: icon("ic_finalized"),
  // },
  // {
  //   title: "Not Position Listeleme",
  //   path: "/dashboard/notpositionlisteleme",
  //   icon: icon("ic_notposition"),
  // },
  // {
  //   title: "Unexpected Listeleme",
  //   path: "/dashboard/unexpectedlisteleme",
  //   icon: icon("ic_unexpected"),
  // },

  // {
  //   title: "All End Points",
  //   path: "/dashboard/allendpoints",
  //   icon: icon("ic_lock"),
  // },

  // {
  //   title: "App to gateway",
  //   path: "/dashboard/apptogatewaytransactions",
  //   icon: icon("ic_lock"),
  // },
  // {
  //   title: "userEmtia",
  //   path: "/dashboard/adduseremtia",
  //   icon: icon("ic_lock"),
  // },
  // {
  //   title: "coming soon",
  //   path: "/dashboard/comingsoon",
  //   icon: icon("ic_lock"),
  // },
];

export default navConfig;
