import { React, useState, useEffect } from "react";
import { Box, Button, Typography } from "@mui/material";
import Chart from "react-apexcharts";
import TextField from "@mui/material/TextField";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import ArrowDownwardIcon from "@mui/icons-material/ArrowDownward";
import dayjs from "dayjs";
const DonutTarım = () => {
  const [val1, setVal1] = useState("darkBlue");
  const [val2, setVal2] = useState("#2065D1");
  const [val3, setVal3] = useState("#2065D1");
  const [val4, setVal4] = useState("#2065D1");
  const [val5, setVal5] = useState("#2065D1");
  const [val6, setVal6] = useState("#2065D1");
  const [val7, setVal7] = useState("#2065D1");

  const [selected, setSelected] = useState("Arpa");
  const [selectedStatus, setSelectedStatus] = useState("Günlük");

  const [timeRange, setTimeRange] = useState(false);
  const [open, setOpen] = useState(false);
  const [startDate, setStartDate] = useState(dayjs());
  const [startDate2, setStartDate2] = useState(dayjs());
  const handleOpen = () => {
    setOpen(!open);
  };

  useEffect(() => {
    selected === "Arpa" ? setVal1("darkBlue") : setVal1("#2065D1");
    selected === "Buğday" ? setVal2("darkBlue") : setVal2("#2065D1");
    selected === "Mısır" ? setVal3("darkBlue") : setVal3("#2065D1");
    selected === "Hepsi" ? setVal4("darkBlue") : setVal4("#2065D1");
  }, [selected]);

  useEffect(() => {
    selectedStatus === "Günlük" ? setVal5("darkBlue") : setVal5("#2065D1");
    selectedStatus === "Aylık" ? setVal6("darkBlue") : setVal6("#2065D1");
    selectedStatus === "Yıllık" ? setVal7("darkBlue") : setVal7("#2065D1");
  }, [selected, selectedStatus]);

  const handleOnChange = (e) => {
    setSelected(e);
    if (e === "Buğday" || "Mısır" || "Hepsi") {
      setSelectedStatus("Günlük");
    }
  };

  const handleChange = (e) => {
    setSelectedStatus(e);
  };

  const options = {
    series:
      selected === "Arpa" && selectedStatus === "Günlük"
        ? [0]
        : selected === "Arpa" && selectedStatus === "Aylık"
        ? [0]
        : selected === "Arpa" && selectedStatus === "Yıllık"
        ? [0]
        : selected === "Buğday" && selectedStatus === "Günlük"
        ? [0]
        : selected === "Buğday" && selectedStatus === "Aylık"
        ? [0]
        : selected === "Buğday" && selectedStatus === "Yıllık"
        ? [0]
        : selected === "Mısır" && selectedStatus === "Günlük"
        ? [0]
        : selected === "Mısır" && selectedStatus === "Aylık"
        ? [0]
        : selected === "Mısır" && selectedStatus === "Yıllık"
        ? [0]
        : selected === "Hepsi" && selectedStatus === "Günlük"
        ? [0]
        : selected === "Hepsi" && selectedStatus === "Aylık"
        ? [0]
        : [0],
    labels:
      selected === "Hepsi"
        ? [
            "Arpa Alım",
            "Arpa Satım",
            "Buğday Alım",
            "Buğday Satım",
            "Mısır Alım",
            "Mısır Satım",
          ]
        : ["Alım", "Satım"],
    chart: {
      type: "donut",
      toolbar: {
        show: true,
        offsetX: 1,
        offsetY: 1,
        tools: {
          download: true,
          selection: true,
          zoom: true,
          zoomin: true,
          zoomout: true,
          pan: true,
          reset: true | '<img src="/static/icons/reset.png" width="20">',
          customIcons: [],
        },
        export: {
          csv: {
            filename:
              selected === "Arpa" && selectedStatus === "Günlük"
                ? "Günlük Arpa Alım-Satım Oranları"
                : selected === "Arpa" && selectedStatus === "Aylık"
                ? "Aylık Arpa Alım-Satım Oranları"
                : selected === "Arpa" && selectedStatus === "Yıllık"
                ? "Yıllık Arpa Alım-Satım Oranları"
                : selected === "Buğday" && selectedStatus === "Günlük"
                ? "Günlük Buğday Alım-Satım Oranları"
                : selected === "Buğday" && selectedStatus === "Aylık"
                ? "Aylık Buğday Alım-Satım Oranları"
                : selected === "Buğday" && selectedStatus === "Yıllık"
                ? "Yıllık Buğday Alım-Satım Oranları"
                : selected === "Mısır" && selectedStatus === "Günlük"
                ? "Günlük Mısır Alım-Satım Oranları"
                : selected === "Mısır" && selectedStatus === "Aylık"
                ? "Aylık Mısır Alım-Satım Oranları"
                : selected === "Mısır" && selectedStatus === "Yıllık"
                ? "Yıllık Mısır Alım-Satım Oranları"
                : selected === "Hepsi" && selectedStatus === "Günlük"
                ? "Günlük Emtia Alım-Satım Oranları"
                : selected === "Hepsi" && selectedStatus === "Aylık"
                ? "Aylık Emtia Alım-Satım Oranları"
                : "Yıllık Emtia Alım-Satım Oranları",
            columnDelimiter: ",",
            headerCategory: "category",
            headerValue: "value",
            dateFormatter(timestamp) {
              return new Date(timestamp).toDateString();
            },
          },
          svg: {
            filename:
              selected === "Arpa" && selectedStatus === "Günlük"
                ? "Günlük Arpa Alım-Satım Oranları"
                : selected === "Arpa" && selectedStatus === "Aylık"
                ? "Aylık Arpa Alım-Satım Oranları"
                : selected === "Arpa" && selectedStatus === "Yıllık"
                ? "Yıllık Arpa Alım-Satım Oranları"
                : selected === "Buğday" && selectedStatus === "Günlük"
                ? "Günlük Buğday Alım-Satım Oranları"
                : selected === "Buğday" && selectedStatus === "Aylık"
                ? "Aylık Buğday Alım-Satım Oranları"
                : selected === "Buğday" && selectedStatus === "Yıllık"
                ? "Yıllık Buğday Alım-Satım Oranları"
                : selected === "Mısır" && selectedStatus === "Günlük"
                ? "Günlük Mısır Alım-Satım Oranları"
                : selected === "Mısır" && selectedStatus === "Aylık"
                ? "Aylık Mısır Alım-Satım Oranları"
                : selected === "Mısır" && selectedStatus === "Yıllık"
                ? "Yıllık Mısır Alım-Satım Oranları"
                : selected === "Hepsi" && selectedStatus === "Günlük"
                ? "Günlük Emtia Alım-Satım Oranları"
                : selected === "Hepsi" && selectedStatus === "Aylık"
                ? "Aylık Emtia Alım-Satım Oranları"
                : "Yıllık Emtia Alım-Satım Oranları",
          },
          png: {
            filename:
              selected === "Arpa" && selectedStatus === "Günlük"
                ? "Günlük Arpa Alım-Satım Oranları"
                : selected === "Arpa" && selectedStatus === "Aylık"
                ? "Aylık Arpa Alım-Satım Oranları"
                : selected === "Arpa" && selectedStatus === "Yıllık"
                ? "Yıllık Arpa Alım-Satım Oranları"
                : selected === "Buğday" && selectedStatus === "Günlük"
                ? "Günlük Buğday Alım-Satım Oranları"
                : selected === "Buğday" && selectedStatus === "Aylık"
                ? "Aylık Buğday Alım-Satım Oranları"
                : selected === "Buğday" && selectedStatus === "Yıllık"
                ? "Yıllık Buğday Alım-Satım Oranları"
                : selected === "Mısır" && selectedStatus === "Günlük"
                ? "Günlük Mısır Alım-Satım Oranları"
                : selected === "Mısır" && selectedStatus === "Aylık"
                ? "Aylık Mısır Alım-Satım Oranları"
                : selected === "Mısır" && selectedStatus === "Yıllık"
                ? "Yıllık Mısır Alım-Satım Oranları"
                : selected === "Hepsi" && selectedStatus === "Günlük"
                ? "Günlük Emtia Alım-Satım Oranları"
                : selected === "Hepsi" && selectedStatus === "Aylık"
                ? "Aylık Emtia Alım-Satım Oranları"
                : "Yıllık Emtia Alım-Satım Oranları",
          },
        },
        autoSelected: "zoom",
      },
    },

    plotOptions: {
      pie: {
        customScale: 0.6,
        dataLabels: {
          position: "bottom",
        },
      },
    },
    legend: {
      position: "bottom",
      horizontalAlign: "center",
      offsetX: 0,
      offsetY: -30,
    },
    title: {
      text:
        selected === "Arpa" && selectedStatus === "Günlük"
          ? "Günlük Arpa Alım-Satım Oranları"
          : selected === "Arpa" && selectedStatus === "Aylık"
          ? "Aylık Arpa Alım-Satım Oranları"
          : selected === "Arpa" && selectedStatus === "Yıllık"
          ? "Yıllık Arpa Alım-Satım Oranları"
          : selected === "Buğday" && selectedStatus === "Günlük"
          ? "Günlük Buğday Alım-Satım Oranları"
          : selected === "Buğday" && selectedStatus === "Aylık"
          ? "Aylık Buğday Alım-Satım Oranları"
          : selected === "Buğday" && selectedStatus === "Yıllık"
          ? "Yıllık Buğday Alım-Satım Oranları"
          : selected === "Mısır" && selectedStatus === "Günlük"
          ? "Günlük Mısır Alım-Satım Oranları"
          : selected === "Mısır" && selectedStatus === "Aylık"
          ? "Aylık Mısır Alım-Satım Oranları"
          : selected === "Mısır" && selectedStatus === "Yıllık"
          ? "Yıllık Mısır Alım-Satım Oranları"
          : selected === "Hepsi" && selectedStatus === "Günlük"
          ? "Günlük Emtia Alım-Satım Oranları"
          : selected === "Hepsi" && selectedStatus === "Aylık"
          ? "Aylık Emtia Alım-Satım Oranları"
          : "Yıllık Emtia Alım-Satım Oranları",
      align: "center",
      offsetX: 0,
      offsetY: 40,
      floating: false,
      style: {
        fontSize: "16px",
        fontWeight: "bold",
        fontFamily: undefined,
        color: "#263238",
      },
    },

    colors:
      selected === "Hepsi"
        ? ["#b9a88f", "#aa5500", "#bb9457", "#731817", "#6d4c3d", "#c9a227"]
        : ["#bb9457", "#731817"],

    dataLabels: {
      enabled: true,
      style: {
        fontSize: "20px",
        colors: ["#fff"],
      },
    },

    responsive: [
      {
        breakpoint: 480,
        options: {
          chart: {
            width: 200,
          },
          legend: {
            position: "bottom",
          },
        },
      },
    ],
  };

  return (
    <LocalizationProvider dateAdapter={AdapterDayjs}>
      <Box sx={{ width: "100%" }}>
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
          }}
        >
          <Button
            onClick={() => {
              handleOpen();
            }}
            variant="outlined"
            endIcon={<ArrowDownwardIcon />}
          >
            {" "}
            Tarih Aralığı{" "}
          </Button>
          {open && (
            <>
              <Box
                style={{
                  display: "flex",
                  flexDirection: "row",
                  width: "100%",
                  alignItems: "center",
                  justifyContent: "center",
                  margin: "1rem 0",
                  gap: "1rem",
                }}
              >
                <DatePicker
                  value={startDate}
                  onChange={(date) => setStartDate(date)}
                  renderInput={(params) => (
                    <TextField {...params} size="small" />
                  )}
                  label="Başlangıç Tarihi"
                  inputFormat="DD/MM/YYYY"
                />
                {timeRange && (
                  <DatePicker
                    value={startDate2}
                    onChange={(date) => setStartDate2(date)}
                    renderInput={(params) => (
                      <TextField {...params} size="small" />
                    )}
                    label="Bitiş Tarihi"
                    inputFormat="DD/MM/YYYY"
                  />
                )}
              </Box>
            </>
          )}
        </Box>

        <Box sx={{ display: "flex", justifyContent: "space-between" }}>
          <Box sx={{ marginTop: "0px" }}>
            <Button
              variant="outlined"
              sx={{ marginRight: "5px", color: `${val1}` }}
              onClick={() => handleOnChange("Arpa")}
            >
              Arpa
            </Button>
            <Button
              variant="outlined"
              sx={{ marginRight: "5px", color: `${val2}` }}
              onClick={() => handleOnChange("Buğday")}
            >
              Buğday
            </Button>
            <Button
              variant="outlined"
              sx={{ marginRight: "5px", color: `${val3}` }}
              onClick={() => handleOnChange("Mısır")}
            >
              Mısır
            </Button>
            <Button
              variant="outlined"
              sx={{ color: `${val4}` }}
              onClick={() => handleOnChange("Hepsi")}
            >
              Hepsi
            </Button>
          </Box>
          <Box sx={{ marginTop: "0px" }}>
            <Button
              variant="outlined"
              size="small"
              sx={{ marginRight: "5px", color: `${val5}` }}
              onClick={() => handleChange("Günlük")}
            >
              Günlük
            </Button>
            <Button
              variant="outlined"
              size="small"
              sx={{ color: `${val6}` }}
              onClick={() => handleChange("Aylık")}
            >
              Zaman Aralığı
            </Button>
          </Box>
        </Box>
        <Box sx={{ justifyContent: "center", marginTop: "1.5rem" }}>
          <Typography
            sx={{
              alignSelf: "center",
              marginLeft: "19.55rem",
              fontWeight: "900",
            }}
          >
            Şu anda veri bulunmamaktadır.
          </Typography>
        </Box>

        <Chart options={options} series={options.series} type="donut" />
      </Box>
    </LocalizationProvider>
  );
};

export default DonutTarım;
