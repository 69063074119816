import { Balance } from "@mui/icons-material";
import {
  Box,
  Button,
  TextField,
  Typography,
  Select,
  MenuItem,
  InputLabel,
  FormControl,
} from "@mui/material";
import React from "react";

function UpdateVendorBalance(props) {
  const [authCode, setAuthCode] = React.useState(
    sessionStorage.getItem("AuthCode")
  );
  const [fetchUrl, setFetchUrl] = React.useState(
    sessionStorage.getItem("FetchUrl")
  );
  const [choosenVendor, setChoosenVendor] = React.useState("");
  const [choosenCommodity, setChoosenCommodity] = React.useState("");
  const [grAmount, setGrAmount] = React.useState("");
  const [note, setNote] = React.useState("");
  const [isBuy, setIsBuy] = React.useState("");
  const [res, setRes] = React.useState([]);
  const [comm, setComm] = React.useState([]);
  const [popup, setPopup] = React.useState(false);
  const [durum, setDurum] = React.useState(false);
  const [message, setMessage] = React.useState("");
  const handleChooseVendor = (name) => {
    setChoosenVendor(name.target.value);
  };
  const handleChooseCommodity = (name) => {
    setChoosenCommodity(name.target.value);
  };
  const handleChooseIsBuy = (name) => {
    setIsBuy(name.target.value);
  };
  React.useEffect(() => {
    fetchedVendor();
    fetchedCommodity();
  }, []);

  async function fetchedVendor() {
    var myHeaders = new Headers();
    myHeaders.append("Authorization", authCode);

    var requestOptions = {
      method: "GET",
      headers: myHeaders,
      redirect: "follow",
    };

    const response = await fetch(
      `${fetchUrl}Admin/GetAll?pageNumber=1`,
      requestOptions
    );
    const fetchedData = await response.json();
    const myData = fetchedData.Data;
    setRes(myData);
  }

  async function fetchedCommodity() {
    var myHeaders = new Headers();
    myHeaders.append("Authorization", authCode);

    var requestOptions = {
      method: "GET",
      headers: myHeaders,
      redirect: "follow",
    };

    const response = await fetch(
      `${fetchUrl}Admin/GetAllCommodity?pageNumber=1`,
      requestOptions
    );
    const fetchedData = await response.json();
    const myData = fetchedData.Data;
    setComm(myData);
  }

  async function fetchedManuelBuySell(
    choosenVendor,
    choosenCommodity,
    grAmount,
    note,
    isBuy
  ) {
    var myHeaders = new Headers();

    myHeaders.append("Authorization", authCode);
    myHeaders.append("Content-Type", "application/json");

    var requestOptions = {
      method: "POST",
      headers: myHeaders,

      redirect: "follow",
    };

    const response = await fetch(
      `${fetchUrl}Admin/ManuelBuySell?vendorId=${choosenVendor}&commoditySuffix=${choosenCommodity}&gramAmount=${grAmount}&note=${note}&isBuy=${isBuy}`,
      requestOptions
    );
    const fetchedData = await response.json();
    fetchedData.Success === true ? setDurum(true) : setDurum(false);
    setMessage(fetchedData.Message);
  }

  return (
    <Box>
      <Box sx={{ marginLeft: "3rem" }}>
        <Typography variant="h4" gutterBottom>
          Manuel Buy Sell
        </Typography>
        <Box
          sx={{
            display: "flex",
            direction: "row",
            gap: "6rem",
            marginTop: "3rem",
          }}
        >
          <Box
            component="form"
            sx={{
              "& > :not(style)": { m: 1, width: "35ch" },
              display: "flex",
              flexDirection: "column",
              gap: 2,
              backgroundColor: "rgba(232, 219, 176, 0.5)",

              borderRadius: "0.5rem",
              justifyContent: "center",
              alignItems: "flex-start",
              padding: "2rem",
            }}
            noValidate
            autoComplete="off"
          >
            <FormControl sx={{ width: "160px" }}>
              <InputLabel id="demo-simple-select-label">
                Vendor Seçiniz
              </InputLabel>
              <Select
                labelId="demo-simple-select-label"
                id="demo-simple-select"
                value={choosenVendor}
                label="Vendor Seçiniz"
                onChange={handleChooseVendor}
              >
                {res.map((res) => (
                  <MenuItem key={res.Id} value={res.Id}>
                    {res.Name}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
            <FormControl sx={{ width: "160px" }}>
              <InputLabel id="demo-simple-select-label">
                Commodity Seçiniz
              </InputLabel>
              <Select
                labelId="demo-simple-select-label"
                id="demo-simple-select"
                value={choosenCommodity}
                label="Commodity Seçiniz"
                onChange={handleChooseCommodity}
              >
                {comm.map((comm) => (
                  <MenuItem key={comm.Suffix} value={comm.Suffix}>
                    {comm.Name}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>

            <TextField
              type="number"
              id="standard-required"
              label={"Miktar giriniz"}
              defaultValue=""
              variant="standard"
              onChange={(event) => setGrAmount(event.target.value)}
              value={grAmount}
            />
            <TextField
              id="standard-required"
              label={"Not giriniz"}
              defaultValue=""
              variant="standard"
              onChange={(event) => setNote(event.target.value)}
              value={note}
            />
            <FormControl sx={{ width: "160px" }}>
              <InputLabel id="demo-simple-select-label">
                Alış/Satış Seçiniz
              </InputLabel>
              <Select
                labelId="demo-simple-select-label"
                id="demo-simple-select"
                value={isBuy}
                label="Alış/Satış Seçiniz"
                onChange={handleChooseIsBuy}
                defaultValue="true"
              >
                <MenuItem value={"true"}>Alış</MenuItem>
                <MenuItem value={"false"}>Satış</MenuItem>
              </Select>
            </FormControl>

            <Box sx={{ marginTop: "1rem" }}>
              {grAmount !== "" && note !== "" ? (
                <Button
                  variant="contained"
                  onClick={() =>
                    fetchedManuelBuySell(
                      choosenVendor,
                      choosenCommodity,
                      grAmount,
                      note,
                      isBuy
                    )
                  }
                >
                  Oluştur
                </Button>
              ) : (
                <Button variant="contained" disabled>
                  Oluştur
                </Button>
              )}
            </Box>
            {message.length > 0 && (
              <Typography
                sx={{
                  color: durum ? "green" : "red",
                  fontWeight: "bold",
                  marginTop: "1rem",
                }}
              >
                {message}
              </Typography>
            )}
          </Box>
        </Box>
      </Box>
    </Box>
  );
}

export default UpdateVendorBalance;
