import React from "react";
import { Box, Button } from "@mui/material";
import Chart from "react-apexcharts";
import DatePicker from "react-multi-date-picker";
import ArrowDownwardIcon from "@mui/icons-material/ArrowDownward";

const Hemtia = () => {
  const [startDate, setStartDate] = React.useState(new Date());
  const [startDate2, setStartDate2] = React.useState(new Date());

  const newDate = new Date();
  const day = newDate.getDate();
  const month = newDate.getMonth() + 1;
  const year = newDate.getFullYear();

  const [dateValue, setDateValue] = React.useState(day);
  const [monthValue, setMonthValue] = React.useState(month);
  const [yearValue, setYearValue] = React.useState(year);

  const [dateValue2, setDateValue2] = React.useState(day);
  const [monthValue2, setMonthValue2] = React.useState(month);
  const [yearValue2, setYearValue2] = React.useState(year);

  const [authCode, setAuthCode] = React.useState(
    sessionStorage.getItem("AuthCode")
  );
  const [fetchUrl, setFetchUrl] = React.useState(
    sessionStorage.getItem("FetchUrl")
  );

  const [isGold, setGold] = React.useState();
  const [isSilver, setSilver] = React.useState();
  const [isPlatin, setPlatin] = React.useState();

  const [silverVolume, setSilverVolume] = React.useState(0);
  const [goldVolume, setGoldVolume] = React.useState(0);
  const [platinVolume, setPlatinVolume] = React.useState(0);

  let silverTotal = 0;
  let goldTotal = 0;
  let platinTotal = 0;

  const [open, setOpen] = React.useState(false);

  React.useEffect(() => {
    getDateX();
    getDate2();
    fetchData();
  }, [startDate2]);

  const handleOpen = () => {
    setOpen(!open);
  };

  function getDateX() {
    if (
      startDate.day === undefined &&
      startDate.month === undefined &&
      startDate.monthIndex === undefined
    ) {
      setDateValue(day);
      setMonthValue(month);
      setYearValue(year);
    } else {
      setDateValue(startDate.day);
      setYearValue(startDate.year);

      switch (startDate.monthIndex) {
        case 0:
          setMonthValue(parseFloat("01"));
          break;
        case 1:
          setMonthValue(parseFloat("02"));
          break;
        case 2:
          setMonthValue(parseFloat("03"));
          break;
        case 3:
          setMonthValue(parseFloat("04"));
          break;
        case 4:
          setMonthValue(parseFloat("05"));
          break;
        case 5:
          setMonthValue(parseFloat("06"));
          break;
        case 6:
          setMonthValue(parseFloat("07"));
          break;
        case 7:
          setMonthValue(parseFloat("08"));
          break;
        case 8:
          setMonthValue(parseFloat("09"));
          break;
        case 9:
          setMonthValue(parseFloat("10"));
          break;
        case 10:
          setMonthValue(parseFloat("11"));
          break;
        case 11:
          setMonthValue(parseFloat("12"));
          break;
      }
    }
  }
  function getDate2() {
    if (
      startDate2.day === undefined &&
      startDate2.month === undefined &&
      startDate2.monthIndex === undefined
    ) {
      setDateValue2(day);
      setMonthValue2(month);
      setYearValue2(year);
    } else {
      setDateValue2(startDate2.day);
      setYearValue2(startDate2.year);

      switch (startDate2.monthIndex) {
        case 0:
          setMonthValue2(parseFloat("01"));
          break;
        case 1:
          setMonthValue2(parseFloat("02"));
          break;
        case 2:
          setMonthValue2(parseFloat("03"));
          break;
        case 3:
          setMonthValue2(parseFloat("04"));
          break;
        case 4:
          setMonthValue2(parseFloat("05"));
          break;
        case 5:
          setMonthValue2(parseFloat("06"));
          break;
        case 6:
          setMonthValue2(parseFloat("07"));
          break;
        case 7:
          setMonthValue2(parseFloat("08"));
          break;
        case 8:
          setMonthValue2(parseFloat("09"));
          break;
        case 9:
          setMonthValue2(parseFloat("10"));
          break;
        case 10:
          setMonthValue2(parseFloat("11"));
          break;
        case 11:
          setMonthValue2(parseFloat("12"));
          break;
      }
    }
  }

  async function fetchData() {
    var myHeaders = new Headers();
    myHeaders.append("Authorization", authCode);

    var requestOptions = {
      method: "GET",
      headers: myHeaders,
      redirect: "follow",
    };

    const response = await fetch(
      `${fetchUrl}Admin/GetHourlyStaticsticsByDateRange?startHour=0&endDate=${monthValue2}%2F${dateValue2}%2F${yearValue2}&endHour=24&startDate=${monthValue}%2F${dateValue}%2F${yearValue}`,
      requestOptions
    );
    const fetchedData = await response.json();
    const stringfyData = fetchedData.Data;

    for (let i = 0; i < stringfyData.length; i++) {
      if (
        stringfyData[i].CommodityId.includes(
          "7BE7C383-03E0-4A29-9564-1772BF0E996E"
        ) === false
      ) {
        setGold(false);
      } else if (
        stringfyData[i].CommodityId.includes(
          "5D713008-15BB-4509-BE0D-B2F9355E8FF4"
        ) === false
      ) {
        setSilver(false);
      } else if (
        stringfyData[i].CommodityId.includes(
          "8FC928D5-D9F9-4ABD-9859-567883AA3F06"
        ) === false
      ) {
        setPlatin(false);
      }
    }

    if (isGold === false) {
      setGoldVolume(0);
    } else if (isSilver === false) {
      setSilverVolume(0);
    } else if (isPlatin === false) {
      setPlatinVolume(0);
    }

    if (stringfyData.length === 0) {
      setGoldVolume(0);
      setGoldVolume(0);
      setPlatinVolume(0);
    } else {
      for (let i = 0; i < stringfyData.length; i++) {
        if (
          stringfyData[i].CommodityId === "5D713008-15BB-4509-BE0D-B2F9355E8FF4"
        ) {
          silverTotal += stringfyData[i].TotalTlAmount;
          setSilverVolume(silverTotal);
        } else if (
          stringfyData[i].CommodityId === "7BE7C383-03E0-4A29-9564-1772BF0E996E"
        ) {
          goldTotal += stringfyData[i].TotalTlAmount;
          setGoldVolume(goldTotal);
        } else if (
          stringfyData[i].CommodityId === "8FC928D5-D9F9-4ABD-9859-567883AA3F06"
        ) {
          platinTotal += stringfyData[i].TotalTlAmount;
          setPlatinVolume(platinTotal);
        }
      }
    }
  }

  const options = {
    series: [
      {
        data: [Math.floor(goldVolume)],
        color: "#c9a227",
        name: "Altın",
      },
      {
        data: [Math.floor(silverVolume)],
        color: "#adb5bd",
        name: "Gümüş",
      },
      {
        data: [Math.floor(platinVolume)],
        color: "#e0e2db",
        name: "Platin",
      },
    ],

    chart: {
      toolbar: {
        show: true,
        offsetX: 0,
        offsetY: 0,
        tools: {
          download: true,
          selection: true,
          zoom: true,
          zoomin: true,
          zoomout: true,
          pan: true,
          reset: true | '<img src="/static/icons/reset.png" width="20">',
          customIcons: [],
        },
        export: {
          csv: {
            filename: `Daily_Volume_${dateValue}-${monthValue}_${dateValue2}-${monthValue2}`,
            columnDelimiter: ",",
            headerCategory: "category",
            headerValue: "value",
            dateFormatter(timestamp) {
              return new Date(timestamp).toDateString();
            },
          },
          svg: {
            filename: `Daily_Volume_${dateValue}-${monthValue}_${dateValue2}-${monthValue2}`,
          },
          png: {
            filename: `Daily_Volume_${dateValue}-${monthValue}_${dateValue2}-${monthValue2}`,
          },
        },
        autoSelected: "zoom",
      },
    },

    plotOptions: {
      bar: {
        horizontal: true,
        dataLabels: {
          position: "top",
        },
      },
    },

    dataLabels: {
      enabled: true,
      offsetX: -6,
      style: {
        fontSize: "12px",
        colors: ["#000"],
      },
    },
    stroke: {
      show: true,
      width: 1,
      colors: ["#c9a227", "#adb5bd", "#D9DCD4"],
    },

    tooltip: {
      shared: true,
      intersect: false,
    },
    xaxis: {
      categories: [
        `${dateValue}/${monthValue} ~ ${dateValue2}/${monthValue2} `,
      ],
    },
    title: {
      text: "Günlük Hacimler",
      align: "left",
      margin: 10,
      offsetX: 0,
      offsetY: 0,
      floating: false,
      style: {
        fontSize: "16px",
        fontWeight: "bold",
        color: "#263238",
      },
    },
    subtitle: {
      text: "Türk Lirasına göre hesaplanmıştır.",
      align: "center",
      margin: 10,
      offsetX: 0,
      offsetY: 25,
      floating: false,
      style: {
        fontSize: "12px",
        fontWeight: "normal",
        fontFamily: undefined,
        color: "#9699a2",
      },
    },
  };

  return (
    <Box sx={{ width: "100%" }}>
      <Box
        sx={{ display: "flex", flexDirection: "column", alignItems: "center" }}
      >
        <Button
          onClick={() => {
            handleOpen();
          }}
          variant="outlined"
          endIcon={<ArrowDownwardIcon />}
        >
          {" "}
          Tarih Aralığı{" "}
        </Button>
        {open && (
          <Box
            sx={{
              flexDirection: "row",
              gap: "1rem",
              display: "flex",
              marginTop: "1rem",
            }}
          >
            <DatePicker
              selected={startDate}
              onChange={(date) => setStartDate(date)}
              placeholder="Başlangıç Tarihi"
            />
            <DatePicker
              selected={startDate2}
              onChange={(date) => setStartDate2(date)}
              placeholder="Bitiş Tarihi"
            />
          </Box>
        )}
      </Box>
      <Chart type="bar" options={options} series={options.series} />
    </Box>
  );
};

export default Hemtia;
