import { Typography } from "@mui/material";
import { Box } from "@mui/system";
import React from "react";
import { useLocation } from "react-router-dom";
import AuthForm from "src/sectionsCrypto/auth/login/AuthForm";

function AuthCrypto(props) {
  const [mail, setMail] = React.useState();
  const location = useLocation();

  React.useEffect(() => {
    setMail(location.state.mailValue);
  }, []);

  return (
    <Box
      sx={{
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        width: "100%",
        height: "100%",
        flexDirection: "column",
      }}
    >
      <Typography
        sx={{ fontSize: 24, marginBottom: "0.5rem", textAlign: "center" }}
      >
        Lütfen aşağıdaki mailinize gelen doğruluma kodunu girin.
      </Typography>
      <Typography sx={{ textDecoration: "underline" }}>
        {location.state.mailValue}
      </Typography>
      <AuthForm mail={mail} />
    </Box>
  );
}

export default AuthCrypto;
