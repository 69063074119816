import * as React from "react";

import { Box, Button, Typography, Snackbar } from "@mui/material";

import FileDownloadIcon from "@mui/icons-material/FileDownload";
import { ExportToCsv } from "export-to-csv";
import MaterialReactTable from "material-react-table";
import CircularProgress from "@mui/material/CircularProgress";
import Backdrop from "@mui/material/Backdrop";
import { useNavigate } from "react-router-dom";
import { useLocation } from "react-router-dom";
import MuiAlert from "@mui/material/Alert";

export default function TransferlerUserId() {
  const [res, setRes] = React.useState([]);
  const navigate = useNavigate();
  const [range, setRange] = React.useState([]);
  const [rangeById, setRangeById] = React.useState([]);
  const location = useLocation();
  const { data } = location.state;
  const [id, setId] = React.useState("");

  const [open, setOpen] = React.useState(false);
  const handleChange = (event) => {
    setVendorId(event.target.value);
  };

  const [choosenVendor, setChoosenVendor] = React.useState("");
  const handleChooseVendor = (name) => {
    setStatus(false);
    setChoosenVendor(name.target.value);
  };
  const handleChooseUser = (name) => {
    setStatus(false);
    setChoosenUser(name.target.value);
  };
  const [authCode, setAuthCode] = React.useState(
    sessionStorage.getItem("AuthCode")
  );
  const [fetchUrl, setFetchUrl] = React.useState(
    sessionStorage.getItem("FetchUrl")
  );

  const [vendorId, setVendorId] = React.useState(0);
  const [emtiaId, setEmtiaId] = React.useState("");
  const [choosenUser, setChoosenUser] = React.useState("");
  const [messageUser, setMessageUser] = React.useState("");

  const [status, setStatus] = React.useState(false);

  const [userStatus, setUserStatus] = React.useState(true);

  const [alertmessage, setAlertMessage] = React.useState("");
  const [alertStatus, setAlertStatus] = React.useState(Boolean);
  const [openAlert, setOpenAlert] = React.useState(false);

  const Alert = React.forwardRef(function Alert(props, ref) {
    return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
  });

  const handleAlertClick = () => {
    setOpenAlert(true);
  };

  const handleAlertClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }

    setOpenAlert(false);
  };

  React.useEffect(() => {
    fetchedTransferUserId();
  }, []);

  async function fetchedTransferUserId() {
    setOpen(true);
    var myHeaders = new Headers();
    myHeaders.append("Authorization", authCode);

    var requestOptions = {
      method: "POST",
      headers: myHeaders,
      redirect: "follow",
    };

    const response = await fetch(
      `${fetchUrl}Admin/GetVendorUserTransferByUserIdForAdmin?pageNumber=1&userId=${data.id}`,
      requestOptions
    );
    const fetchedData = await response.json();
    console.log(fetchedData);
    setTimeout(() => {
      setOpen(false);
    }, 1000);
    setTimeout(() => {
      setMessageUser("");
    }, 3000);
    const myData = fetchedData.Data;
    setMessageUser(fetchedData.Message);
    var endpointsArr = [];
    for (let i = 0; i < myData.length; i++) {
      endpointsArr.push({
        FromUserId: myData[i].FromUserId,
        ToUserId: myData[i].ToUserId,
        CommodityName: myData[i].CommodityName,
        Amount: myData[i].Amount,
        FromFee: myData[i].FromFee,
        ToFee: myData[i].ToFee,
        CreatedDate: myData[i].CreatedDate,
      });
    }
    setRange(endpointsArr);

    setAlertMessage(fetchedData.Message);
    setAlertStatus(fetchedData.Success);
    handleAlertClick();
  }
  const columns = React.useMemo(
    () => [
      {
        accessorKey: "FromUserId",
        header: "FromUserId",
        size: 30,
      },
      {
        accessorKey: "ToUserId",
        header: "ToUserId",
        size: 120,
      },
      {
        accessorKey: "CommodityName",
        header: "CommodityName",
        size: 120,
      },
      {
        accessorKey: "Amount",
        header: "Amount",
        size: 30,
      },
      {
        accessorKey: "FromFee",
        header: "FromFee",
        size: 30,
      },
      {
        accessorKey: "ToFee",
        header: "ToFee",
        size: 20,
      },
      {
        accessorKey: "CreatedDate",
        header: "CreatedDate",
        size: 30,
      },
    ],
    []
  );
  const csvOptions = {
    fieldSeparator: ";",
    quoteStrings: '"',
    decimalSeparator: ".",
    showLabels: true,
    useBom: true,
    useKeysAsHeaders: false,
    headers: columns.map((c) => c.header),
    filename: "Transferler",
  };

  const csvExporter = new ExportToCsv(csvOptions);

  const handleExportRows = (rows) => {
    const filteredRows = rows.map((row) => {
      const { VendorUserId, ...rest } = row.original; // Date sütununu ayırıyoruz
      return rest;
    });
    csvExporter.generateCsv(filteredRows);
  };

  const handleExportData = () => {
    const filteredData = range.map((row) => {
      const { VendorUserId, ...rest } = row; // Date sütununu ayırıyoruz
      return rest;
    });
    csvExporter.generateCsv(filteredData);
  };

  return (
    <Box sx={{ marginLeft: "3rem" }}>
      <Typography variant="h4" gutterBottom>
        Transferler (User Id'ye göre)
      </Typography>
      <Backdrop
        sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
        open={open}
      >
        <CircularProgress color="inherit" />
      </Backdrop>
      <Box
        sx={{
          gap: "5rem",
          alignItems: "center",
          display: "flex",
          marginBottom: "2rem",
          marginTop: "2rem",
        }}
      >
        <Button
          variant="contained"
          onClick={() => navigate("/dashboard/transferler")}
        >
          Geri dön
        </Button>
      </Box>

      <MaterialReactTable
        columns={columns}
        data={range}
        enableRowSelection
        positionToolbarAlertBanner="bottom"
        renderTopToolbarCustomActions={({ table }) => (
          <Box
            sx={{
              display: "flex",
              gap: "1rem",
              p: "0.5rem",
              flexWrap: "wrap",
            }}
          >
            <Button
              color="primary"
              onClick={handleExportData}
              startIcon={<FileDownloadIcon />}
              variant="outlined"
            >
              Tüm Verileri Dışa Aktar
            </Button>
            <Button
              disabled={table.getPrePaginationRowModel().rows.length === 0}
              onClick={() =>
                handleExportRows(table.getPrePaginationRowModel().rows)
              }
              startIcon={<FileDownloadIcon />}
              variant="outlined"
            >
              Tüm Satırları Dışa Aktar
            </Button>
            <Button
              disabled={table.getRowModel().rows.length === 0}
              onClick={() => handleExportRows(table.getRowModel().rows)}
              startIcon={<FileDownloadIcon />}
              variant="outlined"
            >
              Bu Sayfadaki Satırları Dışa Aktar
            </Button>
            <Button
              disabled={
                !table.getIsSomeRowsSelected() && !table.getIsAllRowsSelected()
              }
              onClick={() => handleExportRows(table.getSelectedRowModel().rows)}
              startIcon={<FileDownloadIcon />}
              variant="outlined"
            >
              Seçilmiş Satırları Dışa Aktar
            </Button>
          </Box>
        )}
      />

      <Snackbar
        open={openAlert}
        onClose={handleAlertClose}
        autoHideDuration={2000}
      >
        <Alert severity={alertStatus ? "success" : "error"}>
          {alertmessage}
        </Alert>
      </Snackbar>
    </Box>
  );
}
