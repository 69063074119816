import React from 'react'
import {Typography,Box,Button} from '@mui/material';
import Chart from "react-apexcharts";
import DatePicker from "react-multi-date-picker"
import ArrowDownwardIcon from '@mui/icons-material/ArrowDownward';
const Htarım = () => {
  const [startDate, setStartDate] = React.useState(new Date());
  const [startDate2, setStartDate2] = React.useState(new Date());
  const [open,setOpen] = React.useState(false)
  const handleOpen = (() => {
    setOpen(!open)
  }) 
  const options = {
    series: [{
    data: [0],
    color: '#5a380b',
    name: 'Arpa',
  }, {
    data: [0],
    color: '#ed862b',
    name: 'Buğday',
  },{
    data: [0],
    color: '#dc5713',
    name: 'Mısır',
  }
],
  
  plotOptions: {
    bar: {
      horizontal: true,
      dataLabels: {
        position: 'top',
      },
    }
  },
  dataLabels: {
    enabled: true,
    offsetX: -6,
    style: {
      fontSize: '12px',
      colors: ['#000']
    }
  },
  stroke: {
    show: false,
    width: 1,
    colors: ['#5a380b','#ed862b','#dc5713']
  },
  tooltip: {
    shared: true,
    intersect: false
  },
  xaxis: {
    categories: ["Eylül", "Ekim", "Kasım", "Aralık", "Ocak"],
  },
  };
  

  return (
    <Box sx={{width:'100%'}}>
         <Typography sx={{fontWeight:'bold'}}>Aylık Hacimler</Typography>
         <Box sx={{display:"flex",flexDirection:"column",alignItems:"center"}}>
         <Button onClick={() =>{handleOpen()}} variant="outlined" endIcon={<ArrowDownwardIcon/>}> Tarih Aralığı </Button>
                { open && 
                <Box sx={{flexDirection:"row",gap:"1rem",display:"flex",marginTop:"1rem"}}>
                  <DatePicker  selected={startDate} onChange={(date) => setStartDate(date)} placeholder='Başlangıç Tarihi'/>
                  <DatePicker  selected={startDate2} onChange={(date) => setStartDate2(date)} placeholder='Bitiş Tarihi'/>
                </Box>
                }
          </Box>
          <Box sx={{justifyContent:'center',marginTop:"1.5rem"}}>
  <Typography sx={{alignSelf:'center',marginLeft:'19.55rem',fontWeight:'900'}}>Şu anda veri bulunmamaktadır.</Typography>
          </Box>
         <Chart
        type="bar"

      
        options={options}
        series={options.series}
      />
    </Box>
  )
}

export default Htarım