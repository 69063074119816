import { React, useState, useEffect } from "react";
import { Box, Button, Typography } from "@mui/material";
import Chart from "react-apexcharts";
import ArrowDownwardIcon from "@mui/icons-material/ArrowDownward";

//
import TextField from "@mui/material/TextField";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import dayjs from "dayjs";

const DonutEmtia = () => {
  const [authCode, setAuthCode] = useState(sessionStorage.getItem("AuthCode"));
  const [fetchUrl, setFetchUrl] = useState(sessionStorage.getItem("FetchUrl"));

  const [timeRange, setTimeRange] = useState(false);
  const [open, setOpen] = useState(false);

  let newDate = new Date();
  let date = newDate.getDate();
  let month = newDate.getMonth() + 1;
  let year = newDate.getFullYear();

  const [startDate, setStartDate] = useState(dayjs());
  const [startDate2, setStartDate2] = useState(dayjs());

  const [isGold, setGold] = useState();
  const [isSilver, setSilver] = useState();
  const [isPlatin, setPlatin] = useState();

  const [isGoldRange, setGoldRange] = useState();
  const [isSilverRange, setSilverRange] = useState();
  const [isPlatinRange, setPlatinRange] = useState();

  const [goldSell, setGoldSell] = useState(0);
  const [goldBuy, setGoldBuy] = useState(0);
  const [goldSellRange, setGoldSellRange] = useState(0);
  const [goldBuyRange, setGoldBuyRange] = useState(0);

  const [platinSell, setPlatinSell] = useState(0);
  const [platinBuy, setPlatinBuy] = useState(0);
  const [platinSellRange, setPlatinSellRange] = useState(0);
  const [platinBuyRange, setPlatinBuyRange] = useState(0);

  const [silverSell, setSilverSell] = useState(0);
  const [silverBuy, setSilverBuy] = useState(0);
  const [silverSellRange, setSilverSellRange] = useState(0);
  const [silverBuyRange, setSilverBuyRange] = useState(0);

  let silverBuyTotal = 0;
  let silverSellTotal = 0;

  let goldBuyTotal = 0;
  let goldSellTotal = 0;

  let platinBuyTotal = 0;
  let platinSellTotal = 0;

  const handleOpen = () => {
    setOpen(!open);
  };

  useEffect(() => {
    if (selectedStatus === "Günlük") {
      fetchData();
    } else {
      fetchDataforRange();
    }
  }, [startDate, startDate2]);

  async function fetchData() {
    var myHeaders = new Headers();
    myHeaders.append("Authorization", authCode);

    var requestOptions = {
      method: "GET",
      headers: myHeaders,
      redirect: "follow",
    };

    let zeroValue = 0;
    if (startDate.$M + 1 > 9) {
      zeroValue = "";
    }

    const response = await fetch(
      `${fetchUrl}Admin/GetDailyStaticstics?date=${zeroValue}${
        startDate.$M + 1
      }%2F${startDate.$D}%2F${startDate.$y}\n`,
      requestOptions
    );
    const fetchedData = await response.json();
    const stringfyData = fetchedData.Data;

    for (let i = 0; i < stringfyData.length; i++) {
      if (
        stringfyData[i].CommodityId.includes(
          "7BE7C383-03E0-4A29-9564-1772BF0E996E"
        ) === false
      ) {
        setGold(false);
      } else if (
        stringfyData[i].CommodityId.includes(
          "5D713008-15BB-4509-BE0D-B2F9355E8FF4"
        ) === false
      ) {
        setSilver(false);
      } else if (
        stringfyData[i].CommodityId.includes(
          "8FC928D5-D9F9-4ABD-9859-567883AA3F06"
        ) === false
      ) {
        setPlatin(false);
      }
    }
    if (isGold === false) {
      setGoldBuy(0);
      setGoldSell(0);
    } else if (isSilver === false) {
      setSilverBuy(0);
      setSilverSell(0);
    } else if (isPlatin === false) {
      setPlatinBuy(0);
      setPlatinSell(0);
    }

    if (stringfyData.length === 0) {
      setSilverBuy(0);
      setSilverSell(0);
      setGoldBuy(0);
      setGoldSell(0);
      setPlatinBuy(0);
      setPlatinSell(0);
    } else {
      for (let i = 0; i < stringfyData.length; i++) {
        if (
          stringfyData[i].CommodityId === "7BE7C383-03E0-4A29-9564-1772BF0E996E"
        ) {
          setGoldBuy(stringfyData[i].TotalTlAmountBuy);
          setGoldSell(stringfyData[i].TotalTlAmountSell);
        } else if (
          stringfyData[i].CommodityId === "5D713008-15BB-4509-BE0D-B2F9355E8FF4"
        ) {
          setSilverBuy(stringfyData[i].TotalTlAmountBuy);
          setSilverSell(stringfyData[i].TotalTlAmountSell);
        } else if (
          stringfyData[i].CommodityId === "8FC928D5-D9F9-4ABD-9859-567883AA3F06"
        ) {
          setPlatinBuy(stringfyData[i].TotalTlAmountBuy);
          setPlatinSell(stringfyData[i].TotalTlAmountSell);
        }
      }
    }
  }

  async function fetchDataforRange() {
    var myHeaders = new Headers();
    myHeaders.append("Authorization", authCode);

    var requestOptions = {
      method: "GET",
      headers: myHeaders,
      redirect: "follow",
    };

    let zeroValue = 0;
    if (startDate.$M + 1 > 9) {
      zeroValue = "";
    }

    let zeroValue2 = 0;
    if (startDate2.$M + 1 > 9) {
      zeroValue2 = "";
    }

    const response = await fetch(
      `${fetchUrl}Admin/GetHourlyStaticsticsByDateRange?startHour=0&endDate=${zeroValue2}${
        startDate2.$M + 1
      }%2F${startDate2.$D}%2F${
        startDate2.$y
      }&endHour=24&startDate=${zeroValue}${startDate.$M + 1}%2F${
        startDate.$D
      }%2F${startDate.$y}`,
      requestOptions
    );
    const fetchedData = await response.json();
    const stringfyData = fetchedData.Data;

    for (let i = 0; i < stringfyData.length; i++) {
      if (
        stringfyData[i].CommodityId.includes(
          "7BE7C383-03E0-4A29-9564-1772BF0E996E"
        ) === false
      ) {
        setGoldRange(false);
      } else if (
        stringfyData[i].CommodityId.includes(
          "5D713008-15BB-4509-BE0D-B2F9355E8FF4"
        ) === false
      ) {
        setSilverRange(false);
      } else if (
        stringfyData[i].CommodityId.includes(
          "8FC928D5-D9F9-4ABD-9859-567883AA3F06"
        ) === false
      ) {
        setPlatinRange(false);
      }
    }

    if (isGoldRange === false) {
      setGoldBuyRange(0);
      setGoldSellRange(0);
    } else if (isSilverRange === false) {
      setSilverBuyRange(0);
      setSilverSellRange(0);
    } else if (isPlatinRange === false) {
      setPlatinBuyRange(0);
      setPlatinSellRange(0);
    }

    if (stringfyData.length === 0) {
      setSilverBuyRange(0);
      setSilverSellRange(0);
      setGoldBuyRange(0);
      setGoldSellRange(0);
      setPlatinBuyRange(0);
      setPlatinSellRange(0);
    } else {
      for (let i = 0; i < stringfyData.length; i++) {
        if (
          stringfyData[i].CommodityId === "5D713008-15BB-4509-BE0D-B2F9355E8FF4"
        ) {
          silverBuyTotal += stringfyData[i].TotalTlAmountBuy;
          silverSellTotal += stringfyData[i].TotalTlAmountSell;

          setSilverBuyRange(silverBuyTotal);
          setSilverSellRange(silverSellTotal);
        } else if (
          stringfyData[i].CommodityId === "7BE7C383-03E0-4A29-9564-1772BF0E996E"
        ) {
          goldBuyTotal += stringfyData[i].TotalTlAmountBuy;
          goldSellTotal += stringfyData[i].TotalTlAmountSell;

          setGoldBuyRange(goldBuyTotal);
          setGoldSellRange(goldSellTotal);
        } else if (
          stringfyData[i].CommodityId === "8FC928D5-D9F9-4ABD-9859-567883AA3F06"
        ) {
          platinBuyTotal += stringfyData[i].TotalTlAmountBuy;
          platinSellTotal += stringfyData[i].TotalTlAmountSell;

          setPlatinBuyRange(platinBuyTotal);
          setPlatinSellRange(platinSellTotal);
        }
      }
    }
  }

  const [val1, setVal1] = useState("darkBlue");
  const [val2, setVal2] = useState("#2065D1");
  const [val3, setVal3] = useState("#2065D1");
  const [val4, setVal4] = useState("#2065D1");
  const [val5, setVal5] = useState("#2065D1");
  const [val6, setVal6] = useState("#2065D1");
  const [val7, setVal7] = useState("#2065D1");

  const [selected, setSelected] = useState("Altın");
  const [selectedStatus, setSelectedStatus] = useState("Günlük");

  useEffect(() => {
    selected === "Altın" ? setVal1("darkBlue") : setVal1("#2065D1");
    selected === "Gümüş" ? setVal2("darkBlue") : setVal2("#2065D1");
    selected === "Platin" ? setVal3("darkBlue") : setVal3("#2065D1");
    selected === "Hepsi" ? setVal4("darkBlue") : setVal4("#2065D1");
  }, [selected]);

  useEffect(() => {
    selectedStatus === "Günlük" ? setVal5("darkBlue") : setVal5("#2065D1");
    selectedStatus === "Aylık" ? setVal6("darkBlue") : setVal6("#2065D1");
  }, [selected, selectedStatus]);

  const handleOnChange = (e) => {
    setSelected(e);
  };

  const handleChange = (e) => {
    setSelectedStatus(e);
  };

  const options = {
    series:
      selected === "Altın" && selectedStatus === "Günlük"
        ? [goldBuy, goldSell]
        : selected === "Altın" && selectedStatus === "Aylık"
        ? [goldBuyRange, goldSellRange]
        : selected === "Altın" && selectedStatus === "Yıllık"
        ? [42, 32]
        : selected === "Gümüş" && selectedStatus === "Günlük"
        ? [silverBuy, silverSell]
        : selected === "Gümüş" && selectedStatus === "Aylık"
        ? [silverBuyRange, silverSellRange]
        : selected === "Gümüş" && selectedStatus === "Yıllık"
        ? [45, 55]
        : selected === "Platin" && selectedStatus === "Günlük"
        ? [platinBuy, platinSell]
        : selected === "Platin" && selectedStatus === "Aylık"
        ? [platinBuyRange, platinSellRange]
        : selected === "Platin" && selectedStatus === "Yıllık"
        ? [40, 30]
        : selected === "Hepsi" && selectedStatus === "Günlük"
        ? [goldBuy, goldSell, silverBuy, silverSell, platinBuy, platinSell]
        : selected === "Hepsi" && selectedStatus === "Aylık"
        ? [
            goldBuyRange,
            goldSellRange,
            silverBuyRange,
            silverSellRange,
            platinBuyRange,
            platinSellRange,
          ]
        : "",

    labels:
      selected === "Hepsi"
        ? [
            "Altın Alım",
            "Altın Satım",
            "Gümüş Alım",
            "Gümüş Satım",
            "Platin Alım",
            "Platin Satım",
          ]
        : ["Alım", "Satım"],
    chart: {
      type: "donut",
      toolbar: {
        show: true,
        offsetX: 1,
        offsetY: 1,
        tools: {
          download: true,
          selection: true,
          zoom: true,
          zoomin: true,
          zoomout: true,
          pan: true,
          reset: true | '<img src="/static/icons/reset.png" width="20">',
          customIcons: [],
        },
        export: {
          csv: {
            filename:
              selected === "Altın" && selectedStatus === "Günlük"
                ? "Günlük Altın Alım-Satım Oranları"
                : selected === "Altın" && selectedStatus === "Aylık"
                ? "Aylık Altın Alım-Satım Oranları"
                : selected === "Altın" && selectedStatus === "Yıllık"
                ? "Yıllık Altın Alım-Satım Oranları"
                : selected === "Gümüş" && selectedStatus === "Günlük"
                ? "Günlük Gümüş Alım-Satım Oranları"
                : selected === "Gümüş" && selectedStatus === "Aylık"
                ? "Aylık Gümüş Alım-Satım Oranları"
                : selected === "Gümüş" && selectedStatus === "Yıllık"
                ? "Yıllık Gümüş Alım-Satım Oranları"
                : selected === "Platin" && selectedStatus === "Günlük"
                ? "Günlük Platin Alım-Satım Oranları"
                : selected === "Platin" && selectedStatus === "Aylık"
                ? "Aylık Platin Alım-Satım Oranları"
                : selected === "Platin" && selectedStatus === "Yıllık"
                ? "Yıllık Platin Alım-Satım Oranları"
                : selected === "Hepsi" && selectedStatus === "Günlük"
                ? "Günlük Emtia Alım-Satım Oranları"
                : selected === "Hepsi" && selectedStatus === "Aylık"
                ? "Zaman Aralığına Göre Emtia Alım-Satım Oranları"
                : "Yıllık Emtia Alım-Satım Oranları",
            columnDelimiter: ",",
            headerCategory: "category",
            headerValue: "value",
            dateFormatter(timestamp) {
              return new Date(timestamp).toDateString();
            },
          },
          svg: {
            filename:
              selected === "Altın" && selectedStatus === "Günlük"
                ? "Günlük Altın Alım-Satım Oranları"
                : selected === "Altın" && selectedStatus === "Aylık"
                ? "Zaman Aralığına Göre Altın Alım-Satım Oranları"
                : selected === "Altın" && selectedStatus === "Yıllık"
                ? "Yıllık Altın Alım-Satım Oranları"
                : selected === "Gümüş" && selectedStatus === "Günlük"
                ? "Günlük Gümüş Alım-Satım Oranları"
                : selected === "Gümüş" && selectedStatus === "Aylık"
                ? "Zaman Aralığına Göre Gümüş Alım-Satım Oranları"
                : selected === "Gümüş" && selectedStatus === "Yıllık"
                ? "Yıllık Gümüş Alım-Satım Oranları"
                : selected === "Platin" && selectedStatus === "Günlük"
                ? "Günlük Platin Alım-Satım Oranları"
                : selected === "Platin" && selectedStatus === "Aylık"
                ? "Zaman Aralığına Göre Platin Alım-Satım Oranları"
                : selected === "Platin" && selectedStatus === "Yıllık"
                ? "Yıllık Platin Alım-Satım Oranları"
                : selected === "Hepsi" && selectedStatus === "Günlük"
                ? "Günlük Emtia Alım-Satım Oranları"
                : selected === "Hepsi" && selectedStatus === "Aylık"
                ? "Zaman Aralığına Göre Emtia Alım-Satım Oranları"
                : "Yıllık Emtia Alım-Satım Oranları",
          },
          png: {
            filename:
              selected === "Altın" && selectedStatus === "Günlük"
                ? "Günlük Altın Alım-Satım Oranları"
                : selected === "Altın" && selectedStatus === "Aylık"
                ? "Zaman Aralığına Göre Altın Alım-Satım Oranları"
                : selected === "Altın" && selectedStatus === "Yıllık"
                ? "Yıllık Altın Alım-Satım Oranları"
                : selected === "Gümüş" && selectedStatus === "Günlük"
                ? "Günlük Gümüş Alım-Satım Oranları"
                : selected === "Gümüş" && selectedStatus === "Aylık"
                ? "Zaman Aralığına Göre Gümüş Alım-Satım Oranları"
                : selected === "Gümüş" && selectedStatus === "Yıllık"
                ? "Yıllık Gümüş Alım-Satım Oranları"
                : selected === "Platin" && selectedStatus === "Günlük"
                ? "Günlük Platin Alım-Satım Oranları"
                : selected === "Platin" && selectedStatus === "Aylık"
                ? "Zaman Aralığına Göre Platin Alım-Satım Oranları"
                : selected === "Platin" && selectedStatus === "Yıllık"
                ? "Yıllık Platin Alım-Satım Oranları"
                : selected === "Hepsi" && selectedStatus === "Günlük"
                ? "Günlük Emtia Alım-Satım Oranları"
                : selected === "Hepsi" && selectedStatus === "Aylık"
                ? "Zaman Aralığına Göre Emtia Alım-Satım Oranları"
                : "Yıllık Emtia Alım-Satım Oranları",
          },
        },
        autoSelected: "zoom",
      },
    },

    plotOptions: {
      pie: {
        customScale: 0.6,
        dataLabels: {
          position: "bottom",
        },
      },
    },
    legend: {
      position: "bottom",
      horizontalAlign: "center",
      offsetX: 0,
      offsetY: -30,
    },
    title: {
      text:
        selected === "Altın" && selectedStatus === "Günlük"
          ? "Günlük Altın Alım-Satım Oranları"
          : selected === "Altın" && selectedStatus === "Aylık"
          ? "Zaman Aralığına Göre Altın Alım-Satım Oranları"
          : selected === "Altın" && selectedStatus === "Yıllık"
          ? "Yıllık Altın Alım-Satım Oranları"
          : selected === "Gümüş" && selectedStatus === "Günlük"
          ? "Günlük Gümüş Alım-Satım Oranları"
          : selected === "Gümüş" && selectedStatus === "Aylık"
          ? "Zaman Aralığına Göre Gümüş Alım-Satım Oranları"
          : selected === "Gümüş" && selectedStatus === "Yıllık"
          ? "Yıllık Gümüş Alım-Satım Oranları"
          : selected === "Platin" && selectedStatus === "Günlük"
          ? "Günlük Platin Alım-Satım Oranları"
          : selected === "Platin" && selectedStatus === "Aylık"
          ? "Zaman Aralığına Göre  Platin Alım-Satım Oranları"
          : selected === "Platin" && selectedStatus === "Yıllık"
          ? "Yıllık Platin Alım-Satım Oranları"
          : selected === "Hepsi" && selectedStatus === "Günlük"
          ? "Günlük Emtia Alım-Satım Oranları"
          : selected === "Hepsi" && selectedStatus === "Aylık"
          ? "Zaman Aralığına Göre Emtia Alım-Satım Oranları"
          : "Yıllık Emtia Alım-Satım Oranları",
      align: "center",
      offsetX: 0,
      offsetY: 40,
      floating: false,
      style: {
        fontSize: "16px",
        fontWeight: "bold",
        fontFamily: undefined,
        color: "#263238",
      },
    },
    subtitle: {
      text: "Türk Lirasına göre hesaplanmıştır.",
      align: "center",
      margin: 10,
      offsetX: 0,
      offsetY: 0,
      floating: false,
      style: {
        fontSize: "12px",
        fontWeight: "normal",
        fontFamily: undefined,
        color: "#9699a2",
      },
    },

    colors:
      selected === "Hepsi"
        ? ["#c9a227", "#adb5bd", "#D9DCD4", "#7f563a", "#b9a88f", "#ab947e"]
        : ["#c9a227", "#adb5bd"],

    dataLabels: {
      enabled: true,
      style: {
        fontSize: "20px",
        colors: ["#fff"],
      },
    },

    responsive: [
      {
        breakpoint: 480,
        options: {
          chart: {
            width: 200,
          },
          legend: {
            position: "bottom",
          },
        },
      },
    ],
  };

  return (
    <LocalizationProvider dateAdapter={AdapterDayjs}>
      <Box sx={{ width: "100%" }}>
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
          }}
        >
          <Button
            onClick={() => {
              handleOpen();
            }}
            variant="outlined"
            endIcon={<ArrowDownwardIcon />}
          >
            {" "}
            Tarih Aralığı{" "}
          </Button>
          {open && (
            <>
              <Box
                style={{
                  display: "flex",
                  flexDirection: "row",
                  width: "100%",
                  alignItems: "center",
                  justifyContent: "center",
                  margin: "1rem 0",
                  gap: "1rem",
                }}
              >
                <DatePicker
                  value={startDate}
                  onChange={(date) => setStartDate(date)}
                  renderInput={(params) => (
                    <TextField {...params} size="small" />
                  )}
                  label="Başlangıç Tarihi"
                  inputFormat="DD/MM/YYYY"
                />
                {timeRange && (
                  <DatePicker
                    value={startDate2}
                    onChange={(date) => setStartDate2(date)}
                    renderInput={(params) => (
                      <TextField {...params} size="small" />
                    )}
                    label="Bitiş Tarihi"
                    inputFormat="DD/MM/YYYY"
                  />
                )}
              </Box>
            </>
          )}
        </Box>

        <Box sx={{ display: "flex", justifyContent: "space-between" }}>
          <Box sx={{ marginTop: "0px" }}>
            <Button
              variant="outlined"
              sx={{ marginRight: "5px", color: `${val1}` }}
              onClick={() => handleOnChange("Altın")}
            >
              Altın
            </Button>
            <Button
              variant="outlined"
              sx={{ marginRight: "5px", color: `${val2}` }}
              onClick={() => handleOnChange("Gümüş")}
            >
              Gümüş
            </Button>
            <Button
              variant="outlined"
              sx={{ marginRight: "5px", color: `${val3}` }}
              onClick={() => handleOnChange("Platin")}
            >
              Platin
            </Button>
            <Button
              variant="outlined"
              sx={{ color: `${val4}` }}
              onClick={() => handleOnChange("Hepsi")}
            >
              Hepsi
            </Button>
          </Box>
          <Box sx={{ marginTop: "0px" }}>
            <Button
              variant="outlined"
              size="small"
              sx={{ marginRight: "5px", color: `${val5}` }}
              onClick={() => [handleChange("Günlük"), setTimeRange(false)]}
            >
              Günlük
            </Button>
            <Button
              variant="outlined"
              size="small"
              sx={{ color: `${val6}` }}
              onClick={() => [handleChange("Aylık"), setTimeRange(true)]}
            >
              Zaman Aralığı
            </Button>
          </Box>
        </Box>

        <Chart options={options} series={options.series} type="donut" />
      </Box>
    </LocalizationProvider>
  );
};

export default DonutEmtia;
