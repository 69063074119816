import {
  Box,
  Button,
  FormControl,
  FormControlLabel,
  FormLabel,
  Radio,
  RadioGroup,
  TextField,
  Typography,
  MenuItem,
  Modal,
  useMediaQuery,
} from "@mui/material";
import React from "react";
import FileDownloadIcon from "@mui/icons-material/FileDownload";
import { ExportToCsv } from "export-to-csv";
import MaterialReactTable from "material-react-table";
import CircularProgress from "@mui/material/CircularProgress";
import Backdrop from "@mui/material/Backdrop";
import { useTheme } from "@mui/styles";
import Snackbar from "@mui/material/Snackbar";
import MuiAlert from "@mui/material/Alert";

function CommodityListeleme(props) {
  const [authCode, setAuthCode] = React.useState(
    sessionStorage.getItem("AuthCode")
  );
  const [fetchUrl, setFetchUrl] = React.useState(
    sessionStorage.getItem("FetchUrl")
  );
  const [res, setRes] = React.useState([]);
  const [priceValues, setPriceValues] = React.useState([]);
  const [filteredPriceValues, setFilteredPriceValues] = React.useState([]);

  const [nameFetched, setNameFetched] = React.useState([]);
  const [status, setStatus] = React.useState("");
  const [name, setName] = React.useState("");

  const [commodityName, setCommodityName] = React.useState("");
  const [commodityId, setCommodityId] = React.useState("");
  const [commoditySuffix, setCommoditySuffix] = React.useState("");
  const [commodityIsActive, setCommodityActive] = React.useState("");
  const [durum, setDurum] = React.useState(false);
  const [message, setMessage] = React.useState("");
  const [open, setOpen] = React.useState(false);
  const [close, setClose] = React.useState(false);

  const [openM, setOpenM] = React.useState(false);
  const [alertmessage, setAlertMessage] = React.useState("");
  const [alertStatus, setAlertStatus] = React.useState(false);

  const [openAlert, setOpenAlert] = React.useState(false);

  const Alert = React.forwardRef(function Alert(props, ref) {
    return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
  });

  const handleAlertClick = () => {
    setOpenAlert(true);
  };

  const handleAlertClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }

    setOpenAlert(false);
  };

  const theme = useTheme();
  const isSmallScreen = useMediaQuery(theme.breakpoints.down("sm"));

  React.useEffect(() => {
    getAllFetched();
  }, [close]);

  React.useEffect(() => {
    getProviderPriceValues();
  }, []);

  async function getAllFetched() {
    setOpen(true);
    var myHeaders = new Headers();
    myHeaders.append("Authorization", authCode);

    var requestOptions = {
      method: "GET",
      headers: myHeaders,
      redirect: "follow",
    };

    const response = await fetch(
      `${fetchUrl}Admin/GetActiveProviderPrices`,
      requestOptions
    );
    const fetchedData = await response.json();
    const myData = fetchedData;
    var endpointsArr = [];
    for (let i = 0; i < myData.length; i++) {
      if (
        myData[i].CommodityName == "Altin" ||
        myData[i].CommodityName == "Gumus" ||
        myData[i].CommodityName == "Platin"
      ) {
        endpointsArr.push({
          Id: i,
          CommodityId: myData[i].CommodityId,
          CommodityName: myData[i].CommodityName,
          ProviderName: myData[i].ProviderName,
        });
      }

      setRes(endpointsArr);
    }

    setTimeout(() => {
      setOpen(false);
    }, 500);
  }

  async function getProviderPriceValues() {
    var myHeaders = new Headers();
    myHeaders.append("accept", "*/*");
    myHeaders.append("Authorization", authCode);

    var requestOptions = {
      method: "GET",
      headers: myHeaders,
      redirect: "follow",
    };

    fetch(`${fetchUrl}Admin/GetProviderPriceValues`, requestOptions)
      .then((response) => response.json())
      .then((result) => {
        setPriceValues(result);
      })
      .catch((error) => console.log("error", error));
  }

  async function SetPriceValueGold(suffix) {
    setOpen(true);

    var myHeaders = new Headers();
    myHeaders.append("Content-Type", "application/json");
    myHeaders.append("accept", "*/*");
    myHeaders.append("Authorization", authCode);

    var raw = JSON.stringify({
      ProviderSuffix: suffix,
    });

    var requestOptions = {
      method: "POST",
      headers: myHeaders,
      body: raw,
      redirect: "follow",
    };

    const response = await fetch(
      `${fetchUrl}Admin/SetPriceValueGold`,
      requestOptions
    );
    const fetchedData = await response.json();

    setTimeout(() => {
      setOpen(false);
    }, 500);

    getAllFetched();
    handleCloseM();
    setAlertMessage(fetchedData.Message);
    setAlertStatus(fetchedData.Success);
    handleAlertClick();
  }

  async function SetPriceValueSilver(suffix) {
    setOpen(true);

    var myHeaders = new Headers();
    myHeaders.append("Content-Type", "application/json");
    myHeaders.append("accept", "*/*");
    myHeaders.append("Authorization", authCode);

    var raw = JSON.stringify({
      ProviderSuffix: suffix,
    });

    var requestOptions = {
      method: "POST",
      headers: myHeaders,
      body: raw,
      redirect: "follow",
    };

    const response = await fetch(
      `${fetchUrl}Admin/SetPriceValueSilver`,
      requestOptions
    );
    const fetchedData = await response.json();

    setTimeout(() => {
      setOpen(false);
    }, 500);

    getAllFetched();
    handleCloseM();
    setAlertMessage(fetchedData.Message);
    setAlertStatus(fetchedData.Success);
    handleAlertClick();
  }

  async function SetPriceValuePlatin(suffix) {
    setOpen(true);

    var myHeaders = new Headers();
    myHeaders.append("Content-Type", "application/json");
    myHeaders.append("accept", "*/*");
    myHeaders.append("Authorization", authCode);

    var raw = JSON.stringify({
      ProviderSuffix: suffix,
    });

    var requestOptions = {
      method: "POST",
      headers: myHeaders,
      body: raw,
      redirect: "follow",
    };

    const response = await fetch(
      `${fetchUrl}Admin/SetPriceValuePlatin`,
      requestOptions
    );
    const fetchedData = await response.json();

    setTimeout(() => {
      setOpen(false);
    }, 500);

    getAllFetched();
    handleCloseM();
    setAlertMessage(fetchedData.Message);
    setAlertStatus(fetchedData.Success);
    handleAlertClick();
  }

  const handleOpenM = (commodity) => {
    const filteredPriceValues = priceValues.filter(
      (item) => item.CommodityName == commodity
    );

    setFilteredPriceValues(filteredPriceValues);

    setOpenM(true);
  };
  const handleCloseM = () => {
    setOpenM(false);
    setClose(true);
  };

  return (
    <>
      {isSmallScreen ? (
        <Box>
          <Typography variant="h4" gutterBottom>
            Commodity Listeleme
          </Typography>

          <Backdrop
            sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
            open={open}
          >
            <CircularProgress color="inherit" />
          </Backdrop>

          {res.length > 0 && (
            <>
              {res.map((item, index) => (
                <Box
                  key={index}
                  sx={{
                    display: "flex",
                    width: "27.5rem",
                    flexDirection: "row",
                    border: "1px solid #ccc",
                    padding: "0.5rem",
                    margin: "0.5rem",
                    alignItems: "center",
                    justifyContent: "space-between",
                    borderRadius: "8px",
                    height: "60px",
                    padding: "10px",
                  }}
                >
                  <Typography sx={{ fontWeight: "bold", width: "40px" }}>
                    {item.CommodityName}
                  </Typography>

                  <Box
                    style={{
                      width: "1px",
                      marginLeft: "auto",
                      marginRight: "auto",
                      height: "60px",
                      backgroundColor: "#ccc",
                    }}
                  />

                  <Typography>{item.ProviderName}</Typography>

                  <Box
                    style={{
                      width: "1px",
                      marginLeft: "auto",
                      marginRight: "auto",
                      height: "60px",
                      backgroundColor: "#ccc",
                    }}
                  />

                  <Button onClick={() => handleOpenM(item.CommodityName)}>
                    <Typography>Provider Değiştir</Typography>
                  </Button>
                </Box>
              ))}
            </>
          )}

          <Modal
            open={openM}
            onClose={handleCloseM}
            aria-labelledby="modal-modal-title"
            aria-describedby="modal-modal-description"
          >
            <Box
              sx={{
                position: "absolute",
                top: "50%",
                left: "50%",
                transform: "translate(-50%, -50%)",
                bgcolor: "background.paper",
                border: "2px solid #ffb703",
                boxShadow: 24,
                p: 4,
                borderRadius: "2rem",
                display: "flex",
                flexDirection: "row",
              }}
            >
              {filteredPriceValues.length > 0 && (
                <>
                  {filteredPriceValues.map((item, index) => (
                    <Box
                      key={index}
                      sx={{
                        display: "flex",
                        flexDirection: "column",
                        border: "1px solid #ccc",
                        padding: "0.5rem 0",
                        margin: "0.5rem",
                        alignItems: "center",
                        justifyContent: "space-around",
                        width: "8rem",
                        borderRadius: "8px",
                      }}
                    >
                      <Box
                        sx={{
                          alignItems: "center",
                          display: "flex",
                          flexDirection: "column",
                        }}
                      >
                        <Typography sx={{ fontWeight: "bold", fontSize: 13 }}>
                          ProviderName
                        </Typography>
                        <Typography sx={{ fontSize: 12 }}>
                          {item.ProviderName}
                        </Typography>
                      </Box>

                      <Box
                        sx={{
                          backgroundColor: "lightgray",
                          height: "0.01rem",
                          width: "100%",
                          margin: "0.175rem 0rem",
                        }}
                      />

                      <Box
                        sx={{
                          alignItems: "center",
                          display: "flex",
                          flexDirection: "column",
                        }}
                      >
                        <Typography sx={{ fontWeight: "bold", fontSize: 13 }}>
                          CommodityName
                        </Typography>
                        <Typography sx={{ fontSize: 12 }}>
                          {item.CommodityName}
                        </Typography>
                      </Box>

                      <Box
                        sx={{
                          backgroundColor: "lightgray",
                          height: "0.01rem",
                          width: "100%",
                          margin: "0.2rem 0rem",
                        }}
                      />

                      <Box
                        sx={{
                          alignItems: "center",
                          display: "flex",
                          flexDirection: "column",
                        }}
                      >
                        <Typography sx={{ fontWeight: "bold", fontSize: 13 }}>
                          BuyPrice
                        </Typography>
                        <Typography sx={{ fontSize: 12 }}>
                          {item.BuyPrice}
                        </Typography>
                      </Box>

                      <Box
                        sx={{
                          backgroundColor: "lightgray",
                          height: "0.01rem",
                          width: "100%",
                          margin: "0.2rem 0rem",
                        }}
                      />

                      <Box
                        sx={{
                          alignItems: "center",
                          display: "flex",
                          flexDirection: "column",
                        }}
                      >
                        <Typography sx={{ fontWeight: "bold", fontSize: 13 }}>
                          SellPrice
                        </Typography>
                        <Typography sx={{ fontSize: 12 }}>
                          {item.SellPrice}
                        </Typography>
                      </Box>

                      <Box
                        sx={{
                          backgroundColor: "lightgray",
                          height: "0.01rem",
                          width: "100%",
                          margin: "0.2rem 0rem",
                        }}
                      />

                      <Box
                        sx={{
                          alignItems: "center",
                          display: "flex",
                          flexDirection: "column",
                          justifyContent: "center",
                        }}
                      >
                        <Typography sx={{ fontWeight: "bold", fontSize: 13 }}>
                          Modified Date
                        </Typography>
                        <Typography sx={{ fontSize: 12, textAlign: "center" }}>
                          {item.ModifiedDate.replace("T", " ")}
                        </Typography>
                      </Box>

                      <Box
                        sx={{
                          backgroundColor: "lightgray",
                          height: "0.01rem",
                          width: "100%",
                          margin: "0.2rem 0rem",
                        }}
                      />

                      {item.CommodityName == "Altin" && (
                        <Button
                          sx={{
                            marginRight: "1rem",
                            alignItems: "center",
                            display: "flex",
                            flexDirection: "column",
                          }}
                          onClick={() => {
                            SetPriceValueGold(item.ProviderSuffix);
                          }}
                        >
                          <Typography>Seç</Typography>
                        </Button>
                      )}

                      {item.CommodityName == "Gumus" && (
                        <Button
                          sx={{
                            marginRight: "1rem",
                            alignItems: "center",
                            display: "flex",
                            flexDirection: "column",
                          }}
                          onClick={() =>
                            SetPriceValueSilver(item.ProviderSuffix)
                          }
                        >
                          <Typography>Seç</Typography>
                        </Button>
                      )}

                      {item.CommodityName == "Platin" && (
                        <Button
                          sx={{
                            marginRight: "1rem",
                            alignItems: "center",
                            display: "flex",
                            flexDirection: "column",
                          }}
                          onClick={() => {
                            SetPriceValuePlatin(item.ProviderSuffix);
                          }}
                        >
                          <Typography>Seç</Typography>
                        </Button>
                      )}
                    </Box>
                  ))}
                </>
              )}
            </Box>
          </Modal>
        </Box>
      ) : (
        <Box>
          <Typography variant="h4" gutterBottom>
            Commodity Listeleme
          </Typography>

          <Backdrop
            sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
            open={open}
          >
            <CircularProgress color="inherit" />
          </Backdrop>

          {res.length > 0 && (
            <>
              {res.map((item, index) => (
                <Box
                  key={index}
                  sx={{
                    display: "flex",
                    width: "40rem",
                    flexDirection: "row",
                    border: "1px solid #ccc",
                    padding: "0.5rem",
                    margin: "0.5rem",
                    alignItems: "center",
                    justifyContent: "space-between",
                    borderRadius: "8px",
                    height: "60px",
                    padding: "30px",
                  }}
                >
                  <Typography sx={{ fontWeight: "bold", width: "10px" }}>
                    {item.CommodityName}
                  </Typography>
                  <Box
                    style={{
                      width: "1px",
                      marginLeft: "auto",
                      marginRight: "auto",
                      height: "60px",
                      backgroundColor: "#ccc",
                    }}
                  />
                  <Typography>{item.ProviderName}</Typography>

                  <Box
                    style={{
                      width: "1px",
                      marginLeft: "auto",
                      marginRight: "auto",
                      height: "60px",
                      backgroundColor: "#ccc",
                    }}
                  />

                  <Button onClick={() => handleOpenM(item.CommodityName)}>
                    <Typography>Provider Değiştir</Typography>
                  </Button>
                </Box>
              ))}
            </>
          )}

          <Modal
            open={openM}
            onClose={handleCloseM}
            aria-labelledby="modal-modal-title"
            aria-describedby="modal-modal-description"
          >
            <Box
              sx={{
                position: "absolute",
                top: "50%",
                left: "50%",
                transform: "translate(-50%, -50%)",
                bgcolor: "background.paper",
                border: "2px solid #ffb703",
                boxShadow: 24,
                p: 4,
                borderRadius: "2rem",
              }}
            >
              {filteredPriceValues.length > 0 && (
                <>
                  {filteredPriceValues.map((item, index) => (
                    <Box
                      key={index}
                      sx={{
                        display: "flex",
                        flexDirection: "row",
                        border: "1px solid #ccc",
                        padding: "0.5rem",
                        margin: "0.5rem",
                        alignItems: "center",
                        justifyContent: "space-around",
                        width: 750,
                      }}
                    >
                      <Box
                        sx={{
                          marginRight: "1rem",
                          alignItems: "center",
                          display: "flex",
                          flexDirection: "column",
                        }}
                      >
                        <Typography
                          variant="subtitle1"
                          sx={{ fontWeight: "bold" }}
                        >
                          ProviderName
                        </Typography>
                        <Typography
                          variant="body1"
                          sx={{ marginRight: "1rem" }}
                        >
                          {item.ProviderName}
                        </Typography>
                      </Box>

                      <Box
                        sx={{
                          marginRight: "1rem",
                          alignItems: "center",
                          display: "flex",
                          flexDirection: "column",
                        }}
                      >
                        <Typography
                          variant="subtitle1"
                          sx={{ fontWeight: "bold" }}
                        >
                          CommodityName
                        </Typography>
                        <Typography
                          variant="body1"
                          sx={{ marginRight: "1rem" }}
                        >
                          {item.CommodityName}
                        </Typography>
                      </Box>

                      <Box
                        sx={{
                          marginRight: "1rem",
                          alignItems: "center",
                          display: "flex",
                          flexDirection: "column",
                        }}
                      >
                        <Typography
                          variant="subtitle1"
                          sx={{ fontWeight: "bold" }}
                        >
                          BuyPrice
                        </Typography>
                        <Typography
                          variant="body1"
                          sx={{ marginRight: "1rem" }}
                        >
                          {item.BuyPrice}
                        </Typography>
                      </Box>

                      <Box
                        sx={{
                          marginRight: "1rem",
                          alignItems: "center",
                          display: "flex",
                          flexDirection: "column",
                        }}
                      >
                        <Typography
                          variant="subtitle1"
                          sx={{ fontWeight: "bold" }}
                        >
                          SellPrice
                        </Typography>
                        <Typography variant="body1">
                          {item.SellPrice}
                        </Typography>
                      </Box>

                      <Box
                        sx={{
                          marginRight: "1rem",
                          display: "flex",
                          flexDirection: "column",
                          marginLeft: "1rem",
                        }}
                      >
                        <Typography
                          variant="subtitle1"
                          sx={{ fontWeight: "bold" }}
                        >
                          Modified Date
                        </Typography>
                        <Typography variant="body1">
                          {item.ModifiedDate}
                        </Typography>
                      </Box>

                      {item.CommodityName == "Altin" && (
                        <Button
                          sx={{
                            marginRight: "1rem",
                            alignItems: "center",
                            display: "flex",
                            flexDirection: "column",
                          }}
                          onClick={() => {
                            SetPriceValueGold(item.ProviderSuffix);
                          }}
                        >
                          <Typography>Seç</Typography>
                        </Button>
                      )}

                      {item.CommodityName == "Gumus" && (
                        <Button
                          sx={{
                            marginRight: "1rem",
                            alignItems: "center",
                            display: "flex",
                            flexDirection: "column",
                          }}
                          onClick={() =>
                            SetPriceValueSilver(item.ProviderSuffix)
                          }
                        >
                          <Typography>Seç</Typography>
                        </Button>
                      )}

                      {item.CommodityName == "Platin" && (
                        <Button
                          sx={{
                            marginRight: "1rem",
                            alignItems: "center",
                            display: "flex",
                            flexDirection: "column",
                          }}
                          onClick={() => {
                            SetPriceValuePlatin(item.ProviderSuffix);
                          }}
                        >
                          <Typography>Seç</Typography>
                        </Button>
                      )}
                    </Box>
                  ))}
                </>
              )}
            </Box>
          </Modal>

          <Snackbar
            open={openAlert}
            onClose={handleAlertClose}
            autoHideDuration={2000}
          >
            <Alert severity={alertStatus ? "success" : "error"}>
              {alertmessage}
            </Alert>
          </Snackbar>
        </Box>
      )}
    </>
  );
}

export default CommodityListeleme;
