import * as React from "react";
import { Box, Button, MenuItem, Typography, Modal } from "@mui/material";

import MaterialReactTable from "material-react-table";

export default function ComingSoon() {
  const [authCode, setAuthCode] = React.useState(
    sessionStorage.getItem("AuthCode")
  );
  const [fetchUrl, setFetchUrl] = React.useState(
    sessionStorage.getItem("FetchUrl")
  );
  const [range, setRange] = React.useState([]);
  const [aktifId, setAktifId] = React.useState("");
  const [pasifId, setPasifid] = React.useState("");

  const [closeStatus, setCloseStatus] = React.useState(false);
  const [openM, setOpenM] = React.useState(false);
  const handleOpenM = () => setOpenM(true);
  const handleCloseM = () => setOpenM(false);

  const [closeStatus2, setCloseStatus2] = React.useState(false);
  const [openM2, setOpenM2] = React.useState(false);
  const handleOpenM2 = () => setOpenM2(true);
  const handleCloseM2 = () => setOpenM2(false);

  React.useEffect(() => {
    fetchGetAllForSettings();
  }, [closeStatus, closeStatus2]);

  async function fetchGetAllForSettings() {
    var myHeaders = new Headers();
    myHeaders.append("Authorization", authCode);

    var requestOptions = {
      method: "GET",
      headers: myHeaders,
      redirect: "follow",
    };

    const response = await fetch(
      `${fetchUrl}Admin/GetAllForSettings?pageNumber=1`,
      requestOptions
    );
    const fetchedData = await response.json();

    const myData = fetchedData.Data;
    var endpointsArr = [];

    for (let i = 0; i < myData.length; i++) {
      if (myData[i].Key == "BuySellProcess") {
        endpointsArr.push({
          Id: myData[i].Id,
          Type: myData[i].Type,
          Key: myData[i].Key,
          Value: myData[i].Value,
          CustomField1: myData[i].CustomField1,
          CustomField2: myData[i].CustomField2,
          CustomField3: myData[i].CustomField3,
          IsActive:
            myData[i].IsActive === true ? (
              <Typography
                sx={{ color: "blue", fontWeight: "bold", fontStyle: "italic" }}
              >
                Aktif
              </Typography>
            ) : (
              <Typography
                sx={{ color: "red", fontWeight: "bold", fontStyle: "italic" }}
              >
                Pasif
              </Typography>
            ),
          CreatedDate: myData[i].CreatedDate,
        });
      }
    }
    setRange(endpointsArr);
  }

  async function fetchActiveForSettings(aktifId) {
    var myHeaders = new Headers();
    myHeaders.append("Authorization", authCode);

    var requestOptions = {
      method: "GET",
      headers: myHeaders,
      redirect: "follow",
    };

    const response = await fetch(
      `${fetchUrl}Admin/MakeActiveForSettings?id=${aktifId}`,
      requestOptions
    );
    const fetchedData = await response.json();
    const myData = fetchedData.Data;
    setTimeout(() => {
      setCloseStatus(false);
    }, 1000);
    if (fetchedData.success === true) {
      console.log("Aktif işlemi Gerçekleşti.");
    }
  }

  async function fetchPassiveForSettings(aktifId) {
    var myHeaders = new Headers();
    myHeaders.append("Authorization", authCode);

    var requestOptions = {
      method: "GET",
      headers: myHeaders,
      redirect: "follow",
    };

    const response = await fetch(
      `${fetchUrl}Admin/MakePassiveForSettings?id=${aktifId}`,
      requestOptions
    );
    const fetchedData = await response.json();
    const myData = fetchedData.Data;
    setTimeout(() => {
      setCloseStatus2(false);
    }, 1000);
  }

  const columns = React.useMemo(
    () => [
      {
        accessorKey: "Id",
        header: "Id",
        size: 30,
      },
      {
        accessorKey: "IsActive",
        header: "Is Active",
        size: 30,
      },
      {
        accessorKey: "Type",
        header: "Transaction Ids",
        size: 30,
      },
      {
        accessorKey: "Key",
        header: "Key",
        size: 30,
      },
      {
        accessorKey: "Value",
        header: "Value",
        size: 30,
      },
      {
        accessorKey: "CustomField1",
        header: "Field 1",
        size: 30,
      },
      {
        accessorKey: "CustomField2",
        header: "Field 2",
        size: 30,
      },
      {
        accessorKey: "CustomField3",
        header: "Field 3",
        size: 30,
      },
      {
        accessorKey: "CreatedDate",
        header: "Created Date",
        size: 30,
      },
    ],
    []
  );

  return (
    <Box sx={{ paddingLeft: "3rem", paddingRight: "3rem" }}>
      <Typography variant="h4" gutterBottom>
        Coming Soon
      </Typography>

      {closeStatus && (
        <Modal
          open={openM}
          onClose={handleCloseM}
          aria-labelledby="modal-modal-title"
          aria-describedby="modal-modal-description"
        >
          <Box
            sx={{
              position: "absolute",
              top: "50%",
              left: "50%",
              transform: "translate(-50%, -50%)",
              width: 470,
              bgcolor: "background.paper",
              border: "2px solid #ffb703",
              boxShadow: 24,
              p: 4,
              borderRadius: "2rem",
            }}
          >
            <Typography id="modal-modal-title" variant="h6" component="h2">
              Aktif yapmak istediğinize emin misiniz ?
            </Typography>
            <Box
              style={{
                flexDirection: "row",
                display: "flex",
                gap: "2rem",
                justifyContent: "center",
                marginTop: "1rem",
              }}
            >
              <Button
                variant="contained"
                color="success"
                sx={{ color: "white", marginRight: "1rem" }}
              >
                Evet
              </Button>
              <Button variant="contained" color="error" sx={{ color: "white" }}>
                Hayır
              </Button>
            </Box>
          </Box>
        </Modal>
      )}

      <MaterialReactTable
        columns={columns}
        data={range}
        positionToolbarAlertBanner="bottom"
        enableRowActions
        renderRowActions={({ row }) => [
          <Box sx={{ display: "flex", flexDirection: "row", gap: "1rem" }}>
            <MenuItem
              sx={{
                bgcolor: "#005791",
                borderRadius: "0.5rem",
                color: "white",
                "&:hover": {
                  backgroundColor: "##0295f7",
                },
              }}
              key="aktif"
              onClick={() => [
                setCloseStatus(true),
                setAktifId(row.original.Id),
                handleOpenM(),
              ]}
            >
              Göster
            </MenuItem>
          </Box>,
        ]}
      />
    </Box>
  );
}
