import React from 'react';
import { makeStyles } from '@mui/styles';
import { Backdrop, CircularProgress } from '@mui/material';

const useStyles = makeStyles((theme) => ({
  backdrop: {
    zIndex: theme.zIndex.drawer + 1,
    color: '#fff',
  },
}));

function LoadingOverlay({ isOpen }) {
  const classes = useStyles();

  return (
    <Backdrop className={classes.backdrop} open={isOpen}>
      <CircularProgress color="inherit" />
    </Backdrop>
  );
}

export default LoadingOverlay;
