import { Box, TextField, Typography } from "@mui/material";
import React, { useState } from "react";

function PricesDetailsFintagAltin(props) {
  const [goldData, setGoldData] = useState([]);
  const [silverData, setSilverData] = useState([]);
  const [platinData, setPlatinData] = useState([]);

  const [authCode, setAuthCode] = useState(sessionStorage.getItem("AuthCode"));
  const [fetchUrl, setFetchUrl] = useState(sessionStorage.getItem("FetchUrl"));

  React.useEffect(() => {
    fetchData();
  }, []);

  React.useEffect(() => {
    setTimeout(() => {
      fetchData();
    }, 1200);
  }, [props.open]);

  async function fetchData() {
    var requestOptions = {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Authorization: authCode,
      },
    };
    const response = await fetch(
      `${fetchUrl}Admin/GetAllProviderPrice?pageNumber=1`,
      requestOptions
    );
    const fetchedData = await response.json();
    const stringfyData = await fetchedData.Data;

    for (let i = 0; i < stringfyData.length; i++) {
      if (
        stringfyData[i].ProviderId == "40B16FE1-CE48-4846-B380-635EFB36E97A"
      ) {
        if (
          stringfyData[i].CommodityId == "7BE7C383-03E0-4A29-9564-1772BF0E996E"
        ) {
          setGoldData(stringfyData[i]);
        } else if (
          stringfyData[i].CommodityId == "5D713008-15BB-4509-BE0D-B2F9355E8FF4"
        ) {
          setSilverData(stringfyData[i]);
        } else if (
          stringfyData[i].CommodityId == "8FC928D5-D9F9-4ABD-9859-567883AA3F06"
        ) {
          setPlatinData(stringfyData[i]);
        }
      }
    }
  }

  return (
    <Box
      style={{
        display: "flex",
        flexDirection: "row",
        gap: "5rem",
        alignItems: "center",
        justifyContent: "space-around",
        marginTop: "2.5rem",
      }}
    >
      <Box
        component="form"
        sx={[formStyle, { backgroundColor: "#f5f3eb" }]}
        noValidate
        autoComplete="off"
      >
        <Box sx={frameStyle}>
          <Typography sx={textStyle}>VPosBuyPrice :</Typography>
          <Typography>{goldData.VPosBuyPrice}</Typography>
        </Box>
        <Box sx={frameStyle}>
          <Typography sx={textStyle}>BuyProfitTl :</Typography>
          <Typography>{goldData.BuyProfitTl}</Typography>
        </Box>
        <Box sx={frameStyle}>
          <Typography sx={textStyle}>SellProfitTl :</Typography>
          <Typography>{goldData.SellProfitTl}</Typography>
        </Box>
        <Box sx={frameStyle}>
          <Typography sx={textStyle}>VPosRate :</Typography>
          <Typography>{goldData.VPosRate}</Typography>
        </Box>
        <Box sx={frameStyle}>
          <Typography sx={textStyle}>BuyPercantage :</Typography>
          <Typography>{goldData.BuyPercantage}</Typography>
        </Box>
        <Box sx={frameStyle}>
          <Typography sx={textStyle}>SellPercantage :</Typography>
          <Typography>{goldData.SellPercantage}</Typography>
        </Box>
        <Box sx={frameStyle}>
          <Typography sx={textStyle}>BSMV :</Typography>
          <Typography>{goldData.BSMV}</Typography>
        </Box>
      </Box>
    </Box>
  );
}

export default PricesDetailsFintagAltin;

const formStyle = {
  "& > :not(style)": { width: "15rem" },
  display: "flex",
  flexDirection: "column",
  gap: 2,
  backgroundColor: "rgba(232, 219, 176, 0.5)",
  borderRadius: "0.5rem",
  justifyContent: "center",
  alignItems: "center",
  padding: "2rem",
};

const textStyle = {
  fontWeight: "600",
  textDecoration: "underline",
};

const frameStyle = {
  display: "flex",
  flexDirection: "row",
  justifyContent: "space-between",
};
