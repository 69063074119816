import { Box, Button, Grid, Hidden, Typography } from "@mui/material";
import React from "react";
import VendorListeleme from "src/components/AleynaComponents/VendorListeleme";
import VendorCreate from "../components/AleynaComponents/VendorCreate";
import VendorMakeAutomaticActive from "../components/AleynaComponents/VendorMakeAutomaticActive";
import VendorUpdate from "../components/AleynaComponents/VendorUpdate";
import UpdateVendorBalance from "src/components/AleynaComponents/UpdateVendorBalance";

function VendorIslemleri(props) {
  const [switchIt, setSwitchIT] = React.useState("VendorListeleme");

  return (
    <Box sx={{ display: "flex", flexDirection: "column" }}>
      <Box
        sx={{
          display: "flex",
          alignSelf: "center",
          gap: "5rem",
        }}
      >
        <Button
          variant={switchIt == "VendorListeleme" ? "contained" : "outlined"}
          onClick={() => setSwitchIT("VendorListeleme")}
        >
          <Typography>Vendor Listeleme</Typography>
        </Button>

        <Button
          variant={switchIt == "vendorIslemleri" ? "contained" : "outlined"}
          onClick={() => setSwitchIT("vendorIslemleri")}
        >
          <Typography>Vendor İşlemleri</Typography>
        </Button>
      </Box>

      <Box
        sx={{
          width: "100%",
          height: "0.01rem",
          backgroundColor: "lightgray",
          marginBottom: "2rem",
          marginTop: "2rem",
        }}
      />

      {switchIt == "VendorListeleme" ? (
        <Box>
          <VendorListeleme />
        </Box>
      ) : (
        <Box sx={{ marginLeft: "3rem" }}>
          <Grid
            container
            rowSpacing={{ xs: 5, sm: 2, md: 3 }}
            columnSpacing={{ xs: 3, sm: 2, md: 3 }} // Spacing ayarını değiştirdim
          >
            <Grid item xs={12} sm={6}>
              <VendorCreate />
            </Grid>
            <Grid item xs={12} sm={6}>
              <VendorUpdate />
            </Grid>

            <Hidden xsDown={true}>
              {" "}
              {/* Sadece xs boyutunda gizlenir */}
              <Box
                sx={{
                  width: "100%",
                  height: "0.01rem",
                  backgroundColor: "lightgray",
                  marginBottom: "2rem",
                  marginTop: "2rem",
                }}
              />
            </Hidden>

            <Grid item xs={12} sm={6}>
              <VendorMakeAutomaticActive />
            </Grid>
            <Grid item xs={12} sm={6}>
              <UpdateVendorBalance />
            </Grid>
          </Grid>
        </Box>
      )}

      <Box
        sx={{
          height: "0.01rem",
          width: "100%",
          marginTop: "2rem",
          border: "0.01rem dotted #F0EBD5",
        }}
      />
    </Box>
  );
}

export default VendorIslemleri;
