import * as React from "react";
import { Box, Button, Typography, Snackbar } from "@mui/material";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import FileDownloadIcon from "@mui/icons-material/FileDownload";
import { ExportToCsv } from "export-to-csv";
import MaterialReactTable from "material-react-table";
import CircularProgress from "@mui/material/CircularProgress";
import Backdrop from "@mui/material/Backdrop";
import MuiAlert from "@mui/material/Alert";

export default function UnexpectedListeleme() {
  const [authCode, setAuthCode] = React.useState(
    sessionStorage.getItem("AuthCode")
  );
  const [fetchUrl, setFetchUrl] = React.useState(
    sessionStorage.getItem("FetchUrl")
  );
  const [range, setRange] = React.useState([]);
  const [vendors, setVendors] = React.useState([]);
  const [status, setStatus] = React.useState(false);
  const [choosenStatus, setChoosenStatus] = React.useState("");

  const [choosenVendor, setChoosenVendor] = React.useState("");
  const [open, setOpen] = React.useState(false);

  const [alertmessage, setAlertMessage] = React.useState("");
  const [alertStatus, setAlertStatus] = React.useState(false);
  const [openAlert, setOpenAlert] = React.useState(false);

  const Alert = React.forwardRef(function Alert(props, ref) {
    return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
  });

  const handleAlertClick = () => {
    setOpenAlert(true);
  };

  const handleAlertClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }

    setOpenAlert(false);
  };

  React.useEffect(() => {
    fetchedData();
  }, []);

  async function fetchedDataRange(choosenVendor) {
    setOpen(true);
    var myHeaders = new Headers();
    myHeaders.append("Authorization", authCode);

    var requestOptions = {
      method: "GET",
      headers: myHeaders,
      redirect: "follow",
    };

    const response = await fetch(
      `${fetchUrl}Admin/GetVendorUnexpectedsByVendorId?vendorId=${choosenVendor}`,
      requestOptions
    );
    const fetchedData = await response.json();
    setTimeout(() => {
      setOpen(false);
    }, 1000);
    const myData = fetchedData.Data;
    var endpointsArr = [];

    for (let i = 0; i < myData.length; i++) {
      endpointsArr.push({
        VendorId: myData[i].VendorId,
        TransactionIds: myData[i].TransactionIds,
        TransactionCount: myData[i].TransactionCount,
        ExpectedTlAmount: myData[i].ExpectedTlAmount,
        ActualTlAmount: myData[i].ActualTlAmount,
        Id: myData[i].Id,
        IsActive: myData[i].IsActive == true ? "true" : "false",
        IsDeleted: myData[i].IsDeleted == true ? "true" : "false",
        CreatedDate: myData[i].CreatedDate,
        ModifiedDate: myData[i].ModifiedDate,
        AdminNote: myData[i].AdminNote,
      });
    }
    setRange(endpointsArr);

    setAlertMessage(fetchedData.Message);
    setAlertStatus(fetchedData.Success);
    handleAlertClick();
  }

  async function fetchedData() {
    var myHeaders = new Headers();
    myHeaders.append("Authorization", authCode);

    var requestOptions = {
      method: "GET",
      headers: myHeaders,
      redirect: "follow",
    };

    const response = await fetch(
      `${fetchUrl}Admin/GetAll?pageNumber=1`,
      requestOptions
    );
    const fetchedData = await response.json();
    const myData = fetchedData.Data;
    setVendors(myData);
  }

  const handleChooseVendor = (name) => {
    setChoosenVendor(name.target.value);
    setStatus(false);
  };

  const columns = React.useMemo(
    () => [
      {
        accessorKey: "VendorId",
        header: "Vendor Id",
        size: 30,
      },
      {
        accessorKey: "TransactionIds",
        header: "Transaction Ids",
        size: 30,
      },
      {
        accessorKey: "TransactionCount",
        header: "Transaction Count",
        size: 30,
      },
      {
        accessorKey: "ExpectedTlAmount",
        header: "Expected Tl Amount",
        size: 30,
      },
      {
        accessorKey: "ActualTlAmount",
        header: "Actual Tl Amount",
        size: 30,
      },
      {
        accessorKey: "Id",
        header: "Id",
        size: 30,
      },

      {
        accessorKey: "IsActive",
        header: "Is Active",
        size: 30,
      },
      {
        accessorKey: "IsDeleted",
        header: "Is Deleted",
        size: 30,
      },
      {
        accessorKey: "CreatedDate",
        header: "Created Date",
        size: 30,
      },
      {
        accessorKey: "ModifiedDate",
        header: "Modified Date",
        size: 30,
      },
      {
        accessorKey: "AdminNote",
        header: "AdminNote",
        size: 30,
      },
    ],
    []
  );

  const csvOptions = {
    fieldSeparator: ";",
    quoteStrings: '"',
    decimalSeparator: ".",
    showLabels: true,
    useBom: true,
    useKeysAsHeaders: false,
    headers: columns.map((c) => c.header),
    filename: "Transactions",
  };

  const csvExporter = new ExportToCsv(csvOptions);

  const handleExportRows = (rows) => {
    csvExporter.generateCsv(rows.map((row) => row.original));
  };

  const handleExportData = () => {
    csvExporter.generateCsv(range);
  };

  return (
    <Box sx={{ marginLeft: "3rem" }}>
      <Typography variant="h4" gutterBottom>
        Unexpected İşlemler
      </Typography>
      <Backdrop
        sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
        open={open}
      >
        <CircularProgress color="inherit" />
      </Backdrop>
      <Box
        sx={{
          display: "flex",
          flexDirection: "row",
          alignItems: "center",
          gap: "5rem",
          marginTop: "2rem",
          marginBottom: "2rem",
        }}
      >
        <FormControl sx={{ width: "160px" }}>
          <InputLabel id="demo-simple-select-label">Vendor Seçiniz</InputLabel>
          <Select
            labelId="demo-simple-select-label"
            id="demo-simple-select"
            value={choosenVendor}
            label="Vendor Seçiniz"
            onChange={handleChooseVendor}
          >
            {vendors.map((vendors) => (
              <MenuItem key={vendors.Id} value={vendors.Id}>
                {vendors.Name}
              </MenuItem>
            ))}
          </Select>
        </FormControl>

        {choosenVendor != "" ? (
          <Button
            variant="contained"
            onClick={() => [fetchedDataRange(choosenVendor), setStatus(true)]}
          >
            Listele
          </Button>
        ) : (
          <Button variant="contained" disabled>
            Listele
          </Button>
        )}
      </Box>

      <MaterialReactTable
        columns={columns}
        data={range}
        enableRowSelection
        positionToolbarAlertBanner="bottom"
        renderTopToolbarCustomActions={({ table }) => (
          <Box
            sx={{ display: "flex", gap: "1rem", p: "0.5rem", flexWrap: "wrap" }}
          >
            <Button
              color="primary"
              onClick={handleExportData}
              startIcon={<FileDownloadIcon />}
              variant="outlined"
              size="small"
              sx={{ fontSize: { xs: "10px", sm: "12px", md: "12px" } }}
            >
              Tüm Verileri Dışa Aktar
            </Button>
            <Button
              disabled={table.getPrePaginationRowModel().rows.length === 0}
              onClick={() =>
                handleExportRows(table.getPrePaginationRowModel().rows)
              }
              startIcon={<FileDownloadIcon />}
              variant="outlined"
              size="small"
              sx={{ fontSize: { xs: "10px", sm: "12px", md: "12px" } }}
            >
              Tüm Satırları Dışa Aktar
            </Button>
            <Button
              disabled={table.getRowModel().rows.length === 0}
              onClick={() => handleExportRows(table.getRowModel().rows)}
              startIcon={<FileDownloadIcon />}
              variant="outlined"
              size="small"
              sx={{ fontSize: { xs: "10px", sm: "12px", md: "12px" } }}
            >
              Bu Sayfadaki Satırları Dışa Aktar
            </Button>
            <Button
              disabled={
                !table.getIsSomeRowsSelected() && !table.getIsAllRowsSelected()
              }
              onClick={() => handleExportRows(table.getSelectedRowModel().rows)}
              startIcon={<FileDownloadIcon />}
              variant="outlined"
              size="small"
              sx={{ fontSize: { xs: "10px", sm: "12px", md: "12px" } }}
            >
              Seçilmiş Satırları Dışa Aktar
            </Button>
          </Box>
        )}
      />

      <Snackbar
        open={openAlert}
        onClose={handleAlertClose}
        autoHideDuration={2000}
      >
        <Alert severity={alertStatus ? "success" : "error"}>
          {alertmessage}
        </Alert>
      </Snackbar>
    </Box>
  );
}
