import {
  Box,
  Button,
  FormControl,
  FormControlLabel,
  Select,
  MenuItem,
  InputLabel,
  FormLabel,
  TextField,
  Radio,
  RadioGroup,
  Typography,
} from "@mui/material";
import React from "react";

function CommodityMakeActivePassive(props) {
  const [commId, setCommId] = React.useState("");
  const [commData, setCommData] = React.useState([]);
  const [status, setStatus] = React.useState("");
  const [dataMessage, setDataMessage] = React.useState("");
  const [popup, setPopup] = React.useState(false);
  const [message, setMessage] = React.useState("");
  const [comm, setComm] = React.useState([]);
  const [choosenCommodity, setChoosenCommodity] = React.useState("");
  const handleChooseCommodity = (name) => {
    setChoosenCommodity(name.target.value);
  };

  React.useEffect(() => {
    const interval = setInterval(() => {
      setMessage("");
    }, 2000);
    return () => clearInterval(interval);
  }, []);

  const [authCode, setAuthCode] = React.useState(
    sessionStorage.getItem("AuthCode")
  );
  const [fetchUrl, setFetchUrl] = React.useState(
    sessionStorage.getItem("FetchUrl")
  );

  const handleChangeRadioBtn = (event) => {
    setStatus(event.target.value);
  };

  React.useEffect(() => {
    fetchedCommodity();
  }, []);

  React.useEffect(() => {
    const interval = setInterval(() => {
      setMessage("");
    }, 2000);
    return () => clearInterval(interval);
  }, []);

  async function activePassiveFetchedData() {
    var myHeaders = new Headers();
    myHeaders.append("Authorization", authCode);

    var requestOptions = {
      method: "POST",
      headers: myHeaders,
      redirect: "follow",
    };

    const response = await fetch(
      `${fetchUrl}Admin/Make${status}Commodity?id=${choosenCommodity}`,
      requestOptions
    );
    const fetchedData = await response.json();

    fetchedData.Success === true ? setPopup(true) : setPopup(false);
    setMessage(fetchedData.Message);
  }

  async function fetchedCommodity() {
    var myHeaders = new Headers();
    myHeaders.append("Authorization", authCode);

    var requestOptions = {
      method: "GET",
      headers: myHeaders,
      redirect: "follow",
    };

    const response = await fetch(
      `${fetchUrl}Admin/GetAllCommodity?pageNumber=1`,
      requestOptions
    );
    const fetchedData = await response.json();

    const myData = fetchedData.Data;
    var endpointsArr = [];
    var newArr = [];
    for (let i = 0; i < myData.length; i++) {
      endpointsArr.push({
        Id: myData[i].Id,
        Suffix: myData[i].Suffix,
        Name: myData[i].Name === "TL" ? null : myData[i].Name,
        IsActive: myData[i].IsActive,
      });
      if (endpointsArr[i].Name !== null) {
        newArr.push(endpointsArr[i]);
      }
    }
    setComm(newArr);
  }
  return (
    <Box sx={{ display: "column" }}>
      <Typography variant="h4" gutterBottom>
        Commodity Aktif/Pasif
      </Typography>
      <Box sx={{ marginBottom: "2.5rem", marginTop: "0" }}>
        <FormControl sx={{ width: "200px" }}>
          <InputLabel id="demo-simple-select-label">
            Commodity Seçiniz
          </InputLabel>
          <Select
            labelId="demo-simple-select-label"
            id="demo-simple-select"
            value={choosenCommodity}
            label="Commodity Seçiniz"
            onChange={handleChooseCommodity}
          >
            {comm.map((comm) => (
              <MenuItem key={comm.Id} value={comm.Id}>
                {comm.Name}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
      </Box>

      <Box sx={{ marginBottom: "2.5rem" }}>
        <FormControl>
          <FormLabel id="demo-controlled-radio-buttons-group">
            Yapılacak İşlemi Seçiniz
          </FormLabel>
          <RadioGroup
            aria-labelledby="demo-controlled-radio-buttons-group"
            name="controlled-radio-buttons-group"
            value={status}
            onChange={handleChangeRadioBtn}
          >
            <FormControlLabel
              value="active"
              control={<Radio />}
              label="Aktif"
            />
            <FormControlLabel
              value="passive"
              control={<Radio />}
              label="Pasif"
            />
          </RadioGroup>
        </FormControl>
      </Box>

      <Box>
        {status !== "" && choosenCommodity !== "" ? (
          <Button
            variant="contained"
            sx={{ width: "50px", height: "30px" }}
            onClick={() => activePassiveFetchedData()}
          >
            Onayla
          </Button>
        ) : (
          <Button
            variant="contained"
            sx={{ width: "50px", height: "30px" }}
            disabled
          >
            Onayla
          </Button>
        )}
        {message.length > 0 && (
          <Typography
            sx={{
              color: popup ? "green" : "red",
              fontWeight: "bold",
              marginTop: "1rem",
            }}
          >
            {message}
          </Typography>
        )}
      </Box>
    </Box>
  );
}

export default CommodityMakeActivePassive;
