import { Box, Button, Typography } from "@mui/material";
import React from "react";
import ProviderListeleme from "src/components/AleynaComponents/ProviderListeleme";
import ProviderMakeActivePassive from "src/components/AleynaComponents/ProviderMakeActivePassive";

function Provider(props) {
  const [switchIt, setSwitchIT] = React.useState("ProviderListeleme");

  return (
    <Box sx={{ display: "flex", flexDirection: "column" }}>
      <Box
        sx={{
          display: "flex",
          alignSelf: "center",
          gap: "5rem",
        }}
      >
        <Button
          variant={switchIt == "ProviderListeleme" ? "contained" : "outlined"}
          onClick={() => setSwitchIT("ProviderListeleme")}
        >
          <Typography>Provider Listeleme</Typography>
        </Button>

        <Button
          variant={switchIt == "ProviderIslemleri" ? "contained" : "outlined"}
          onClick={() => setSwitchIT("ProviderIslemleri")}
        >
          <Typography>Provider İşlemleri</Typography>
        </Button>
      </Box>

      <Box
        sx={{
          width: "100%",
          height: "0.01rem",
          backgroundColor: "lightgray",
          marginBottom: "2rem",
          marginTop: "2rem",
        }}
      />

      {switchIt === "ProviderListeleme" ? (
        <Box sx={{ marginLeft: "3rem" }}>
          <ProviderListeleme />
        </Box>
      ) : (
        <Box sx={{ marginLeft: "3rem" }}>
          <ProviderMakeActivePassive />
        </Box>
      )}

      <Box
        sx={{
          width: "100%",
          height: "0.01rem",
          backgroundColor: "lightgray",
          marginBottom: "2rem",
          marginTop: "2rem",
        }}
      />
    </Box>
  );
}

export default Provider;
