import PropTypes from 'prop-types';
import { forwardRef } from 'react';
import { Link as RouterLink } from 'react-router-dom';
// @mui
import { useTheme } from '@mui/material/styles';
import { Box, Link } from '@mui/material';

// ----------------------------------------------------------------------

const Logo = forwardRef(({ disabledLink = false, sx, ...other }, ref) => {
  const theme = useTheme();

  const WARNING_MAIN = theme.palette.warning.main;

  const WARNING_DARK = theme.palette.warning.dark;

  const WARNING_DARKER = theme.palette.warning.darker;

  // OR using local (public folder)
  // -------------------------------------------------------
  // const logo = (
  //   <Box
  //     component="img"
  //     src="/logo/logo_single.svg" => your path
  //     sx={{ width: 40, height: 40, cursor: 'pointer', ...sx }}
  //   />
  // );

  const logo = (
    <Box
      ref={ref}
      component="div"
      sx={{
        width: 40,
        height: 40,
        display: 'inline-flex',
        ...sx,
      }}
      {...other}
    >
      <svg xmlns="http://www.w3.org/2000/svg" width="100rem" height="100%" viewBox="0 0 378.000000 378.000000">
        <defs>
          <linearGradient id="BG1" x1="100%" x2="50%" y1="9.946%" y2="50%">
            <stop offset="0%" stopColor={WARNING_MAIN } />
            <stop offset="100%" stopColor={WARNING_DARK} />
          </linearGradient>

          <linearGradient id="BG2" x1="0%" x2="50%" y1="50%" y2="100%">
            <stop offset="0%" stopColor={WARNING_DARK} />
            <stop offset="100%" stopColor={WARNING_MAIN } />
          </linearGradient>

          <linearGradient id="BG3" x1="50%" x2="50%" y1="0%" y2="100%">
            <stop offset="0%" stopColor={WARNING_DARKER} />
            <stop offset="100%" stopColor={WARNING_MAIN} />
          </linearGradient>
        </defs>

        <g fill={WARNING_MAIN} fillRule="evenodd" stroke="none" strokeWidth="1" transform="translate(0.000000,378.000000) scale(0.100000,-0.100000)" >
          <path
            fill="url(#BG1)"
            d="M1640 3290 c-227 -48 -408 -145 -574 -307 -360 -353 -452 -888 -231
            -1339 310 -635 1083 -846 1675 -458 98 63 243 208 310 308 91 137 164 317 184
            459 7 47 7 47 -22 47 -27 0 -29 -3 -41 -66 -17 -93 -73 -244 -122 -330 -58
            -101 -156 -221 -232 -285 -65 -55 -203 -149 -218 -149 -5 0 -9 186 -9 415 l0
            415 -45 0 -45 0 0 -435 0 -434 -27 -15 c-16 -7 -68 -24 -118 -37 -121 -31
            -368 -34 -489 -5 -255 59 -471 197 -627 399 -312 403 -313 954 -4 1357 56 73
            178 188 255 240 140 95 322 160 504 181 101 11 106 13 106 35 0 23 -3 24 -72
            23 -40 0 -111 -9 -158 -19z"
          />
          <path
            fill="url(#BG2)"
            d="M2268 2513 l-3 -168 -119 -2 -119 -1 -1 -46 -1 -46 123 0 122 0 0
            -60 0 -60 45 0 45 0 0 60 0 60 331 0 330 0 -3 46 -3 45 -325 2 -325 2 -3 168
            -2 167 -45 0 -45 0 -2 -167z"
          />
          <path
            fill="M2310 946 l-35 -13 -3 -101 c-3 -96 -2 -102 17 -102 20 0 21 -7 24
            -187 l2 -188 3 188 c2 180 3 187 22 187 19 0 20 7 20 115 0 63 -3 115 -7 114
            -5 0 -24 -6 -43 -13z"
          />
        </g>
      </svg>
    </Box>
  );

  if (disabledLink) {
    return <>{logo}</>;
  }

  return (
    <Link to="/" component={RouterLink} sx={{ display: 'contents' }}>
      {logo}
    </Link>
  );
});

Logo.propTypes = {
  sx: PropTypes.object,
  disabledLink: PropTypes.bool,
};

export default Logo;
