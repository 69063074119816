import { React, useState } from "react";
import { Button, Box } from "@mui/material";
import Chart from "react-apexcharts";
import { useEffect } from "react";
import ArrowDownwardIcon from "@mui/icons-material/ArrowDownward";

import TextField from "@mui/material/TextField";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import dayjs from "dayjs";

const Gunluk = () => {
  const [open, setOpen] = useState(false);

  let newDate = new Date();
  let date = newDate.getDate();
  let month = newDate.getMonth() + 1;
  let year = newDate.getFullYear();

  const [authCode, setAuthCode] = useState(sessionStorage.getItem("AuthCode"));
  const [fetchUrl, setFetchUrl] = useState(sessionStorage.getItem("FetchUrl"));

  const [dateValue, setDateValue] = useState(dayjs().format("D"));
  const [monthValue, setMonthValue] = useState(dayjs().format("MM"));
  const [yearValue, setYearValue] = useState(dayjs().format("YYYY"));

  const [startDate, setStartDate] = useState(dayjs);

  const [silverVolume, setSilverVolume] = useState(0);
  const [goldVolume, setGoldVolume] = useState(0);
  const [platinVolume, setPlatinVolume] = useState(0);

  const [isGold, setGold] = useState();
  const [isSilver, setSilver] = useState();
  const [isPlatin, setPlatin] = useState();

  const handleOpen = () => {
    setOpen(!open);
  };

  useEffect(() => {
    fetchData();
  }, [startDate]);

  async function fetchData() {
    var myHeaders = new Headers();
    myHeaders.append("Authorization", authCode);

    var requestOptions = {
      method: "GET",
      headers: myHeaders,
      redirect: "follow",
    };

    let zeroValue = 0;
    if (startDate.$M + 1 > 9) {
      zeroValue = "";
    }

    const response = await fetch(
      `${fetchUrl}Admin/GetHourlyStaticsticsByDateRange?startDate=${zeroValue}${
        startDate.$M + 1
      }%2F${startDate.$D}%2F${startDate.$y}&startHour=0&endDate=${
        startDate.$M + 1
      }%2F${startDate.$D}%2F${startDate.$y}&endHour=23
    `,
      requestOptions
    );
    const fetchedData = await response.json();
    const stringfyData = fetchedData.Data;

    for (let s = 0; s < stringfyData.length; s++) {
      if (
        stringfyData[s].CommodityId.includes(
          "7BE7C383-03E0-4A29-9564-1772BF0E996E"
        ) === false
      ) {
        setGold(false);
      } else if (
        stringfyData[s].CommodityId.includes(
          "5D713008-15BB-4509-BE0D-B2F9355E8FF4"
        ) === false
      ) {
        setSilver(false);
      } else if (
        stringfyData[s].CommodityId.includes(
          "8FC928D5-D9F9-4ABD-9859-567883AA3F06"
        ) === false
      ) {
        setPlatin(false);
      }
    }

    let silverTotal = 0;
    let goldTotal = 0;
    let platinTotal = 0;

    if (isGold === false) {
      setGoldVolume(0);
    } else if (isSilver === false) {
      setSilverVolume(0);
    } else if (isPlatin === false) {
      setPlatinVolume(0);
    }

    if (stringfyData.length === 0) {
      setPlatinVolume(0);
      setGoldVolume(0);
      setSilverVolume(0);
    } else {
      for (let i = 0; i < stringfyData.length; i++) {
        if (
          stringfyData[i].CommodityId === "5D713008-15BB-4509-BE0D-B2F9355E8FF4"
        ) {
          silverTotal = silverTotal + stringfyData[i].TotalTlAmount;
          setSilverVolume(silverTotal);
        } else if (
          stringfyData[i].CommodityId === "7BE7C383-03E0-4A29-9564-1772BF0E996E"
        ) {
          goldTotal = goldTotal + stringfyData[i].TotalTlAmount;
          setGoldVolume(goldTotal);
        } else if (
          stringfyData[i].CommodityId === "8FC928D5-D9F9-4ABD-9859-567883AA3F06"
        ) {
          platinTotal = platinTotal + stringfyData[i].TotalTlAmount;
          setPlatinVolume(platinTotal);
        }
      }
    }
  }

  const options = {
    series: [
      {
        name: "Altın",
        data: [0, goldVolume],
        color: "#c9a227",
      },
      {
        name: "Gümüş",
        data: [0, silverVolume],
        color: "#adb5bd",
      },
      {
        name: "Platin",
        data: [0, `${platinVolume}` + "TL"],
        color: "#36241c",
      },
    ],
    chart: {
      height: 350,
      type: "area",

      toolbar: {
        show: true,
        offsetX: 0,
        offsetY: 0,
        tools: {
          download: true,
          selection: true,
          zoom: true,
          zoomin: true,
          zoomout: true,
          pan: true,
          reset: true | '<img src="/static/icons/reset.png" width="20">',
          customIcons: [],
        },
        export: {
          csv: {
            filename: undefined,
            columnDelimiter: ",",
            headerCategory: "category",
            headerValue: "value",
            dateFormatter(timestamp) {
              return new Date(timestamp).toDateString();
            },
          },
          svg: {
            filename: undefined,
          },
          png: {
            filename: undefined,
          },
        },
        autoSelected: "zoom",
      },
    },
    dataLabels: {
      enabled: true,
    },
    stroke: {
      curve: "smooth",
      colors: ["#c9a227", "#adb5bd", "#36241c"],
    },
    markers: {
      colors: ["#c9a227", "#adb5bd", "#36241c"],
    },
    fill: {
      colors: ["#c9a227", "#adb5bd", "#36241c"],
    },
    labels: {
      colors: ["#c9a227", "#adb5bd", "#36241c"],
    },
    title: {
      text: "Saatlik Hacimler",
      align: "left",
      margin: 10,
      offsetX: 0,
      offsetY: 0,
      floating: false,
      style: {
        fontSize: "16px",
        fontWeight: "bold",

        color: "#263238",
      },
    },
    subtitle: {
      text: "Türk Lirasına göre hesaplanmıştır.",
      align: "center",
      margin: 10,
      offsetX: 0,
      offsetY: 10,
      floating: false,
      style: {
        fontSize: "12px",
        fontWeight: "normal",
        fontFamily: undefined,
        color: "#9699a2",
      },
    },
    xaxis: {
      type: "category",
      categories: ["00:00", "23:00"],
    },
    tooltip: {
      x: {
        format: "dd/MM/yy HH:mm",
      },
    },
  };

  return (
    <LocalizationProvider dateAdapter={AdapterDayjs}>
      <Box sx={{ width: "100%" }}>
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
          }}
        >
          <Button
            onClick={() => {
              handleOpen();
            }}
            variant="outlined"
            endIcon={<ArrowDownwardIcon />}
            sx={{ marginBottom: "1rem" }}
          >
            {" "}
            Tarih Aralığı{" "}
          </Button>
          {open && (
            <DatePicker
              value={startDate}
              onChange={(date) => setStartDate(date)}
              renderInput={(params) => <TextField {...params} size="small" />}
              label="İncelemek istediğiniz tarihi seçin"
              inputFormat="DD/MM/YYYY"
            />
          )}
        </Box>

        <Chart options={options} series={options.series} type="area" />
      </Box>
    </LocalizationProvider>
  );
};

export default Gunluk;
