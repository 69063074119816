import {
  Box,
  Button,
  TextField,
  Typography,
  Select,
  MenuItem,
  InputLabel,
  FormControl,
} from "@mui/material";
import React from "react";

function AddUserEmtia(props) {
  const [authCode, setAuthCode] = React.useState(
    sessionStorage.getItem("AuthCode")
  );
  const [fetchUrl, setFetchUrl] = React.useState(
    sessionStorage.getItem("FetchUrl")
  );
  const [choosenVendor, setChoosenVendor] = React.useState("");
  const [choosenCommodity, setChoosenCommodity] = React.useState("");
  const [userId, setUserId] = React.useState("");
  const [res, setRes] = React.useState([]);
  const [comm, setComm] = React.useState([]);

  const [durum, setDurum] = React.useState(false);
  const [vendorBalance, setVendorBalance] = React.useState([]);
  const [filtered, setFiltered] = React.useState([]);
  // const total = filtered.reduce((acc,balance)=> acc + balance.Balance,0)
  const [message, setMessage] = React.useState("");
  React.useEffect(() => {
    const interval = setInterval(() => {
      setMessage("");
    }, 2000);
    return () => clearInterval(interval);
  }, []);

  const handleChooseVendor = (name) => {
    setChoosenVendor(name.target.value);
    setChoosenCommodity("");
  };
  const handleChooseCommodity = (name) => {
    setChoosenCommodity(name.target.value);
  };

  React.useEffect(() => {
    fetchedBalance(choosenVendor, choosenCommodity);
  }, [choosenVendor]);

  React.useEffect(() => {
    fetchedBalance(choosenVendor, choosenCommodity);
  }, [choosenCommodity]);

  React.useEffect(() => {
    fetchedVendor();
    fetchedCommodity();
  }, []);

  async function fetchedVendor() {
    var myHeaders = new Headers();
    myHeaders.append("Authorization", authCode);

    var requestOptions = {
      method: "GET",
      headers: myHeaders,
      redirect: "follow",
    };

    const response = await fetch(
      `${fetchUrl}Admin/GetAll?pageNumber=1`,
      requestOptions
    );
    const fetchedData = await response.json();
    const myData = fetchedData.Data;
    setRes(myData);
  }

  async function fetchedCommodity() {
    var myHeaders = new Headers();
    myHeaders.append("Authorization", authCode);

    var requestOptions = {
      method: "GET",
      headers: myHeaders,
      redirect: "follow",
    };

    const response = await fetch(
      `${fetchUrl}Admin/GetAllCommodity?pageNumber=1`,
      requestOptions
    );
    const fetchedData = await response.json();

    const myData = fetchedData.Data;
    var endpointsArr = [];
    var newArr = [];
    for (let i = 0; i < myData.length; i++) {
      endpointsArr.push({
        Id: myData[i].Id,
        Suffix: myData[i].Suffix,
        Name: myData[i].Name === "TL" ? null : myData[i].Name,
        IsActive: myData[i].IsActive,
      });
      if (endpointsArr[i].Name !== null) {
        newArr.push(endpointsArr[i]);
      }
    }
    setComm(newArr);
  }

  async function fetchedBalance(choosenVendor, choosenCommodity) {
    var myHeaders = new Headers();
    myHeaders.append("Authorization", authCode);

    var requestOptions = {
      method: "GET",
      headers: myHeaders,
      redirect: "follow",
    };

    const response = await fetch(
      `${fetchUrl}Admin/GetByVendorIdWithBalances?vendorId=${choosenVendor}`,
      requestOptions
    );
    const fetchedData = await response.json();
    const myData = fetchedData.Data.Balances;
    setVendorBalance(myData);
    for (let i = 0; i < myData.length; i++) {
      if (vendorBalance[i].CommodityId === choosenCommodity) {
        setFiltered(
          vendorBalance.filter((info) => info.CommodityId === choosenCommodity)
        );
      }
    }
  }

  return (
    <Box sx={{ marginLeft: "3rem" }}>
      <Typography variant="h4" gutterBottom>
        Usera Emtia Tanımlama
      </Typography>
      <Box
        sx={{
          display: "flex",
          direction: "row",
          gap: "6rem",
          marginTop: "3rem",
        }}
      >
        <Box
          component="form"
          sx={{
            "& > :not(style)": { m: 1, width: "35ch" },
            display: "flex",
            flexDirection: "column",
            gap: 2,
            backgroundColor: "rgba(232, 219, 176, 0.5)",

            borderRadius: "0.5rem",
            justifyContent: "center",
            alignItems: "flex-start",
            padding: "2rem",
          }}
          noValidate
          autoComplete="off"
        >
          <TextField
            type="number"
            id="standard-required"
            label={"User Id giriniz"}
            defaultValue=""
            variant="standard"
            onChange={(event) => setUserId(event.target.value)}
            value={userId}
          />
          <FormControl sx={{ width: "160px" }}>
            <InputLabel id="demo-simple-select-label">User Seçiniz</InputLabel>
            <Select
              labelId="demo-simple-select-label"
              id="demo-simple-select"
              value={choosenVendor}
              label="User Seçiniz"
              onChange={handleChooseVendor}
            >
              {res.map((res) => (
                <MenuItem key={res.Id} value={res.Id}>
                  {res.Name}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
          <FormControl sx={{ width: "160px" }}>
            <InputLabel id="demo-simple-select-label">
              Commodity Seçiniz
            </InputLabel>
            <Select
              labelId="demo-simple-select-label"
              id="demo-simple-select"
              value={choosenCommodity}
              label="Commodity Seçiniz"
              onChange={handleChooseCommodity}
            >
              {comm.map((comm) => (
                <MenuItem key={comm.Id} value={comm.Id}>
                  {comm.Name}
                </MenuItem>
              ))}
            </Select>
          </FormControl>

          <Box sx={{ marginTop: "1rem" }}>
            {choosenCommodity !== "" && choosenVendor != "" ? (
              <Button variant="contained">Ekle</Button>
            ) : (
              <Button variant="contained" disabled>
                Ekle
              </Button>
            )}
          </Box>
          {message.length > 0 && (
            <Typography
              sx={{
                color: durum ? "green" : "red",
                fontWeight: "bold",
                marginTop: "1rem",
              }}
            >
              {message}
            </Typography>
          )}
        </Box>
      </Box>
    </Box>
  );
}

export default AddUserEmtia;
