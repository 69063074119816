import { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
// @mui
import {
  Stack,
  IconButton,
  InputAdornment,
  TextField,
  Typography,
  InputLabel,
  Select,
  MenuItem,
  FormControl,
} from "@mui/material";
import { LoadingButton } from "@mui/lab";
// components
import Iconify from "../../../components/iconify";
import LoadingOverlay from "src/components/LoadingOverlay/LoadingOverlay";

// ----------------------------------------------------------------------

export default function LoginForm() {
  const navigate = useNavigate();

  const [mailValue, setMailValue] = useState("");
  const [passValue, setPassValue] = useState("");
  const [showPassword, setShowPassword] = useState(false);
  const [loginStatus, setLoginStatus] = useState(true);
  const [loginMessage, setLoginMessage] = useState("");
  const [res, setRes] = useState([
    { id: 1, Name: "https://www.fintag.org/" },
    { id: 2, Name: "https://www.fintag.co/" },
    { id: 3, Name: "https://www.goldtagdev.com/" },
    { id: 4, Name: "https://www.goldtagtech.com/" },
  ]);

  const [choosenStatus, setChoosenStatus] = useState("");

  const [loading, setLoading] = useState(false);

  global.MAIL = choosenStatus;

  sessionStorage.setItem("FetchUrl", global.FETCHURL);

  async function sendAuthCode(mailValue, passValue) {
    setLoading(true);

    setTimeout(() => {
      setLoading(false);
    }, 6000);
    var myHeaders = new Headers();

    myHeaders.append("Content-Type", "application/json");

    var raw = JSON.stringify({
      email: mailValue,
      password: passValue,
    });

    var requestOptions = {
      method: "POST",
      headers: myHeaders,
      body: raw,
      redirect: "follow",
    };

    const response = await fetch(
      `${global.FETCHURL}Auth/LoginForAdmin`,
      requestOptions
    );
    const fetchedData = await response.json();

    if (fetchedData.Success) {
      navigate("/auth", { state: { mailValue } });
    } else if (fetchedData.Success === false) {
      setLoginStatus(false);
      setLoginMessage(fetchedData.Message);
    }
  }

  const handleChooseStatus = (name) => {
    setChoosenStatus(name.target.value);

    global.FETCHURL = name.target.value;

    sessionStorage.setItem("fetchUrl", global.FETCHURL);
  };

  return (
    <>
      <Stack spacing={3}>
        <TextField
          name="email"
          label="Email adresi"
          value={mailValue}
          onChange={(e) => setMailValue(e.target.value)}
        />

        <TextField
          name="password"
          label="Parola"
          type={showPassword ? "text" : "password"}
          value={passValue}
          onChange={(e) => setPassValue(e.target.value)}
          InputProps={{
            endAdornment: (
              <InputAdornment position="end">
                <IconButton
                  onClick={() => setShowPassword(!showPassword)}
                  edge="end"
                >
                  <Iconify
                    icon={showPassword ? "eva:eye-fill" : "eva:eye-off-fill"}
                  />
                </IconButton>
              </InputAdornment>
            ),
          }}
        />

        <FormControl sx={{ width: "160px" }}>
          <InputLabel id="demo-simple-select-label">URL Seçiniz</InputLabel>
          <Select
            labelId="demo-simple-select-label"
            id="demo-simple-select"
            value={choosenStatus}
            label="URL Seçiniz"
            onChange={handleChooseStatus}
            style={{ width: "15rem" }}
          >
            {res.map((res) => (
              <MenuItem key={res.Name} value={res.Name}>
                {res.Name}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
      </Stack>
      {loginStatus ? (
        ""
      ) : (
        <Typography
          sx={{
            color: "red",
            alignSelf: "center",
            marginTop: "1rem",
            fontStyle: "italic",
          }}
        >
          {loginMessage}
        </Typography>
      )}

      <LoadingButton
        sx={{ marginTop: "2rem" }}
        fullWidth
        size="large"
        type="submit"
        variant="contained"
        onClick={() => {
          sendAuthCode(mailValue, passValue);
        }}
      >
        Giriş Yap
      </LoadingButton>

      <LoadingOverlay isOpen={loading} />
    </>
  );
}
