// routes
import Router from "./routes";
// theme
import ThemeProvider from "./theme";
// components
import { StyledChart } from "./components/chart";

import ArrowCircleUpIcon from "@mui/icons-material/ArrowCircleUp";
import { Button, Box, Typography } from "@mui/material";
import React, { useState, useEffect } from "react";
import DashboardAppPage from "./pages/DashboardAppPage";
import DashboardMainPage from "./CryptoPages/DashboardMainPage";
import { lightTitle14 } from "./utils/customStyles";

// ----------------------------------------------------------------------

export default function App() {
  const [showButton, setShowButton] = useState(false);
  const [url, setUrl] = useState(sessionStorage.getItem("FetchUrl"));

  useEffect(() => {
    const HandleScrollButtonVisibility = () => {
      window.pageYOffset > 300 ? setShowButton(true) : setShowButton(false);
    };

    window.addEventListener("scroll", HandleScrollButtonVisibility);

    return () => {
      window.removeEventListener("scroll", HandleScrollButtonVisibility);
    };
  });

  const HandleScrollToTop = () => {
    window.scrollTo({ top: 0, behavior: "smooth" });
  };

  console.log = () => {};

  return (
    <ThemeProvider>
      {showButton && (
        <Box
          sx={{
            position: "absolute",
            top: 900,
            right: 100,
            webkitBoxShadow: " 10px 10px 30px 0px rgba(173,127,35,1)",
            mozBoxShadow: " 10px 10px 30px 0px rgba(173,127,35,1)",
            boxShadow: " 10px 10px 30px 0px rgba(173,127,35,1)",
          }}
        >
          <Button
            onClick={HandleScrollToTop}
            sx={{
              position: "fixed",
              zIndex: "22",
              opacity: "1",
            }}
          >
            <ArrowCircleUpIcon sx={{ width: "3rem", height: "3rem" }} />
          </Button>
        </Box>
      )}

      <StyledChart />
      <Router />
    </ThemeProvider>
  );
}
