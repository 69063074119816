const authCode = sessionStorage.getItem("AuthCode");

const fetchUrl = sessionStorage.getItem("FetchUrl");

export async function GetCurrencyVendors() {
  var myHeaders = new Headers();
  myHeaders.append("Authorization", authCode);

  var requestOptions = {
    method: "GET",
    headers: myHeaders,
    redirect: "follow",
  };

  const response = await fetch(
    `${fetchUrl}Admin/GetCurrencyVendors`,
    requestOptions
  );

  const fetchedData = await response.json();

  const myData = fetchedData.Data;
  return myData;
}

export async function fetchPrices() {
  var requestOptions = {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
      Authorization: authCode,
    },
  };
  const response = await fetch(
    `${fetchUrl}Admin/GetCurrencyPrices`,
    requestOptions
  );
  const fetchedData = await response.json();
  const stringfyData = await fetchedData.Data;

  let altinkaynak = [];
  let kuveytturk = [];
  let serbest = [];
  let fintag = [];
  console.log(stringfyData);

  for (let i = 0; i < stringfyData.length; i++) {
    if (stringfyData[i].Id == "cc4725fe-5a87-4c08-96ae-0c299e11c63f") {
      altinkaynak = stringfyData[i];
    } else if (stringfyData[i].Id == "622fcc12-d924-4bc0-893f-442d69bacfe5") {
      kuveytturk = stringfyData[i];
    } else if (stringfyData[i].Id == "7170a241-7b46-4750-8ea3-88c36c0f21f4") {
      serbest = stringfyData[i];
    } else if (stringfyData[i].Id == "def59578-4db1-4f2f-adda-aaec94fc3719") {
      fintag = stringfyData[i];
    }
  }

  return { altinkaynak, kuveytturk, serbest, fintag };
}
