import background from "../utils/images/background.png";

export const pageContainer = {
  marginLeft: { xs: "1rem", sm: "2rem", md: "3rem" },
};

export const priceCard = {
  border: "1px solid #D9DFE3",
  display: "flex",
  marginTop: "1rem",
  marginBottom: "1rem",
  flexDirection: "row",
  marginRight: "auto",
  borderRadius: "20px",
  padding: "1rem",
  bgcolor: "background.paper",
  justifyContent: "space-between",
  paddingLeft: 8,
  paddingRight: 8,
  alignItems: "center",
};

export const lightTitle14 = {
  opacity: 0.5,
  fontSize: "14px",
  letterSpacing: 0.5,
};

export const underlineBold18 = {
  fontWeight: "bold",
  textDecoration: "underline",
  color: "#383838",
  fontSize: 18,
};

export const dividerVertical = {
  backgroundColor: "#383838",
  opacity: 0.2,
  height: "50px",
  width: "1px",
  margin: 1,
};

export const cardList = {
  display: "flex",
  justifyContent: "space-between",
  alignItems: "center",
  gap: "2rem",
  background: " rgb(255,255,255)",
  background:
    "linear-gradient(90deg, rgba(255,255,255,1) 0%, rgba(56,56,56,0.1) 100%)",
  borderRadius: 36,
  padding: 1,
  width: { xs: "300px", sm: "100%", md: "100%" },
};

export const circleStatus = {
  width: 24,
  height: 24,
  marginTop: 1,
  marginRight: { xs: 0, sm: 12, md: 6 },
  marginLeft: { xs: 0, sm: 12, md: 6 },
};

export const imageCircle = {
  display: "absolute",
  width: 50,
  height: 50,
  marginRight: "5px",
  borderRadius: 999,
  backgroundColor: "white",
  alignItems: "center",
  justifyContent: "center",
  display: { xs: "none", sm: "none", md: "flex" },
};

export const frameOrders1 = {
  display: "flex",
  flexDirection: "row",
  alignItems: "center",
  gap: "0.7rem",
  justifyContent: "center",
};

export const dateStyle = {
  fontSize: "12px",
  color: "#383838",
  textAlign: "center",
  fontWeight: "bold",
  opacity: 0.3,
  display: { xs: "none", sm: "none", md: "flex" },
};

export const insideTitle16 = {
  fontWeight: "bold",
  color: "#383838",
  fontSize: { xs: "12px", sm: "14px", md: "16px" },
  letterSpacing: 1,
  width: { xs: 60, sm: 130, md: 130 },
};

export const priceFrame = {
  display: "flex",
  justifyContent: "space-between",
  alignItems: "center",
  gap: "2rem",
  marginRight: { xs: 0, sm: 12, md: 12 },
};

export const priceTitle = {
  fontSize: "13px",
  color: "#383838",
  textAlign: "center",
};

export const priceSubTitle = {
  fontSize: "12px",
  color: "#383838",
  textAlign: "center",
  fontWeight: "bold",
};

export const orderContainer = {
  display: "flex",
  flexDirection: "column",
  backgroundColor: "white",
  width: "100%",
  borderRadius: "5px",
  gap: "1rem",
  marginTop: "15px",
};

export const startPageContainer = {
  display: "flex",
  flexDirection: "column",
  backgroundImage: `url(${background})`,
  backgroundSize: "cover",
  backgroundPosition: "center",
  justifyContent: "center",
  alignItems: "center",
  width: "100vw",
  height: "100vh",
  overflowX: "hidden",
};

export const startButton = {
  backgroundImage: `url(${background})`,
  backgroundSize: "cover",
  backgroundPosition: "center",
  borderRadius: 6,
  width: { xs: 175, sm: 300, md: 300 },
  height: { xs: 60, sm: 100, md: 100 },
  webkitBoxShadow: "0px 0px 46px -10px rgba(246,244,238,1)",
  mozBoxShadow: "0px 0px 46px -10px rgba(246,244,238,1)",
  boxShadow: " 0px 0px 46px -10px rgba(246,244,238,1)",
};

export const startGrid = {
  alignItems: "center",
  justifyContent: "center",
  display: "flex",
  backgroundColor: "#383838",
  width: "60%",
  height: "30%",
  webkitBoxShadow: "0px 0px 50px 3px rgba(56, 56, 56, 0.5)",
  mozBoxShadow: "0px 0px 50px 3px rgba(56, 56, 56, 0.5)",
  boxShadow: " 0px 0px 50px 3px rgba(56, 56, 56, 0.5)",
  marginTop: 15,
};

export const startLogo = {
  position: "absolute",
  top: 150,
  alignItems: "center",
  display: "flex",
  justifyContent: "center",
  flexDirection: "column",
};

export const startText = {
  position: "absolute",
  top: 200,
  alignItems: "center",
  display: "flex",
  justifyContent: "center",
  flexDirection: "column",
};

export const startBigTitle = {
  color: "#383838",
  fontWeight: "bold",
  fontSize: { xs: 16, sm: 24, md: 24 },
  letterSpacing: 1.5,
};

export const startSmallText = {
  opacity: 0.5,
  letterSpacing: 1.5,
  fontSize: 11.1,
  width: "138px",
};

export const transactionFrame1 = {
  display: "flex",
  flexDirection: "column",
  alignItems: "flex-start",
};

export const modalStyle = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 450,
  height: 200,
  border: "1px solid #000",
  boxShadow: 24,
  p: 4,
  justifyContent: "center",
  alignItems: "center",
  display: "flex",
  flexDirection: "column",
  borderRadius: "12px",
  backgroundColor: "#D9DFE3",
};

export const priceEnterButton = {
  width: "9rem",
  alignSelf: "center",
  marginTop: "1rem",
};

export const amountContainer = {
  border: "1px solid #D9DFE3",
  display: "flex",
  marginTop: "1rem",
  marginBottom: "1rem",
  flexDirection: "column",
  width: "42rem",
  borderRadius: "20px",
  padding: "1rem",
  bgcolor: "background.paper",
  width: "30rem",
};

export const flexRow = {
  display: "flex",
  flexDirection: "row",
  alignItems: "flex-end",
};

export const amountTitle = {
  fontSize: "16px",
  alignItems: "center",
  gap: "0.2rem",
  width: "50%",
  marginTop: "0.4rem",
};

export const amount = {
  fontWeight: "600",
  textDecoration: "underline",
  color: "#383838",
  marginRight: "0.5rem",
};
