import {
  Backdrop,
  Box,
  Button,
  CircularProgress,
  FormControl,
  FormControlLabel,
  FormLabel,
  InputLabel,
  Radio,
  RadioGroup,
  Select,
  Typography,
} from "@mui/material";
import React from "react";
import MenuItem from "@mui/material/MenuItem";
function VendorMakeAutomaticActive(props) {
  const [choosenVendor, setChoosenVendor] = React.useState("");
  const [vendorData, setVendorData] = React.useState([]);
  const [status, setStatus] = React.useState("");
  const [open, setOpen] = React.useState(false);
  const [popup, setPopup] = React.useState(false);
  const [durum, setDurum] = React.useState(false);
  const [message, setMessage] = React.useState("");

  const handleClose = () => {
    setOpen(false);
  };
  setTimeout(handleClose, 1000);
  const handleToggle = () => {
    setOpen(!open);
  };
  React.useEffect(() => {
    const interval = setInterval(() => {
      setPopup(false);
    }, 3000);
    return () => clearInterval(interval);
  }, []);

  const [authCode, setAuthCode] = React.useState(
    sessionStorage.getItem("AuthCode")
  );
  const [fetchUrl, setFetchUrl] = React.useState(
    sessionStorage.getItem("FetchUrl")
  );

  const handleChooseVendor = (name) => {
    setChoosenVendor(name.target.value);
  };
  const handleChangeRadioBtn = (event) => {
    setStatus(event.target.value);
  };

  React.useEffect(() => {
    fetchedData();
  }, []);

  async function fetchedData() {
    var myHeaders = new Headers();
    myHeaders.append("Authorization", authCode);

    var requestOptions = {
      method: "GET",
      headers: myHeaders,
      redirect: "follow",
    };

    const response = await fetch(
      `${fetchUrl}Admin/GetAll?pageNumber=1`,
      requestOptions
    );
    const fetchedData = await response.json();
    const myData = fetchedData.Data;
    setVendorData(myData);
  }
  async function activePassiveFetchedData() {
    var myHeaders = new Headers();
    myHeaders.append("Authorization", authCode);

    var requestOptions = {
      method: "POST",
      headers: myHeaders,
      redirect: "follow",
    };

    const response = await fetch(
      `${fetchUrl}Admin/MakeAutomatic${status}?vendorId=${choosenVendor}`,
      requestOptions
    );
    const fetchedData = await response.json();
    fetchedData.Success === true ? setDurum(true) : setDurum(false);
    setMessage(fetchedData.Message);
  }

  return (
    <Box sx={{ display: "column", flexShrink: 1, minWidth: "30px" }}>
      <Typography variant="h4" gutterBottom>
        Vendor Aktif/Pasif
      </Typography>
      <Box
        sx={{
          marginBottom: "2.5rem",
          marginTop: "1rem",
          flexShrink: 1,
          minWidth: "30px",
        }}
      >
        <FormControl sx={{ width: "375px" }}>
          <InputLabel id="demo-simple-select-label">
            İşlem Yapmak istediğiniz Vendoru Seçiniz
          </InputLabel>
          <Select
            labelId="demo-simple-select-label"
            id="demo-simple-select"
            value={choosenVendor}
            label="Aktif yapmak istediğiniz Vendoru Seçiniz"
            onChange={handleChooseVendor}
          >
            {vendorData.map((res) => (
              <MenuItem key={res.Id} value={res.Id}>
                {res.Name}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
      </Box>

      <Box sx={{ marginBottom: "2.5rem" }}>
        <FormControl>
          <FormLabel id="demo-controlled-radio-buttons-group">
            Yapılacak İşlemi Seçiniz
          </FormLabel>
          <RadioGroup
            aria-labelledby="demo-controlled-radio-buttons-group"
            name="controlled-radio-buttons-group"
            value={status}
            onChange={handleChangeRadioBtn}
          >
            <FormControlLabel
              value="active"
              control={<Radio />}
              label="Aktif"
            />
            <FormControlLabel
              value="passive"
              control={<Radio />}
              label="Pasif"
            />
          </RadioGroup>
        </FormControl>
      </Box>

      <Box>
        {status !== "" && choosenVendor !== "" ? (
          <Button
            variant="contained"
            sx={{ width: "50px", height: "30px" }}
            onClick={() => [activePassiveFetchedData(), handleToggle()]}
          >
            Onayla
          </Button>
        ) : (
          <Button
            variant="contained"
            sx={{ width: "50px", height: "30px" }}
            disabled
          >
            Onayla
          </Button>
        )}
      </Box>
      <Backdrop
        sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
        open={open}
        onClick={handleClose}
      >
        <CircularProgress color="inherit" />
      </Backdrop>
      {message.length > 0 && (
        <Typography
          sx={{
            color: durum ? "green" : "red",
            fontWeight: "bold",
            marginTop: "1rem",
          }}
        >
          {message}
        </Typography>
      )}
    </Box>
  );
}

export default VendorMakeAutomaticActive;
