import {
  Box,
  Button,
  FormControl,
  MenuItem,
  FormControlLabel,
  Select,
  FormLabel,
  InputLabel,
  Radio,
  RadioGroup,
  Typography,
} from "@mui/material";
import React from "react";

function ProviderMakeActivePassive(props) {
  const [providerId, setProviderId] = React.useState("");
  const [providerData, setProviderData] = React.useState([]);
  const [status, setStatus] = React.useState("");
  const [authCode, setAuthCode] = React.useState(
    sessionStorage.getItem("AuthCode")
  );
  const [fetchUrl, setFetchUrl] = React.useState(
    sessionStorage.getItem("FetchUrl")
  );
  const [durum, setDurum] = React.useState(false);
  const [provName, setProvName] = React.useState("");
  const [message, setMessage] = React.useState("");
  const [choosenProvider, setChoosenProvider] = React.useState("");
  const [provider, setProvider] = React.useState([]);
  const handleChangeRadioBtn = (event) => {
    setStatus(event.target.value);
  };
  const handleChoosenProvider = (name) => {
    setChoosenProvider(name.target.value);
  };
  React.useEffect(() => {
    fetchedData();
    getAllFetchedProvider();
  }, []);

  async function getAllFetchedProvider() {
    var myHeaders = new Headers();
    myHeaders.append("Authorization", authCode);

    var requestOptions = {
      method: "GET",
      headers: myHeaders,
      redirect: "follow",
    };

    const response = await fetch(
      `${fetchUrl}Admin/GetAllProvider?pageNumber=1`,
      requestOptions
    );
    const fetchedData = await response.json();
    const myData = fetchedData.Data;
    setProvider(myData);
  }
  React.useEffect(() => {
    const interval = setInterval(() => {
      setMessage("");
    }, 2000);
    return () => clearInterval(interval);
  }, []);
  async function fetchedData() {
    var myHeaders = new Headers();
    myHeaders.append("Authorization", authCode);

    var requestOptions = {
      method: "GET",
      headers: myHeaders,
      redirect: "follow",
    };

    const response = await fetch(
      `${fetchUrl}Admin/GetAll?pageNumber=1`,
      requestOptions
    );
    const fetchedData = await response.json();
    const myData = fetchedData.Data;
    setProviderData(myData);
  }

  async function activePassiveFetchedData() {
    var myHeaders = new Headers();
    myHeaders.append("Authorization", authCode);

    var requestOptions = {
      method: "POST",
      headers: myHeaders,
      redirect: "follow",
    };

    const response = await fetch(
      `${fetchUrl}Admin/Make${status}Provider?id=${choosenProvider}`,
      requestOptions
    );
    const fetchedData = await response.json();

    fetchedData.Success === true ? setDurum(true) : setDurum(false);
    setMessage(fetchedData.Message);
  }
  return (
    <Box sx={{ display: "column" }}>
      <Typography variant="h4" gutterBottom>
        Provider Aktif/Pasif
      </Typography>
      <Box sx={{ marginBottom: "2.5rem", marginTop: "3rem" }}>
        <FormControl sx={{ width: "220px" }}>
          <InputLabel id="demo-simple-select-label">
            Provider Seçiniz
          </InputLabel>
          <Select
            labelId="demo-simple-select-label"
            id="demo-simple-select"
            value={choosenProvider}
            label="Vendor Seçiniz"
            onChange={handleChoosenProvider}
            provName={provName}
          >
            {provider.map((provider) => (
              <MenuItem
                key={provider.Id}
                value={provider.Id}
                provName={provider.Name}
                onClick={() => setProvName(provider.Name)}
              >
                {provider.Name}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
      </Box>

      <Box sx={{ marginBottom: "2.5rem" }}>
        <FormControl>
          <FormLabel id="demo-controlled-radio-buttons-group">
            Yapılacak İşlemi Seçiniz
          </FormLabel>
          <RadioGroup
            aria-labelledby="demo-controlled-radio-buttons-group"
            name="controlled-radio-buttons-group"
            value={status}
            onChange={handleChangeRadioBtn}
          >
            <FormControlLabel
              value="active"
              control={<Radio />}
              label="Aktif"
            />
            <FormControlLabel
              value="passive"
              control={<Radio />}
              label="Pasif"
            />
          </RadioGroup>
        </FormControl>
      </Box>

      <Box>
        {status !== "" && choosenProvider !== "" ? (
          <Button
            variant="contained"
            sx={{ width: "50px", height: "30px" }}
            onClick={() => activePassiveFetchedData()}
          >
            Onayla
          </Button>
        ) : (
          <Button
            variant="contained"
            sx={{ width: "50px", height: "30px" }}
            disabled
          >
            Onayla
          </Button>
        )}
        {message.length > 0 && (
          <Typography
            sx={{
              color: durum ? "green" : "red",
              fontWeight: "bold",
              marginTop: "1rem",
            }}
          >
            {message}
          </Typography>
        )}
      </Box>
    </Box>
  );
}

export default ProviderMakeActivePassive;
