import {React,useState} from 'react'
import {Box,Button, Typography} from '@mui/material';
import Chart from "react-apexcharts";

import ArrowDownwardIcon from "@mui/icons-material/ArrowDownward";

import TextField from "@mui/material/TextField";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import dayjs from "dayjs";


const TarımGrafik = () => {
  const [open, setOpen] = useState(false);
  const [startDate, setStartDate] = useState(dayjs);
  const handleOpen = () => {
    setOpen(!open);
  };
  const [datas, setDatas] = useState([
    [0],
  
  ])  
  const options = {
        series: [{
        name: 'Arpa',
        data: datas[0],
        color: '#5a380b'
      }, {
        name: 'Buğday',
        data: datas[1],
        color: '#ed862b'
      },{
        name: 'Mısır',
        data: datas[2],
        color: '#5c633a'
      }
    
    ],
    
    fill: {
      colors: ['#5a380b', '#ed862b', '#5c633a'],
     

    },
        chart: {
        height: 350,
        type: 'area',
       
      },
      dataLabels: {
        enabled: false,
      },
      
      markers: {
        colors: ['#5a380b', '#ed862b', '#5c633a'],
       
     },

  
      stroke: {
        curve: 'smooth',
        colors:['#5a380b', '#ed862b', '#5c633a']
      
      },
      xaxis: {
        type: 'datetime',
        categories: ["2018-09-19T00:00:00.000Z", "2018-09-19T01:30:00.000Z", "2018-09-19T02:30:00.000Z", "2018-09-19T03:30:00.000Z", "2018-09-19T04:30:00.000Z", "2018-09-19T05:30:00.000Z", "2018-09-19T06:30:00.000Z"],
       
      },
      labels: {
        colors: ['#03071e', '#d00000', '#ffba08'],
      
      },
      title: {
        text: 'Saatlik Hacimler',
        align: 'left',
        margin: 10,
        offsetX: 0,
        offsetY: 0,
        floating: false,
        style: {
          fontSize:  '16px',
          fontWeight:  'bold',
         
          color:  '#263238'
        },
    },
   
      tooltip: {
        
        x: {
          format: 'dd/MM/yy HH:mm',
          
        },
      },
      };
  
  return (
    <LocalizationProvider dateAdapter={AdapterDayjs}>
    <Box sx={{width:'100%'}}>
          <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
          }}
        >
          <Button
            onClick={() => {
              handleOpen();
            }}
            variant="outlined"
            endIcon={<ArrowDownwardIcon />}
            sx={{ marginBottom: "1rem" }}
          >
            {" "}
            Tarih Aralığı{" "}
          </Button>
          {open && (
            <DatePicker
              value={startDate}
              onChange={(date) => setStartDate(date)}
              renderInput={(params) => <TextField {...params} size="small" />}
              label="İncelemek istediğiniz tarihi seçin"
              inputFormat="DD/MM/YYYY"
            />
          )}
        </Box>
        <Box sx={{justifyContent:'center'}}>
  <Typography sx={{alignSelf:'center',marginLeft:'19.55rem',fontWeight:'900'}}>Şu anda veri bulunmamaktadır.</Typography>
          </Box>
        <Chart options={options} series={options.series} type="area" />
    </Box>
    </LocalizationProvider>
  )
}

export default TarımGrafik