import { Box, TextField, Typography, Button } from "@mui/material";
import React from "react";
import { useNavigate } from "react-router-dom";
import CircularProgress from "@mui/material/CircularProgress";
import Backdrop from "@mui/material/Backdrop";
function CreateAdmin(props) {
  const [authCode, setAuthCode] = React.useState(
    sessionStorage.getItem("AuthCode")
  );
  const [fetchUrl, setFetchUrl] = React.useState(
    sessionStorage.getItem("FetchUrl")
  );
  const [fetchStatus, setFetchStatus] = React.useState(false);
  const [message, setMessage] = React.useState("");
  const [messageChecked, setMessageChecked] = React.useState(false);

  const [name, setName] = React.useState("");
  const [mail, setMail] = React.useState("");
  const [phone, setPhone] = React.useState("");
  const [open, setOpen] = React.useState(false);
  const navigate = useNavigate();

  React.useEffect(() => {
    const interval = setInterval(() => {
      setOpen(false);
    }, 1000);
    return () => clearInterval(interval);
  }, []);

  React.useEffect(() => {
    const interval = setInterval(() => {
      setFetchStatus(false);
    }, 3000);
    return () => clearInterval(interval);
  }, [fetchStatus]);

  async function CreateAdmin(name, mail, phone) {
    var myHeaders = new Headers();
    myHeaders.append("Authorization", authCode);
    myHeaders.append("accept", "*/*");
    myHeaders.append("Content-Type", "application/json");
    setOpen(true);

    var raw = JSON.stringify({
      Name: name,
      Mail: mail,
      Phone: phone,
    });

    var requestOptions = {
      method: "POST",
      headers: myHeaders,
      body: raw,
      redirect: "follow",
    };

    const response = await fetch(
      `${fetchUrl}Auth/SignupForAdmin`,
      requestOptions
    );
    const fetchedData = await response.json();

    setFetchStatus(true);
    setMessage(fetchedData.Message);

    if (fetchedData.Success === true) {
      setTimeout(() => {
        navigate("/dashboard/allendpoints");
      }, 2000);
    }
  }

  return (
    <Box sx={{ paddingLeft: "3rem", paddingRight: "3rem" }}>
      <Typography variant="h4" gutterBottom>
        Admin Ekle
      </Typography>
      <Backdrop
        sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
        open={open}
      >
        <CircularProgress color="inherit" />
      </Backdrop>
      <Box
        component="form"
        sx={{
          "& > :not(style)": { m: 1, width: "25ch" },
          display: "flex",
          flexDirection: "column",
          gap: 2,
          backgroundColor: "rgba(223, 221, 208, 0.5)",
          borderRadius: "0.5rem",
          justifyContent: "center",
          alignItems: "center",
          padding: "2rem",
          width: "30rem",
          flexShrink: 1,
        }}
        noValidate
        autoComplete="off"
      >
        <TextField
          id="standard-required"
          label={"Kullanıcı Adı"}
          defaultValue=""
          variant="standard"
          onChange={(event) => {
            setName(event.target.value);
          }}
          value={name}
        />

        <TextField
          id="standard-required"
          label={"Mail Adresi"}
          defaultValue=""
          variant="standard"
          onChange={(event) => {
            setMail(event.target.value);
          }}
          value={mail}
        />

        <TextField
          id="standard-required"
          label={"Telefon Numarası (Örn: 05345135805)"}
          defaultValue=""
          variant="standard"
          onChange={(event) => {
            setPhone(event.target.value);
          }}
          value={phone}
          inputProps={{ maxLength: 11 }}
        />

        {name === "" || mail === "" || phone === "" ? (
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
              width: "250px",
              marginLeft: "56rem",
              marginTop: "2rem",
            }}
          >
            <Button variant="contained" disabled>
              Onayla
            </Button>
          </Box>
        ) : (
          <Button
            variant="contained"
            onClick={() =>
              sessionStorage.getItem("Mail") === "trnmmtz@gmail.com"
                ? CreateAdmin(name, mail, phone)
                : setMessageChecked(true)
            }
          >
            Onayla
          </Button>
        )}

        {fetchStatus && (
          <Typography
            sx={{
              textAlign: "center",
              color: message === "İşlem başarılı." ? "green" : "red",
              fontStyle: "italic",
            }}
          >
            {message}
          </Typography>
        )}
        {messageChecked && (
          <Typography
            sx={{ textAlign: "center", color: "red", fontStyle: "italic" }}
          >
            Admin ekleme sadece superadmin tarafından yapılır.
          </Typography>
        )}
      </Box>
    </Box>
  );
}

export default CreateAdmin;
