import Marquee from "react-fast-marquee";
import { useState } from "react";
import { useEffect } from "react";
import { Box } from "@mui/system";
import { Helmet } from "react-helmet-async";
// @mui
import { Grid, Container, Typography } from "@mui/material";

// components
import Card1 from "../components/AleynaComponents/Card1";
import Card2 from "../components/AleynaComponents/Card2";
import Card3 from "../components/AleynaComponents/Card3";
import Card4 from "../components/AleynaComponents/Card4";
import DonutTab from "../components/AleynaComponents/DonutTab";
import Kur from "../components/AleynaComponents/Kur";
import Aylık from "../components/AleynaComponents/Aylık";
import ETgrafik from "../components/AleynaComponents/ETgrafik";

// ----------------------------------------------------------------------

export default function Vendor() {
  const [systemStatus, setSystemStatus] = useState();
  const [priceProvider, setPriceProvider] = useState();
  const [vendorStatus, setVendorStatus] = useState([]);
  const [authCode, setAuthCode] = useState(sessionStorage.getItem("AuthCode"));

  const [fetchUrl, setFetchUrl] = useState(sessionStorage.getItem("FetchUrl"));

  useEffect(() => {
    fetchSystemStatus();
    fetchPriceStatus();
    fetchAllVendors();
  }, []);

  /// Fetch
  async function fetchSystemStatus() {
    var myHeaders = new Headers();
    myHeaders.append("Authorization", authCode);

    var requestOptions = {
      method: "GET",
      headers: myHeaders,
      redirect: "follow",
    };

    const response = await fetch(
      `${fetchUrl}Admin/GetAllForSettings?pageNumber=1`,
      requestOptions
    );
    const fetchedData = await response.json();
    const stringfyData = fetchedData.Data;

    for (let y = 0; 0 < stringfyData.length; y++) {
      if (stringfyData[y].Key === "BuySellProcess") {
        if (stringfyData[y].isActive) {
          setSystemStatus("AUTO");
        } else {
          setSystemStatus("MANUEL");
        }
      }
    }
  }

  async function fetchPriceStatus() {
    var myHeaders = new Headers();
    myHeaders.append("Authorization", authCode);

    var requestOptions = {
      method: "GET",
      headers: myHeaders,
      redirect: "follow",
    };

    const response = await fetch(
      `${fetchUrl}Provider/GetAll?pageNumber=1`,
      requestOptions
    );
    const fetchedData = await response.json();
    const stringfyData = fetchedData.Data;

    for (let x = 0; 0 < stringfyData.length; x++) {
      if (stringfyData[x].IsActive === "True") {
        setPriceProvider(stringfyData[x].Name);
      }
    }
  }

  async function fetchAllVendors() {
    var myHeaders = new Headers();
    myHeaders.append("Authorization", authCode);

    var requestOptions = {
      method: "GET",
      headers: myHeaders,
      redirect: "follow",
    };

    const response = await fetch(
      `${fetchUrl}Admin/GetAll?pageNumber=1
          `,
      requestOptions
    );
    const fetchedData = await response.json();
    const stringfyData = fetchedData.Data;

    setVendorStatus(stringfyData);
  }

  return (
    <Box>
      <Helmet>
        <title>Dashboard | Vendor</title>
      </Helmet>

      <Container maxWidth="xl">
        <Marquee
          speed={20}
          loop={0}
          gradient={false}
          pauseOnHover={true}
          style={{
            backgroundColor: "black",
            borderRadius: "20px",
            justifyContent: "center",
            alignItems: "center",
            display: "flex",
            marginBottom: "1rem",
            padding: "0.3rem",
          }}
        >
          <Typography
            sx={{
              fontWeight: "bold",
              fontStyle: "normal",
              color: "#3ffc00",
              marginLeft: "2rem",
            }}
          >
            Sistem Durumu :{" "}
            <span style={{ fontStyle: "italic", fontWeight: "normal" }}>
              &nbsp;{systemStatus}
            </span>
          </Typography>
          <Typography
            sx={{
              fontWeight: "bold",
              fontStyle: "normal",
              color: "#3ffc00",
              marginLeft: "2rem",
            }}
          >
            Fiyat Sağlayıcı:{" "}
            <span style={{ fontStyle: "italic", fontWeight: "normal" }}>
              &nbsp;{priceProvider}
            </span>
          </Typography>
          {vendorStatus.map((item) => (
            <Typography
              key={item.Id}
              sx={{
                fontWeight: "bold",
                fontStyle: "normal",
                color: "#3ffc00",
                marginLeft: "2rem",
              }}
            >
              {item.Name} :{" "}
              <span style={{ fontStyle: "italic", fontWeight: "normal" }}>
                {item.Automatic ? "AUTO" : "MANUEL"}
              </span>
            </Typography>
          ))}
        </Marquee>

        <Grid
          container
          spacing={2}
          display="flex"
          style={{
            alignSelf: "center",
            justifyContent: "center",
          }}
        >
          <Grid item xs={6} sm={4} md={2}>
            <Card1 />
          </Grid>
          <Grid item xs={6} sm={4} md={2}>
            <Card2 />
          </Grid>
          <Grid item xs={6} sm={4} md={2}>
            <Card3 />
          </Grid>
          <Grid item xs={6} sm={4} md={2}>
            <Card4 />
          </Grid>

          <Grid item xs={12} md={6} lg={8}>
            <ETgrafik />
            <Box sx={{ marginTop: "2rem" }}>
              <DonutTab />
            </Box>
          </Grid>

          <Grid item xs={12} md={6} lg={4}>
            <Kur />
          </Grid>

          <Grid item xs={12} md={6} lg={8} sx={{ marginRight: "auto" }}>
            <Aylık />
          </Grid>
        </Grid>
      </Container>
    </Box>
  );
}
