import { Box, Typography,TextField,Button,FormControl,InputLabel,Select,MenuItem } from '@mui/material';
import React from 'react';

function VendorUpdate(props) {
    const [name, setName] = React.useState("")
    const [email, setEmail] = React.useState("")
    const [phone, setPhone] = React.useState("")
    const [accountNum, setAccountNum] = React.useState("")
    const [accountSuffix,setAccountSuffix] = React.useState("")
    const [id, setId] = React.useState("")
    const [checked, setChecked] = React.useState(false)
    const [authCode,setAuthCode] = React.useState(sessionStorage.getItem("AuthCode"))
    const [fetchUrl,setFetchUrl] = React.useState(sessionStorage.getItem("FetchUrl"))
    const [durum, setDurum] =  React.useState(false);
    const [message, setMessage] = React.useState("")
    const [vendorData, setVendorData] = React.useState([])
    const [choosenVendor, setChoosenVendor] = React.useState("")

    const handleChooseVendor = (name) => {
        setChoosenVendor(name.target.value);
        
       }
    React.useEffect(() => {
        const interval = setInterval(() => {
          setMessage("")
        }, 2000)
        return () => clearInterval(interval)},[])

        React.useEffect(()=>{
            fetchedData()
        },[])

  async function fetchedData(){
        var myHeaders = new Headers();
        myHeaders.append("Authorization", authCode);
    
        var requestOptions = {
          method: 'GET',
          headers: myHeaders,
          redirect: 'follow'
        };
    
        const response = await fetch(`${fetchUrl}Admin/GetAll?pageNumber=1`, requestOptions)
        const fetchedData = await response.json();
        const myData =fetchedData.Data
        setVendorData(myData)
      
       }
    async function updateVendor(name,email,phone,accountNum,accountSuffix,havaleCode){
        var myHeaders = new Headers();
    
        myHeaders.append("Authorization", authCode);
        myHeaders.append("Content-Type", "application/json");

    
        var raw = JSON.stringify({
            "Id": choosenVendor,
            "Name": name,
            "Email": email,
            "Phone": phone,
            "AccountNumber": accountNum,
            "AccountSuffix": accountSuffix,
            "HavaleCode": havaleCode
        });
    
        var requestOptions = {
          method: 'POST',
          headers: myHeaders,
          body: raw,
          redirect: 'follow'
        };
    
        const response = await fetch(`${fetchUrl}Admin/UpdateVendor`, requestOptions)
        const fetchedData = await response.json();
        
        fetchedData.Success===true ? setDurum(true) : setDurum(false)
        setMessage(fetchedData.Message)
    }
    return (
        <Box sx={{flexShrink:1,minWidth:"30px",marginRight:"3rem"}}>
        <Typography variant="h4" gutterBottom>
            Vendor Güncelle
        </Typography>
        <Box sx={{display:"flex", direction:'row'}}>
        <Box  component="form"
         sx={{
           '& > :not(style)': { m: 1, width: '35ch' },
           display:'flex',
           flexDirection:'column',
           gap:2,
           backgroundColor:'rgba(232, 219, 176, 0.5)',
         
           borderRadius:'0.5rem',
           justifyContent:'center',
           alignItems:'flex-start',
           padding:'2rem',
           flexShrink:1,minWidth:"30px"
       
         }}
       
         noValidate
         autoComplete="off">

              <Box sx={{marginBottom:"2.5rem",marginTop:'3rem', flexShrink:1,minWidth:"30px"}}>
            <FormControl sx={{width:'340px'}}>
            <InputLabel id="demo-simple-select-label">Vendor Seçiniz</InputLabel>
            <Select
                labelId="demo-simple-select-label"
                id="demo-simple-select"
                value={choosenVendor}
                label=" Vendor Seçiniz"
                onChange={handleChooseVendor}
            >
              {vendorData.map((res)=>(
                <MenuItem    
                    key={res.Id}
                    value={res.Id}>
                     {res.Name}
                      
                </MenuItem>
              ))}
            </Select>
            </FormControl>
            </Box>
                  <TextField
                     
                     id="standard-required"
                     label={"İsim giriniz"}
                     defaultValue=""
                     variant="standard"
                     onChange={(event) => setName(event.target.value) }
                     value={name}
                 />
                 <TextField
                     
                     id="standard-required"
                     label={"Email giriniz"}
                     defaultValue=""
                     variant="standard"
                     onChange={(event) => setEmail(event.target.value) }
                     value={email}
                 />
                 <TextField
                     
                     id="standard-required"
                     label={"Telefon Numarası giriniz"}
                     defaultValue=""
                     variant="standard"
                     onChange={(event) => setPhone(event.target.value) }
                     value={phone}
                 />
                 <TextField
                     
                     id="standard-required"
                     label={"Account number giriniz"}
                     defaultValue=""
                     variant="standard"
                     onChange={(event) => setAccountNum(event.target.value) }
                     value={accountNum}
                 />
                  <TextField
                     
                     id="standard-required"
                     label={"Account Suffix giriniz"}
                     defaultValue=""
                     variant="standard"
                     onChange={(event) => setAccountSuffix(event.target.value) }
                     value={accountSuffix}
                 />
             
         </Box> 
       
         </Box>
         <Box sx={{marginTop:'1rem'}}>
           { name!== "" && email !== "" && phone!== "" 
           ? <Button variant="contained" onClick={()=>[updateVendor(name,email,phone,accountNum,accountSuffix),setChecked(true)]}>Güncelle</Button>  
           : <Button  variant="contained" disabled >Güncelle</Button>}
           </Box>
         
           { message.length > 0 && <Typography sx={{color:durum ? "green" : "red",fontWeight:"bold",marginTop:"1rem"}}>{message}</Typography> }

   </Box>
    );
}

export default VendorUpdate;