import * as React from "react";
import { Box, Button, Snackbar, Typography } from "@mui/material";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import FileDownloadIcon from "@mui/icons-material/FileDownload";
import { ExportToCsv } from "export-to-csv";
import MaterialReactTable from "material-react-table";
import CircularProgress from "@mui/material/CircularProgress";
import Backdrop from "@mui/material/Backdrop";
import MuiAlert from "@mui/material/Alert";
import { pageContainer } from "src/utils/customStyles";

export default function GetAccountsSecond() {
  const [range, setRange] = React.useState([]);
  const [vendors, setVendors] = React.useState([]);
  const [status, setStatus] = React.useState(false);
  const [authCode, setAuthCode] = React.useState(
    sessionStorage.getItem("AuthCode")
  );
  const [fetchUrl, setFetchUrl] = React.useState(
    sessionStorage.getItem("FetchUrl")
  );

  const [page, setPage] = React.useState("1");

  const [choosenVendor, setChoosenVendor] = React.useState("");
  const [open, setOpen] = React.useState(false);
  const [summary, setSummary] = React.useState([]);

  const [alertmessage, setAlertMessage] = React.useState("");
  const [alertStatus, setAlertStatus] = React.useState(Boolean);
  const [openAlert, setOpenAlert] = React.useState(false);

  const Alert = React.forwardRef(function Alert(props, ref) {
    return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
  });

  const handleAlertClick = () => {
    setOpenAlert(true);
  };

  React.useEffect(() => {
    fetchedData();

    setPage("1");
  }, [choosenVendor]);

  React.useEffect(() => {
    if (choosenVendor != "") {
      fetchedDataRange(choosenVendor);
    }
  }, [page]);

  async function fetchedDataRange(choosenVendor) {
    setOpen(true);

    setStatus(false);

    var myHeaders = new Headers();
    myHeaders.append("Authorization", authCode);

    var requestOptions = {
      method: "POST",
      headers: myHeaders,
      redirect: "follow",
    };

    const response = await fetch(
      `${fetchUrl}Admin/GetAllVendorUsers?pageNumber=${page}&vendorId=${choosenVendor}`,
      requestOptions
    );
    const fetchedData = await response.json();

    const myData = fetchedData.Data;
    var endpointsArr = [];
    for (let i = 0; i < myData.length; i++) {
      endpointsArr.push({
        VendorUserId: myData[i].VendorUserId,
        CommodityName: myData[i].CommodityName,
        Balance: myData[i].Balance,
      });
    }
    setRange(endpointsArr);

    setStatus(true);

    setAlertMessage(fetchedData.Message);
    setAlertStatus(fetchedData.Success);
    handleAlertClick();

    setTimeout(() => {
      setOpen(false);
    }, 1000);
    setTimeout(() => {
      setStatus(false);
    }, 5000);
  }

  async function fetchedData() {
    var myHeaders = new Headers();
    myHeaders.append("Authorization", authCode);

    var requestOptions = {
      method: "GET",
      headers: myHeaders,
      redirect: "follow",
    };

    const response = await fetch(
      `${fetchUrl}Admin/GetAll?pageNumber=1`,
      requestOptions
    );
    const fetchedData = await response.json();
    const myData = fetchedData.Data;
    setVendors(myData);
  }

  async function WalletSummaryReport(choosenVendor) {
    var myHeaders = new Headers();
    myHeaders.append("Authorization", authCode);

    var requestOptions = {
      method: "GET",
      headers: myHeaders,
      redirect: "follow",
    };

    const response = await fetch(
      `${fetchUrl}Admin/WalletSummaryReport?vendorId=${choosenVendor}`,
      requestOptions
    );
    const fetchedData = await response.json();
    const myData = fetchedData.Data;
    setSummary(myData);
  }

  const handleChooseVendor = (name) => {
    setChoosenVendor(name.target.value);
    setStatus(false);
  };

  const columns = React.useMemo(
    () => [
      {
        accessorKey: "VendorUserId",
        header: "VendorUserId",
        size: 275,
      },
      {
        accessorKey: "CommodityName",
        header: "CommodityName",
        size: 275,
      },
      {
        accessorKey: "Balance",
        header: "Balance",
        size: 275,
      },
    ],
    []
  );

  const csvOptions = {
    fieldSeparator: ";",
    quoteStrings: '"',
    decimalSeparator: ".",
    showLabels: true,
    useBom: true,
    useKeysAsHeaders: false,
    headers: columns.map((c) => c.header),
    filename: "Transactions",
  };

  const csvExporter = new ExportToCsv(csvOptions);

  const handleExportRows = (rows) => {
    csvExporter.generateCsv(rows.map((row) => row.original));
  };

  const handleExportData = () => {
    csvExporter.generateCsv(range);
  };

  const handleAlertClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }

    setOpenAlert(false);
  };

  return (
    <Box sx={pageContainer}>
      <Typography variant="h4" gutterBottom>
        Hesaplar
      </Typography>
      <Backdrop
        sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
        open={open}
      >
        <CircularProgress color="inherit" />
      </Backdrop>
      <Box
        sx={{
          gap: "5rem",
          display: "flex",
          marginBottom: "2rem",
          marginTop: "2rem",
        }}
      >
        <FormControl sx={{ minWidth: "160px" }}>
          <InputLabel id="demo-simple-select-label">Vendor Seçiniz</InputLabel>
          <Select
            labelId="demo-simple-select-label"
            id="demo-simple-select"
            value={choosenVendor}
            label="Vendor Seçiniz"
            onChange={handleChooseVendor}
          >
            {vendors.map((vendors) => (
              <MenuItem key={vendors.Id} value={vendors.Id}>
                {vendors.Name}
              </MenuItem>
            ))}
          </Select>
        </FormControl>

        {choosenVendor != "" ? (
          <Button
            variant="contained"
            onClick={() => {
              fetchedDataRange(choosenVendor);
              WalletSummaryReport(choosenVendor);
            }}
            sx={{ height: "36px", marginTop: "0.7rem" }}
          >
            Listele
          </Button>
        ) : (
          <Button
            variant="contained"
            disabled
            sx={{ height: "36px", marginTop: "0.7rem" }}
          >
            Listele
          </Button>
        )}
      </Box>

      <Box
        style={{
          display: "flex",
        }}
      >
        {summary.length != [] && (
          <Box
            sx={{
              border: "1px solid #D9DFE3",
              display: "flex",
              flexDirection: "column",
              width: "30rem",
              borderRadius: "20px",
              padding: "1rem",
              bgcolor: "background.paper",
              marginBottom: "1rem",
            }}
          >
            <Typography
              sx={{
                display: "flex",
                flexDirection: "row",
                fontSize: "16px",
                alignItems: "center",
                gap: "0.2rem",
              }}
            >
              Toplam Hesap :{" "}
              <span
                style={{
                  fontWeight: "600",
                  textDecoration: "underline",
                  color: "#383838",
                }}
              >
                {summary.TotalNumberOfUsers}
              </span>{" "}
              Hesap
            </Typography>
            <Typography
              sx={{
                display: "flex",
                flexDirection: "row",
                fontSize: "16px",
                alignItems: "center",
                gap: "0.2rem",
              }}
            >
              Toplam Altın Miktarı :{" "}
              <span
                style={{
                  fontWeight: "600",
                  textDecoration: "underline",
                  color: "#383838",
                }}
              >
                {summary.TotalGold}
              </span>{" "}
              gr
              <Typography
                sx={{
                  marginLeft: "1rem",
                  color: "darkgrey",
                  fontWeight: "bold",
                  fontSize: "16px",
                }}
              ></Typography>
            </Typography>
            <Typography
              sx={{
                display: "flex",
                flexDirection: "row",
                fontSize: "16px",
                alignItems: "center",
                gap: "0.2rem",
              }}
            >
              Toplam Gümüş Miktarı :{" "}
              <span
                style={{
                  fontWeight: "600",
                  textDecoration: "underline",
                  color: "#383838",
                }}
              >
                {summary.TotalSilver}
              </span>
              gr
            </Typography>
            <Typography
              sx={{
                display: "flex",
                flexDirection: "row",
                fontSize: "16px",
                alignItems: "center",
                gap: "0.2rem",
              }}
            >
              Toplam Platin Miktarı :{" "}
              <span
                style={{
                  fontWeight: "600",
                  textDecoration: "underline",
                  color: "#383838",
                }}
              >
                {summary.TotalPlatin}
              </span>{" "}
              gr
            </Typography>
          </Box>
        )}
      </Box>

      {summary.length != [] && (
        <Box style={{ display: "flex", paddingBottom: "0.5rem" }}>
          <Box
            sx={{
              display: "flex",
              gap: "1rem",
              justifyContent: "flex-end",
            }}
          >
            <Button
              onClick={() => {
                let x = Number(page) - 1;
                setPage(x.toString());
              }}
              variant="contained"
              disabled={page <= 1 ? true : false}
            >
              <Typography>Geri</Typography>
            </Button>
            <Button
              onClick={() => {
                let x = Number(page) + 1;
                setPage(x.toString());
              }}
              variant="contained"
            >
              <Typography>İleri</Typography>
            </Button>
          </Box>
        </Box>
      )}

      <MaterialReactTable
        columns={columns}
        data={range}
        enableRowSelection
        initialState={{
          pagination: {
            pageSize: 50,
            pageIndex: 0,
          },
        }}
        renderTopToolbarCustomActions={({ table }) => (
          <Box
            sx={{ display: "flex", gap: "1rem", p: "0.5rem", flexWrap: "wrap" }}
          >
            <Button
              color="primary"
              onClick={handleExportData}
              startIcon={<FileDownloadIcon />}
              variant="outlined"
              size="small"
              sx={{ fontSize: { xs: "10px", sm: "12px", md: "12px" } }}
            >
              Tüm Verileri Dışa Aktar
            </Button>
            <Button
              disabled={table.getPrePaginationRowModel().rows.length === 0}
              onClick={() =>
                handleExportRows(table.getPrePaginationRowModel().rows)
              }
              startIcon={<FileDownloadIcon />}
              variant="outlined"
              size="small"
              sx={{ fontSize: { xs: "10px", sm: "12px", md: "12px" } }}
            >
              Tüm Satırları Dışa Aktar
            </Button>
            <Button
              disabled={table.getRowModel().rows.length === 0}
              onClick={() => handleExportRows(table.getRowModel().rows)}
              startIcon={<FileDownloadIcon />}
              variant="outlined"
              size="small"
              sx={{ fontSize: { xs: "10px", sm: "12px", md: "12px" } }}
            >
              Bu Sayfadaki Satırları Dışa Aktar
            </Button>
            <Button
              disabled={
                !table.getIsSomeRowsSelected() && !table.getIsAllRowsSelected()
              }
              onClick={() => handleExportRows(table.getSelectedRowModel().rows)}
              startIcon={<FileDownloadIcon />}
              variant="outlined"
              size="small"
              sx={{ fontSize: { xs: "10px", sm: "12px", md: "12px" } }}
            >
              Seçilmiş Satırları Dışa Aktar
            </Button>
          </Box>
        )}
      />

      <Snackbar
        open={openAlert}
        onClose={handleAlertClose}
        autoHideDuration={2000}
      >
        <Alert severity={alertStatus ? "success" : "error"}>
          {alertmessage}
        </Alert>
      </Snackbar>
    </Box>
  );
}
