import {React,useState,useEffect } from 'react'
import {Box, Button, Typography} from '@mui/material';
import Chart from "react-apexcharts";

const DonutHepsi = () => {
     
      const [val1, setVal1] = useState('darkBlue');
      const [val2, setVal2] = useState('#2065D1');
      const [val5, setVal5] = useState('darkBlue');
      const [val6, setVal6] = useState('#2065D1');
      const [val7, setVal7] = useState('#2065D1');

     
      
      const [selectedStatus, setSelectedStatus] = useState('Günlük');
      const [selected, setSelected] =  useState('Alım');

      

       useEffect(() => {
        selected === "Alım" ? setVal1('darkBlue') : setVal1('#2065D1');
        selected === "Satım" ? setVal2('darkBlue') : setVal2('#2065D1') ;

 
       }, [selected]);
      useEffect(()=>{
        
        selectedStatus === "Günlük" ? setVal5('darkBlue') : setVal5('#2065D1');
        selectedStatus === "Aylık" ? setVal6('darkBlue') : setVal6('#2065D1');
        selectedStatus === "Yıllık" ? setVal7('darkBlue') : setVal7('#2065D1');
        
       },[selected, selectedStatus])


      

      const handleChange=(e) => {
         setSelectedStatus(e);
        if(e ===   "Aylık" ||"Yıllık"){
         setSelected("Alım")
        }
             
          };
   const handleOnChange = (e) => {
        setSelected(e); 
       
       };
    const options = {
        series: ((selectedStatus === "Günlük" && selected=== "Alım" )? [0] : (selectedStatus === "Aylık" && selected=== "Alım" ) ?  [0] : (selectedStatus === "Yıllık" && selected=== "Alım" ) ?  [0] : (selectedStatus === "Günlük" && selected=== "Satım" ) ?  [0] :(selectedStatus === "Aylık" && selected=== "Satım" ) ? [0] : [0]),
        labels: (selected === "Alım" ? ['Altın', 'Gümüş', 'Platin', 'Arpa','Buğday', 'Mısır'] : ['Altın', 'Gümüş', 'Platin', 'Arpa','Buğday ', 'Mısır'] ),
        chart: {
        type: 'donut',
        toolbar: {
          show: true,
          offsetX: 1,
          offsetY: 1,
          tools: {
            download: true,
            selection: true,
            zoom: true,
            zoomin: true,
            zoomout: true,
            pan: true,
            reset: true | '<img src="/static/icons/reset.png" width="20">',
            customIcons: []
          },
          export: {
            csv: {
              filename: ((selectedStatus === "Günlük" && selected === "Alım") ? 'Günlük Emtia-Tarım Alım Oranları' : (selected === "Alım" && selectedStatus === "Aylık") ?  'Aylık Emtia-Tarım Alım Oranları' : (selected === "Alım" && selectedStatus === "Yıllık") ? 'Yıllık Emtia-Tarım Alım Oranları' : (selected === "Satım" && selectedStatus === "Günlük") ? 'Günlük Emtia-Tarım Satım Oranları' : (selected === "Satım" && selectedStatus === "Aylık") ? 'Aylık Emtia-Tarım Satım Oranları' : 'Yıllık Emtia-Tarım Satım Oranları'),
              columnDelimiter: ',',
              headerCategory: 'category',
              headerValue: 'value',
              dateFormatter(timestamp) {
                return new Date(timestamp).toDateString()
              }
            },
            svg: {
              filename:((selectedStatus === "Günlük" && selected === "Alım") ? 'Günlük Emtia-Tarım Alım Oranları' : (selected === "Alım" && selectedStatus === "Aylık") ?  'Aylık Emtia-Tarım Alım Oranları' : (selected === "Alım" && selectedStatus === "Yıllık") ? 'Yıllık Emtia-Tarım Alım Oranları' : (selected === "Satım" && selectedStatus === "Günlük") ? 'Günlük Emtia-Tarım Satım Oranları' : (selected === "Satım" && selectedStatus === "Aylık") ? 'Aylık Emtia-Tarım Satım Oranları' : 'Yıllık Emtia-Tarım Satım Oranları'),
            },
            png: {
              filename: ((selectedStatus === "Günlük" && selected === "Alım") ? 'Günlük Emtia-Tarım Alım Oranları' : (selected === "Alım" && selectedStatus === "Aylık") ?  'Aylık Emtia-Tarım Alım Oranları' : (selected === "Alım" && selectedStatus === "Yıllık") ? 'Yıllık Emtia-Tarım Alım Oranları' : (selected === "Satım" && selectedStatus === "Günlük") ? 'Günlük Emtia-Tarım Satım Oranları' : (selected === "Satım" && selectedStatus === "Aylık") ? 'Aylık Emtia-Tarım Satım Oranları' : 'Yıllık Emtia-Tarım Satım Oranları'),
            }
          },
          autoSelected: 'zoom' 
        },
      },
      
      plotOptions: {
        
        pie: {
          customScale: 0.6,
          dataLabels: {
            position: 'bottom',
          },
        }
      },
      legend: {
        position: 'bottom',
        horizontalAlign: 'center', 
        offsetX: 0,
        offsetY: -30,
       
      
      },
      title: {
        text: ((selectedStatus === "Günlük" && selected === "Alım") ? 'Günlük Emtia-Tarım Alım Oranları' : (selected === "Alım" && selectedStatus === "Aylık") ?  'Aylık Emtia-Tarım Alım Oranları' : (selected === "Alım" && selectedStatus === "Yıllık") ? 'Yıllık Emtia-Tarım Alım Oranları' : (selected === "Satım" && selectedStatus === "Günlük") ? 'Günlük Emtia-Tarım Satım Oranları' : (selected === "Satım" && selectedStatus === "Aylık") ? 'Aylık Emtia-Tarım Satım Oranları' : 'Yıllık Emtia-Tarım Satım Oranları'),
        align: 'center',
        offsetX: 0,
        offsetY: 40,
        floating: false,
        style: {
          fontSize:  '16px',
          fontWeight:  'bold',
          fontFamily:  undefined,
          color:  '#263238',
        },
    },
      
      colors:  ['#ab947e','#aa5500','#D9DCD4','#731817','#6d4c3d','#c9a227'] ,

      dataLabels: {
        enabled: true,
        style: {
          fontSize: '20px',
          colors: ['#fff']
        }
      },
      
      responsive: [{
        breakpoint: 480,
        options: {
          chart: {
            width: 200
          },
          legend: {
            position: 'bottom'
          }
        }
      }]
      };


      return(
        <Box sx={{width:'100%'}}>
          
          <Box sx={{display:'flex', justifyContent:'space-between'}}>
          <Box sx={{marginTop:'0px'}}>
               

               <Button variant="outlined"  sx={{marginRight:'5px', color:`${val5}`}} onClick={() =>handleChange('Günlük')}>Günlük</Button>
                <Button variant="outlined"  sx={{color:`${val6}`}} onClick={() =>handleChange('Aylık')}>Aylık</Button>
                <Button variant="outlined" sx={{color:`${val7}`}} onClick={() =>handleChange('Yıllık')}>Yıllık</Button>
          </Box>
           <Box sx={{marginTop:'0px'}}>
                
           <Button variant="outlined" size='small' sx={{marginRight:'5px', color:`${val1}`}} onClick={() =>handleOnChange('Alım')}>Alım</Button>
               <Button variant="outlined"size='small' sx={{marginRight:'5px', color:`${val2}`}} onClick={() =>handleOnChange('Satım')}>Satım</Button>
           </Box>
          </Box>
          <Box sx={{justifyContent:'center'}}>
  <Typography sx={{alignSelf:'center',marginLeft:'19.55rem',fontWeight:'900'}}>Şu anda veri bulunmamaktadır.</Typography>
          </Box>
       
         <Chart options={options} series={options.series} type="donut"/>
         
        </Box>
      )
    

}

export default DonutHepsi