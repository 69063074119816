import { React, useState } from "react";
import { Button, Box } from "@mui/material";
import Chart from "react-apexcharts";
import { useEffect } from "react";
import ArrowDownwardIcon from "@mui/icons-material/ArrowDownward";

import TextField from "@mui/material/TextField";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import dayjs from "dayjs";

const Gunluk2 = () => {
  const [open, setOpen] = useState(false);
  const [authCode, setAuthCode] = useState(sessionStorage.getItem("AuthCode"));
  const [fetchUrl, setFetchUrl] = useState(sessionStorage.getItem("FetchUrl"));

  const [startDate, setStartDate] = useState(dayjs);
  const [isGold, setGold] = useState();
  const [isSilver, setSilver] = useState();
  const [isPlatin, setPlatin] = useState();
  const [GoldArray, setGoldArray] = useState([
    0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0,
  ]);
  const [SilverArray, setSilverArray] = useState([
    0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0,
  ]);
  const [PlatinArray, setPlatinArray] = useState([
    0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0,
  ]);
  const [series, setSeries] = useState([]);

  const handleOpen = () => {
    setOpen(!open);
  };

  useEffect(() => {
    fetchData();
  }, [startDate]);

  async function fetchData() {
    var myHeaders = new Headers();
    myHeaders.append("Authorization", authCode);

    var requestOptions = {
      method: "GET",
      headers: myHeaders,
      redirect: "follow",
    };

    let zeroValue = 0;
    if (startDate.$M + 1 > 9) {
      zeroValue = "";
    }

    const response = await fetch(
      `${fetchUrl}Admin/GetHourlyStaticsticsByDateRange?startDate=${zeroValue}${
        startDate.$M + 1
      }%2F${startDate.$D}%2F${startDate.$y}&startHour=0&endDate=${
        startDate.$M + 1
      }%2F${startDate.$D}%2F${startDate.$y}&endHour=23
    `,
      requestOptions
    );
    const fetchedData = await response.json();
    const stringfyData = fetchedData.Data;

    for (let s = 0; s < stringfyData.length; s++) {
      if (
        stringfyData[s].CommodityId.includes(
          "7BE7C383-03E0-4A29-9564-1772BF0E996E"
        ) === false
      ) {
        setGold(false);
      }
      if (
        stringfyData[s].CommodityId.includes(
          "5D713008-15BB-4509-BE0D-B2F9355E8FF4"
        ) === false
      ) {
        setSilver(false);
      }

      if (
        stringfyData[s].CommodityId.includes(
          "8FC928D5-D9F9-4ABD-9859-567883AA3F06"
        ) === false
      ) {
        setPlatin(false);
      }
    }

    if (isGold === false) {
      setGoldArray([
        0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0,
      ]);
    }

    if (isSilver === false) {
      setSilverArray([
        0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0,
      ]);
    }

    if (isPlatin === false) {
      setPlatinArray([
        0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0,
      ]);
    }

    if (stringfyData.length == 0) {
      setGoldArray([
        0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0,
      ]);
      setSilverArray([
        0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0,
      ]);
      setPlatinArray([
        0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0,
      ]);
      setSeries([
        {
          name: "Altın",
          data: [
            GoldArray[0],
            GoldArray[1],
            GoldArray[2],
            GoldArray[3],
            GoldArray[4],
            GoldArray[5],
            GoldArray[6],
            GoldArray[7],
            GoldArray[8],
            GoldArray[9],
            GoldArray[10],
            GoldArray[11],
            GoldArray[12],
            GoldArray[13],
            GoldArray[14],
            GoldArray[15],
            GoldArray[16],
            GoldArray[17],
            GoldArray[18],
            GoldArray[19],
            GoldArray[20],
            GoldArray[21],
            GoldArray[22],
            GoldArray[23],
          ],
          color: "#c9a227",
        },
        {
          name: "Gümüş",
          data: [
            SilverArray[0],
            SilverArray[1],
            SilverArray[2],
            SilverArray[3],
            SilverArray[4],
            SilverArray[5],
            SilverArray[6],
            SilverArray[7],
            SilverArray[8],
            SilverArray[9],
            SilverArray[10],
            SilverArray[11],
            SilverArray[12],
            SilverArray[13],
            SilverArray[14],
            SilverArray[15],
            SilverArray[16],
            SilverArray[17],
            SilverArray[18],
            SilverArray[19],
            SilverArray[20],
            SilverArray[21],
            SilverArray[22],
            SilverArray[23],
          ],
          color: "#adb5bd",
        },
        {
          name: "Platin",
          data: [
            PlatinArray[0],
            PlatinArray[1],
            PlatinArray[2],
            PlatinArray[3],
            PlatinArray[4],
            PlatinArray[5],
            PlatinArray[6],
            PlatinArray[7],
            PlatinArray[8],
            PlatinArray[9],
            PlatinArray[10],
            PlatinArray[11],
            PlatinArray[12],
            PlatinArray[13],
            PlatinArray[14],
            PlatinArray[15],
            PlatinArray[16],
            PlatinArray[17],
            PlatinArray[18],
            PlatinArray[19],
            PlatinArray[20],
            PlatinArray[21],
            PlatinArray[22],
            PlatinArray[23],
          ],
          color: "#36241c",
        },
      ]);
    } else {
      for (let i = 0; i < stringfyData.length; i++) {
        if (
          stringfyData[i].CommodityId == "5D713008-15BB-4509-BE0D-B2F9355E8FF4"
        ) {
          let dateIndexSilver = stringfyData[i].TransactionDate.slice(
            11,
            13
          ).startsWith("0")
            ? parseInt(stringfyData[i].TransactionDate.slice(12, 13))
            : parseInt(stringfyData[i].TransactionDate.slice(11, 13));
          SilverArray[dateIndexSilver] = stringfyData[i].TotalTlAmount;
        } else if (
          stringfyData[i].CommodityId == "7BE7C383-03E0-4A29-9564-1772BF0E996E"
        ) {
          let timeIndexGold = stringfyData[i].TransactionDate.slice(
            11,
            13
          ).startsWith("0")
            ? parseInt(stringfyData[i].TransactionDate.slice(12, 13))
            : parseInt(stringfyData[i].TransactionDate.slice(11, 13));
          GoldArray[timeIndexGold] = stringfyData[i].TotalTlAmount;
        } else if (
          stringfyData[i].CommodityId == "8FC928D5-D9F9-4ABD-9859-567883AA3F06"
        ) {
          let timeIndexPlatin = stringfyData[i].TransactionDate.slice(
            11,
            13
          ).startsWith("0")
            ? parseInt(stringfyData[i].TransactionDate.slice(12, 13))
            : parseInt(stringfyData[i].TransactionDate.slice(11, 13));
          PlatinArray[timeIndexPlatin] = stringfyData[i].TotalTlAmount;
        }
      }

      setSeries([
        {
          name: "Altın",
          data: [
            GoldArray[0],
            GoldArray[1],
            GoldArray[2],
            GoldArray[3],
            GoldArray[4],
            GoldArray[5],
            GoldArray[6],
            GoldArray[7],
            GoldArray[8],
            GoldArray[9],
            GoldArray[10],
            GoldArray[11],
            GoldArray[12],
            GoldArray[13],
            GoldArray[14],
            GoldArray[15],
            GoldArray[16],
            GoldArray[17],
            GoldArray[18],
            GoldArray[19],
            GoldArray[20],
            GoldArray[21],
            GoldArray[22],
            GoldArray[23],
          ],
          color: "#c9a227",
        },
        {
          name: "Gümüş",
          data: [
            SilverArray[0],
            SilverArray[1],
            SilverArray[2],
            SilverArray[3],
            SilverArray[4],
            SilverArray[5],
            SilverArray[6],
            SilverArray[7],
            SilverArray[8],
            SilverArray[9],
            SilverArray[10],
            SilverArray[11],
            SilverArray[12],
            SilverArray[13],
            SilverArray[14],
            SilverArray[15],
            SilverArray[16],
            SilverArray[17],
            SilverArray[18],
            SilverArray[19],
            SilverArray[20],
            SilverArray[21],
            SilverArray[22],
            SilverArray[23],
          ],
          color: "#adb5bd",
        },
        {
          name: "Platin",
          data: [
            PlatinArray[0],
            PlatinArray[1],
            PlatinArray[2],
            PlatinArray[3],
            PlatinArray[4],
            PlatinArray[5],
            PlatinArray[6],
            PlatinArray[7],
            PlatinArray[8],
            PlatinArray[9],
            PlatinArray[10],
            PlatinArray[11],
            PlatinArray[12],
            PlatinArray[13],
            PlatinArray[14],
            PlatinArray[15],
            PlatinArray[16],
            PlatinArray[17],
            PlatinArray[18],
            PlatinArray[19],
            PlatinArray[20],
            PlatinArray[21],
            PlatinArray[22],
            PlatinArray[23],
          ],
          color: "#36241c",
        },
      ]);
    }
  }

  const options = {
    chart: {
      height: 350,
      type: "area",

      toolbar: {
        show: true,
        offsetX: 0,
        offsetY: 0,
        tools: {
          download: true,
          selection: true,
          zoom: true,
          zoomin: true,
          zoomout: true,
          pan: true,
          reset: true | '<img src="/static/icons/reset.png" width="20">',
          customIcons: [],
        },
        export: {
          csv: {
            filename: `Daily_Volume_${startDate.$D}-${startDate.$M}`,
            columnDelimiter: ",",
            headerCategory: "category",
            headerValue: "value",
            dateFormatter(timestamp) {
              return new Date(timestamp).toDateString();
            },
          },
          svg: {
            filename: `Daily_Volume_${startDate.$D}-${startDate.$M}`,
          },
          png: {
            filename: `Daily_Volume_${startDate.$D}-${startDate.$M}`,
          },
        },
        autoSelected: "zoom",
      },
    },
    dataLabels: {
      enabled: false,
    },
    stroke: {
      curve: "smooth",
      colors: ["#c9a227", "#adb5bd", "#36241c"],
    },
    markers: {
      colors: ["#c9a227", "#adb5bd", "#36241c"],
    },
    fill: {
      colors: ["#c9a227", "#adb5bd", "#36241c"],
    },
    labels: {
      colors: ["#c9a227", "#adb5bd", "#36241c"],
    },
    title: {
      text: "Saatlik Hacimler",
      align: "left",
      margin: 10,
      offsetX: 0,
      offsetY: 0,
      floating: false,
      style: {
        fontSize: "16px",
        fontWeight: "bold",
        color: "#263238",
      },
    },
    subtitle: {
      text: "Türk Lirasına göre hesaplanmıştır.",
      align: "center",
      margin: 10,
      offsetX: 0,
      offsetY: 25,
      floating: false,
      style: {
        fontSize: "12px",
        fontWeight: "normal",
        fontFamily: undefined,
        color: "#9699a2",
      },
    },
    xaxis: {
      type: "category",
      categories: [
        "00:00",
        "01:00",
        "02:00",
        "03:00",
        "04:00",
        "05:00",
        "06:00",
        "07:00",
        "08:00",
        "09:00",
        "10:00",
        "11:00",
        "12:00",
        "13:00",
        "14:00",
        "15:00",
        "16:00",
        "17:00",
        "18:00",
        "19:00",
        "20:00",
        "21:00",
        "22:00",
        "23:00",
      ],
    },
    tooltip: {
      x: {
        format: "dd/MM/yy HH:mm",
      },
    },
  };

  return (
    <LocalizationProvider dateAdapter={AdapterDayjs}>
      <Box sx={{ width: "100%" }}>
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
          }}
        >
          <Button
            onClick={() => {
              handleOpen();
            }}
            variant="outlined"
            endIcon={<ArrowDownwardIcon />}
            sx={{ marginBottom: "1rem" }}
          >
            {" "}
            Tarih Aralığı{" "}
          </Button>
          {open && (
            <DatePicker
              value={startDate}
              onChange={(date) => setStartDate(date)}
              renderInput={(params) => <TextField {...params} size="small" />}
              label="İncelemek istediğiniz tarihi seçin"
              inputFormat="DD/MM/YYYY"
            />
          )}
        </Box>
        <Chart options={options} series={series} type="area" />
      </Box>
    </LocalizationProvider>
  );
};

export default Gunluk2;
