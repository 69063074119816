import {
  Backdrop,
  Box,
  Button,
  CircularProgress,
  MenuItem,
  Select,
  Snackbar,
  TextField,
  Typography,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import { makeStyles } from "@mui/styles";
import { priceEnterButton, underlineBold18 } from "src/utils/customStyles";
import FintagPriceDetails from "src/CryptoComponents/Prices/FintagPriceDetails";
import MuiAlert from "@mui/material/Alert";

function UsdtPrices(props) {
  const classes = useStyles();
  const [fetchUrl, setFetchUrl] = React.useState(
    sessionStorage.getItem("FetchUrl")
  );
  const [authCode, setAuthCode] = React.useState(
    sessionStorage.getItem("AuthCode")
  );
  // Indicator
  const [open, setOpen] = React.useState(false);

  const [finKey, setFinKey] = useState("");
  const [akKey, setAkKey] = useState("");
  const [ktKey, setKtKey] = useState("");
  const [sbKey, setSbKey] = useState("");

  const [finValue, setFinValue] = useState(0);
  const [akValue, setAkValue] = useState(0);
  const [ktValue, setKtValue] = useState(0);
  const [sbValue, setSbValue] = useState(0);
  const [fintagMinMaxPrices, setFintagMinMaxPrices] = useState([]);
  const handleInputChangeFintag = (event) => {
    const inputValue = event.target.value;

    // Nokta veya virgül içeriyorsa
    if (inputValue.includes(".") || inputValue.includes(",")) {
      // Nokta veya virgülün index'ini bul
      const decimalIndex =
        inputValue.indexOf(".") !== -1
          ? inputValue.indexOf(".")
          : inputValue.indexOf(",");

      // Nokta veya virgülden sonraki kısmı al ve uzunluğunu kontrol et
      const decimalPartLength = inputValue.substring(decimalIndex + 1).length;

      // Eğer nokta veya virgülden sonraki kısım 4 haneden uzunsa
      if (decimalPartLength > 4) {
        // İlk 4 hane dışındaki karakterleri at ve sadece ilk 4 haneyi al
        const newValue = inputValue.substring(0, decimalIndex + 5);
        setFinValue(newValue);
      } else {
        // 4 haneden azsa herhangi bir işlem yapma, direkt değeri at
        setFinValue(inputValue);
      }
    } else {
      // Nokta veya virgül yoksa direkt değeri at
      setFinValue(inputValue);
    }
  };
  const handleInputChangeAK = (event) => {
    const inputValue = event.target.value;

    // Nokta veya virgül içeriyorsa
    if (inputValue.includes(".") || inputValue.includes(",")) {
      // Nokta veya virgülün index'ini bul
      const decimalIndex =
        inputValue.indexOf(".") !== -1
          ? inputValue.indexOf(".")
          : inputValue.indexOf(",");

      // Nokta veya virgülden sonraki kısmı al ve uzunluğunu kontrol et
      const decimalPartLength = inputValue.substring(decimalIndex + 1).length;

      // Eğer nokta veya virgülden sonraki kısım 4 haneden uzunsa
      if (decimalPartLength > 4) {
        // İlk 4 hane dışındaki karakterleri at ve sadece ilk 4 haneyi al
        const newValue = inputValue.substring(0, decimalIndex + 5);
        setAkValue(newValue);
      } else {
        // 4 haneden azsa herhangi bir işlem yapma, direkt değeri at
        setAkValue(inputValue);
      }
    } else {
      // Nokta veya virgül yoksa direkt değeri at
      setAkValue(inputValue);
    }
  };
  const handleInputChangeKT = (event) => {
    const inputValue = event.target.value;

    // Nokta veya virgül içeriyorsa
    if (inputValue.includes(".") || inputValue.includes(",")) {
      // Nokta veya virgülün index'ini bul
      const decimalIndex =
        inputValue.indexOf(".") !== -1
          ? inputValue.indexOf(".")
          : inputValue.indexOf(",");

      // Nokta veya virgülden sonraki kısmı al ve uzunluğunu kontrol et
      const decimalPartLength = inputValue.substring(decimalIndex + 1).length;

      // Eğer nokta veya virgülden sonraki kısım 4 haneden uzunsa
      if (decimalPartLength > 4) {
        // İlk 4 hane dışındaki karakterleri at ve sadece ilk 4 haneyi al
        const newValue = inputValue.substring(0, decimalIndex + 5);
        setKtValue(newValue);
      } else {
        // 4 haneden azsa herhangi bir işlem yapma, direkt değeri at
        setKtValue(inputValue);
      }
    } else {
      // Nokta veya virgül yoksa direkt değeri at
      setKtValue(inputValue);
    }
  };
  const handleInputChangeSB = (event) => {
    const inputValue = event.target.value;

    // Nokta veya virgül içeriyorsa
    if (inputValue.includes(".") || inputValue.includes(",")) {
      // Nokta veya virgülün index'ini bul
      const decimalIndex =
        inputValue.indexOf(".") !== -1
          ? inputValue.indexOf(".")
          : inputValue.indexOf(",");

      // Nokta veya virgülden sonraki kısmı al ve uzunluğunu kontrol et
      const decimalPartLength = inputValue.substring(decimalIndex + 1).length;

      // Eğer nokta veya virgülden sonraki kısım 4 haneden uzunsa
      if (decimalPartLength > 4) {
        // İlk 4 hane dışındaki karakterleri at ve sadece ilk 4 haneyi al
        const newValue = inputValue.substring(0, decimalIndex + 5);
        setSbValue(newValue);
      } else {
        // 4 haneden azsa herhangi bir işlem yapma, direkt değeri at
        setSbValue(inputValue);
      }
    } else {
      // Nokta veya virgül yoksa direkt değeri at
      setSbValue(inputValue);
    }
  };
  useEffect(() => {}, [open]);

  async function fetchMinMax() {
    var requestOptions = {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Authorization: authCode,
      },
    };
    const response = await fetch(
      `${fetchUrl}Admin/GetCurrencyPriceForAdmin`,
      requestOptions
    );
    const fetchedData = await response.json();
    const stringfyData = await fetchedData.Data;
    for (let i = 0; i < stringfyData.length; i++) {
      if (stringfyData[i].ProviderSuffix == 20) {
        setFintagMinMaxPrices(stringfyData[i]);
      }
    }
  }
  useEffect(() => {
    fetchMinMax();
  }, []);
  async function checkFintagPrice() {
    const int = parseFloat(finValue);
    const intMax = parseFloat(fintagMinMaxPrices.ViewBuyPrice);
    const intMin = parseFloat(fintagMinMaxPrices.BuyPrice);

    if (finKey == 3 || finKey == 4 || finKey == 5) {
      if (finValue < 0 || finValue >= 1) {
        setAlertMessage(
          "Lütfen 0'a eşit ve ya büyük ve 1'den küçük bir değer girin."
        );
        setAlertStatus(false);
        handleAlertClick();
      } else {
        updateFintagPrice();
      }
    } else {
      if (fintagMinMaxPrices.IsDeleted == true) {
        if (finKey == 1) {
          if (
            fintagMinMaxPrices.ViewBuyPrice / 1 >= finValue / 1 &&
            finValue / 1 >= fintagMinMaxPrices.BuyPrice / 1
          ) {
            updateFintagPrice();
          } else {
            setAlertMessage(
              "BuyPrice değeri Fintag Min-Max Buy Price değerleri arasında olmalıdır."
            );
            setAlertStatus(false);
            handleAlertClick();
          }
        } else if (finKey == 2) {
          if (
            fintagMinMaxPrices.ViewSellPrice / 1 >= finValue / 1 &&
            finValue / 1 >= fintagMinMaxPrices.SellPrice / 1
          ) {
            updateFintagPrice();
          } else {
            setAlertMessage(
              "SellPrice değeri Fintag Min-Max Sell Price değerleri arasında olmalıdır."
            );
            setAlertStatus(false);
            handleAlertClick();
          }
        } else updateFintagPrice();
      } else {
        updateFintagPrice();
      }
    }
  }

  async function updateFintagPrice() {
    setOpen(true);

    var myHeaders = new Headers();
    myHeaders.append("Authorization", authCode);
    myHeaders.append("Content-Type", "application/json");
    myHeaders.append("accept", "*/*");

    var raw = {};
    if (finKey === 1) {
      raw = {
        ProviderSuffix: 2,
        BuyPrice: parseFloat(finValue),
        SellPrice: -1,
        BuyProfitRate: -1,
        SellProfitRate: -1,
        Bsmv: -1,
        IsActive: false,
      };
    } else if (finKey === 2) {
      raw = {
        ProviderSuffix: 2,
        BuyPrice: -1,
        SellPrice: parseFloat(finValue), // value burada atanacak değer olacak
        BuyProfitRate: -1,
        SellProfitRate: -1,
        Bsmv: -1,
        IsActive: false,
      };
    } else if (finKey === 3) {
      raw = {
        ProviderSuffix: 2,
        BuyPrice: -1,
        SellPrice: -1,
        BuyProfitRate: parseFloat(finValue), // value burada atanacak değer olacak
        SellProfitRate: -1,
        Bsmv: -1,
        IsActive: false,
      };
    } else if (finKey === 4) {
      raw = {
        ProviderSuffix: 2,
        BuyPrice: -1,
        SellPrice: -1,
        BuyProfitRate: -1,
        SellProfitRate: parseFloat(finValue), // value burada atanacak değer olacak
        Bsmv: -1,
        IsActive: false,
      };
    } else if (finKey === 5) {
      raw = {
        ProviderSuffix: 2,
        BuyPrice: -1,
        SellPrice: -1,
        BuyProfitRate: -1,
        SellProfitRate: -1,
        Bsmv: parseFloat(finValue), // value burada atanacak değer olacak
        IsActive: false,
      };
    }

    var jsonString = JSON.stringify(raw);

    var requestOptions = {
      method: "POST",
      headers: myHeaders,
      body: jsonString,
      redirect: "follow",
    };
    const response = await fetch(
      `${fetchUrl}Admin/SetCurrencyPriceForAdmin`,
      requestOptions
    );
    const fetchedDataPrice = await response.json();
    if (fetchedDataPrice.Success === true) {
      setAlertMessage(fetchedDataPrice.Message);
      setAlertStatus(fetchedDataPrice.Success);
      handleAlertClick();
    } else if (fetchedDataPrice.Success === false) {
      setAlertMessage(fetchedDataPrice.Message);
      setAlertStatus(fetchedDataPrice.Success);
      handleAlertClick();
    }

    setOpen(false);
  }

  async function checkAltinKaynakPrice() {
    if (akKey == 3 || akKey == 4 || akKey == 5) {
      if (akValue < 0 || akValue >= 1) {
        setAlertMessage(
          "Lütfen 0'a eşit ve ya büyük ve 1'den küçük bir değer girin."
        );
        setAlertStatus(false);
        handleAlertClick();
      } else {
        updateAltinKaynakPrice();
      }
    } else {
      updateAltinKaynakPrice();
    }
  }

  async function updateAltinKaynakPrice() {
    setOpen(true);

    var myHeaders = new Headers();
    myHeaders.append("Authorization", authCode);
    myHeaders.append("Content-Type", "application/json");
    myHeaders.append("accept", "*/*");

    var raw = {};
    if (akKey === 1) {
      raw = {
        ProviderSuffix: 3,
        BuyPrice: parseFloat(akValue),
        SellPrice: -1,
        BuyProfitRate: -1,
        SellProfitRate: -1,
        Bsmv: -1,
        IsActive: false,
      };
    } else if (akKey === 2) {
      raw = {
        ProviderSuffix: 3,
        BuyPrice: -1,
        SellPrice: parseFloat(akValue), // value burada atanacak değer olacak
        BuyProfitRate: -1,
        SellProfitRate: -1,
        Bsmv: -1,
        IsActive: false,
      };
    } else if (akKey === 3) {
      raw = {
        ProviderSuffix: 3,
        BuyPrice: -1,
        SellPrice: -1,
        BuyProfitRate: parseFloat(akValue), // value burada atanacak değer olacak
        SellProfitRate: -1,
        Bsmv: -1,
        IsActive: false,
      };
    } else if (akKey === 4) {
      raw = {
        ProviderSuffix: 3,
        BuyPrice: -1,
        SellPrice: -1,
        BuyProfitRate: -1,
        SellProfitRate: parseFloat(akValue), // value burada atanacak değer olacak
        Bsmv: -1,
        IsActive: false,
      };
    } else if (akKey === 5) {
      raw = {
        ProviderSuffix: 3,
        BuyPrice: -1,
        SellPrice: -1,
        BuyProfitRate: -1,
        SellProfitRate: -1,
        Bsmv: parseFloat(akValue), // value burada atanacak değer olacak
        IsActive: false,
      };
    }

    var jsonString = JSON.stringify(raw);

    var requestOptions = {
      method: "POST",
      headers: myHeaders,
      body: jsonString,
      redirect: "follow",
    };
    const response = await fetch(
      `${fetchUrl}Admin/SetCurrencyPriceForAdmin`,
      requestOptions
    );

    const fetchedDataPrice = await response.json();
    console.log(fetchedDataPrice);
    if (fetchedDataPrice.Success === true) {
      setAlertMessage(fetchedDataPrice.Message);
      setAlertStatus(fetchedDataPrice.Success);
      handleAlertClick();
    } else if (fetchedDataPrice.Success === false) {
      setAlertMessage(fetchedDataPrice.Message);
      setAlertStatus(fetchedDataPrice.Success);
      handleAlertClick();
    }

    setOpen(false);
  }

  async function checkKuveytturkPrice() {
    if (ktKey == 3 || ktKey == 4 || ktKey == 5) {
      if (ktValue < 0 || ktValue >= 1) {
        setAlertMessage(
          "Lütfen 0'a eşit ve ya büyük ve 1'den küçük bir değer girin."
        );
        setAlertStatus(false);
        handleAlertClick();
      } else {
        updateKuveytturkPrice();
      }
    } else {
      updateKuveytturkPrice();
    }
  }

  async function updateKuveytturkPrice() {
    setOpen(true);

    var myHeaders = new Headers();
    myHeaders.append("Authorization", authCode);
    myHeaders.append("Content-Type", "application/json");
    myHeaders.append("accept", "*/*");

    var raw = {};
    if (ktKey === 1) {
      raw = {
        ProviderSuffix: 1,
        BuyPrice: parseFloat(ktValue),
        SellPrice: -1,
        BuyProfitRate: -1,
        SellProfitRate: -1,
        Bsmv: -1,
        IsActive: false,
      };
    } else if (ktKey === 2) {
      raw = {
        ProviderSuffix: 1,
        BuyPrice: -1,
        SellPrice: parseFloat(ktValue), // value burada atanacak değer olacak
        BuyProfitRate: -1,
        SellProfitRate: -1,
        Bsmv: -1,
        IsActive: false,
      };
    } else if (ktKey === 3) {
      raw = {
        ProviderSuffix: 1,
        BuyPrice: -1,
        SellPrice: -1,
        BuyProfitRate: parseFloat(ktValue), // value burada atanacak değer olacak
        SellProfitRate: -1,
        Bsmv: -1,
        IsActive: false,
      };
    } else if (ktKey === 4) {
      raw = {
        ProviderSuffix: 1,
        BuyPrice: -1,
        SellPrice: -1,
        BuyProfitRate: -1,
        SellProfitRate: parseFloat(ktValue), // value burada atanacak değer olacak
        Bsmv: -1,
        IsActive: false,
      };
    } else if (ktKey === 5) {
      raw = {
        ProviderSuffix: 1,
        BuyPrice: -1,
        SellPrice: -1,
        BuyProfitRate: -1,
        SellProfitRate: -1,
        Bsmv: parseFloat(ktValue), // value burada atanacak değer olacak
        IsActive: false,
      };
    }

    var jsonString = JSON.stringify(raw);

    var requestOptions = {
      method: "POST",
      headers: myHeaders,
      body: jsonString,
      redirect: "follow",
    };
    const response = await fetch(
      `${fetchUrl}Admin/SetCurrencyPriceForAdmin`,
      requestOptions
    );

    const fetchedDataPrice = await response.json();
    console.log(fetchedDataPrice);
    if (fetchedDataPrice.Success === true) {
      setAlertMessage(fetchedDataPrice.Message);
      setAlertStatus(fetchedDataPrice.Success);
      handleAlertClick();
    } else if (fetchedDataPrice.Success === false) {
      setAlertMessage(fetchedDataPrice.Message);
      setAlertStatus(fetchedDataPrice.Success);
      handleAlertClick();
    }

    setOpen(false);
  }

  async function checkSerbestPrice() {
    if (sbKey == 3 || sbKey == 4 || sbKey == 5) {
      if (sbValue < 0 || sbValue >= 1) {
        setAlertMessage(
          "Lütfen 0'a eşit ve ya büyük ve 1'den küçük bir değer girin."
        );
        setAlertStatus(false);
        handleAlertClick();
      } else {
        updateSerbestPrice();
      }
    } else {
      updateSerbestPrice();
    }
  }

  async function updateSerbestPrice() {
    setOpen(true);

    var myHeaders = new Headers();
    myHeaders.append("Authorization", authCode);
    myHeaders.append("Content-Type", "application/json");
    myHeaders.append("accept", "*/*");

    var raw = {};
    if (sbKey === 1) {
      raw = {
        ProviderSuffix: 4,
        BuyPrice: parseFloat(sbValue),
        SellPrice: -1,
        BuyProfitRate: -1,
        SellProfitRate: -1,
        Bsmv: -1,
        IsActive: false,
      };
    } else if (sbKey === 2) {
      raw = {
        ProviderSuffix: 4,
        BuyPrice: -1,
        SellPrice: parseFloat(sbValue), // value burada atanacak değer olacak
        BuyProfitRate: -1,
        SellProfitRate: -1,
        Bsmv: -1,
        IsActive: false,
      };
    } else if (sbKey === 3) {
      raw = {
        ProviderSuffix: 4,
        BuyPrice: -1,
        SellPrice: -1,
        BuyProfitRate: parseFloat(sbValue), // value burada atanacak değer olacak
        SellProfitRate: -1,
        Bsmv: -1,
        IsActive: false,
      };
    } else if (sbKey === 4) {
      raw = {
        ProviderSuffix: 4,
        BuyPrice: -1,
        SellPrice: -1,
        BuyProfitRate: -1,
        SellProfitRate: parseFloat(sbValue), // value burada atanacak değer olacak
        Bsmv: -1,
        IsActive: false,
      };
    } else if (sbKey === 5) {
      raw = {
        ProviderSuffix: 4,
        BuyPrice: -1,
        SellPrice: -1,
        BuyProfitRate: -1,
        SellProfitRate: -1,
        Bsmv: parseFloat(sbValue), // value burada atanacak değer olacak
        IsActive: false,
      };
    }

    var jsonString = JSON.stringify(raw);

    var requestOptions = {
      method: "POST",
      headers: myHeaders,
      body: jsonString,
      redirect: "follow",
    };
    const response = await fetch(
      `${fetchUrl}Admin/SetCurrencyPriceForAdmin`,
      requestOptions
    );

    const fetchedDataPrice = await response.json();
    console.log(fetchedDataPrice);
    if (fetchedDataPrice.Success === true) {
      setAlertMessage(fetchedDataPrice.Message);
      setAlertStatus(fetchedDataPrice.Success);
      handleAlertClick();
    } else if (fetchedDataPrice.Success === false) {
      setAlertMessage(fetchedDataPrice.Message);
      setAlertStatus(fetchedDataPrice.Success);
      handleAlertClick();
    }

    setOpen(false);
  }

  // SnackBar
  const [alertmessage, setAlertMessage] = React.useState("");
  const [alertStatus, setAlertStatus] = React.useState(false);
  const [openAlert, setOpenAlert] = React.useState(false);

  const Alert = React.forwardRef(function Alert(props, ref) {
    return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
  });

  const handleAlertClick = () => {
    setOpenAlert(true);
  };

  const handleAlertClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }

    setOpenAlert(false);
  };

  return (
    <Box>
      <Backdrop
        sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
        open={open}
      >
        <CircularProgress color="inherit" />
      </Backdrop>

      <Snackbar
        open={openAlert}
        onClose={handleAlertClose}
        autoHideDuration={2000}
      >
        <Alert severity={alertStatus ? "success" : "error"}>
          {alertmessage}
        </Alert>
      </Snackbar>

      <Box
        style={{
          display: "flex",
          flexDirection: "column",
          justifyContent: "center",
        }}
      >
        <Box
          sx={{
            display: "flex",
            flexDirection: "row",
            justifyContent: "space-between",
            flexWrap: "wrap",
            gap: { xs: "1rem", md: "1rem", lg: 0 },
          }}
        >
          {/* Fintag Altın Fiyatları  */}
          <Box
            component="form"
            sx={[formStyle, { backgroundColor: "#c7ae5d62" }]}
            noValidate
            autoComplete="off"
          >
            <Typography sx={[underlineBold18, { textAlign: "center" }]}>
              Fintag
            </Typography>
            <Select
              labelId="demo-customized-select-label"
              id="demo-customized-select"
              className={classes.select}
              style={{ fontSize: 14 }}
              MenuProps={{
                PaperProps: {
                  style: {
                    backgroundColor: "#f5f3eb", // Açılan menünün arka plan rengini buradan ayarlayabilirsiniz
                  },
                },
              }}
            >
              {options.map((option) => (
                <MenuItem
                  key={option.key}
                  value={option.value}
                  onClick={() => setFinKey(option.key)}
                >
                  {option.value}
                </MenuItem>
              ))}
            </Select>
            <TextField
              type="number"
              id="standard-required"
              label={"Değer Girin: "}
              defaultValue=""
              variant="standard"
              value={finValue}
              onChange={handleInputChangeFintag}
            />

            <Button
              color="primary"
              variant="contained"
              style={priceEnterButton}
              onClick={() => checkFintagPrice()}
            >
              <Typography sx={{ fontSize: 14 }}>Fiyatları Onayla</Typography>
            </Button>
            <Box sx={{ display: "flex", marginLeft: -8 }}>
              <FintagPriceDetails State={2} open={open} />
            </Box>
          </Box>
          {/* Altınkaynak  Fiyatları  */}
          <Box
            component="form"
            sx={[formStyle, { backgroundColor: "#a3956f6b" }]}
            noValidate
            autoComplete="off"
          >
            <Typography sx={[underlineBold18, { textAlign: "center" }]}>
              Altınkaynak
            </Typography>
            <Select
              labelId="demo-customized-select-label"
              id="demo-customized-select"
              className={classes.select}
              MenuProps={{
                PaperProps: {
                  style: {
                    backgroundColor: "#f5f3eb", // Açılan menünün arka plan rengini buradan ayarlayabilirsiniz
                  },
                },
              }}
            >
              {optionsCustom.map((option) => (
                <MenuItem
                  key={option.key}
                  value={option.value}
                  onClick={() => setAkKey(option.key)}
                >
                  {option.value}
                </MenuItem>
              ))}
            </Select>
            <TextField
              type="number"
              id="standard-required"
              label={"Değer Girin: "}
              defaultValue=""
              variant="standard"
              value={akValue}
              onChange={handleInputChangeAK}
            />

            <Button
              color="primary"
              variant="contained"
              style={priceEnterButton}
              onClick={() => checkAltinKaynakPrice()}
            >
              <Typography sx={{ fontSize: 14 }}>Fiyatları Onayla</Typography>
            </Button>
            <Box sx={{ display: "flex", marginLeft: -8 }}>
              <FintagPriceDetails State={3} open={open} />
            </Box>
          </Box>
          {/* KT Fiyatları  */}
          <Box
            component="form"
            sx={[formStyle, { backgroundColor: "#04664954" }]}
            noValidate
            autoComplete="off"
          >
            <Typography sx={[underlineBold18, { textAlign: "center" }]}>
              KuveytTürk
            </Typography>
            <Select
              labelId="demo-customized-select-label"
              id="demo-customized-select"
              className={classes.select}
              MenuProps={{
                PaperProps: {
                  style: {
                    backgroundColor: "#f5f3eb", // Açılan menünün arka plan rengini buradan ayarlayabilirsiniz
                  },
                },
              }}
            >
              {optionsCustom.map((option) => (
                <MenuItem
                  key={option.key}
                  value={option.value}
                  onClick={() => setKtKey(option.key)}
                >
                  {option.value}
                </MenuItem>
              ))}
            </Select>
            <TextField
              type="number"
              id="standard-required"
              label={"Değer Girin: "}
              defaultValue=""
              variant="standard"
              value={ktValue}
              onChange={handleInputChangeKT}
            />

            <Button
              color="primary"
              variant="contained"
              style={priceEnterButton}
              onClick={() => checkKuveytturkPrice()}
            >
              <Typography sx={{ fontSize: 14 }}>Fiyatları Onayla</Typography>
            </Button>
            <Box sx={{ display: "flex", marginLeft: -8 }}>
              <FintagPriceDetails State={1} open={open} />
            </Box>
          </Box>
          {/* Serbest Fiyatları  */}
          <Box
            component="form"
            sx={[formStyle, { backgroundColor: "#38383850" }]}
            noValidate
            autoComplete="off"
          >
            <Typography
              sx={[underlineBold18, { textAlign: "center", opacity: 0.6 }]}
            >
              Serbest Piyasa
            </Typography>
            <Select
              labelId="demo-customized-select-label"
              id="demo-customized-select"
              className={classes.select}
              disabled={true}
              MenuProps={{
                PaperProps: {
                  style: {
                    backgroundColor: "#f5f3eb", // Açılan menünün arka plan rengini buradan ayarlayabilirsiniz
                  },
                },
              }}
            >
              {options.map((option) => (
                <MenuItem
                  key={option.key}
                  value={option.value}
                  onClick={() => setSbKey(option.key)}
                >
                  {option.value}
                </MenuItem>
              ))}
            </Select>
            <TextField
              type="number"
              id="standard-required"
              label={"Değer Girin: "}
              defaultValue=""
              variant="standard"
              value={sbValue}
              onChange={handleInputChangeSB}
              disabled={true}
            />

            <Button
              color="primary"
              variant="contained"
              style={priceEnterButton}
              onClick={() => checkSerbestPrice()}
              disabled={true}
            >
              <Typography sx={{ fontSize: 14 }}>Fiyatları Onayla</Typography>
            </Button>
            <Box sx={{ display: "flex", marginLeft: -8 }}>
              <FintagPriceDetails State={4} open={open} />
            </Box>
          </Box>
        </Box>
      </Box>
    </Box>
  );
}

export default UsdtPrices;

const options = [
  { key: 1, value: "BuyPrice" },
  { key: 2, value: "SellPrice" },
  { key: 3, value: "BuyProfitRate" },
  { key: 4, value: "SellProfitRate" },
  { key: 5, value: "Bsmv" },
];

const optionsCustom = [
  { key: 3, value: "BuyProfitRate" },
  { key: 4, value: "SellProfitRate" },
  { key: 5, value: "Bsmv" },
];

const formStyle = {
  "& > :not(style)": { width: "10rem" },
  display: "flex",
  flexDirection: "column",
  gap: 2,
  borderRadius: "0.5rem",
  justifyContent: "center",
  alignItems: "center",
  padding: "1rem",
  width: "20rem",
};

const useStyles = makeStyles((theme) => ({
  select: {
    fontSize: "16px", // Dropdown içeriğinin font boyutu
    height: "2rem", // Dropdown boyutu 5rem olarak ayarlandı
  },
}));
