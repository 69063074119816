import { Box, ImageListItem, Typography } from "@mui/material";
import React, { useState } from "react";
import { useEffect } from "react";

const KtPrices = () => {
  const [goldPrice, setGoldPrice] = useState();
  const [silverPrice, setSilverPrice] = useState();
  const [platinPrice, setPlatinPrice] = useState();

  const [goldVendorPrice, setGoldVendorPrice] = useState();
  const [silverVendorPrice, setSilverVendorPrice] = useState();
  const [platinVendorPrice, setPlatinVendorPrice] = useState();

  const [authCode, setAuthCode] = useState(sessionStorage.getItem("AuthCode"));
  const [fetchUrl, setFetchUrl] = useState(sessionStorage.getItem("FetchUrl"));

  useEffect(() => {
    fetchData();
    const interval = setInterval(() => {
      fetchData();
    }, 60000);
    return () => clearInterval(interval);
  }, []);

  async function fetchData() {
    var requestOptions = {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Authorization: authCode,
      },
    };
    const response = await fetch(
      `${fetchUrl}Admin/GetAllProviderPrice?pageNumber=1`,
      requestOptions
    );
    const fetchedData = await response.json();
    const stringfyData = await fetchedData.Data;

    for (let i = 0; i < stringfyData.length; i++) {
      if (
        stringfyData[i].ProviderId == "4BEA4F9A-6467-4117-85C5-22C79E413C83"
      ) {
        if (
          stringfyData[i].CommodityId == "7BE7C383-03E0-4A29-9564-1772BF0E996E"
        ) {
          setGoldPrice(stringfyData[i].BuyPrice);
          setGoldVendorPrice(stringfyData[i].SellPrice);
        } else if (
          stringfyData[i].CommodityId == "5D713008-15BB-4509-BE0D-B2F9355E8FF4"
        ) {
          setSilverPrice(stringfyData[i].BuyPrice);
          setSilverVendorPrice(stringfyData[i].SellPrice);
        } else if (
          stringfyData[i].CommodityId == "8FC928D5-D9F9-4ABD-9859-567883AA3F06"
        ) {
          setPlatinPrice(stringfyData[i].BuyPrice);
          setPlatinVendorPrice(stringfyData[i].SellPrice);
        }
      }
    }
  }

  const data = [
    [
      "https://cdn-icons-png.flaticon.com/512/1473/1473504.png",
      "Altın",
      "KT Alış Kuru",
      `${goldVendorPrice}₺`,
      "KT Satış Kuru",
      `${goldPrice}₺`,
    ],
    [
      "https://cdn-icons-png.flaticon.com/512/1473/1473430.png",
      "Gümüş",
      "KT Alış Kuru",
      `${silverVendorPrice}₺`,
      "KT Satış Kuru",
      `${silverPrice}₺`,
    ],
    [
      "https://cdn-icons-png.flaticon.com/512/1473/1473578.png",
      "Platin",
      "KT Alış Kuru",
      `${platinVendorPrice}₺`,
      "KT Satış Kuru",
      `${platinPrice}₺`,
    ],
  ];

  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        backgroundColor: "white",
        width: "100%",
        borderRadius: "5px",
        padding: "1rem",
        gap: "1rem",
        marginTop: "15px",
      }}
    >
      <Typography
        sx={{
          marginLeft: "3rem",
          fontSize: 16,
          textAlign: "center",
          color: "#B78103",
          fontWeight: "bold",
          textDecoration: "underline",
        }}
      >
        KüveytTürk
      </Typography>

      {data.map((item) => {
        return (
          <Box
            sx={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
              gap: "2rem",
            }}
          >
            <Box
              sx={{
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
                gap: "0.7rem",
              }}
            >
              <ImageListItem
                sx={{
                  display: "absolute",
                  width: "50px",
                  height: "30px",
                  marginRight: "5px",
                }}
              >
                <img src={item[0]} alt="img" />
              </ImageListItem>

              <Typography
                sx={{
                  fontWeight: "bold",
                  color: "#2F4365",
                  fontSize: "15px",
                  marginBottom: "2px",
                }}
              >
                {item[1]}
              </Typography>
            </Box>
            <Box
              sx={{
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",
                gap: "2rem",
              }}
            >
              <Box>
                <Typography
                  sx={{
                    fontSize: "13px",
                    textAlign: "center",
                    color: "#B78103",
                    fontWeight: "bold",
                    width: "120px",
                  }}
                >
                  {item[2]}
                </Typography>
                <Typography
                  sx={{
                    fontSize: "12px",
                    color: "#2F4365",
                    textAlign: "center",
                    fontWeight: "bold",
                  }}
                >
                  {item[3]}
                </Typography>
              </Box>
              <Box>
                <Typography
                  sx={{
                    fontSize: "13px",
                    color: "#B78103",
                    fontWeight: "bold",
                    textAlign: "center",
                    width: "120px",
                  }}
                >
                  {item[4]}
                </Typography>
                <Typography
                  sx={{
                    fontSize: "12px",
                    color: "#2F4365",
                    textAlign: "center",
                    fontWeight: "bold",
                  }}
                >
                  {item[5]}
                </Typography>
              </Box>
            </Box>
          </Box>
        );
      })}
    </Box>
  );
};

export default KtPrices;
