import { Box, TextField, Typography } from "@mui/material";
import React, { useState } from "react";

function FintagPriceDetailsMinMax(props) {
  const [fintagPrices, setFintagPrices] = useState([]);
  const [fintagMinMaxPrices, setFintagMinMaxPrices] = useState([]);
  const [altinKaynakPrices, setAltinKaynakPrices] = useState([]);
  const [kuveytTurkPrices, setKuveytTurkPrices] = useState([]);
  const [serbestPrices, setSerbestPrices] = useState([]);

  const [authCode, setAuthCode] = useState(sessionStorage.getItem("AuthCode"));
  const [fetchUrl, setFetchUrl] = useState(sessionStorage.getItem("FetchUrl"));

  React.useEffect(() => {
    fetchData();
  }, []);

  React.useEffect(() => {
    fetchData();
  }, [props.open]);

  async function fetchData() {
    var requestOptions = {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Authorization: authCode,
      },
    };
    const response = await fetch(
      `${fetchUrl}Admin/GetCurrencyPriceForAdmin`,
      requestOptions
    );
    const fetchedData = await response.json();
    const stringfyData = await fetchedData.Data;
    for (let i = 0; i < stringfyData.length; i++) {
      if (stringfyData[i].ProviderSuffix == 3) {
        setAltinKaynakPrices(stringfyData[i]);
      } else if (stringfyData[i].ProviderSuffix == 1) {
        setKuveytTurkPrices(stringfyData[i]);
      } else if (stringfyData[i].ProviderSuffix == 4) {
        setSerbestPrices(stringfyData[i]);
      } else if (stringfyData[i].ProviderSuffix == 2) {
        setFintagPrices(stringfyData[i]);
      } else if (stringfyData[i].ProviderSuffix == 20) {
        setFintagMinMaxPrices(stringfyData[i]);
      }
    }
  }

  return (
    <Box
      style={{
        display: "flex",
        flexDirection: "row",
        gap: "5rem",
        alignItems: "center",
        justifyContent: "space-around",
        marginTop: "2.5rem",
      }}
    >
      <Box
        component="form"
        sx={[formStyle, { backgroundColor: "#f5f3eb" }]}
        noValidate
        autoComplete="off"
      >
        <Box sx={frameStyle}>
          <Typography sx={textStyle}>Max Buy Price :</Typography>
          {props.State == 2 ? (
            <Typography>{fintagPrices.ViewBuyPrice}</Typography>
          ) : props.State == 3 ? (
            <Typography>{altinKaynakPrices.ViewBuyPrice}</Typography>
          ) : props.State == 4 ? (
            <Typography>{serbestPrices.ViewBuyPrice}</Typography>
          ) : props.State == 1 ? (
            <Typography>{kuveytTurkPrices.ViewBuyPrice}</Typography>
          ) : (
            <Typography>{fintagMinMaxPrices.ViewBuyPrice}</Typography>
          )}
        </Box>
        <Box sx={frameStyle}>
          <Typography sx={textStyle}>Min Buy Price :</Typography>
          {props.State == 2 ? (
            <Typography>{fintagPrices.BuyPrice}</Typography>
          ) : props.State == 3 ? (
            <Typography>{altinKaynakPrices.BuyPrice}</Typography>
          ) : props.State == 4 ? (
            <Typography>{serbestPrices.BuyPrice}</Typography>
          ) : props.State == 1 ? (
            <Typography>{kuveytTurkPrices.BuyPrice}</Typography>
          ) : (
            <Typography>{fintagMinMaxPrices.BuyPrice}</Typography>
          )}
        </Box>
        <Box sx={frameStyle}>
          <Typography sx={textStyle}>Max Sell Price : </Typography>
          {props.State == 2 ? (
            <Typography>{fintagPrices.ViewSellPrice}</Typography>
          ) : props.State == 3 ? (
            <Typography>{altinKaynakPrices.ViewSellPrice}</Typography>
          ) : props.State == 4 ? (
            <Typography>{serbestPrices.ViewSellPrice}</Typography>
          ) : props.State == 1 ? (
            <Typography>{kuveytTurkPrices.ViewSellPrice}</Typography>
          ) : (
            <Typography>{fintagMinMaxPrices.ViewSellPrice}</Typography>
          )}
        </Box>
        <Box sx={frameStyle}>
          <Typography sx={textStyle}>Min Sell Price :</Typography>
          {props.State == 2 ? (
            <Typography>{fintagPrices.SellPrice}</Typography>
          ) : props.State == 3 ? (
            <Typography>{altinKaynakPrices.SellPrice}</Typography>
          ) : props.State == 4 ? (
            <Typography>{serbestPrices.SellPrice}</Typography>
          ) : props.State == 1 ? (
            <Typography>{kuveytTurkPrices.SellPrice}</Typography>
          ) : (
            <Typography>{fintagMinMaxPrices.SellPrice}</Typography>
          )}
        </Box>
      </Box>
    </Box>
  );
}

export default FintagPriceDetailsMinMax;

const formStyle = {
  "& > :not(style)": { width: "12rem" },
  display: "flex",
  flexDirection: "column",
  gap: 2,
  backgroundColor: "rgba(232, 219, 176, 0.5)",
  borderRadius: "0.5rem",
  justifyContent: "center",
  alignItems: "center",
  padding: "2rem",
};

const textStyle = {
  fontWeight: "600",
  textDecoration: "underline",
};

const frameStyle = {
  display: "flex",
  flexDirection: "row",
  justifyContent: "space-between",
};
