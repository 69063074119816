import React from "react";
import { Box } from "@mui/material";
import ProviderPriceHistoryListele from "src/components/AleynaComponents/ProviderPriceHistoryListele";

function ProviderPriceHistory(props) {
  return (
    <Box>
      <ProviderPriceHistoryListele />
    </Box>
  );
}

export default ProviderPriceHistory;
