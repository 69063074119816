import * as React from "react";
const CurlySvg = (props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={64}
    height={64}
    fill="none"
    viewBox="0 0 24 24"
    {...props}
  >
    <path
      stroke="rgba(183, 129, 4,0.2)"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth={1.5}
      d="M4 12h2.5M20 12l-6-6m6 6-6 6m6-6H9.5"
    />
  </svg>
);
export default CurlySvg;
