import React from "react";
import {
  Box,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  Typography,
  TextField,
  Button,
} from "@mui/material";

export default function UpdateSettings() {
  const [authCode, setAuthCode] = React.useState(
    sessionStorage.getItem("AuthCode")
  );
  const [fetchUrl, setFetchUrl] = React.useState(
    sessionStorage.getItem("FetchUrl")
  );
  const [res, setRes] = React.useState([]);
  const [choosenKey, setChoosenKey] = React.useState("");
  const [value, setValue] = React.useState("");
  const [durum, setDurum] = React.useState(false);
  const [message, setMessage] = React.useState("");

  const handleChooseKey = (name) => {
    setChoosenKey(name.target.value);
  };

  React.useEffect(() => {
    fetchedKey();
  }, []);

  async function fetchedKey() {
    var myHeaders = new Headers();
    myHeaders.append("Authorization", authCode);

    var requestOptions = {
      method: "GET",
      headers: myHeaders,
      redirect: "follow",
    };

    const response = await fetch(
      `${fetchUrl}Admin/GetAllForSettings?pageNumber=1`,
      requestOptions
    );
    const fetchedData = await response.json();
    const myData = fetchedData.Data;
    setRes(myData);
  }
  async function updateKey(choosenKey, value) {
    var myHeaders = new Headers();

    myHeaders.append("Authorization", authCode);
    myHeaders.append("Content-Type", "application/json");

    var raw = JSON.stringify({
      Id: choosenKey,
      Value: value,
    });

    var requestOptions = {
      method: "POST",
      headers: myHeaders,
      body: raw,
      redirect: "follow",
    };

    const response = await fetch(
      `${fetchUrl}Admin/UpdateForSettings`,
      requestOptions
    );
    const fetchedData = await response.json();
    fetchedData.Success === true ? setDurum(true) : setDurum(false);

    setMessage(fetchedData.Message);
  }

  return (
    <Box sx={{ display: "column" }}>
      <Typography variant="h4" gutterBottom>
        Update Settings
      </Typography>
      <Box
        component="form"
        sx={{
          "& > :not(style)": { m: 1, width: "35ch" },
          display: "flex",
          flexDirection: "column",
          gap: 2,
          backgroundColor: "rgba(232, 219, 176, 0.5)",
          width: "30rem",
          borderRadius: "0.5rem",
          justifyContent: "center",
          alignItems: "flex-start",
          padding: "2rem",
        }}
        noValidate
        autoComplete="off"
      >
        <FormControl sx={{ width: "160px" }}>
          <InputLabel id="demo-simple-select-label">Key Seçiniz</InputLabel>
          <Select
            labelId="demo-simple-select-label"
            id="demo-simple-select"
            value={choosenKey}
            label="key Seçiniz"
            onChange={handleChooseKey}
          >
            {res.map((res) => (
              <MenuItem key={res.Id} value={res.Id}>
                {res.Key}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
        <TextField
          type="number"
          id="standard-required"
          label={"Value giriniz"}
          defaultValue=""
          variant="standard"
          onChange={(event) => setValue(event.target.value)}
          value={value}
          inputMode="number"
        />
        <Box sx={{ marginTop: "1rem" }}>
          {value !== "" && choosenKey !== "" ? (
            <Button
              variant="contained"
              onClick={() => updateKey(choosenKey, value)}
            >
              Güncelle
            </Button>
          ) : (
            <Button variant="contained" disabled>
              Güncelle
            </Button>
          )}
        </Box>
        {message.length > 0 && (
          <Typography
            sx={{
              color: durum ? "green" : "red",
              fontWeight: "bold",
              marginTop: "1rem",
            }}
          >
            {message}
          </Typography>
        )}
      </Box>
    </Box>
  );
}
