import { React, useState, useEffect } from "react";
import { Box, Typography, Card } from "@mui/material";

import ArrowCircleUpTwoToneIcon from "@mui/icons-material/ArrowCircleUpTwoTone";

const Card1 = () => {
  const [authCode, setAuthCode] = useState(sessionStorage.getItem("AuthCode"));
  const [last1Hour, setLast1Hour] = useState(0);
  const [fetchUrl, setFetchUrl] = useState(sessionStorage.getItem("FetchUrl"));

  const [data, setData] = useState([
    [
      "Altın Satışları ( Son 1 saat içerisinde)",
      last1Hour,
      <ArrowCircleUpTwoToneIcon />,
      "+10.23%",
    ],
  ]);

  let newDate = new Date();
  let date = newDate.getDate();
  let month = newDate.getMonth() + 1;
  let year = newDate.getFullYear();
  let hour = newDate.getHours();
  let minutes = newDate.getMinutes();

  const [dateValue, setDateValue] = useState(date);
  const [monthValue, setMonthValue] = useState(month);
  const [yearValue, setYearValue] = useState(year);
  const [hourValue, setHourValue] = useState(hour);

  useEffect(() => {
    fetchData();
  }, [last1Hour]);

  async function fetchData() {
    //   var myHeaders = new Headers();
    //   myHeaders.append("Authorization", authCode);
    //   var requestOptions = {
    //     method: 'GET',
    //     headers: myHeaders,
    //     redirect: 'follow'
    //   };
    // const response = await fetch(`${fetchUrl}Admin/GetHourlyStaticsticsByCommodity?commodityId=7BE7C383-03E0-4A29-9564-1772BF0E996E`, requestOptions)
    // const fetchedData = await response.json();
    // const stringfyData = fetchedData.Data
    // let total = 0;
    // let zeroValue = 0;
    // if(hourValue > 9){
    //  zeroValue=""
    //  }
    // for(let i=0; i<stringfyData.length; i++){
    //   if(stringfyData[i].TransactionDate.slice(0,4) == yearValue.toString()){
    //     if(stringfyData[i].TransactionDate.slice(5,7) == `${zeroValue}${monthValue.toString()}`){
    //       if(stringfyData[i].TransactionDate.slice(8,10) == dateValue.toString()){
    //         if(stringfyData[i].TransactionDate.slice(11,13) == `${zeroValue}${hourValue.toString()}` ){
    //           total = total + stringfyData[i].TotalTlAmountBuy;
    //           setLast1Hour(total)
    //           setData([
    //             ['Altın Satışları ( Son 1 saat içerisinde)',last1Hour,<ArrowCircleUpTwoToneIcon/>,'+20.4%'],
    //            ])
    //         }
    //       }
    //     }
    //   }
    // }
  }

  const curr = data.map((item) => {
    return (
      <Box
        sx={{
          backgroundColor: "white",
          margin: "5px",
          display: "flex ",
          flexDirection: "column",
          justifyContent: "start",
          alignItems: "start",
          width: "100%",
          marginLeft: "10px",
          padding: "15px",
        }}
      >
        <Typography
          sx={{ color: "#2F4365", fontSize: "15px", marginBottom: "7px" }}
        >
          {item[0]}
        </Typography>
        <Typography
          sx={{
            color: "#2F4365",
            fontWeight: "bold",
            fontSize: "25px",
            marginBottom: "10px",
          }}
        >
          {item[1]} <span style={{ fontSize: 16 }}>₺</span>
        </Typography>
        {/* <Typography  sx={{color:'#27CE88', fontSize:'small',fontWeight:'bold',display:'flex', alignItems:'center',justifyContent:'center'}}>
           {item[2]}
           <Typography sx={{color:'#27CE88', fontSize:'15px',fontWeight:'bold',display:'flex', alignItems:'center', marginLeft:'5px'}}> {item[3]}</Typography>
          </Typography> */}
      </Box>
    );
  });
  return (
    <Card
      sx={{
        display: "flex",
        justifyContent: "start",
        alignItems: "start",
        backgroundColor: "white",
        borderRadius: "5px",
        borderStyle: "none",
        marginRight: "80px",
        width: "200px",
      }}
    >
      {curr}
    </Card>
  );
};
export default Card1;
