// component
import SvgColor from "../../../components/svg-color";

// ----------------------------------------------------------------------

const icon = (name) => (
  <SvgColor
    src={`/assets/icons/navbar/${name}.svg`}
    sx={{ width: 1, height: 1 }}
  />
);

const navConfig = [
  {
    title: "vendor",
    path: "/dashboard/vendor",
    icon: icon("ic_vendor"),
  },
  {
    title: "Fiyatlar",
    path: "/dashboard/price",
    icon: icon("ic_price"),
  },
  {
    title: "İşlemler",
    path: "/dashboard/transactions",
    icon: icon("ic_trans"),
  },
  {
    title: "Transferler",
    path: "/dashboard/transferler",
    icon: icon("ic_buysell"),
  },
  {
    title: "Hesaplar",
    path: "/dashboard/hesaplar",
    icon: icon("ic_userz"),
  },
  {
    title: "Pozisyon Listelemeleri",
    path: "/dashboard/pozisyonlistelemeleri",
    icon: icon("ic_position"),
  },
  {
    title: "Vendor İşlemleri",
    path: "/dashboard/vendorislemleri",
    icon: icon("ic_vendor"),
  },
  {
    title: "Commodity İşlemleri",
    path: "/dashboard/commodityislemleri",
    icon: icon("ic_product"),
  },
  {
    title: "Provider İşlemleri",
    path: "/dashboard/providercreate",
    icon: icon("ic_wait"),
  },
  {
    title: "Banka İşlemleri",
    path: "/dashboard/bankactivities",
    icon: icon("ic_provider"),
  },
  {
    title: "Settings İşlemleri",
    path: "/dashboard/getallforsettings",
    icon: icon("ic_settings"),
  },
  {
    title: "All End Points",
    path: "/dashboard/allendpoints",
    icon: icon("ic_lock"),
  },

  // {
  //   title: 'Bekleyen Grain İşlemleri',
  //   path: '/dashboard/getgraintransactionsbuysell',
  //   icon: icon('ic_providerprice'),
  // },
  // {
  //   title: 'Bekleyen Grain İşlemleri Muhasebe',
  //   path: '/dashboard/getgraintransactions',
  //   icon: icon('ic_wait'),
  // },
  // {
  //   title: 'KuveytTürk Hesapları',
  //   path: '/dashboard/hesaplar',
  //   icon: icon('ic_userz'),
  // },
  // {
  //   title: "Finalized Listeleme",
  //   path: "/dashboard/finalizedlisteleme",
  //   icon: icon("ic_finalized"),
  // },
  // {
  //   title: "Not Position Listeleme",
  //   path: "/dashboard/notpositionlisteleme",
  //   icon: icon("ic_notposition"),
  // },
  // {
  //   title: "Unexpected Listeleme",
  //   path: "/dashboard/unexpectedlisteleme",
  //   icon: icon("ic_unexpected"),
  // },

  // {
  //   title: "All End Points",
  //   path: "/dashboard/allendpoints",
  //   icon: icon("ic_lock"),
  // },

  // {
  //   title: "App to gateway",
  //   path: "/dashboard/apptogatewaytransactions",
  //   icon: icon("ic_lock"),
  // },
  // {
  //   title: "userEmtia",
  //   path: "/dashboard/adduseremtia",
  //   icon: icon("ic_lock"),
  // },
  // {
  //   title: "coming soon",
  //   path: "/dashboard/comingsoon",
  //   icon: icon("ic_lock"),
  // },
];

export default navConfig;
