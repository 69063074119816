import { Button, Typography } from "@mui/material";
import { Box } from "@mui/system";
import React from "react";
import CommodityListeleme from "src/components/AleynaComponents/CommodityListeleme";
import CommodityMakeActivePassive from "src/components/AleynaComponents/CommodityMakeActivePassive";

function Commodity(props) {
  const [switchIt, setSwitchIT] = React.useState("Commodityİşlemleri");

  return (
    <Box sx={{ display: "flex", flexDirection: "column" }}>
      <Box
        sx={{
          display: "flex",
          alignSelf: "center",
          gap: "5rem",
        }}
      >
        <Button
          variant={switchIt == "Commodityİşlemleri" ? "contained" : "outlined"}
          onClick={() => setSwitchIT("Commodityİşlemleri")}
        >
          <Typography>Commodity İşlemleri</Typography>
        </Button>
      </Box>

      <Box
        sx={{
          height: "0.01rem",
          width: "100%",
          marginTop: "2rem",
          backgroundColor: "lightgray",
          marginBottom: "2rem",
        }}
      />

      {switchIt == "CommodityListeleme" ? (
        <Box sx={{ marginLeft: "3rem" }}>
          <CommodityListeleme />
        </Box>
      ) : (
        <Box sx={{ marginLeft: "3rem" }}>
          <CommodityListeleme />
          <Box
            sx={{
              height: "0.01rem",
              width: "50%",
              marginTop: "4rem",
              backgroundColor: "lightgray",
              marginBottom: "4rem",
              marginLeft: "auto",
              marginRight: "auto",
            }}
          />
          <CommodityMakeActivePassive />
        </Box>
      )}

      <Box
        sx={{
          width: "100%",
          height: "0.01rem",
          backgroundColor: "lightgray",
          marginBottom: "2rem",
          marginTop: "2rem",
        }}
      />
    </Box>
  );
}

export default Commodity;
