import { Helmet } from "react-helmet-async";
import { faker } from "@faker-js/faker";
import React, { useState, useEffect } from "react";
// @mui
import { useTheme } from "@mui/material/styles";
import { Grid, Container, Button, Box, Link, Typography } from "@mui/material";
import BasicTabs from "src/CryptoComponents/Tabs/Kur";
import { pageContainer } from "src/utils/customStyles";
// components
// sections
// ----------------------------------------------------------------------
export default function DashboardMainPage() {
  const theme = useTheme();

  return (
    <Box sx={pageContainer}>
      <Helmet>
        <title> Dashboard | Currency Goldtag </title>
      </Helmet>

      <Typography variant="h4" gutterBottom sx={{ marginBottom: "2rem" }}>
        Kurlar
      </Typography>

      <Grid container spacing={0}>
        <Grid item xs={12} md={12} lg={12}>
          <BasicTabs />
        </Grid>
      </Grid>
    </Box>
  );
}
