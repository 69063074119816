import {
  Box,
  Button,
  FormControl,
  FormControlLabel,
  FormLabel,
  InputLabel,
  MenuItem,
  Radio,
  RadioGroup,
  Select,
  TextField,
  Typography,
} from "@mui/material";
import React from "react";
import FileDownloadIcon from "@mui/icons-material/FileDownload";
import { ExportToCsv } from "export-to-csv";
import MaterialReactTable from "material-react-table";
import CircularProgress from "@mui/material/CircularProgress";
import Backdrop from "@mui/material/Backdrop";
function ProviderPriceHistoryListele(props) {
  const [authCode, setAuthCode] = React.useState(
    sessionStorage.getItem("AuthCode")
  );
  const [fetchUrl, setFetchUrl] = React.useState(
    sessionStorage.getItem("FetchUrl")
  );
  const [res, setRes] = React.useState([]);
  const [provider, setProvider] = React.useState([]);
  const [status, setStatus] = React.useState("");
  const [choosenProvider, setChoosenProvider] = React.useState("");
  const [commodity, setCommodity] = React.useState([]);
  const [choosenCommodity, setChoosenCommodity] = React.useState("");
  const [provName, setProvName] = React.useState("");
  const [commName, setCommName] = React.useState("");
  const [open, setOpen] = React.useState(false);
  const handleChoosenProvider = (name) => {
    setChoosenProvider(name.target.value);
    setStatus(false);
  };
  const handleChoosenCommodity = (name) => {
    setChoosenCommodity(name.target.value);
  };
  const columns = React.useMemo(
    () => [
      {
        accessorKey: "ProviderId",
        header: "Provider Id",
        size: 30,
      },
      {
        accessorKey: "CommodityId",
        header: "Commodity Id",
        size: 30,
      },
      {
        accessorKey: "BuyPrice",
        header: "Buy Price",
        size: 30,
      },
      {
        accessorKey: "SellPrice",
        header: "Sell Price",
        size: 30,
      },
      {
        accessorKey: "CreatedDate",
        header: "Created Date",
        size: 40,
      },
      {
        accessorKey: "GoldtagBuyPrice",
        header: "Goldtag Buy Price",
        size: 30,
      },
      {
        accessorKey: "GoldtagSellPrice",
        header: "Goldtag Sell Price",
        size: 30,
      },
    ],
    []
  );
  const csvOptions = {
    fieldSeparator: ";",
    quoteStrings: '"',
    decimalSeparator: ".",
    showLabels: true,
    useBom: true,
    useKeysAsHeaders: false,
    headers: columns.map((c) => c.header),
    filename: "ProviderPriceHistory",
  };

  const csvExporter = new ExportToCsv(csvOptions);
  const handleExportRows = (rows) => {
    csvExporter.generateCsv(rows.map((row) => row.original));
  };
  const handleExportData = () => {
    csvExporter.generateCsv(res);
  };

  React.useEffect(() => {
    getAllFetchedProvider();
    getAllFetchedCommodity();
  }, []);

  async function getAllFetched(choosenProvider, choosenCommodity) {
    setOpen(true);
    var myHeaders = new Headers();
    myHeaders.append("Authorization", authCode);

    var requestOptions = {
      method: "GET",
      headers: myHeaders,
      redirect: "follow",
    };

    const response = await fetch(
      `${fetchUrl}Admin/GetByProviderIdAndCommodityId?providerId=${choosenProvider}&commodityId=${choosenCommodity}`,
      requestOptions
    );
    const fetchedData = await response.json();
    setTimeout(() => {
      setOpen(false);
    }, 1000);
    const myData = fetchedData.Data;
    var endPointsArr = [];
    var newArr = [];
    for (let i = 0; i < myData.length; i++) {
      endPointsArr.push({
        ProviderId: myData[i].ProviderId,
        CommodityId: myData[i].CommodityId,
        BuyPrice: myData[i].BuyPrice,
        SellPrice: myData[i].SellPrice,
        CreatedDate: myData[i].CreatedDate,
        GoldtagBuyPrice: myData[i].GoldtagBuyPrice,
        GoldtagSellPrice: myData[i].GoldtagSellPrice,
      });
    }

    setRes(endPointsArr);
  }

  async function getAllFetchedProvider() {
    var myHeaders = new Headers();
    myHeaders.append("Authorization", authCode);

    var requestOptions = {
      method: "GET",
      headers: myHeaders,
      redirect: "follow",
    };

    const response = await fetch(
      `${fetchUrl}Admin/GetAllProvider?pageNumber=1`,
      requestOptions
    );
    const fetchedData = await response.json();
    const myData = fetchedData.Data;
    setProvider(myData);
  }

  async function getAllFetchedCommodity() {
    var myHeaders = new Headers();
    myHeaders.append("Authorization", authCode);

    var requestOptions = {
      method: "GET",
      headers: myHeaders,
      redirect: "follow",
    };

    const response = await fetch(
      `${fetchUrl}Admin/GetAllCommodity?pageNumber=1`,
      requestOptions
    );
    const fetchedData = await response.json();
    const myData = fetchedData.Data;
    var endpointsArr = [];
    var newArr = [];
    for (let i = 0; i < myData.length; i++) {
      endpointsArr.push({
        Id: myData[i].Id,
        Suffix: myData[i].Suffix,
        Name: myData[i].Name === "TL" ? null : myData[i].Name,
        IsActive: myData[i].IsActive,
      });
      if (endpointsArr[i].Name !== null) {
        newArr.push(endpointsArr[i]);
      }
    }
    setCommodity(newArr);
  }

  return (
    <Box sx={{ paddingLeft: "3rem", paddingRight: "3rem" }}>
      <Typography variant="h4" gutterBottom>
        Provider Price History Listeleme
      </Typography>
      <Box
        sx={{
          display: "flex",
          flexDirection: "row",
          alignItems: "center",
          gap: "5rem",
          marginTop: "2rem",
          marginBottom: "2rem",
        }}
      >
        <Backdrop
          sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
          open={open}
        >
          <CircularProgress color="inherit" />
        </Backdrop>
        <FormControl sx={{ width: "220px" }}>
          <InputLabel id="demo-simple-select-label">
            Provider Seçiniz
          </InputLabel>
          <Select
            labelId="demo-simple-select-label"
            id="demo-simple-select"
            value={choosenProvider}
            label="Vendor Seçiniz"
            onChange={handleChoosenProvider}
            provName={provName}
          >
            {provider.map((provider) => (
              <MenuItem
                key={provider.Id}
                value={provider.Id}
                provName={provider.Name}
                onClick={() => setProvName(provider.Name)}
              >
                {provider.Name}
              </MenuItem>
            ))}
          </Select>
        </FormControl>

        <FormControl sx={{ width: "220px" }}>
          <InputLabel id="demo-simple-select-label">
            Commodity Seçiniz
          </InputLabel>
          <Select
            labelId="demo-simple-select-label"
            id="demo-simple-select"
            value={choosenCommodity}
            label="Vendor Seçiniz"
            onChange={handleChoosenCommodity}
            commName={commName}
          >
            {commodity.map((comm) => (
              <MenuItem
                key={comm.Id}
                value={comm.Id}
                commName={comm.Name}
                onClick={() => setCommName(comm.Name)}
              >
                {comm.Name}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
        <Button
          variant="contained"
          onClick={() => [
            getAllFetched(choosenProvider, choosenCommodity),
            setStatus(true),
          ]}
        >
          Listele
        </Button>
      </Box>

      {status && res.length === 0 ? (
        <Typography
          sx={{ color: "#ffb703", fontWeight: "bold", marginTop: "1rem" }}
        >
          Girilen bilgilere ilişkin veri bulunmamaktadır.
        </Typography>
      ) : status && res.length > 0 ? (
        <Typography
          sx={{ color: "green", fontWeight: "bold", marginTop: "1rem" }}
        >
          İşlem Başarılı.
        </Typography>
      ) : (
        status && (
          <Typography
            sx={{ color: "red", fontWeight: "bold", marginTop: "1rem" }}
          >
            İşlem Başarısız.
          </Typography>
        )
      )}
      <MaterialReactTable
        columns={columns}
        data={res}
        enableRowSelection
        positionToolbarAlertBanner="bottom"
        renderTopToolbarCustomActions={({ table }) => (
          <Box
            sx={{ display: "flex", gap: "1rem", p: "0.5rem", flexWrap: "wrap" }}
          >
            <Button
              color="primary"
              onClick={handleExportData}
              startIcon={<FileDownloadIcon />}
              variant="contained"
            >
              Tüm Verileri Dışa Aktar
            </Button>
            <Button
              disabled={table.getPrePaginationRowModel().rows.length === 0}
              onClick={() =>
                handleExportRows(table.getPrePaginationRowModel().rows)
              }
              startIcon={<FileDownloadIcon />}
              variant="contained"
            >
              Tüm Satırları Dışa Aktar
            </Button>
            <Button
              disabled={table.getRowModel().rows.length === 0}
              onClick={() => handleExportRows(table.getRowModel().rows)}
              startIcon={<FileDownloadIcon />}
              variant="contained"
            >
              Bu Sayfadaki Satırları Dışa Aktar
            </Button>
            <Button
              disabled={
                !table.getIsSomeRowsSelected() && !table.getIsAllRowsSelected()
              }
              onClick={() => handleExportRows(table.getSelectedRowModel().rows)}
              startIcon={<FileDownloadIcon />}
              variant="contained"
            >
              Seçilmiş Satırları Dışa Aktar
            </Button>
          </Box>
        )}
      />
    </Box>
  );
}

export default ProviderPriceHistoryListele;
