import {
  Box,
  Button,
  TextField,
  Typography,
  Select,
  MenuItem,
  InputLabel,
  FormControl,
} from "@mui/material";
import React from "react";
import CircularProgress from "@mui/material/CircularProgress";
import Backdrop from "@mui/material/Backdrop";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
export default function BankActivities() {
  const [authCode, setAuthCode] = React.useState(
    sessionStorage.getItem("AuthCode")
  );
  const [fetchUrl, setFetchUrl] = React.useState(
    sessionStorage.getItem("FetchUrl")
  );
  const [res, setRes] = React.useState([]);
  const [bank, setBank] = React.useState([]);
  const [choosenVendor, setChoosenVendor] = React.useState("");
  const [choosenBank, setChoosenBank] = React.useState("");
  const [suffix, setSuffix] = React.useState("");
  const [startDate, setStartDate] = React.useState("");
  const [startDay, setStartDay] = React.useState("");
  const [startMonth, setStartMonth] = React.useState("");
  const [startYear, setStartYear] = React.useState("");
  const [saat, setSaat] = React.useState("");
  const [dk, setDk] = React.useState("");
  const [sn, setSn] = React.useState("");
  const [desc, setDesc] = React.useState("");
  const [amount, setAmount] = React.useState("");
  const [balance, setBalance] = React.useState("");
  const [message, setMessage] = React.useState("");
  const [status, setStatus] = React.useState(null);
  const [durum, setDurum] = React.useState(false);
  const [open, setOpen] = React.useState(false);
  const [timeMsg, setTimeMsg] = React.useState("");

  const handleChooseVendor = (name) => {
    setChoosenVendor(name.target.value);
  };
  const handleChooseBank = (name) => {
    setChoosenBank(name.target.value);
  };
  const restart = () => {
    setTimeout(() => {
      setChoosenVendor("");
      setChoosenBank("");
      setSuffix("");
      setStartDate("");
      setDesc("");
      setSaat("");
      setDk("");
      setSn("");
      setAmount("");
      setBalance("");
    }, 3000);
  };
  const handleChangeStart = (event) => {
    setStartDate(event);
    if (event.$D < 10) {
      setStartDay("0" + event.$D);
    } else {
      setStartDay(event.$D.toString());
    }
    if (event.$M < 9) {
      setStartMonth("0" + (event.$M + 1));
      setStartYear(event.$y.toString());
    } else {
      setStartMonth((event.$M + 1).toString());
      setStartYear(event.$y.toString());
    }
  };

  const joinDateTime = async () => {
    const stringArray = [startYear, startMonth, startDay];
    const newDateArr = stringArray.join("-");
    const stringArray2 = [saat, dk, sn];
    const newTimeArr = stringArray2.join(":");
    const stringArray3 = [newDateArr, "T", newTimeArr, "Z"];
    const newDateTimeArr = stringArray3.join("");
    await fetchedInsertBankActivities(newDateTimeArr);
  };
  React.useEffect(() => {
    fetchedVendor();
    fetchedBanks();
  }, []);
  async function fetchedVendor() {
    var myHeaders = new Headers();
    myHeaders.append("Authorization", authCode);

    var requestOptions = {
      method: "GET",
      headers: myHeaders,
      redirect: "follow",
    };

    const response = await fetch(
      `${fetchUrl}Admin/GetAll?pageNumber=1`,
      requestOptions
    );
    const fetchedData = await response.json();
    const myData = fetchedData.Data;
    setRes(myData);
  }
  async function fetchedBanks() {
    var myHeaders = new Headers();
    myHeaders.append("Authorization", authCode);
    myHeaders.append("accept", "*/*");
    var requestOptions = {
      method: "GET",
      headers: myHeaders,
      redirect: "follow",
    };

    const response = await fetch(
      `${fetchUrl}Admin/GetBankList`,
      requestOptions
    );
    const fetchedData = await response.json();
    const myData = fetchedData.Data.EnumList;
    setBank(myData);
  }
  async function fetchedInsertBankActivities(date) {
    setOpen(true);
    setTimeout(() => {
      setOpen(false);
    }, 1000);
    if (
      saat < 0 ||
      saat > 23 ||
      saat.toString().length !== 2 ||
      dk < 0 ||
      dk > 59 ||
      dk.toString().length !== 2 ||
      sn < 0 ||
      sn > 59 ||
      sn.toString().length !== 2
    ) {
      setTimeout(() => {
        setTimeMsg("");
      }, 2000);
    } else {
    }
    var requestOptions = {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: authCode,
        accept: "*/*",
      },
      body: JSON.stringify({
        VendorId: choosenVendor,
        BankId: 1,
        Suffix: suffix,
        Date: date,
        Description: desc,
        Amount: amount,
        Balance: balance,
      }),
    };
    const response = await fetch(
      `${fetchUrl}Admin/InsertBankActivitie`,
      requestOptions
    );
    const fetchedData = await response.json();
    setDurum(true);
    setTimeout(() => {
      setDurum(false);
    }, 2000);

    if (fetchedData.Success == true) {
      restart();
      setMessage(fetchedData.Message);
      setStatus(fetchedData.Success);
    } else if (fetchedData.Success == false) {
      setMessage(fetchedData.Message);
      setStatus(fetchedData.Success);
    } else if (fetchedData.status == 400) {
      setMessage(
        "Girdiğiniz zaman değerlerinin çift haneli ve uygun aralıkta olduğuna emin olunuz!"
      );
      setStatus(false);
    }
  }
  return (
    <Box>
      <Backdrop
        sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
        open={open}
      >
        <CircularProgress color="inherit" />
      </Backdrop>
      <Box sx={{ marginLeft: "3rem" }}>
        <Typography variant="h4" gutterBottom>
          Bank Activities
        </Typography>
        <Box
          sx={{
            display: "flex",
            direction: "row",
            gap: "6rem",
            marginTop: "3rem",
          }}
        >
          <Box
            component="form"
            sx={{
              "& > :not(style)": { m: 1, width: "35ch" },
              display: "flex",
              flexDirection: "column",
              gap: 2,
              backgroundColor: "rgba(232, 219, 176, 0.5)",

              borderRadius: "0.5rem",
              justifyContent: "center",
              alignItems: "flex-start",
              padding: "2rem",
            }}
            noValidate
            autoComplete="off"
          >
            <FormControl sx={{ width: "160px" }}>
              <InputLabel id="demo-simple-select-label">
                Vendor Seçiniz
              </InputLabel>
              <Select
                labelId="demo-simple-select-label"
                id="demo-simple-select"
                value={choosenVendor}
                label="Vendor Seçiniz"
                onChange={handleChooseVendor}
              >
                {res.map((res) => (
                  <MenuItem key={res.Id} value={res.Id}>
                    {res.Name}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
            <FormControl sx={{ width: "160px" }}>
              <InputLabel id="demo-simple-select-label">
                Banka Seçiniz
              </InputLabel>
              <Select
                labelId="demo-simple-select-label"
                id="demo-simple-select"
                value={choosenBank}
                label="Banka Seçiniz"
                onChange={handleChooseBank}
              >
                {bank.map((bank) => (
                  <MenuItem key={bank.Id} value={bank.Id}>
                    {bank.Name}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
            <TextField
              type="number"
              id="standard-required"
              label={"Suffix giriniz"}
              defaultValue=""
              variant="standard"
              onChange={(event) => setSuffix(event.target.value)}
              value={suffix}
            />
            <LocalizationProvider dateAdapter={AdapterDayjs}>
              <DatePicker
                label="Tarih Seçiniz"
                value={startDate}
                onChange={handleChangeStart}
                renderInput={(params) => (
                  <TextField {...params} error={false} />
                )}
              />
            </LocalizationProvider>
            <Box>
              <Box sx={{ display: "flex", flexDirection: "row", gap: "10px" }}>
                <TextField
                  type="number"
                  id="standard-required"
                  label={"Saat giriniz"}
                  defaultValue=""
                  variant="standard"
                  onChange={(event) => {
                    const value = event.target.value;
                    // İki haneli bir değer olup olmadığını kontrol et
                    if (/^\d{0,2}$/.test(value)) {
                      // İki haneli ise, saat değerini güncelle
                      setSaat(value);
                    }
                  }}
                  value={saat}
                />
                <TextField
                  type="number"
                  id="standard-required-dakika"
                  label={"Dakika giriniz"}
                  defaultValue=""
                  variant="standard"
                  onChange={(event) => {
                    const value = event.target.value;
                    // İki haneli bir değer olup olmadığını kontrol et
                    if (/^\d{0,2}$/.test(value)) {
                      // İki haneli ise, dakika değerini güncelle
                      setDk(value);
                    }
                  }}
                  value={dk}
                />

                <TextField
                  type="number"
                  id="standard-required-saniye"
                  label={"Saniye giriniz"}
                  defaultValue=""
                  variant="standard"
                  onChange={(event) => {
                    const value = event.target.value;
                    // İki haneli bir değer olup olmadığını kontrol et
                    if (/^\d{0,2}$/.test(value)) {
                      // İki haneli ise, saniye değerini güncelle
                      setSn(value);
                    }
                  }}
                  value={sn}
                />
              </Box>
              <Typography>{timeMsg}</Typography>
            </Box>
            <TextField
              id="standard-required"
              label={"Açıklama giriniz"}
              defaultValue=""
              variant="standard"
              onChange={(event) => setDesc(event.target.value)}
              value={desc}
            />

            <TextField
              type="number"
              id="standard-required"
              label={"Miktar giriniz"}
              defaultValue=""
              variant="standard"
              onChange={(event) => setAmount(event.target.value)}
              value={amount}
            />
            <TextField
              type="number"
              id="standard-required"
              label={"Balance giriniz"}
              defaultValue=""
              variant="standard"
              onChange={(event) => setBalance(event.target.value)}
              value={balance}
            />

            <Button
              variant="contained"
              disabled={
                balance !== "" &&
                amount !== "" &&
                desc !== "" &&
                sn !== "" &&
                dk !== "" &&
                saat !== "" &&
                startDate !== "" &&
                suffix !== "" &&
                choosenBank !== "" &&
                choosenVendor
                  ? false
                  : true
              }
              onClick={() => [joinDateTime()]}
            >
              Ekle
            </Button>
            {durum && (
              <Typography sx={{ color: status == true ? "green" : "red" }}>
                {message}
              </Typography>
            )}
          </Box>
        </Box>
      </Box>
    </Box>
  );
}
