import { Typography } from '@mui/material';
import { Box } from '@mui/system';
import React from 'react';
import AuthForm from 'src/sections/auth/login/AuthForm';
import {useLocation} from 'react-router-dom';

function Authpage(props) {
    const [mail,setMail] = React.useState()
    const location = useLocation();

    React.useEffect(() => {
        setMail(location.state.mailValue)
    },[])
    
    
    return (
        <Box sx={{display:"flex",alignItems:"center",justifyContent:"center",paddingTop:"23rem",flexDirection:"column"}}>
            <Typography sx={{fontSize:24,marginBottom:"0.5rem"}}>Lütfen aşağıdaki mailinize gelen doğruluma kodunu girin.</Typography>
            <Typography sx={{textDecoration:"underline"}}>{location.state.mailValue}</Typography>
            <AuthForm mail = {mail}/>
        </Box>
    );
}

export default Authpage;