import * as React from "react";

import { Box, Button, Typography, Snackbar } from "@mui/material";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import TextField from "@mui/material/TextField";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import FileDownloadIcon from "@mui/icons-material/FileDownload";
import { ExportToCsv } from "export-to-csv";
import MaterialReactTable from "material-react-table";
import CircularProgress from "@mui/material/CircularProgress";
import Backdrop from "@mui/material/Backdrop";
import MuiAlert from "@mui/material/Alert";
import {
  amount,
  amountContainer,
  amountTitle,
  flexRow,
  pageContainer,
} from "src/utils/customStyles";
import CurlySvg from "src/utils/images/CurlySvg";

export default function Transactions() {
  const [res, setRes] = React.useState([]);
  const [range, setRange] = React.useState([]);
  const [open, setOpen] = React.useState(false);
  const handleChange = (event) => {
    setVendorId(event.target.value);
  };
  const [choosenVendor, setChoosenVendor] = React.useState("");
  const handleChooseVendor = (name) => {
    setStatus(false);
    setChoosenVendor(name.target.value);
  };
  const [authCode, setAuthCode] = React.useState(
    sessionStorage.getItem("AuthCode")
  );
  const [fetchUrl, setFetchUrl] = React.useState(
    sessionStorage.getItem("FetchUrl")
  );

  const [vendorId, setVendorId] = React.useState(0);
  const [emtiaId, setEmtiaId] = React.useState("");
  const [emtiaName, setEmtiaName] = React.useState("");

  const [pricesAmount, setPricesAmount] = React.useState([]);

  const handleOnChange = (event) => {
    setEmtiaId(event.target.value);
  };

  const [startDate, setStartDate] = React.useState("00/01/2023");
  const [endDate, setEndDate] = React.useState("00/00/0000");
  const [startDay, setStartDay] = React.useState("");
  const [startMonth, setStartMonth] = React.useState("");
  const [startYear, setStartYear] = React.useState("");

  const [endDay, setEndDay] = React.useState("");
  const [endMonth, setEndMonth] = React.useState("");
  const [endYear, setEndYear] = React.useState("");
  const [status, setStatus] = React.useState(false);

  const [filtered, setFiltered] = React.useState([]);
  const [buyFilter, setBuyFilter] = React.useState([]);
  const [buyFilterTL, setBuyFilterTL] = React.useState([]);
  const [buyFilterTL2, setBuyFilterTL2] = React.useState([]);
  const total = filtered.reduce((acc, gram) => acc + gram.GrAmount, 0);

  const totalsell = buyFilter.reduce((acc, gram) => acc + gram.GrAmount, 0);

  const totalTL = buyFilterTL.reduce((acc, gram) => acc + gram.TLAmount, 0);
  const totalsellTL = buyFilterTL2.reduce(
    (acc, gram) => acc + gram.TLAmount,
    0
  );

  const handleChangeStart = (event) => {
    setStartDate(event);
    if (event.$D < 10) {
      setStartDay("0" + event.$D);
    } else {
      setStartDay(event.$D.toString());
    }
    if (event.$M < 9) {
      setStartMonth("0" + (event.$M + 1));
      setStartYear(event.$y.toString());
    } else {
      setStartMonth((event.$M + 1).toString());
      setStartYear(event.$y.toString());
    }
  };
  const handleChangeEnd = (event) => {
    setEndDate(event);
    if (event.$D < 10) {
      setEndDay("0" + event.$D);
    } else {
      setEndDay(event.$D.toString());
    }
    if (event.$M < 9) {
      setEndMonth("0" + (event.$M + 1));
      setEndYear(event.$y.toString());
    } else {
      setEndMonth((event.$M + 1).toString());
      setEndYear(event.$y.toString());
    }
  };

  const [alertmessage, setAlertMessage] = React.useState("");
  const [alertStatus, setAlertStatus] = React.useState(false);
  const [openAlert, setOpenAlert] = React.useState(false);

  const Alert = React.forwardRef(function Alert(props, ref) {
    return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
  });

  const handleAlertClick = () => {
    setOpenAlert(true);
  };

  const handleAlertClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }

    setOpenAlert(false);
  };

  React.useEffect(() => {
    fetchedData();
  }, []);

  async function fetchedData() {
    var myHeaders = new Headers();
    myHeaders.append("Authorization", authCode);

    var requestOptions = {
      method: "GET",
      headers: myHeaders,
      redirect: "follow",
    };

    const response = await fetch(
      `${fetchUrl}Admin/GetAll?pageNumber=1`,
      requestOptions
    );

    const fetchedData = await response.json();

    const myData = fetchedData.Data;
    setRes(myData);
  }

  async function fetchedDataRange(
    choosenVendor,
    emtiaId,
    startDay,
    startMonth,
    startYear,
    endDay,
    endMonth,
    endYear
  ) {
    setOpen(true);
    var myHeaders = new Headers();
    myHeaders.append("Authorization", authCode);

    var requestOptions = {
      method: "GET",
      headers: myHeaders,
      redirect: "follow",
    };

    const response = await fetch(
      `${fetchUrl}Admin/GetVendorTransactions?vendorId=${choosenVendor}&commodityId=${emtiaId}&startDate=${startYear}-${startMonth}-${startDay}&endDate=${endYear}-${endMonth}-${endDay}`,
      requestOptions
    );
    const fetchedData = await response.json();
    setTimeout(() => {
      setOpen(false);
    }, 1000);

    const myData = fetchedData.Data;
    var endpointsArr = [];
    for (let i = 0; i < myData.length; i++) {
      endpointsArr.push({
        VendorUserId: myData[i].VendorUserId,
        Id: myData[i].Id,
        OrderType: myData[i].OrderType == true ? "0" : "1",
        Ticker:
          myData[i].CommodityName === "Gold"
            ? "gld-tl"
            : myData[i].CommodityName === "Silver"
            ? "slv-tl"
            : "plt-tl",
        TLAmount: myData[i].TlAmount,
        GrAmount: myData[i].GramAmount,
        Date: myData[i].TransactionDateTime,
      });
      if (endpointsArr[i].OrderType === "0") {
        setFiltered(endpointsArr.filter((info) => info.OrderType === "0"));
        setBuyFilterTL(endpointsArr.filter((info) => info.OrderType === "0"));
      } else if (endpointsArr[i].OrderType === "1") {
        setBuyFilter(endpointsArr.filter((info) => info.OrderType === "1"));
        setBuyFilterTL2(endpointsArr.filter((info) => info.OrderType === "1"));
      }
    }
    setRange(endpointsArr);
    setAlertMessage(fetchedData.Message);
    setAlertStatus(fetchedData.Success);
    handleAlertClick();
  }

  async function fetchedDataAmount(
    choosenVendor,
    emtiaId,
    startDay,
    startMonth,
    startYear,
    endDay,
    endMonth,
    endYear
  ) {
    var myHeaders = new Headers();
    myHeaders.append("Authorization", authCode);

    var requestOptions = {
      method: "GET",
      headers: myHeaders,
      redirect: "follow",
    };

    const response = await fetch(
      `${fetchUrl}Admin/GetRevenueVendorId?vendorId=${choosenVendor}&commodityId=${emtiaId}&startDate=${startYear}-${startMonth}-${startDay}&endDate=${endYear}-${endMonth}-${endDay}`,
      requestOptions
    );
    const fetchedData = await response.json();

    const myData = fetchedData.Data;
    console.log(myData);
    setPricesAmount(myData);
    setAlertMessage(fetchedData.Message);
    setAlertStatus(fetchedData.Success);
    handleAlertClick();
  }

  const columns = React.useMemo(
    () => [
      {
        accessorKey: "Id",
        header: "ID",
        size: 30,
      },
      {
        accessorKey: "OrderType",
        header: "OrderType",
        size: 120,
      },
      {
        accessorKey: "Ticker",
        header: "Ticker",
        size: 120,
      },
      {
        accessorKey: "TLAmount",
        header: "TLAmount",
        size: 30,
      },
      {
        accessorKey: "GrAmount",
        header: "GrAmount",
        size: 30,
      },
      {
        accessorKey: "Date",
        header: "Date",
        size: 20,
      },
      {
        accessorKey: "VendorUserId",
        header: "VendorUserId",
        size: 30,
      },
    ],
    []
  );
  const csvOptions = {
    fieldSeparator: ";",
    quoteStrings: '"',
    decimalSeparator: ".",
    showLabels: true,
    useBom: true,
    useKeysAsHeaders: true,
    headers: columns.map((c) => c.header),
    filename: "Transactions",
  };

  const csvExporter = new ExportToCsv(csvOptions);

  const handleExportRows = (rows) => {
    const filteredRows = rows.map((row) => {
      const { VendorUserId, ...rest } = row.original; // Date sütununu ayırıyoruz
      return rest;
    });
    csvExporter.generateCsv(filteredRows);
  };

  const handleExportData = () => {
    const filteredData = range.map((row) => {
      const { VendorUserId, ...rest } = row; // Date sütununu ayırıyoruz
      return rest;
    });
    csvExporter.generateCsv(filteredData);
  };

  function GetCard() {
    return (
      <Box sx={amountContainer}>
        <Box sx={flexRow}>
          <Typography sx={amountTitle}>Toplam Alınan Emtia : </Typography>
          <Typography sx={amount}>
            {pricesAmount.length == 0
              ? "0"
              : pricesAmount?.TotalBuyGram.toFixed(2)}{" "}
          </Typography>
          <Typography>gr</Typography>
        </Box>
        <Box sx={flexRow}>
          <Typography sx={amountTitle}>Toplam Alınan Emtia : </Typography>
          <Typography sx={amount}>
            {pricesAmount.length == 0
              ? "0"
              : pricesAmount?.TotalBuyTL.toFixed(2)}{" "}
          </Typography>
          <Typography>₺</Typography>
        </Box>
        <Box sx={flexRow}>
          <Typography sx={amountTitle}>Toplam Bozdurulan Emtia : </Typography>
          <Typography sx={amount}>
            {pricesAmount.length == 0
              ? "0"
              : pricesAmount?.TotalSellGram.toFixed(2)}{" "}
          </Typography>
          <Typography>gr</Typography>
        </Box>

        <Box sx={flexRow}>
          <Typography sx={amountTitle}> Toplam Bozdurulan Emtia : </Typography>
          <Typography sx={amount}>
            {pricesAmount.length == 0
              ? "0"
              : pricesAmount?.TotalSellTL.toFixed(2)}{" "}
          </Typography>
          <Typography>₺</Typography>
        </Box>

        <Box sx={flexRow}>
          <Typography sx={amountTitle}>Toplam Fark Emtia:</Typography>
          <Typography sx={amount}>
            {(pricesAmount.length == 0
              ? "0"
              : pricesAmount?.TotalBuyGram.toFixed(2) -
                pricesAmount?.TotalSellGram.toFixed(2)
            ).toFixed(2)}
          </Typography>
          <Typography>$</Typography>
        </Box>
      </Box>
    );
  }

  return (
    <Box sx={pageContainer}>
      <Typography variant="h4" gutterBottom>
        İşlemler
      </Typography>
      <Backdrop
        sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
        open={open}
      >
        <CircularProgress color="inherit" />
      </Backdrop>

      <Box
        sx={{
          gap: "2rem",
          alignItems: "center",
          display: "flex",
          marginBottom: "2rem",
          marginTop: "2rem",
          paddingRight: "auto",
        }}
      >
        <FormControl sx={{ width: "160px", minWidth: "80px" }}>
          <InputLabel id="demo-simple-select-label">Vendor Seçiniz</InputLabel>
          <Select
            labelId="demo-simple-select-label"
            id="demo-simple-select"
            value={choosenVendor}
            label="Vendor Seçiniz"
            onChange={handleChooseVendor}
          >
            {res.map((res) => (
              <MenuItem key={res.Id} value={res.Id}>
                {res.Name}
              </MenuItem>
            ))}
          </Select>
        </FormControl>

        <FormControl sx={{ width: "160px", minWidth: "80px" }}>
          <InputLabel id="demo-simple-select-label">Emtia Seçiniz</InputLabel>
          <Select
            labelId="demo-simple-select-label"
            id="demo-simple-select"
            value={emtiaId}
            label="Emtia Seçiniz"
            onChange={handleOnChange}
          >
            <MenuItem value="7BE7C383-03E0-4A29-9564-1772BF0E996E">
              Altın
            </MenuItem>
            <MenuItem value="5D713008-15BB-4509-BE0D-B2F9355E8FF4">
              Gümüş
            </MenuItem>
            <MenuItem value="8FC928D5-D9F9-4ABD-9859-567883AA3F06">
              Platin
            </MenuItem>
          </Select>
        </FormControl>
        <LocalizationProvider dateAdapter={AdapterDayjs}>
          <DatePicker
            label="Başlangıç Tarihi"
            value={startDate}
            onChange={handleChangeStart}
            renderInput={(params) => (
              <TextField
                {...params}
                error={false}
                style={{ minWidth: "80px" }}
              />
            )}
          />

          <DatePicker
            label="Bitiş Tarihi"
            value={endDate}
            onChange={handleChangeEnd}
            renderInput={(params) => (
              <TextField
                {...params}
                error={false}
                style={{ minWidth: "80px" }}
              />
            )}
          />
        </LocalizationProvider>

        {endYear != "" ? (
          <Button
            variant="contained"
            onClick={() => [
              setRange([]),
              fetchedDataAmount(
                choosenVendor,
                emtiaId,
                startDay,
                startMonth,
                startYear,
                endDay,
                endMonth,
                endYear
              ),
            ]}
          >
            Listele
          </Button>
        ) : (
          <Button variant="contained" disabled>
            Listele
          </Button>
        )}
      </Box>

      {pricesAmount.length != 0 ? (
        <Box sx={{ display: "flex", alignItems: "center", gap: "3rem" }}>
          <GetCard />
          <CurlySvg />

          <Button
            variant="outlined"
            sx={{
              width: "180px",
              height: "40px",
            }}
            onClick={() => [
              fetchedDataRange(
                choosenVendor,
                emtiaId,
                startDay,
                startMonth,
                startYear,
                endDay,
                endMonth,
                endYear
              ),
              setStatus(true),
            ]}
          >
            <Typography>İşlemleri Göster</Typography>
          </Button>
        </Box>
      ) : null}

      <MaterialReactTable
        columns={columns}
        data={range}
        enableRowSelection
        positionToolbarAlertBanner="bottom"
        renderTopToolbarCustomActions={({ table }) => (
          <Box
            sx={{ display: "flex", gap: "1rem", p: "0.5rem", flexWrap: "wrap" }}
          >
            <Button
              color="primary"
              onClick={handleExportData}
              startIcon={<FileDownloadIcon />}
              variant="outlined"
              size="small"
              sx={{ fontSize: { xs: "10px", sm: "12px", md: "12px" } }}
            >
              Tüm Verileri Dışa Aktar
            </Button>
            <Button
              disabled={table.getPrePaginationRowModel().rows.length === 0}
              onClick={() =>
                handleExportRows(table.getPrePaginationRowModel().rows)
              }
              startIcon={<FileDownloadIcon />}
              variant="outlined"
              size="small"
              sx={{ fontSize: { xs: "10px", sm: "12px", md: "12px" } }}
            >
              Tüm Satırları Dışa Aktar
            </Button>
            <Button
              disabled={table.getRowModel().rows.length === 0}
              onClick={() => handleExportRows(table.getRowModel().rows)}
              startIcon={<FileDownloadIcon />}
              variant="outlined"
              size="small"
              sx={{ fontSize: { xs: "10px", sm: "12px", md: "12px" } }}
            >
              Bu Sayfadaki Satırları Dışa Aktar
            </Button>
            <Button
              disabled={
                !table.getIsSomeRowsSelected() && !table.getIsAllRowsSelected()
              }
              onClick={() => handleExportRows(table.getSelectedRowModel().rows)}
              startIcon={<FileDownloadIcon />}
              variant="outlined"
              size="small"
              sx={{ fontSize: { xs: "10px", sm: "12px", md: "12px" } }}
            >
              Seçilmiş Satırları Dışa Aktar
            </Button>
          </Box>
        )}
      />

      <Snackbar
        open={openAlert}
        onClose={handleAlertClose}
        autoHideDuration={2000}
      >
        <Alert severity={alertStatus ? "success" : "error"}>
          {alertmessage}
        </Alert>
      </Snackbar>
    </Box>
  );
}
