import { useState } from "react";
import { useNavigate } from "react-router-dom";
// @mui
import { TextField, Box, Typography } from "@mui/material";
import { LoadingButton } from "@mui/lab";
import { styled } from "@mui/material/styles";
import global from "src/Global";

// components

// ----------------------------------------------------------------------

export default function AuthForm(props) {
  const [validationCode, setValidationCode] = useState("");
  const [loading, setLoading] = useState(false);
  const [loginStatus, setLoginStatus] = useState(true);
  const [loginMessage, setLoginMessage] = useState(false);
  const [fetchUrl, setFetchUrl] = useState(sessionStorage.getItem("FetchUrl"));

  global.MAIL = props.mail;
  sessionStorage.setItem("Mail", global.MAIL);

  const StyledContent = styled("div")(({ theme }) => ({
    maxWidth: 480,
    margin: "auto",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    flexDirection: "column",
    padding: theme.spacing(6, 0),
  }));

  const navigate = useNavigate();

  async function sendAuthCode(validationCode) {
    setLoading(true);

    var myHeaders = new Headers();
    myHeaders.append("Content-Type", "application/json");

    var raw = JSON.stringify({
      email: global.MAIL,
      code: validationCode,
    });

    var requestOptions = {
      method: "POST",
      headers: myHeaders,
      body: raw,
      redirect: "follow",
    };

    const response = await fetch(
      `${fetchUrl}Auth/LoginForAdminValidate`,
      requestOptions
    );
    const fetchedData = await response.json();
    setLoading(false);

    if (fetchedData.Success) {
      global.AUTH_CODE = fetchedData.Data.AuthCode;
      sessionStorage.setItem("AuthCode", global.AUTH_CODE);
      sessionStorage.setItem("Logged", true);
      navigate("/dashboard");
    } else if (fetchedData.Success === false) {
      setLoginStatus(false);
      setLoginMessage(fetchedData.Message);
    }
  }

  return (
    <>
      <Box
        style={{
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          justifyContent: "center",
          marginTop: "2rem",
        }}
      >
        <TextField
          name="validationCode"
          label="Doğrulama Kodu"
          value={validationCode}
          onChange={(e) => setValidationCode(e.target.value)}
          sx={{ width: "20rem" }}
        />

        {loginStatus ? (
          ""
        ) : (
          <Typography
            sx={{
              color: "red",
              alignSelf: "center",
              marginTop: "1rem",
              fontStyle: "italic",
            }}
          >
            {loginMessage}
          </Typography>
        )}

        <LoadingButton
          fullWidth
          size="large"
          type="submit"
          variant="contained"
          sx={{ width: "20rem", marginTop: "1rem" }}
          onClick={() => {
            sendAuthCode(validationCode);
          }}
        >
          Onayla
        </LoadingButton>
      </Box>
    </>
  );
}
