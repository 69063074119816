// ----------------------------------------------------------------------
import global from "src/Global";

const account = {
  displayName: "Goldtag Admin",
  email: sessionStorage.getItem("Mail"),
  photoURL: "/assets/admin.svg",
};

export default account;
