import * as React from "react";
import {
  Box,
  Button,
  MenuItem,
  Typography,
  Modal,
  Snackbar,
} from "@mui/material";

import MaterialReactTable from "material-react-table";

import UpdateSettings from "./UpdateSettings";
import CircularProgress from "@mui/material/CircularProgress";
import Backdrop from "@mui/material/Backdrop";
import MuiAlert from "@mui/material/Alert";

export default function GetAllForSettings() {
  const [authCode, setAuthCode] = React.useState(
    sessionStorage.getItem("AuthCode")
  );
  const [fetchUrl, setFetchUrl] = React.useState(
    sessionStorage.getItem("FetchUrl")
  );
  const [range, setRange] = React.useState([]);
  const [aktifId, setAktifId] = React.useState("");
  const [pasifId, setPasifid] = React.useState("");

  const [closeStatus, setCloseStatus] = React.useState(false);
  const [openM, setOpenM] = React.useState(false);
  const handleOpenM = () => setOpenM(true);
  const handleCloseM = () => setOpenM(false);

  const [closeStatus2, setCloseStatus2] = React.useState(false);
  const [openM2, setOpenM2] = React.useState(false);
  const handleOpenM2 = () => setOpenM2(true);
  const handleCloseM2 = () => setOpenM2(false);
  const [open, setOpen] = React.useState(false);

  const [switchIt, setSwitchIT] = React.useState("SettingsListeleme");

  const [alertmessage, setAlertMessage] = React.useState("");
  const [alertStatus, setAlertStatus] = React.useState(false);
  const [openAlert, setOpenAlert] = React.useState(false);

  const Alert = React.forwardRef(function Alert(props, ref) {
    return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
  });

  const handleAlertClick = () => {
    setOpenAlert(true);
  };

  const handleAlertClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }

    setOpenAlert(false);
  };

  React.useEffect(() => {
    fetchGetAllForSettings();
  }, [closeStatus, closeStatus2]);

  async function fetchGetAllForSettings() {
    setOpen(true);
    var myHeaders = new Headers();
    myHeaders.append("Authorization", authCode);

    var requestOptions = {
      method: "GET",
      headers: myHeaders,
      redirect: "follow",
    };

    const response = await fetch(
      `${fetchUrl}Admin/GetAllForSettings?pageNumber=1`,
      requestOptions
    );
    const fetchedData = await response.json();

    const myData = fetchedData.Data;
    var endpointsArr = [];

    for (let i = 0; i < myData.length; i++) {
      if (myData[i].Key == "BuySellProcess") {
        endpointsArr.push({
          Id: myData[i].Id,
          Type: myData[i].Type,
          Key: myData[i].Key,
          Value: myData[i].Value,
          CustomField1: myData[i].CustomField1,
          CustomField2: myData[i].CustomField2,
          CustomField3: myData[i].CustomField3,
          IsActive:
            myData[i].IsActive === true ? (
              <Typography
                sx={{ color: "blue", fontWeight: "bold", fontStyle: "italic" }}
              >
                Aktif
              </Typography>
            ) : (
              <Typography
                sx={{ color: "red", fontWeight: "bold", fontStyle: "italic" }}
              >
                Pasif
              </Typography>
            ),
          CreatedDate: myData[i].CreatedDate,
        });
      }
    }
    setRange(endpointsArr);
    setTimeout(() => {
      setOpen(false);
    }, 500);

    setAlertMessage(fetchedData.Message);
    setAlertStatus(fetchedData.Success);
    handleAlertClick();
  }

  async function fetchActiveForSettings(aktifId) {
    var myHeaders = new Headers();
    myHeaders.append("Authorization", authCode);

    var requestOptions = {
      method: "GET",
      headers: myHeaders,
      redirect: "follow",
    };

    const response = await fetch(
      `${fetchUrl}Admin/MakeActiveForSettings?id=${aktifId}`,
      requestOptions
    );
    const fetchedData = await response.json();
    const myData = fetchedData.Data;
    setTimeout(() => {
      setCloseStatus(false);
    }, 1000);
    if (fetchedData.success === true) {
      console.log("Aktif işlemi Gerçekleşti.");
    }
  }

  async function fetchPassiveForSettings(aktifId) {
    var myHeaders = new Headers();
    myHeaders.append("Authorization", authCode);

    var requestOptions = {
      method: "GET",
      headers: myHeaders,
      redirect: "follow",
    };

    const response = await fetch(
      `${fetchUrl}Admin/MakePassiveForSettings?id=${aktifId}`,
      requestOptions
    );
    const fetchedData = await response.json();
    const myData = fetchedData.Data;
    setTimeout(() => {
      setCloseStatus2(false);
    }, 1000);
    console.log("Pasif işlemi Gerçekleşti.");
  }

  const columns = React.useMemo(
    () => [
      {
        accessorKey: "Id",
        header: "Id",
        size: 60,
      },
      {
        accessorKey: "IsActive",
        header: "Is Active",
        size: 60,
      },
      {
        accessorKey: "Type",
        header: "Transaction Ids",
        size: 60,
      },
      {
        accessorKey: "Key",
        header: "Key",
        size: 60,
      },
      {
        accessorKey: "Value",
        header: "Value",
        size: 60,
      },
      {
        accessorKey: "CustomField1",
        header: "Field 1",
        size: 30,
      },
      {
        accessorKey: "CustomField2",
        header: "Field 2",
        size: 30,
      },
      {
        accessorKey: "CustomField3",
        header: "Field 3",
        size: 30,
      },
      {
        accessorKey: "CreatedDate",
        header: "Created Date",
        size: 30,
      },
    ],
    []
  );

  return (
    <Box sx={{ display: "flex", flexDirection: "column" }}>
      <Box
        sx={{
          display: "flex",
          alignSelf: "center",
          gap: "5rem",
        }}
      >
        <Button
          variant={switchIt == "SettingsListeleme" ? "contained" : "outlined"}
          onClick={() => setSwitchIT("SettingsListeleme")}
        >
          <Typography>Settings Listeleme</Typography>
        </Button>

        <Button
          variant={switchIt == "Settingsİşlemleri" ? "contained" : "outlined"}
          onClick={() => setSwitchIT("Settingsİşlemleri")}
        >
          <Typography>Settings İşlemleri</Typography>
        </Button>
      </Box>

      <Box
        sx={{
          width: "100%",
          height: "0.01rem",
          backgroundColor: "lightgray",
          marginBottom: "2rem",
          marginTop: "2rem",
        }}
      />

      {switchIt === "SettingsListeleme" ? (
        <>
          {closeStatus && (
            <Modal
              open={openM}
              onClose={handleCloseM}
              aria-labelledby="modal-modal-title"
              aria-describedby="modal-modal-description"
            >
              <Box
                sx={{
                  position: "absolute",
                  top: "50%",
                  left: "50%",
                  transform: "translate(-50%, -50%)",
                  width: 470,
                  bgcolor: "background.paper",
                  border: "2px solid #ffb703",
                  boxShadow: 24,
                  p: 4,
                  borderRadius: "2rem",
                }}
              >
                <Typography id="modal-modal-title" variant="h6" component="h2">
                  Aktif yapmak istediğinize emin misiniz ?
                </Typography>
                <Box
                  style={{
                    flexDirection: "row",
                    display: "flex",
                    gap: "2rem",
                    justifyContent: "center",
                    marginTop: "1rem",
                  }}
                >
                  <Button
                    variant="contained"
                    color="success"
                    sx={{ color: "white", marginRight: "1rem" }}
                    onClick={() => fetchActiveForSettings(aktifId)}
                  >
                    Evet
                  </Button>
                  <Button
                    variant="contained"
                    color="error"
                    sx={{ color: "white" }}
                    onClick={() => setCloseStatus(false)}
                  >
                    Hayır
                  </Button>
                </Box>
              </Box>
            </Modal>
          )}

          {closeStatus2 && (
            <Modal
              open={openM2}
              onClose={handleCloseM2}
              aria-labelledby="modal-modal-title"
              aria-describedby="modal-modal-description"
            >
              <Box
                sx={{
                  position: "absolute",
                  top: "50%",
                  left: "50%",
                  transform: "translate(-50%, -50%)",
                  width: 470,
                  bgcolor: "background.paper",
                  border: "2px solid #ffb703",
                  boxShadow: 24,
                  p: 4,
                  borderRadius: "2rem",
                }}
              >
                <Typography id="modal-modal-title" variant="h6" component="h2">
                  Pasif yapmak istediğinize emin misiniz ?
                </Typography>
                <Box
                  style={{
                    flexDirection: "row",
                    display: "flex",
                    gap: "2rem",
                    justifyContent: "center",
                    marginTop: "1rem",
                  }}
                >
                  <Button
                    variant="contained"
                    color="success"
                    sx={{ color: "white", marginRight: "1rem" }}
                    onClick={() => fetchPassiveForSettings(pasifId)}
                  >
                    Evet
                  </Button>
                  <Button
                    variant="contained"
                    color="error"
                    sx={{ color: "white" }}
                    onClick={() => setCloseStatus2(false)}
                  >
                    Hayır
                  </Button>
                </Box>
              </Box>
            </Modal>
          )}
          <Box sx={{ marginLeft: "3rem" }}>
            <Typography variant="h4" gutterBottom>
              Settings Listeleme
            </Typography>

            <Backdrop
              sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
              open={open}
            >
              <CircularProgress color="inherit" />
            </Backdrop>
            <Button
              variant="contained"
              onClick={() => fetchGetAllForSettings()}
              sx={{ marginBottom: "1rem", marginTop: "1rem" }}
            >
              Listele
            </Button>
            <MaterialReactTable
              columns={columns}
              data={range}
              positionToolbarAlertBanner="bottom"
              enableRowActions
              renderRowActions={({ row }) => [
                <Box
                  sx={{
                    display: "flex",
                    gap: "1rem",
                    p: "0.5rem",
                    flexWrap: "wrap",
                  }}
                >
                  <MenuItem
                    sx={{
                      bgcolor: "#005791",
                      borderRadius: "0.5rem",
                      color: "white",
                      "&:hover": {
                        backgroundColor: "##0295f7",
                      },
                    }}
                    key="aktif"
                    onClick={() => [
                      setCloseStatus(true),
                      setAktifId(row.original.Id),
                      handleOpenM(),
                    ]}
                  >
                    Aktif
                  </MenuItem>

                  <MenuItem
                    sx={{
                      bgcolor: "#c20000",
                      borderRadius: "0.5rem",
                      color: "white",
                      "&:hover": {
                        backgroundColor: "#fa0000",
                      },
                    }}
                    key="pasif"
                    onClick={() => [
                      setCloseStatus2(true),
                      setPasifid(row.original.Id),
                      handleOpenM2(),
                    ]}
                  >
                    Pasif
                  </MenuItem>
                </Box>,
              ]}
            />
          </Box>
        </>
      ) : (
        <Box
          sx={{
            display: "flex",
            gap: "5rem",
            justifyContent: "center",
          }}
        >
          <UpdateSettings />
        </Box>
      )}

      <Box
        sx={{
          width: "100%",
          height: "0.01rem",
          backgroundColor: "lightgray",
          marginBottom: "2rem",
          marginTop: "2rem",
        }}
      />

      <Snackbar
        open={openAlert}
        onClose={handleAlertClose}
        autoHideDuration={2000}
      >
        <Alert severity={alertStatus ? "success" : "error"}>
          {alertmessage}
        </Alert>
      </Snackbar>
    </Box>
  );
}
