import { Box, ImageListItem, Typography } from '@mui/material'
import React from 'react'
import { useState } from 'react'
import { useEffect } from 'react'

const OrdersTarım = () => {
  const [arpaPrice,setArpaPrice] = useState()
  const [bugdayMakarnalikPrice,setBugdayMakarnalikPrice] = useState()
  const [bugdayEkmeklikPrice,setBugdayEkmeklikPrice] = useState()
  const [misirPrice,setMisirrPrice] = useState()
  const [authCode,setAuthCode] = useState(sessionStorage.getItem("AuthCode"))
  const [fetchUrl,setFetchUrl] = useState(sessionStorage.getItem("FetchUrl"))

  useEffect(() => {
    fetchData();
    const interval = setInterval(() => {
      fetchData()
    }, 60000)
    return () => clearInterval(interval)
  },[misirPrice,arpaPrice])


  async function fetchData() {
    var requestOptions={
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
      'Authorization': authCode
    },
    body: JSON.stringify({
      "grain_kg_amount": 0
    })
  }
  const response = await fetch(`${fetchUrl}Admin/get_grains_price`, requestOptions)
  const fetchedData = await response.json();
  const stringfyData = await fetchedData.Data
  
  setMisirrPrice(stringfyData.sell_misir)
  setBugdayEkmeklikPrice(stringfyData.sell_bugdayekmeklik)
  setBugdayMakarnalikPrice(stringfyData.sell_bugdaymakarnalik)
  setArpaPrice(stringfyData.sell_arpa)

}
  
  
  const data = [
    ['/assets/images/covers/cover_arpa.png', 'Arpa', 'Goldtag Kuru', `${arpaPrice}₺`],
    ['/assets/images/covers/cover_bugday.png', 'Buğday','Goldtag Kuru (Ekmeklik / Buğdaylık)',`${bugdayMakarnalikPrice}₺ / ${bugdayEkmeklikPrice}₺`],
    ['/assets/images/covers/cover_mısır.png', 'Mısır', 'Goldtag Kuru', `${misirPrice}₺`,],

  ]
  
  
    return (
        <Box sx={{display:'flex', flexDirection:'column',backgroundColor:'white', width:'100%',borderRadius:'5px',padding:'1rem',gap:'1rem', marginTop:'15px',justifyContent:"center"}}>
        
        <Typography sx={{marginLeft:"3rem",fontSize:12,textAlign:"center",color:"#B78103"}}>1 Kilogram'a göre hesaplanmıştır.</Typography>
         
   {data.map((item)=>{
    return(
       
      <Box sx={{ display:'flex', justifyContent:'space-between',alignItems:'center',gap:'2rem',alignItems:"center"}}>
        

        <Box sx={{display:'flex', flexDirection:'column',alignItems:'center',gap:'0.7rem'}}>
          <ImageListItem sx={{display:'absolute',width:'50px', height:'30px',marginRight:'5px'}}>
              <img
              src={item[0]}
              alt='img'/>
          </ImageListItem>
          
          <Typography sx={{fontWeight:'bold', color:'#2F4365', fontSize:'15px',marginBottom:'2px'}}>
                  {item[1]}
              </Typography>
          </Box>  
         
          <Box sx={{width:"10rem",height:"10rem",alignItems:"center"}}>
                <Typography sx={{ fontSize:'13px',color:'#B78103', textAlign:'center',marginTop:"3rem",fontWeight:"bold"}}>
                    {item[2]}
                </Typography>
                <Typography  sx={{ fontSize:'12px',color:'#2F4365', textAlign:'center',fontWeight:'bold',marginTop:"0.5rem"}}>
                    {item[3]}
                </Typography>
              
          </Box>
  </Box>
        
    )
  })}
  </Box>
  )
}

export default OrdersTarım