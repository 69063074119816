import {
  Box,
  Button,
  CircularProgress,
  Snackbar,
  Modal,
  Tooltip,
  Typography,
  Backdrop,
  IconButton,
} from "@mui/material";
import React, { lazy, Suspense, useState } from "react";
import { fetchPrices } from "src/services/api";
import {
  cardList,
  circleStatus,
  dateStyle,
  frameOrders1,
  imageCircle,
  insideTitle16,
  lightTitle14,
  modalStyle,
  orderContainer,
  priceFrame,
  priceSubTitle,
  priceTitle,
  underlineBold18,
} from "src/utils/customStyles";
import { fDateTimeTurkish } from "src/utils/formatTime";
import { delayForDemo } from "src/utils/helpers/helper";
import MuiAlert from "@mui/material/Alert";

const Orders = () => {
  const [fetchUrl, setFetchUrl] = React.useState(
    sessionStorage.getItem("FetchUrl")
  );
  const [authCode, setAuthCode] = React.useState(
    sessionStorage.getItem("AuthCode")
  );
  const [dataAltinKaynak, setDataAltinKaynak] = useState([]);
  const [dataKT, setDataKT] = useState([]);
  const [dataFintag, setDataFintag] = useState([]);
  const [dataFintagMinMax, setDataFintagMinMax] = useState([]);
  const [dataSerbest, setDataSerbest] = useState([]);
  const [open, setOpen] = React.useState(false);
  const [suffix, setSuffix] = React.useState("");

  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  const LazyImage = lazy(() => delayForDemo(import("../Images/KTLazy")));
  const LazyImage2 = lazy(() => delayForDemo(import("../Images/FTLazy")));
  const LazyImage3 = lazy(() => delayForDemo(import("../Images/MoneyLazy")));
  const LazyImage4 = lazy(() =>
    delayForDemo(import("../Images/AltinKynkLazy"))
  );
  const data = [
    [
      `${fDateTimeTurkish(dataFintag.ModifiedDate)}`,
      "Fintag",
      "Buy Price",
      `${dataFintag.ViewBuyPrice}`,
      "Sell Price",
      `${dataFintag.ViewSellPrice}`,
      `${dataFintag.IsActive}`,
      `${dataFintag.ProviderName}`,
    ],
    [
      `${fDateTimeTurkish(dataAltinKaynak.ModifiedDate)}`,
      "Altınkaynak",
      "Buy Price",
      `${dataAltinKaynak.ViewBuyPrice}`,
      "Sell Price",
      `${dataAltinKaynak.ViewSellPrice}`,
      `${dataAltinKaynak.IsActive}`,
      `${dataAltinKaynak.ProviderName}`,
    ],
    [
      `${fDateTimeTurkish(dataKT.ModifiedDate)}`,
      "Kuveyttürk",
      "Buy Price",
      `${dataKT.ViewBuyPrice}`,
      "Sell Price",
      `${dataKT.ViewSellPrice}`,
      `${dataKT.IsActive}`,
      `${dataKT.ProviderName}`,
    ],
    [
      `${fDateTimeTurkish(dataSerbest.ModifiedDate)}`,
      "Serbest Piyasa",
      "Buy Price",
      `${dataSerbest.ViewBuyPrice}`,
      "Sell Price",
      `${dataSerbest.ViewSellPrice}`,
      `${dataSerbest.IsActive}`,
      `${dataSerbest.ProviderName}`,
    ],
  ];

  React.useEffect(() => {
    getPrices();

    const interval = setInterval(() => {
      getPrices();
    }, 30000);

    return () => clearInterval(interval);
  }, [open]);

  async function getPrices() {
    var requestOptions = {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Authorization: authCode,
      },
    };
    const response = await fetch(
      `${fetchUrl}Admin/GetCurrencyPriceForAdmin`,
      requestOptions
    );
    const fetchedData = await response.json();
    const stringfyData = await fetchedData.Data;
    console.log(stringfyData);
    for (let i = 0; i < stringfyData.length; i++) {
      if (stringfyData[i].ProviderSuffix == 3) {
        setDataAltinKaynak(stringfyData[i]);
      } else if (stringfyData[i].ProviderSuffix == 1) {
        setDataKT(stringfyData[i]);
      } else if (stringfyData[i].ProviderSuffix == 4) {
        setDataSerbest(stringfyData[i]);
      } else if (stringfyData[i].ProviderSuffix == 2) {
        setDataFintag(stringfyData[i]);
      } else if (stringfyData[i].ProviderSuffix == 20) {
        setDataFintagMinMax(stringfyData[i]);
      }
    }
  }

  async function UpdateStatus() {
    setOpen(true);

    var myHeaders = new Headers();
    myHeaders.append("Authorization", authCode);
    myHeaders.append("Content-Type", "application/json");
    myHeaders.append("accept", "*/*");

    var raw = {
      ProviderSuffix: suffix,
      BuyPrice: -1,
      SellPrice: -1,
      BuyProfitRate: -1,
      SellProfitRate: -1,
      Bsmv: -1,
      IsActive: true,
    };

    var jsonString = JSON.stringify(raw);

    var requestOptions = {
      method: "POST",
      headers: myHeaders,
      body: jsonString,
      redirect: "follow",
    };
    const response = await fetch(
      `${fetchUrl}Admin/SetCurrencyPriceForAdmin`,
      requestOptions
    );

    const fetchedDataPrice = await response.json();
    if (fetchedDataPrice.Success === true) {
      setAlertMessage(fetchedDataPrice.Message);
      setAlertStatus(fetchedDataPrice.Success);
      handleAlertClick();
    } else if (fetchedDataPrice.Success === false) {
      setAlertMessage(fetchedDataPrice.Message);
      setAlertStatus(fetchedDataPrice.Success);
      handleAlertClick();
    }

    setOpen(false);
  }

  // SnackBar
  const [alertmessage, setAlertMessage] = React.useState("");
  const [alertStatus, setAlertStatus] = React.useState(false);
  const [openAlert, setOpenAlert] = React.useState(false);

  const Alert = React.forwardRef(function Alert(props, ref) {
    return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
  });

  const handleAlertClick = () => {
    setOpenAlert(true);
  };

  const handleAlertClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }

    setOpenAlert(false);
  };

  console.log(authCode);
  return (
    <Box sx={orderContainer}>
      <Backdrop
        sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
        open={open}
      >
        <CircularProgress color="inherit" />
      </Backdrop>

      <Snackbar
        open={openAlert}
        onClose={handleAlertClose}
        autoHideDuration={2000}
      >
        <Alert severity={alertStatus ? "success" : "error"}>
          {alertmessage}
        </Alert>
      </Snackbar>

      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={modalStyle}>
          <Typography
            id="modal-modal-title"
            sx={[
              underlineBold18,
              { marginBottom: "1rem", textDecoration: "none" },
            ]}
          >
            İşlemi onaylıyor musunuz?
          </Typography>
          <Typography id="modal-modal-description" sx={lightTitle14}>
            Bu işlem provider'ı aktif edecektir.
          </Typography>

          <Box
            sx={{
              display: "flex",
              justifyContent: "space-around",
              width: "70%",
              marginTop: "1rem",
            }}
          >
            <Button
              variant="contained"
              color="success"
              sx={{ color: "white" }}
              onClick={() => UpdateStatus()}
            >
              Onayla
            </Button>
            <Button
              variant="contained"
              color="error"
              onClick={() => setOpen(false)}
            >
              Vazgeç
            </Button>
          </Box>
        </Box>
      </Modal>

      {data.map((item) => {
        return (
          <Box sx={cardList}>
            <Box sx={frameOrders1}>
              <Box sx={{ m: 1, position: "relative" }}>
                <Tooltip title="Provider'ı Aktif/Pasif durumuna getirmek için tıklayın.">
                  <IconButton
                    sx={[
                      circleStatus,
                      {
                        backgroundColor:
                          item[6] == "true" ? "#51e846" : "rgba(56,56,56,0.1)",
                      },
                    ]}
                    disabled={item[1] == "Serbest Piyasa" && true}
                    onClick={() => {
                      if (item[7] == "Altinkaynak") {
                        setSuffix(3);
                        handleOpen(3);
                      } else if (item[7] == "KuveytTurk") {
                        setSuffix(1);
                        handleOpen(1);
                      } else if (item[7] == "SerbestPiyasa") {
                        setSuffix(4);
                        handleOpen(4);
                      } else if (item[7] == "Fintag") {
                        setSuffix(2);
                        handleOpen();
                      }
                    }}
                  ></IconButton>
                </Tooltip>
              </Box>

              <Box sx={imageCircle}>
                {item[1] == "Serbest Piyasa" && (
                  <Suspense
                    fallback={
                      <CircularProgress
                        color="primary"
                        variant="indeterminate"
                        size={24}
                      />
                    }
                  >
                    <LazyImage3 />
                  </Suspense>
                )}
                {item[1] == "Kuveyttürk" && (
                  <Suspense
                    fallback={
                      <CircularProgress
                        color="primary"
                        variant="indeterminate"
                        size={24}
                      />
                    }
                  >
                    <LazyImage />
                  </Suspense>
                )}
                {item[1] == "Fintag" && (
                  <Suspense
                    fallback={
                      <CircularProgress
                        color="primary"
                        variant="indeterminate"
                        size={24}
                      />
                    }
                  >
                    <LazyImage2 />
                  </Suspense>
                )}
                {item[1] == "Altınkaynak" && (
                  <Suspense
                    fallback={
                      <CircularProgress
                        color="primary"
                        variant="indeterminate"
                        size={24}
                      />
                    }
                  >
                    <LazyImage4 />
                  </Suspense>
                )}
              </Box>

              <Typography sx={insideTitle16}>{item[1]}</Typography>
            </Box>
            <Typography sx={dateStyle}>{item[0]}</Typography>
            <Box sx={priceFrame}>
              <Box>
                <Typography sx={priceTitle}>{item[2]}</Typography>
                <Typography sx={priceSubTitle}>{item[3]}</Typography>
              </Box>
              <Box>
                <Typography sx={priceTitle}>{item[4]}</Typography>
                <Typography sx={priceSubTitle}>{item[5]}</Typography>
              </Box>
            </Box>
          </Box>
        );
      })}
      <Typography
        sx={[
          dateStyle,
          { color: dataFintagMinMax.IsDeleted == false ? "red" : "green" },
        ]}
      >
        Fintag Min Max :
        {dataFintagMinMax.IsDeleted == false ? " Pasif" : " Aktif"}
      </Typography>
    </Box>
  );
};

export default Orders;
