import { Navigate, useRoutes } from "react-router-dom";
// layouts
import DashboardLayout from "./layouts/dashboard";
import SimpleLayout from "./layouts/simple";
//
import LoginPage from "./pages/LoginPage";
import Page404 from "./pages/Page404";
import Vendor from "./pages/Vendor";
import Price from "./pages/Price";
import Transactions from "./pages/Transactions";
import Authpage from "./pages/Authpage";
import ListAllEndPoints from "./pages/ListAllEndPoints";

import ProviderCreate from "./pages/Provider";
import VendorIslemleri from "./pages/VendorIslemleri";
import Commodity from "./pages/Commodity";
import ProviderPriceHistory from "./pages/ProviderPriceHistory";
import CreateAdmin from "./components/AleynaComponents/CreateAdmin";
import UpdateVendorBalance from "./components/AleynaComponents/UpdateVendorBalance";
import ManuelBuySell from "./components/AleynaComponents/ManuelBuySell";
import FinalizedListeleme from "./pages/FinalizedListeleme";
import UnexpectedListeleme from "./pages/UnexpectedListeleme";
import NotPositionListeleme from "./pages/NotPositionsListeleme";
import GetAllForSettings from "./pages/GetAllForSettings";
import ApptoGatewayTransactions from "./components/AleynaComponents/ApptoGatewayTransactions";
import AddUserEmtia from "./components/AleynaComponents/AddUserEmtia";
import ComingSoon from "./components/AleynaComponents/ComingSoon";
import BankActivities from "./pages/BankActivities";
import GetAccountsSecond from "./pages/GetAccountsSecond";
import FinalizedNotPositionUnexpected from "./pages/FinalizedNotPositionUnexpected";
import Transferler from "./pages/Transferler";
import TransferlerUserId from "./pages/TransferlerUserId";
import StartingPage from "./pages/StartingPage";
import LoginCrypto from "./CryptoPages/LoginCrypto";
import DashboardMainPage from "./CryptoPages/DashboardMainPage";
import PriceCrypto from "./CryptoPages/USDTPrices";
import TransactionsCrypto from "./CryptoPages/Transactions";
import DashboardLayoutCurrencies from "./layoutsCrypto/dashboard/DashboardLayout";
import AuthCrypto from "./CryptoPages/AuthCrypto";
import { Box, Typography } from "@mui/material";
import { useEffect, useState } from "react";
import Logo from "./components/logo";
import PricesMainPage from "./CryptoPages/PricesMainPage";
import FintagMinMax from "./CryptoPages/FintagMinMax";
// ----------------------------------------------------------------------

export default function Router() {
  const [ipAddress, setIPAddress] = useState("");
  const myIP = "95.3.15.194";
  const myIP2 = "20.127.243.158";
  const myIP3 = "20.218.164.9";

  const [isLoading, setIsLoading] = useState(true);
  const [isAllowed, setIsAllowed] = useState(false); // Başlangıçta false olarak ayarlandı

  useEffect(() => {
    fetch("https://api.ipify.org?format=json")
      .then((response) => response.json())
      .then((data) => {
        setIPAddress(data.ip);
        setIsLoading(false);
      })
      .catch((error) => {
        console.log(error);
        setIsLoading(false);
      });
  }, []);

  useEffect(() => {
    if (
      !isLoading &&
      ipAddress !== myIP &&
      ipAddress !== myIP2 &&
      ipAddress !== myIP3
    ) {
      showNotAllowed();
    }
  }, [isLoading, ipAddress, myIP, myIP2, myIP3]);

  const showNotAllowed = () => {
    return (
      <Box
        sx={{
          alignItems: "center",
          justifyContent: "center",
          display: "flex",
          flexDirection: "column",
          height: "100vh",
        }}
      >
        <Typography sx={{ fontWeight: 600, color: "#383838", fontSize: 24 }}>
          Aradığınız sayfaya ulaşılamıyor.
        </Typography>
        <Typography
          sx={{
            fontWeight: 600,
            color: "#383838",
            fontSize: 16,
            marginTop: "2rem",
            marginBottom: "2rem",
          }}
        >
          Hata kodu : 403
        </Typography>
        <Logo />
      </Box>
    );
  };

  const routes = useRoutes([
    {
      path: "/",
      element: <StartingPage />,
      index: true,
    },
    {
      path: "/LoginVendor",
      element: <LoginPage />,
    },
    {
      path: "/LoginCrypto",
      element: <LoginCrypto />,
    },
    {
      path: "auth",
      element: <Authpage />,
    },
    {
      path: "authCurrency",
      element: <AuthCrypto />,
    },
    {
      path: "/dash",
      element: <DashboardLayoutCurrencies />,
      children: [
        { element: <Navigate to="/dash/Currencies" />, index: true },
        { path: "Currencies", element: <DashboardMainPage /> },
        { path: "Fiyatlar", element: <PricesMainPage /> },
        { path: "Islemler", element: <TransactionsCrypto /> },
        { path: "FintagMinMaxSetPrice", element: <FintagMinMax /> },
      ],
    },

    sessionStorage.getItem("Mail") === "trnmmtz@gmail.com"
      ? {
          path: "/dashboard",
          element: <DashboardLayout />,
          children: [
            { element: <Navigate to="/dashboard/vendor" />, index: true },
            { path: "vendor", element: <Vendor /> },
            { path: "price", element: <Price /> },
            { path: "transactions", element: <Transactions /> },
            { path: "allendpoints", element: <ListAllEndPoints /> },
            { path: "vendorislemleri", element: <VendorIslemleri /> },
            { path: "commodityislemleri", element: <Commodity /> },
            { path: "providercreate", element: <ProviderCreate /> },
            {
              path: "providerpricehistoryislemleri",
              element: <ProviderPriceHistory />,
            },
            // { path: 'getgraintransactionsbuysell', element: <GetWaitingGrainTransactionsBuySell /> },
            // { path: 'getgraintransactions', element: <GetWaitingGrainForMuhasebe /> },
            { path: "updatevendorbalance", element: <UpdateVendorBalance /> },
            { path: "manuelbuysell", element: <ManuelBuySell /> },
            //{ path: 'hesaplar', element: <GetAccounts /> },
            { path: "adminekle", element: <CreateAdmin /> },
            { path: "getallforsettings", element: <GetAllForSettings /> },
            { path: "finalizedlisteleme", element: <FinalizedListeleme /> },
            { path: "unexpectedlisteleme", element: <UnexpectedListeleme /> },
            { path: "notpositionlisteleme", element: <NotPositionListeleme /> },
            { path: "bankactivities", element: <BankActivities /> },
            { path: "hesaplar", element: <GetAccountsSecond /> },
            { path: "transferler", element: <Transferler /> },
            { path: "transferlerUserId", element: <TransferlerUserId /> },
          ],
        }
      : {
          path: "/dashboard",
          element: <DashboardLayout />,
          children: [
            { element: <Navigate to="/dashboard/vendor" />, index: true },
            { path: "vendor", element: <Vendor /> },
            { path: "price", element: <Price /> },
            { path: "transactions", element: <Transactions /> },
            { path: "allendpoints", element: <ListAllEndPoints /> },
            { path: "vendorislemleri", element: <VendorIslemleri /> },
            { path: "commodityislemleri", element: <Commodity /> },
            { path: "providercreate", element: <ProviderCreate /> },
            {
              path: "providerpricehistoryislemleri",
              element: <ProviderPriceHistory />,
            },
            // { path: 'getgraintransactions', element: <GetWaitingGrainForMuhasebe /> },
            { path: "updatevendorbalance", element: <UpdateVendorBalance /> },
            { path: "manuelbuysell", element: <ManuelBuySell /> },
            //{ path: 'hesaplar', element: <GetAccounts /> },
            { path: "adminekle", element: <CreateAdmin /> },
            { path: "getallforsettings", element: <GetAllForSettings /> },
            { path: "finalizedlisteleme", element: <FinalizedListeleme /> },
            { path: "unexpectedlisteleme", element: <UnexpectedListeleme /> },
            { path: "notpositionlisteleme", element: <NotPositionListeleme /> },
            {
              path: "apptogatewaytransactions",
              element: <ApptoGatewayTransactions />,
            },
            {
              path: "pozisyonlistelemeleri",
              element: <FinalizedNotPositionUnexpected />,
            },
            { path: "adduseremtia", element: <AddUserEmtia /> },
            { path: "comingsoon", element: <ComingSoon /> },
            { path: "bankactivities", element: <BankActivities /> },
            { path: "hesaplar", element: <GetAccountsSecond /> },
            { path: "transferler", element: <Transferler /> },
            { path: "transferlerUserId", element: <TransferlerUserId /> },
          ],
        },
    {
      element: <SimpleLayout />,
      children: [
        { element: <Navigate to="/dashboard/vendor" /> },
        { path: "404", element: <Page404 /> },
        { path: "*", element: <Navigate to="/404" /> },
      ],
    },
    {
      path: "*",
      element: <Navigate to="/404" replace />,
    },
  ]);

  if (
    (isLoading || ipAddress !== myIP) &&
    (isLoading || ipAddress !== myIP2) &&
    (isLoading || ipAddress !== myIP3)
  ) {
    return <Typography>...</Typography>;
  }

  return routes;
}
