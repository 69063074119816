import { Box, Button, Snackbar, Typography } from "@mui/material";
import React from "react";
import FileDownloadIcon from "@mui/icons-material/FileDownload";
import { ExportToCsv } from "export-to-csv";
import MaterialReactTable from "material-react-table";
import CircularProgress from "@mui/material/CircularProgress";
import Backdrop from "@mui/material/Backdrop";
import MuiAlert from "@mui/material/Alert";

function VendorListeleme(props) {
  const [authCode, setAuthCode] = React.useState(
    sessionStorage.getItem("AuthCode")
  );
  const [fetchUrl, setFetchUrl] = React.useState(
    sessionStorage.getItem("FetchUrl")
  );

  const [res, setRes] = React.useState([]);
  const [open, setOpen] = React.useState(false);

  const [alertmessage, setAlertMessage] = React.useState("");
  const [alertStatus, setAlertStatus] = React.useState(false);
  const [openAlert, setOpenAlert] = React.useState(false);

  const Alert = React.forwardRef(function Alert(props, ref) {
    return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
  });

  const handleAlertClick = () => {
    setOpenAlert(true);
  };

  const handleAlertClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }

    setOpenAlert(false);
  };

  const columns = React.useMemo(
    () => [
      {
        accessorKey: "Name",
        header: "Name",
        size: 120,
      },
      {
        accessorKey: "Email",
        header: "Email",
        size: 120,
      },
      {
        accessorKey: "Phone",
        header: "Phone",
        size: 120,
      },

      {
        accessorKey: "AccountNumber",
        header: "AccountNumber",
        size: 120,
      },
      {
        accessorKey: "AccountSuffix",
        header: "AccountSuffix",
        size: 120,
      },
      {
        accessorKey: "Automatic",
        header: "Automatic",
        size: 120,
      },
      {
        accessorKey: "HavaleCode",
        header: "HavaleCode",
        size: 30,
      },

      {
        accessorKey: "IsActive",
        header: "IsActive",
        size: 20,
      },
      {
        accessorKey: "IsDeleted",
        header: "IsDeleted",
        size: 120,
      },
      {
        accessorKey: "CreatedDate",
        header: "CreatedDate",
        size: 30,
      },
      {
        accessorKey: "ModifiedDate",
        header: "ModifiedDate",
        size: 30,
      },
      {
        accessorKey: "AdminNote",
        header: "AdminNote",
        size: 20,
      },
    ],
    []
  );
  const csvOptions = {
    fieldSeparator: ";",
    quoteStrings: '"',
    decimalSeparator: ".",
    showLabels: true,
    useBom: true,
    useKeysAsHeaders: false,
    headers: columns.map((c) => c.header),
    filename: "Vendorlar",
  };

  const csvExporter = new ExportToCsv(csvOptions);

  const handleExportRows = (rows) => {
    csvExporter.generateCsv(rows.map((row) => row.original));
  };

  const handleExportData = () => {
    csvExporter.generateCsv(res);
  };

  async function getAllFetched() {
    setOpen(true);
    var myHeaders = new Headers();
    myHeaders.append("Authorization", authCode);

    var requestOptions = {
      method: "GET",
      headers: myHeaders,
      redirect: "follow",
    };

    const response = await fetch(
      `${fetchUrl}Admin/GetAll?pageNumber=1`,
      requestOptions
    );
    const fetchedData = await response.json();
    const myData = fetchedData.Data;

    var endpointsArr = [];
    for (let i = 0; i < myData.length; i++) {
      endpointsArr.push({
        AccountNumber:
          myData[i].AccountNumber === "" ? "-" : myData[i].AccountNumber,
        AccountSuffix:
          myData[i].AccountSuffix === "" ? "-" : myData[i].AccountSuffix,
        ApiKey: myData[i].ApiKey,
        AdminNote: myData[i].AdminNote === null ? "-" : myData[i].AdminNote,
        Automatic: myData[i].Automatic.toString(),
        CreatedDate: myData[i].CreatedDate,
        Email: myData[i].Email,
        HavaleCode: myData[i].HavaleCode === "" ? "-" : myData[i].HavaleCode,
        Id: myData[i].Id,
        IsActive: myData[i].IsActive.toString(),
        IsCashBalance: myData[i].IsCashBalance.toString(),
        IsDeleted: myData[i].IsDeleted.toString(),
        ModifiedDate: myData[i].ModifiedDate,
        Name: myData[i].Name,
        Phone: myData[i].Phone,
        Secret: myData[i].Secret,
      });
      setRes(endpointsArr);
      setTimeout(() => {
        setOpen(false);
      }, 500);
    }

    setAlertMessage(fetchedData.Message);
    setAlertStatus(fetchedData.Success);
    handleAlertClick();
  }

  React.useEffect(() => {
    getAllFetched();
  }, []);

  return (
    <Box sx={{ marginLeft: "3rem" }}>
      <Typography variant="h4" gutterBottom>
        Vendor Listeleme
      </Typography>

      <Backdrop
        sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
        open={open}
      >
        <CircularProgress color="inherit" />
      </Backdrop>
      <Button
        variant="contained"
        onClick={() => getAllFetched()}
        sx={{ marginBottom: "1rem", marginTop: "1rem" }}
      >
        Listele
      </Button>
      <MaterialReactTable
        columns={columns}
        data={res}
        enableRowSelection
        positionToolbarAlertBanner="bottom"
        renderTopToolbarCustomActions={({ table }) => (
          <Box
            sx={{ display: "flex", gap: "1rem", p: "0.5rem", flexWrap: "wrap" }}
          >
            <Button
              color="primary"
              onClick={handleExportData}
              startIcon={<FileDownloadIcon />}
              variant="outlined"
              size="small"
              sx={{ fontSize: { xs: "10px", sm: "12px", md: "12px" } }}
            >
              Tüm Verileri Dışa Aktar
            </Button>
            <Button
              disabled={table.getPrePaginationRowModel().rows.length === 0}
              onClick={() =>
                handleExportRows(table.getPrePaginationRowModel().rows)
              }
              startIcon={<FileDownloadIcon />}
              variant="outlined"
              size="small"
              sx={{ fontSize: { xs: "10px", sm: "12px", md: "12px" } }}
            >
              Tüm Satırları Dışa Aktar
            </Button>
            <Button
              disabled={table.getRowModel().rows.length === 0}
              onClick={() => handleExportRows(table.getRowModel().rows)}
              startIcon={<FileDownloadIcon />}
              variant="outlined"
              size="small"
              sx={{ fontSize: { xs: "10px", sm: "12px", md: "12px" } }}
            >
              Bu Sayfadaki Satırları Dışa Aktar
            </Button>
            <Button
              disabled={
                !table.getIsSomeRowsSelected() && !table.getIsAllRowsSelected()
              }
              onClick={() => handleExportRows(table.getSelectedRowModel().rows)}
              startIcon={<FileDownloadIcon />}
              variant="outlined"
              size="small"
              sx={{ fontSize: { xs: "10px", sm: "12px", md: "12px" } }}
            >
              Seçilmiş Satırları Dışa Aktar
            </Button>
          </Box>
        )}
      />

      <Snackbar
        open={openAlert}
        onClose={handleAlertClose}
        autoHideDuration={2000}
      >
        <Alert severity={alertStatus ? "success" : "error"}>
          {alertmessage}
        </Alert>
      </Snackbar>
    </Box>
  );
}

export default VendorListeleme;
