import { Box, Typography, TextField, Button } from "@mui/material";
import React from "react";

function VendorCreate(props) {
  const [name, setName] = React.useState("");
  const [email, setEmail] = React.useState("");
  const [phone, setPhone] = React.useState("");
  const [accountNum, setAccountNum] = React.useState("");
  const [accountSuffix, setAccountSuffix] = React.useState("");
  const [havaleCode, setHavaleCode] = React.useState("");
  const [authCode, setAuthCode] = React.useState(
    sessionStorage.getItem("AuthCode")
  );
  const [fetchUrl, setFetchUrl] = React.useState(
    sessionStorage.getItem("FetchUrl")
  );
  const [data, setData] = React.useState([]);
  const [popup, setPopup] = React.useState(false);
  const [message, setMessage] = React.useState("");
  const [durum, setDurum] = React.useState(false);
  React.useEffect(() => {
    const interval = setInterval(() => {
      setMessage("");
    }, 2000);
    return () => clearInterval(interval);
  }, []);

  async function createVendor(
    name,
    email,
    phone,
    accountNum,
    accountSuffix,
    havaleCode
  ) {
    var myHeaders = new Headers();

    myHeaders.append("Authorization", authCode);
    myHeaders.append("Content-Type", "application/json");

    var raw = JSON.stringify({
      Name: name,
      Email: email,
      Phone: phone,
      AccountNumber: accountNum,
      AccountSuffix: accountSuffix,
      HavaleCode: havaleCode,
    });

    var requestOptions = {
      method: "POST",
      headers: myHeaders,
      body: raw,
      redirect: "follow",
    };

    const response = await fetch(
      `${fetchUrl}Admin/CreateVendor`,
      requestOptions
    );
    const fetchedData = await response.json();

    fetchedData.Success === true ? setDurum(true) : setDurum(false);
    setMessage(fetchedData.Message);
  }
  return (
    <Box sx={{ flexShrink: 1, minWidth: "30px" }}>
      <Typography variant="h4" gutterBottom>
        Vendor Oluştur
      </Typography>
      <Box sx={{ display: "flex", direction: "row" }}>
        <Box
          component="form"
          sx={{
            "& > :not(style)": { m: 1, width: "35ch" },
            display: "flex",
            flexDirection: "column",
            gap: 2,
            backgroundColor: "rgba(232, 219, 176, 0.5)",

            borderRadius: "0.5rem",
            justifyContent: "center",
            alignItems: "flex-start",
            padding: "2rem",
            flexShrink: 1,
            minWidth: "30px",
          }}
          noValidate
          autoComplete="off"
        >
          <TextField
            id="standard-required"
            label={"İsim giriniz"}
            defaultValue=""
            variant="standard"
            onChange={(event) => setName(event.target.value)}
            value={name}
          />
          <TextField
            id="standard-required"
            label={"Email giriniz"}
            defaultValue=""
            variant="standard"
            onChange={(event) => setEmail(event.target.value)}
            value={email}
          />
          <TextField
            id="standard-required"
            label={"Telefon Numarası giriniz"}
            defaultValue=""
            variant="standard"
            onChange={(event) => setPhone(event.target.value)}
            value={phone}
            type="number"
          />
          <TextField
            id="standard-required"
            label={"Account number giriniz"}
            defaultValue=""
            variant="standard"
            onChange={(event) => setAccountNum(event.target.value)}
            value={accountNum}
          />
          <TextField
            id="standard-required"
            label={"Account Suffix giriniz"}
            defaultValue=""
            variant="standard"
            onChange={(event) => setAccountSuffix(event.target.value)}
            value={accountSuffix}
          />
          <TextField
            id="standard-required"
            label={"Havale Code giriniz"}
            defaultValue=""
            variant="standard"
            onChange={(event) => setHavaleCode(event.target.value)}
            value={havaleCode}
          />
        </Box>
      </Box>
      <Box sx={{ marginTop: "1rem" }}>
        {name !== "" && email !== "" && phone !== "" ? (
          <Button
            variant="contained"
            onClick={() => [
              createVendor(
                name,
                email,
                phone,
                accountNum,
                accountSuffix,
                havaleCode
              ),
            ]}
          >
            Oluştur
          </Button>
        ) : (
          <Button variant="contained" disabled>
            Oluştur
          </Button>
        )}

        {message.length > 0 && (
          <Typography
            sx={{
              color: durum ? "green" : "red",
              fontWeight: "bold",
              marginTop: "1rem",
            }}
          >
            {message}
          </Typography>
        )}
      </Box>
    </Box>
  );
}

export default VendorCreate;
