import { Box, ImageListItem, Typography } from "@mui/material";
import React, { useState } from "react";
import { useEffect } from "react";

const LastPrice = () => {
  const [goldPrice, setGoldPrice] = useState();
  const [silverPrice, setSilverPrice] = useState();
  const [platinPrice, setPlatinPrice] = useState();

  const [goldVendorPrice, setGoldVendorPrice] = useState();
  const [silverVendorPrice, setSilverVendorPrice] = useState();
  const [platinVendorPrice, setPlatinVendorPrice] = useState();

  const [dataX, setDataX] = useState([]);

  const [authCode, setAuthCode] = useState(sessionStorage.getItem("AuthCode"));
  const [fetchUrl, setFetchUrl] = useState(sessionStorage.getItem("FetchUrl"));

  useEffect(() => {
    fetchData();
    const interval = setInterval(() => {
      fetchData();
    }, 60000);
    return () => clearInterval(interval);
  }, []);

  async function fetchData() {
    var myHeaders = new Headers();
    myHeaders.append("accept", "*/*");
    myHeaders.append("Authorization", authCode);
    myHeaders.append("Content-Type", "application/json");

    var raw = JSON.stringify({
      api_key: "string",
      gram_amount: 1,
    });

    var requestOptions = {
      method: "POST",
      headers: myHeaders,
      body: raw,
      redirect: "follow",
    };

    fetch(`${fetchUrl}Vendors/get_current_price`, requestOptions)
      .then((response) => response.json())
      .then((result) => {
        setGoldPrice(result.buy);
        setGoldVendorPrice(result.sell);

        setSilverPrice(result.buy_silver);
        setSilverVendorPrice(result.sell_silver);

        setPlatinPrice(result.buy_platin);
        setPlatinVendorPrice(result.sell_platin);
      })
      .catch((error) => console.log("error", error));
  }

  const data = [
    [
      "https://cdn-icons-png.flaticon.com/512/1473/1473504.png",
      "Altın",
      "Vendor Bozdurma Kuru",
      `${goldVendorPrice}₺`,
      "Vendor'a Satış Kuru",
      `${goldPrice}₺`,
    ],
    [
      "https://cdn-icons-png.flaticon.com/512/1473/1473430.png",
      "Gümüş",
      "Vendor Bozdurma Kuru",
      `${silverVendorPrice}₺`,
      "Vendor'a Satış Kuru",
      `${silverPrice}₺`,
    ],
    [
      "https://cdn-icons-png.flaticon.com/512/1473/1473578.png",
      "Platin",
      "Vendor Bozdurma Kuru",
      `${platinVendorPrice}₺`,
      "Vendor'a Satış Kuru",
      `${platinPrice}₺`,
    ],
  ];

  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        backgroundColor: "white",
        width: "100%",
        borderRadius: "5px",
        padding: "1rem",
        gap: "1rem",
        marginTop: "15px",
      }}
    >
      <Typography
        sx={{
          marginLeft: "3rem",
          fontSize: 16,
          textAlign: "center",
          color: "#B78103",
          fontWeight: "bold",
          textDecoration: "underline",
        }}
      >
        Vendor Fiyatları
      </Typography>

      {data.map((item) => {
        return (
          <Box
            sx={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
              gap: "2rem",
            }}
          >
            <Box
              sx={{
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
                gap: "0.7rem",
              }}
            >
              <ImageListItem
                sx={{
                  display: "absolute",
                  width: "50px",
                  height: "30px",
                  marginRight: "5px",
                }}
              >
                <img src={item[0]} alt="img" />
              </ImageListItem>

              <Typography
                sx={{
                  fontWeight: "bold",
                  color: "#2F4365",
                  fontSize: "15px",
                  marginBottom: "2px",
                }}
              >
                {item[1]}
              </Typography>
            </Box>
            <Box
              sx={{
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",
                gap: "2rem",
              }}
            >
              <Box>
                <Typography
                  sx={{
                    fontSize: "13px",
                    textAlign: "center",
                    color: "#B78103",
                    fontWeight: "bold",
                    width: "120px",
                  }}
                >
                  {item[2]}
                </Typography>
                <Typography
                  sx={{
                    fontSize: "12px",
                    color: "#2F4365",
                    textAlign: "center",
                    fontWeight: "bold",
                  }}
                >
                  {item[3]}
                </Typography>
              </Box>
              <Box>
                <Typography
                  sx={{
                    fontSize: "13px",
                    color: "#B78103",
                    fontWeight: "bold",
                    textAlign: "center",
                    width: "120px",
                  }}
                >
                  {item[4]}
                </Typography>
                <Typography
                  sx={{
                    fontSize: "12px",
                    color: "#2F4365",
                    textAlign: "center",
                    fontWeight: "bold",
                  }}
                >
                  {item[5]}
                </Typography>
              </Box>
            </Box>
          </Box>
        );
      })}
    </Box>
  );
};

export default LastPrice;
