import * as React from "react";
import { Box, Button, Modal, Typography, Snackbar } from "@mui/material";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import FileDownloadIcon from "@mui/icons-material/FileDownload";
import { ExportToCsv } from "export-to-csv";
import MaterialReactTable from "material-react-table";
import CircularProgress from "@mui/material/CircularProgress";
import Backdrop from "@mui/material/Backdrop";
import MuiAlert from "@mui/material/Alert";

export default function NotPositionListeleme() {
  const [range, setRange] = React.useState([]);
  const [vendors, setVendors] = React.useState([]);
  const [status, setStatus] = React.useState(false);
  const [authCode, setAuthCode] = React.useState(
    sessionStorage.getItem("AuthCode")
  );
  const [fetchUrl, setFetchUrl] = React.useState(
    sessionStorage.getItem("FetchUrl")
  );

  const [closeState, setCloseState] = React.useState(false);
  const [closeStatus, setCloseStatus] = React.useState(false);
  const [closeId, setCloseId] = React.useState("");
  const [choosenVendor, setChoosenVendor] = React.useState("");
  const [open, setOpen] = React.useState(false);

  const [openM, setOpenM] = React.useState(false);
  const handleOpenM = () => setOpenM(true);
  const handleCloseM = () => setOpenM(false);

  const [alertmessage, setAlertMessage] = React.useState("");
  const [alertStatus, setAlertStatus] = React.useState(false);
  const [openAlert, setOpenAlert] = React.useState(false);

  const Alert = React.forwardRef(function Alert(props, ref) {
    return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
  });

  const handleAlertClick = () => {
    setOpenAlert(true);
  };

  const handleAlertClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }

    setOpenAlert(false);
  };

  React.useEffect(() => {
    fetchedData();
  }, [choosenVendor, closeState]);

  async function fetchedDataRange(choosenVendor) {
    setOpen(true);
    var myHeaders = new Headers();
    myHeaders.append("Authorization", authCode);

    var requestOptions = {
      method: "GET",
      headers: myHeaders,
      redirect: "follow",
    };

    const response = await fetch(
      `${fetchUrl}Admin/GetVendorNotPositionsByVendorId?vendorId=${choosenVendor}`,
      requestOptions
    );
    const fetchedData = await response.json();
    const myData = fetchedData.Data;
    var endpointsArr = [];

    if (myData.length < 0) {
      setRange(null);
    } else {
      for (let i = 0; i < myData.length; i++) {
        endpointsArr.push({
          IsBuy:
            myData[i].IsBuy == true ? (
              <Box
                sx={{
                  backgroundColor: "red",
                  borderRadius: "20px",
                  alignItems: "center",
                  justifyContent: "center",
                  display: "flex",
                }}
              >
                <Typography sx={{ color: "white" }}>Alış</Typography>{" "}
              </Box>
            ) : (
              <Box
                sx={{
                  backgroundColor: "blue",
                  borderRadius: "20px",
                  alignItems: "center",
                  justifyContent: "center",
                  display: "flex",
                }}
              >
                {" "}
                <Typography sx={{ color: "white" }}>Satış</Typography>{" "}
              </Box>
            ),
          TransactionIds: myData[i].TransactionIds,
          GramAmount: myData[i].GramAmount,
          TlAmount: myData[i].TlAmount,
          CommoditySuffix: myData[i].CommoditySuffix,
          Rate: myData[i].Rate,
          TransactionCount: myData[i].TransactionCount,
          VendorId: myData[i].VendorId,
          MarketCommudityPrice: myData[i].MarketCommudityPrice,
          GoldtagCommudityFromKtPrice: myData[i].GoldtagCommudityFromKtPrice,
          VendorFromGoldtagPrice: myData[i].VendorFromGoldtagPrice,
          KtExecutionReferenceId: myData[i].KtExecutionReferenceId,
          Comment: myData[i].Comment === "" ? "Boş*" : myData[i].Comment,
          IsSellSucceed: myData[i].IsSellSucceed == true ? "true" : "false",
          Id: myData[i].Id,
          IsActive: myData[i].IsActive == true ? "true" : "false",
          IsDeleted: myData[i].IsDeleted == true ? "true" : "false",
          CreatedDate: myData[i].CreatedDate,
          ModifiedDate: myData[i].ModifiedDate,
          AdminNote: myData[i].AdminNote,
        });
      }
      setRange(endpointsArr);
    }

    setTimeout(() => {
      setOpen(false);
    }, 1000);
    setAlertMessage(fetchedData.Message);
    setAlertStatus(fetchedData.Success);
    handleAlertClick();
  }

  async function fetchedData() {
    var myHeaders = new Headers();
    myHeaders.append("Authorization", authCode);

    var requestOptions = {
      method: "GET",
      headers: myHeaders,
      redirect: "follow",
    };

    const response = await fetch(
      `${fetchUrl}Admin/GetAll?pageNumber=1`,
      requestOptions
    );
    const fetchedData = await response.json();
    const myData = fetchedData.Data;
    setVendors(myData);

    setTimeout(() => {
      setOpen(false);
    }, 1000);
  }

  async function closePosition(closeId) {
    setOpen(true);
    var myHeaders = new Headers();
    myHeaders.append("Authorization", authCode);

    var requestOptions = {
      method: "POST",
      headers: myHeaders,
      redirect: "follow",
    };

    const response = await fetch(
      `${fetchUrl}Admin/ClosePosition?positionId=${closeId}&vendorId=${choosenVendor}`,
      requestOptions
    );
    const fetchedData = await response.json();
    const myData = fetchedData.Data;
    setTimeout(() => {
      setOpen(false);
    }, 1000);

    setCloseState(true);
  }

  const handleChooseVendor = (name) => {
    setChoosenVendor(name.target.value);
    setStatus(false);
  };

  const columns = React.useMemo(
    () => [
      {
        accessorKey: "IsBuy",
        header: "Alış/Satış",
        size: 30,
      },
      {
        accessorKey: "TransactionIds",
        header: "Transaction Ids",
        size: 30,
      },
      {
        accessorKey: "GramAmount",
        header: "Gram Amount",
        size: 30,
      },
      {
        accessorKey: "Tl Amount",
        header: "Tl Amount",
        size: 30,
      },
      {
        accessorKey: "CommoditySuffix",
        header: "Commodity Suffix",
        size: 30,
      },
      {
        accessorKey: "TransactionCount",
        header: "Transaction Count",
        size: 30,
      },

      {
        accessorKey: "VendorId",
        header: "Vendor Id",
        size: 30,
      },
      {
        accessorKey: "MarketCommudityPrice",
        header: "Market Commudity Price",
        size: 30,
      },
      {
        accessorKey: "GoldtagCommudityFromKtPrice",
        header: "Goldtag Commudity From Kt Price",
        size: 30,
      },
      {
        accessorKey: "VendorFromGoldtagPrice",
        header: "Vendor From Goldtag Price",
        size: 30,
      },
      {
        accessorKey: "KtExecutionReferenceId",
        header: "KtExecutionReferenceId",
        size: 30,
      },
      {
        accessorKey: "Comment",
        header: "Comment",
        size: 30,
      },
      {
        accessorKey: "IsSellSucceed",
        header: "Is Sell Succeed",
        size: 30,
      },
      {
        accessorKey: "Id",
        header: "Id",
        size: 30,
      },
      {
        accessorKey: "IsActive",
        header: "Is Active",
        size: 30,
      },
      {
        accessorKey: "IsDeleted",
        header: "Is Deleted",
        size: 30,
      },
      {
        accessorKey: "CreatedDate",
        header: "Created Date",
        size: 30,
      },
      {
        accessorKey: "ModifiedDate",
        header: "Modified Date",
        size: 30,
      },
      {
        accessorKey: "AdminNote",
        header: "Admin Note",
        size: 30,
      },
    ],
    []
  );

  const csvOptions = {
    fieldSeparator: ";",
    quoteStrings: '"',
    decimalSeparator: ".",
    showLabels: true,
    useBom: true,
    useKeysAsHeaders: false,
    headers: columns.map((c) => c.header),
    filename: "Transactions",
  };

  const csvExporter = new ExportToCsv(csvOptions);

  const handleExportRows = (rows) => {
    csvExporter.generateCsv(rows.map((row) => row.original));
  };

  const handleExportData = () => {
    csvExporter.generateCsv(range);
  };

  return (
    <Box sx={{ marginLeft: "3rem" }}>
      <Typography variant="h4" gutterBottom>
        Not Positions İşlemler
      </Typography>

      {closeStatus && (
        <Modal
          open={openM}
          onClose={handleCloseM}
          aria-labelledby="modal-modal-title"
          aria-describedby="modal-modal-description"
        >
          <Box
            sx={{
              position: "absolute",
              top: "50%",
              left: "50%",
              transform: "translate(-50%, -50%)",
              width: 470,
              bgcolor: "background.paper",
              border: "2px solid #ffb703",
              boxShadow: 24,
              p: 4,
              borderRadius: "2rem",
            }}
          >
            <Typography id="modal-modal-title" variant="h6" component="h2">
              Pozisyonu kapatmak istediğinize emin misiniz ?
            </Typography>
            <Box
              style={{
                flexDirection: "row",
                display: "flex",
                gap: "2rem",
                justifyContent: "center",
                marginTop: "1rem",
              }}
            >
              <Button
                variant="contained"
                color="success"
                sx={{ color: "white", marginRight: "1rem" }}
                onClick={() => closePosition(closeId)}
              >
                Evet
              </Button>
              <Button
                variant="contained"
                color="error"
                sx={{ color: "white" }}
                onClick={() => setCloseStatus(false)}
              >
                Hayır
              </Button>
            </Box>
          </Box>
        </Modal>
      )}

      <Backdrop
        sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
        open={open}
      >
        <CircularProgress color="inherit" />
      </Backdrop>
      <Box
        sx={{
          gap: "5rem",
          alignItems: "center",
          display: "flex",
          marginBottom: "2rem",
          marginTop: "2rem",
        }}
      >
        <FormControl sx={{ width: "160px" }}>
          <InputLabel id="demo-simple-select-label">Vendor Seçiniz</InputLabel>
          <Select
            labelId="demo-simple-select-label"
            id="demo-simple-select"
            value={choosenVendor}
            label="Vendor Seçiniz"
            onChange={handleChooseVendor}
          >
            {vendors.map((vendors) => (
              <MenuItem key={vendors.Id} value={vendors.Id}>
                {vendors.Name}
              </MenuItem>
            ))}
          </Select>
        </FormControl>

        {choosenVendor != "" ? (
          <Button
            variant="contained"
            onClick={() => [fetchedDataRange(choosenVendor), setStatus(true)]}
          >
            Listele
          </Button>
        ) : (
          <Button variant="contained" disabled>
            Listele
          </Button>
        )}
      </Box>

      <MaterialReactTable
        columns={columns}
        data={range}
        enableRowSelection
        enableRowActions
        positionToolbarAlertBanner="bottom"
        renderTopToolbarCustomActions={({ table }) => (
          <Box
            sx={{ display: "flex", gap: "1rem", p: "0.5rem", flexWrap: "wrap" }}
          >
            <Button
              color="primary"
              onClick={handleExportData}
              startIcon={<FileDownloadIcon />}
              variant="outlined"
              size="small"
              sx={{ fontSize: { xs: "10px", sm: "12px", md: "12px" } }}
            >
              Tüm Verileri Dışa Aktar
            </Button>
            <Button
              disabled={table.getPrePaginationRowModel().rows.length === 0}
              onClick={() =>
                handleExportRows(table.getPrePaginationRowModel().rows)
              }
              startIcon={<FileDownloadIcon />}
              variant="outlined"
              size="small"
              sx={{ fontSize: { xs: "10px", sm: "12px", md: "12px" } }}
            >
              Tüm Satırları Dışa Aktar
            </Button>
            <Button
              disabled={table.getRowModel().rows.length === 0}
              onClick={() => handleExportRows(table.getRowModel().rows)}
              startIcon={<FileDownloadIcon />}
              variant="outlined"
              size="small"
              sx={{ fontSize: { xs: "10px", sm: "12px", md: "12px" } }}
            >
              Bu Sayfadaki Satırları Dışa Aktar
            </Button>
            <Button
              disabled={
                !table.getIsSomeRowsSelected() && !table.getIsAllRowsSelected()
              }
              onClick={() => handleExportRows(table.getSelectedRowModel().rows)}
              startIcon={<FileDownloadIcon />}
              variant="outlined"
              size="small"
              sx={{ fontSize: { xs: "10px", sm: "12px", md: "12px" } }}
            >
              Seçilmiş Satırları Dışa Aktar
            </Button>
          </Box>
        )}
        renderRowActions={({ row }) => [
          <MenuItem
            sx={{
              bgcolor: "#c20000",
              borderRadius: "0.5rem",
              color: "white",
              "&:hover": {
                backgroundColor: "#fa0000",
              },
            }}
            key="kapat"
            onClick={() => [
              setCloseStatus(true),
              setCloseId(row.original.Id),
              handleOpenM(),
            ]}
          >
            Manuel Pozisyon Kapatma
          </MenuItem>,
        ]}
      />

      <Snackbar
        open={openAlert}
        onClose={handleAlertClose}
        autoHideDuration={2000}
      >
        <Alert severity={alertStatus ? "success" : "error"}>
          {alertmessage}
        </Alert>
      </Snackbar>
    </Box>
  );
}
