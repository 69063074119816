import { Box } from '@mui/material';
import React from 'react'
import Typewriter from "typewriter-effect";

const Typewritter = () => {
  return (
<>

    <Box sx={{
        fontFamily: "sans-serif",
        fontWeight:"bold",
        fontStyle:"italic",
        fontSize:"16px",
        textAlign: "justify",
        display:"flex",
        justifyContent:"center",
        alignItems:"center",
        color:"#E49800",
        marginRight:"2rem",
        marginLeft:"2rem",
    }}>
    
      <Typewriter
        options={{
           loop: true,
        }}
        onInit={(typewriter)=> {
            typewriter
            .typeString("Goldtag ile kolayca 7/24 altın alıp gönderebilirsiniz. Düğün, doğum günü, nişan, babyshower vs gibi özel etkinlikler oluşturup arkadaşlarınız ile paylaşabilirsiniz. Ayrıca bağış kampanyalarına da destek olabilirsiniz.")
            .pauseFor(1000)
            .deleteAll()
            .typeString("Goldtag, bankalar ile entegreli, güvenli bir şekilde müşterilerine altın alım ve satım imkanı sağlar. Goldtag müşterileri bu altınları dilerse bir etkiliğe, arkadaşına transfer edebilir veya birikim de yapabilir . Tamamen müşteri odaklı olarak çalışan Goldtag, müşterilerine hızlı altın transfer deneyimi sunar. Üstelik tüm bunları yaparken sosyal mesafeye dikkat eder :)")
            .pauseFor(1000)
            .deleteAll()
            .start();
        }}
       />
    </Box>

</>


    )
}

export default Typewritter