import { format, getTime, formatDistanceToNow } from "date-fns";
import trLocale from "date-fns/locale/tr";

// ----------------------------------------------------------------------

export function fDate(date, newFormat) {
  const fm = newFormat || "dd MMM yyyy";

  return date ? format(new Date(date), fm) : "";
}

export function fDateTime(date, newFormat) {
  const fm = newFormat || "dd MMM yyyy p";

  return date ? format(new Date(date), fm) : "";
}

export function fTimestamp(date) {
  return date ? getTime(new Date(date)) : "";
}

export function fToNow(date) {
  return date
    ? formatDistanceToNow(new Date(date), {
        addSuffix: true,
      })
    : "";
}

export function fDateTimeTurkish(date, newFormat) {
  const fm = newFormat || "dd MMMM yyyy HH:mm:ss";

  return date ? format(new Date(date), fm, { locale: trLocale }) : "";
}
